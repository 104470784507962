import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { RowData } from '@tanstack/table-core/src/types'
import { FormattedMessage, useIntl } from 'react-intl'
import { getRouterParams } from '@utils'
import { UITableData, useTableUrl } from '../../../../hooks'
import { UIChevronLeftIcon, UIChevronRightIcon } from '../../../../icons'
import clsx from 'clsx'
import intl from '../intl'

export type UITablePaginationNavigatorProps<TData extends RowData> = {
    table: UITableData<TData>
}

type PaginationItemProps = PropsWithChildren & {
    ariaLabel: string
    variant: 'prevNext' | 'current' | 'page'
    url: URL
}

const paginationItemVariant = {
    prevNext: 'ec-uppercase',
    current: clsx(
        'ec-cursor-pointer ec-bg-[#5e5e5d] ec-text-white',
        'hover:ec-text-white visited:ec-text-white hover:ec-no-underline'
    ),
    page: 'ec-text-gray-darker hover:ec-text-gray-darker visited:ec-text-gray-darker'
}

const PaginationItem = (
    { ariaLabel, variant, url, children }: PaginationItemProps
) => {
    return (
        <li>
            <Link to={getRouterParams(url)} aria-label={ariaLabel} className={clsx(
                'ec-inline-flex ec-justify-center ec-items-center ec-text-center ec-leading-7 ec-px-1 ec-min-w-7',
                paginationItemVariant[variant]
            )}>
                {children}
            </Link>
        </li>
    )
}

export const UITablePaginationNavigator = <TData extends RowData>(
    { table }: UITablePaginationNavigatorProps<TData>
) => {
    const { getPageUrl } = useTableUrl()
    const { formatMessage } = useIntl()

    if (table.getPageCount() <= 1) return null
    const { pageIndex } = table.getState().pagination

    return (
        <nav className="" role="navigation"
             aria-label={formatMessage(intl.pagination.label)}>
            <ul className="ec-flex">
                {table.getCanPreviousPage() &&
                    <PaginationItem variant="prevNext"
                                    url={getPageUrl(pageIndex - 1)}
                                    ariaLabel={formatMessage(intl.pagination.prevLabel)}>
                        <UIChevronLeftIcon size="small"/>
                        <FormattedMessage {...intl.pagination.prev}/>
                    </PaginationItem>
                }
                {table.pages.map(page =>
                    <PaginationItem url={getPageUrl(page)}
                                    variant={page === pageIndex ? 'current' : 'page'}
                                    ariaLabel={formatMessage(intl.pagination.currentLabel)}>
                        {page + 1}
                    </PaginationItem>
                )}
                {table.getCanNextPage() &&
                    <PaginationItem variant="prevNext"
                                    url={getPageUrl(pageIndex + 1)}
                                    ariaLabel={formatMessage(intl.pagination.prevLabel)}>
                        <FormattedMessage {...intl.pagination.next}/>
                        <UIChevronRightIcon size="small"/>
                    </PaginationItem>
                }
            </ul>
        </nav>
    )
}
