import React from 'react'
import { useWingmanView } from '../../hooks'
import { FormattedMessage } from 'react-intl'
import { Section } from '@customerPortal/components'
import intl from './intl'

export const Description = () => {
    const { query } = useWingmanView()

    return (
        <Section>
            <Section.Item title={<FormattedMessage {...intl.descriptionTitle}/>}>
                <span className="ec-whitespace-pre-line ec-text-sm">
                {query.data?.wingman?.description}
            </span>
            </Section.Item>
        </Section>
    )
}
