
import {
    getAddToCartProducts,
    getEventContext,
    getListData,
    getProduct,
    getProducts,
    getProviderMeta
} from '../../models'
import {
    AnalyticsEventContext,
    AnalyticsTrackContext,
    AnalyticsMetadata,
    ShareCartEventMeta
} from '../../types'
import { useAnalytics as useWatscoAnalytics } from '@watsco/analytics-react'
import { DEFAULT_CURRENCY } from '@pricing/config'
import { useYup } from '@yup/context'
import {useAnalyticsContext} from '../../context'

export const useAnalytics = () => {
    const { context } = useAnalyticsContext() || {}
    const { yup } = useYup()

    if (!context) {
        console.warn('useAnalytics must be used within the AnalyticsProvider')
    }

    const getCombinedContext = <T = AnalyticsMetadata>
    (eventContext?: AnalyticsEventContext<T>): AnalyticsTrackContext<T> => (
        { ...context, ...(eventContext || {}) } as AnalyticsTrackContext<T>
    )

    const {
        recordProductClicked,
        recordProductListView,
        recordViewMiniCart,
        recordAddToCart,
        recordRemoveFromCart,
        customEvent,
        recordTypeaheadClicked,
        recordProductSearch
    } = useWatscoAnalytics()

    const trackProductClick = async (eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)

        recordProductClicked({
            ...getProduct(trackContext),
            ...getListData(trackContext),
            eventContext: getEventContext(trackContext)
        })
    }

    const trackProductDrawerOpen = async (eventContext?: AnalyticsEventContext) => {
        return trackElementClick('flyoutClick', eventContext)
    }

    const trackContactDrawerOpen = async (eventContext?: AnalyticsEventContext) => {
        return trackElementClick('contactFlyoutClick', eventContext)
    }

    const trackElementClick = async (eventName: string, eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)

        customEvent(eventName, {
            event: eventName,
            eventContext: getEventContext(trackContext),
            ecommerce: {
                click: {
                    actionField: { type: trackContext.area }
                }
            }
        })
    }

    const trackProductListView = async (eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)

        recordProductListView({
            ...getListData(trackContext),
            eventContext: getEventContext(trackContext),
            products: getProducts(trackContext)
        })
    }

    const trackAddToCart = async (eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)
        const products = getAddToCartProducts(trackContext)

        recordAddToCart({
            currency: localStorage.getItem('currency') ?? DEFAULT_CURRENCY,
            cart_id: trackContext.cartId,
            products,
            eventContext: getEventContext(trackContext),
            providerMeta: getProviderMeta(products)
        })
    }

    const trackPartsListSearch = async (eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)

        customEvent('partsListSearch', {
            event: 'partsListSearch',
            eventContext: getEventContext(trackContext),
            'ecommerce': {
                'click': {
                    'actionField': trackContext.metadata
                }
            }
        })
    }

    const trackViewMiniCart = async (eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)

        recordViewMiniCart({
            cart_id: trackContext.cartId,
            products: getProducts(trackContext),
            ...getListData(trackContext),
            eventContext: getEventContext(trackContext),
            providerMeta: getProviderMeta()
        })
    }

    const trackRemoveFromCart = async (eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)
        const products = getAddToCartProducts(trackContext)

        recordRemoveFromCart({
            cart_id: trackContext.cartId,
            products,
            eventContext: getEventContext(trackContext),
            providerMeta: getProviderMeta()
        })
    }

    const trackSavedCartSave = async (eventContext?: AnalyticsEventContext) => {
        const trackContext = getCombinedContext(eventContext)

        customEvent('savedCartSaveClick', {
            event: 'savedCartSaveClick',
            eventContext: getEventContext(trackContext),
            ecommerce: {
                click: {
                    actionField: { area: trackContext.area }
                }
            }
        })
    }

    const trackSavedCartShare = async (eventContext?: AnalyticsEventContext<ShareCartEventMeta>) => {
        const trackContext = getCombinedContext(eventContext)

        const methods = trackContext.metadata?.contacts?.map(contact =>
            yup.string().email().isValidSync(contact) ? 'email' : 'phone'
        ) || []

        customEvent('savedCartShareClick', {
            event: 'savedCartShareClick',
            eventContext: getEventContext(trackContext),
            ecommerce: {
                click: {
                    actionField: { area: trackContext.area },
                    shareMethods: [...new Set(methods)]
                }
            }
        })
    }

    const trackQuerySuggestionClick = async ({ typeaheadEvent, originalQuery, querySuggestion }) => {
        recordTypeaheadClicked({
            typeahead_event: typeaheadEvent,
            query_entered: originalQuery,
            query_selected: querySuggestion,
            queryId: localStorage.getItem('queryID')
        })
    }

    const trackQuerySearch = async ({ query }) => {
        const index = localStorage.getItem('indexUsed')
        recordProductSearch(
            query,
            index
        )
    }

    return {
        trackProductClick,
        trackProductDrawerOpen,
        trackContactDrawerOpen,
        trackProductListView,
        trackPartsListSearch,
        trackRemoveFromCart,
        trackViewMiniCart,
        trackAddToCart,
        trackSavedCartSave,
        trackSavedCartShare,
        trackQuerySuggestionClick,
        trackQuerySearch,
    }
}
