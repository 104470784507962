import React, { useMemo, PropsWithChildren, ReactNode } from 'react'
import { UILoader, UIModal, UITabs } from '@ui/components'
import { OrderTemplateDropdown } from '@orderTemplate/components'
import { BranchInventory, BranchInventoryList } from '@inventory/components'
import { useAnalytics } from '@analytics/hooks'
import { Pricing } from '@pricing/components'
import { Assistance } from '@branch/components'
import { FormattedMessage } from 'react-intl'
import intl from './intl'
import { Product } from '../../types'
import {
    ProductBadges,
    ProductBrand,
    ProductImage,
    ProductName,
    ProductNumbers,
    ProductSpecification
} from '../Product'
import { CatalogProvider } from '../../context'
import { AddToCartQty } from '../AddToCart'
import { useModal } from '@ui/hooks'

export type ProductDrawerProps = {
    product?: Product | null
    loading?: boolean
    title?: ReactNode
    onOpen?: (args: { product: Product }) => void
    onClose?: (args: { product: Product }) => void
    content?: {
        top?: ReactNode
        bottom?: ReactNode
        action?: ReactNode
    }
}

export const ProductDrawer = (
    {
        product,
        title,
        loading = false,
        content,
        children,
        onOpen,
        onClose
    }: PropsWithChildren<ProductDrawerProps>
) => {
    const { trackProductDrawerOpen } = useAnalytics()
    const { open, handleOpen, handleClose } = useModal()

    const tabs = useMemo(() => ([
        {
            key: 'description',
            title: <FormattedMessage {...intl.description}/>,
            panel: product && <div dangerouslySetInnerHTML={{ __html: product.description.html }}/>
        },
        {
            key: 'specification',
            title: <FormattedMessage {...intl.specification}/>,
            panel: product && <ProductSpecification product={product}/>
        }
    ]), [product?.pim_id])

    const handleDrawerOpen = () => {
        handleOpen()
        onOpen && onOpen({ product })
        void trackProductDrawerOpen({ product })
    }

    const handleDrawerClose = () => {
        handleClose()
        onClose && onClose({ product })
    }

    return (
        <div>
            <button onClick={handleDrawerOpen}>
                {children}
            </button>
            <UIModal open={open}
                     onClose={handleDrawerClose}
                     title={title}
                     slide>
                <section className="-ec-mb-4">
                    <div className="ec-flex ec-flex-col ec-gap-4 ec-w-full">
                        <UILoader show={loading} size="large"/>
                        {!loading && product && <>
                            <CatalogProvider product={product}>
                                {content?.top}
                                <div className="ec-flex ec-justify-center">
                                    <ProductImage product={product}/>
                                </div>
                                <div>
                                    <ProductBrand product={product}/>
                                    <ProductName product={product} size="large"/>
                                    <ProductBadges product={product} space="small"/>
                                </div>
                                <ProductNumbers product={product}/>
                                <Pricing product={product}/>
                                <BranchInventory product={product}/>
                                {content?.action ||
                                    <AddToCartQty product={product}
                                                  size="large"
                                                  onAddToCart={handleDrawerClose}/>
                                }
                                <OrderTemplateDropdown product={product} width="full"/>
                                <BranchInventoryList product={product}/>
                                <UIModal.FullWidthSection>
                                    <UITabs accordion tabs={tabs}/>
                                </UIModal.FullWidthSection>
                                {content?.bottom}
                            </CatalogProvider>
                        </>}
                        {!loading && !product &&
                            <Assistance/>
                        }
                    </div>
                </section>
            </UIModal>
        </div>
    )
}
