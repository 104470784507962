import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../../types'
import clsx from 'clsx'

export const UITableBodyRow = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'tr'>>
) => (
    <tr className={clsx(
        'ec-block ec-py-3 ec-border-t first:ec-border-none ec-border-gray-light lg:ec-table-row ec-bg-white',
        { 'lg:ec-cursor-pointer lg:hover:ec-bg-gray-lightest': !!attributes.onClick }
    )} {...attributes}>
        {children}
    </tr>
)
