import React, {memo} from "react";
import {BranchMap} from "./BranchMap";
import {useWindowSize} from "@ui/hooks";
import {BranchMapMobile } from './BranchMap/BranchMapMobile'
import {isPropsAreEqual} from "../../../../../utils";
import {MapFindSkeleton} from "../../../../InventoryFinder/components/Branches/Skeleton/MapFindSkeleton";
import {useInventoryFinderState} from "../../../../InventoryFinder";

export const GoogleMap = memo(() => {
    const {currentBranch, branches, isBranchesLoading, isLoadingData} = useInventoryFinderState();
    const {isMobile} = useWindowSize();

    if (isBranchesLoading || isLoadingData) {
        return (
            <div className={'ec-h-full'}>
                <MapFindSkeleton/>
            </div>
        )
    }

    if (isMobile) {
        return (
            <div className={'ec-h-full'}>
                <BranchMapMobile/>
            </div>
        )
    }

    if (branches.length === 0 || !currentBranch) return null;

    return (
        <div className={'ec-h-full'}>
            <BranchMap/>
        </div>
    )
}, isPropsAreEqual);

GoogleMap.whyDidYouRender = true
