import React, {memo, useMemo} from 'react'
import { Map } from '@map/components'
import { Branch } from '@branch/types'
import {isPropsAreEqual} from "../../../../../utils";
import {useGoogleMapsState, useInventoryFinderState} from '@inventoryFinder/state';
import {BranchMarker} from "../../../../InventoryFinder/components/Branches/Map/BranchMarker";

export const BranchMap = memo(() => {

    const {
        onMapIdle,
        mapContainerRef,
        currentLocation,
        isGeoLocationEnabled
    } = useGoogleMapsState()

    const {
        branches,
        handleMapLocationClick
    } = useInventoryFinderState()

    const handleMarkerClick = (branch: Branch) => () => handleMapLocationClick(branch)

    const isCurrentLocationUnique = useMemo(() => {
        if (!currentLocation) return false;

        return !branches.some(branch =>
            branch.latitude === currentLocation.lat &&
            branch.longitude === currentLocation.lng
        );
    }, [currentLocation, branches, isGeoLocationEnabled]);

    const allMarkers = useMemo(() => {
        const branchMarkers = branches.map(branch => ({
            id: branch.branch_id,
            title: branch.branch_name,
            position: { lat: branch.latitude, lng: branch.longitude },
            onMarkerClick: handleMarkerClick(branch),
            infoWindow: <BranchMarker branch={branch}/>,
            pinProps: {
                background: '#cb0015',
                borderColor: '#ffffff',
                glyphColor: '#ffffff',
                scale: 1.2
            }
        }));

        if (isCurrentLocationUnique && currentLocation) {
            return [
                ...branchMarkers,
                {
                    id: 'current-location',
                    title: 'Current Location',
                    position: { lat: currentLocation.lat, lng: currentLocation.lng },
                }
            ];
        }

        return branchMarkers;
    }, [branches, currentLocation, isCurrentLocationUnique, handleMarkerClick]);

    return (
        <div className={'ec-flex ec-flex-col ec-gap-4 ec-h-full'}>
            <div className={'ec-w-full ec-h-full'} ref={mapContainerRef}>
                <Map defaultCenter={ {
                    lat: 35.5975,
                    lng: -82.5461,
                }}
                     defaultZoom={10}
                     onIdle={onMapIdle}
                     markers={allMarkers}
                />
            </div>
        </div>
    )
}, isPropsAreEqual);

BranchMap.whyDidYouRender = true
