import React from 'react'
import { UIInput, UIInputList, UIInputListColumn, UIPanel } from '@ui/components'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { PreferencesFormData, usePreferencesState } from '../../state'
import { Section } from '@customerPortal/components'
import { useIntl } from 'react-intl'
import { useYup } from '@yup/context'
import intl from './intl'

export const ShareCartPreferences = () => {
    const { register, formState: { errors }, control } = useFormContext<PreferencesFormData>()
    const { isLoading } = usePreferencesState()
    const { formatMessage } = useIntl()
    const { errorMessage } = useYup()

    const columns: UIInputListColumn[] = [
        {
            key: 'shareName',
            label: formatMessage(intl.shareCartName),
            renderer: (field, index) => (
                <UIInput key={field.id}
                         disabled={isLoading}
                         register={register(`shareCart.contacts.${index}.name`)}
                         error={errorMessage(errors, `shareCart.contacts.${index}.name`)}/>
            )
        },
        {
            key: 'shareContact',
            label: formatMessage(intl.shareCartContact),
            renderer: (field, index) => (
                <UIInput key={field.id}
                         disabled={isLoading}
                         register={register(`shareCart.contacts.${index}.contact`)}
                         error={errorMessage(errors, `shareCart.contacts.${index}.contact`)}/>
            )
        }
    ]

    // @ts-ignore
    const { fields, append, remove } = useFieldArray({
        name: 'shareCart.contacts',
        control
    })

    return (
        <UIPanel>
            <UIPanel.Body>
                <Section>
                    <Section.Item title={formatMessage(intl.shareCartTitle)}>
                        <UIInputList columns={columns}
                                     disabled={isLoading}
                                     data={fields}
                                     limit={10}
                                     onAdd={() => append({ name: '', contact: '' })}
                                     onDelete={({ index }) => remove(index)}/>
                    </Section.Item>
                </Section>
            </UIPanel.Body>
        </UIPanel>
    )
}
