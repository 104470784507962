import { generatePath } from 'react-router'
import { LocationDescriptorObject } from 'history'

export type UrlBuilderPathnameParams = Record<string, string | number | boolean> | null
export type UrlBuilderSearchParamValue = string | number | boolean | null | Array<string | number | boolean | null>

export function urlBuilder(url?: string | URL) {
    return {
        url: new URL(url || window.location.origin),
        current() {
            this.url = new URL(window.location.href)
            return this
        },
        reset() {
            this.url = new URL(window.location.origin)
            return this
        },
        setPath(pathname: string, params?: UrlBuilderPathnameParams) {
            this.url.pathname = params
                ? generatePath(pathname, params)
                : pathname

            return this
        },
        setSearchParams(params: { [key: string]: UrlBuilderSearchParamValue }) {
            Object.entries(params).map(([name, value]) => this.setSearchParam(name, value))
            return this
        },
        setSearchParam(name: string, value: UrlBuilderSearchParamValue) {
            if (value === null) {
                this.removeSearchParam(name)
                return this
            }

            const searchParams = this.getSearchParams()
            searchParams.set(name, value.toString())

            this.url.search = '?' + searchParams.toString()
            return this
        },
        removeSearchParam(name: string | string[]) {
            const searchParams = this.getSearchParams()

            Array.isArray(name)
                ? name.forEach(key => searchParams.delete(key))
                : searchParams.delete(name)

            this.url.search = '?' + searchParams.toString()
            return this
        },
        getSearchParams() {
            return this.url.searchParams
        },
        getSearchParam(name: string) {
            return this.url.searchParams.get(name)
        },
        getLocationDescriptor(): LocationDescriptorObject {
            return getRouterParams(this.url)
        },
        getUrl(singleton = false): URL {
            return !singleton ? new URL(this.url) : this.url
        },
        toString() {
            return this.getUrl().toString()
        }
    }
}

export const getRouterParams = (url?: string | URL): LocationDescriptorObject => {
    if (typeof url === 'string') {
        url = new URL(url)
    }

    return {
        pathname: url.pathname,
        search: url.search,
        hash: url.hash
    }
}
