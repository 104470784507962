import React from 'react'
import { useCustomer } from '@customer/hooks'
import { useProposalAction } from '../../hooks'
import { useAccount } from '@account/hooks'
import { UIButton } from '@ui/components'
import { FormattedMessage } from 'react-intl'
import { UIInfoIcon } from '@ui/icons'
import { useStore } from '@store/hooks'

export const Button = () => {
    const { permissions } = useCustomer()
    const { createProposalFromActiveCart } = useProposalAction()
    const { config } = useStore()
    const { account } = useAccount()

    const isAllowed = !!permissions?.proposal
    if (!isAllowed) return null

    const handleClick = () => {
        createProposalFromActiveCart.mutate({
            account: account.customer_id
        })
    }

    return (
        <div className="ec-w-full ec-flex ec-flex-col ec-gap-2">
            <UIButton variant="outlined"
                      onClick={handleClick}
                      disabled={!account?.customer_id}
                      loading={createProposalFromActiveCart.isLoading}>
                <FormattedMessage id="proposal.button"
                                  defaultMessage="Create Proposal"/>
            </UIButton>
            {config.proposal_cart_info &&
                <div className="ec-flex ec-gap-2 ec-p-2 ec-bg-gray-lighter">
                    <div className="ec-flex-none">
                        <UIInfoIcon/>
                    </div>
                    <p className="ec-m-0 ex-text-s ec-text-gray-dark">{config.proposal_cart_info}</p>
                </div>
            }
        </div>
    )
}
