import React, { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { YupRawProvider } from './YupRawProvider'

export const YupProvider = (
    { children }: PropsWithChildren
) => {
    const { formatMessage } = useIntl()

    return (
        <YupRawProvider formatMessage={formatMessage}>
            {children}
        </YupRawProvider>
    )
}
