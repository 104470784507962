import {useCustomerData} from "../../context";

/**
 * @deprecated
 * This sill trigger multiple re-renders in every location where it is used
 */
export const useCustomer = () => {

    const {customerQuery, reloadCustomer, isCustomerLoading, isLoggedIn, customerData, isAssociate} = useCustomerData();

    return {
        ...customerData,
        isLoggedIn,
        customerData: customerData,
        isAssociate,
        isCompanyUser: !!customerQuery.data?.customer?.is_company_user,
        customerCompanyStatus: parseInt(customerQuery.data?.customer?.customer_company_status),
        isCustomerLoading: isCustomerLoading,
        reloadCustomer: reloadCustomer,
        isRequestStatus: parseInt(customerQuery.data?.customer?.customer_company_status) === 2,
    }
}
