import React, {memo, PropsWithChildren, ReactNode, useEffect, useState} from 'react'
import { Branches } from '../Branches/Branches'
import {GoogleMapsProvider, useInventoryFinderState} from '../../state'
import { Product } from '@catalog/types'
import { UIModal } from '@ui/components'
import { useBranchesInventory } from '@inventory/hooks'
import { FormattedMessage } from 'react-intl'
import intl from './intl'

type InventoryFinderProps = PropsWithChildren & {
    open?: boolean
    title?: ReactNode
    product?: Product
    onOpen?: (args: { product?: Product }) => void
    onClose?: (args: { product?: Product }) => void
}

export const isInventoryFinderPropsEqual = (prevProps: InventoryFinderProps, nextProps: InventoryFinderProps) => {
    return prevProps.open === nextProps.open &&
        prevProps.product === nextProps.product &&
        prevProps.title === nextProps.title;
};

export const InventoryFinder = memo((
    { open = false, product, title, onOpen, onClose, children }: InventoryFinderProps
) => {
    const { setProduct, setIsProductInventory, setIsOpen, isOpen } = useInventoryFinderState()
    const { loadInventory } = useBranchesInventory(product)

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    const handleOpen = () => {
        loadInventory()
        setIsOpen(true)
        setProduct(product)
        setIsProductInventory(!!product)

        onOpen && onOpen({ product })
    }

    const handleClose = () => {
        setIsOpen(false)
        onClose && onClose({ product })
    }

    const defaultTitle = !!product
        ? <FormattedMessage {...intl.title}/>
        : null

    return (
        <>
            <button onClick={handleOpen}>
                {children}
            </button>
            <UIModal open={isOpen}
                     slide="wide"
                     onClose={handleClose}
                     scroll={false}
                     title={title ?? defaultTitle}>
                <GoogleMapsProvider>
                    <Branches/>
                </GoogleMapsProvider>
            </UIModal>
        </>
    )
}, isInventoryFinderPropsEqual);
