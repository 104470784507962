export default {
    label: {
        id: 'orderTemplate.label',
        defaultMessage: 'Search your order templates'
    },
    create: {
        id: 'orderTemplate.create',
        defaultMessage: 'Create Order Template'
    },
    formName: {
        id: 'orderTemplate.form.name',
        defaultMessage: 'Order Template Name'
    },
    formDescription: {
        id: 'orderTemplate.form.description',
        defaultMessage: 'Description'
    },
    formSave: {
        id: 'orderTemplate.form.save',
        defaultMessage: 'Save'
    },
    formCancel: {
        id: 'orderTemplate.form.cancel',
        defaultMessage: 'Cancel'
    }
}
