import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { useMutation, useQuery, UseMutationResult, UseQueryResult, QueryClientProvider } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { customerDataReload } from '@utils'
import { FormattedMessage } from 'react-intl'
import { Account, Job } from '../types'
import intl from './intl'
import {
    AccountResult,
    getAccountApi,
    switchAccountApi,
    SwitchAccountResult,
    switchJobApi,
    SwitchJobResult
} from '../api'
import { CustomerProvider, useCustomerData } from '../../Customer'
import { getQueryClient } from '@model/api'
import { StoreProvider } from '../../Store'
import {StorageKeys, useStorageContext, timestampStorage} from "../../Storage";

export type AccountStateProvider = {
    accounts: Account[]
    account: Account
    jobs: Job[]
    job: Job
    accountQuery: UseQueryResult<AccountResult>
    switchAccount: UseMutationResult<SwitchAccountResult, unknown, SwitchAccountProps>
    switchJob: UseMutationResult<SwitchJobResult, unknown, SwitchJobProps>
}

export const AccountContext = createContext<AccountStateProvider>(null)

export type SwitchAccountProps = {
    accountId: number
}

export type SwitchJobProps = {
    jobId: string | null
}

const CUSTOMER_DATA_KEY = 'accounts'
const QUERY_KEY = 'account'

const queryClient = getQueryClient()

export const AccountProvider = ({ children }: PropsWithChildren) => {
    return (
        <QueryClientProvider client={queryClient}>
            <StoreProvider>
                <CustomerProvider>
                    <AccountProviderInner>
                        {children}
                    </AccountProviderInner>
                </CustomerProvider>
            </StoreProvider>
        </QueryClientProvider>
    )
}

export const AccountProviderInner = (
    { children }: PropsWithChildren
) => {

    const {setKeyData, accountStorage} = useStorageContext();
    const accountLocalStorageData = accountStorage;
    const { isLoggedIn, isCompanyUser } = useCustomerData()

    const accountQuery = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const result = await getAccountApi()
            const { errors, ...resultData } = result

            timestampStorage.set(StorageKeys.ACCOUNT);
            const storageData = {[StorageKeys.ACCOUNT]: resultData}
            setKeyData(storageData);

            if (errors) {
                errors.forEach(error => toast.error(error.message))
            }
            return result
        },
        enabled: isLoggedIn && isCompanyUser,
        initialData: accountLocalStorageData,
        initialDataUpdatedAt: () => timestampStorage.get(StorageKeys.ACCOUNT),
        staleTime: 1000 * 60 * 5,
    })

    useEffect(() => {
        if (accountLocalStorageData) {
            if (!window.accountRefetchTimeout) {
                window.accountRefetchTimeout = setTimeout(() => {
                    void accountQuery.refetch()
                }, 1000)

                return () => clearTimeout(window.accountRefetchTimeout)
            }
        }
    }, [accountLocalStorageData])

    const reloadAccount = ({ accountId }: SwitchAccountResult) => {
        void queryClient.invalidateQueries([QUERY_KEY])

        const event = new CustomEvent('watsco-change-account', { detail: { account_id: accountId } })
        document.dispatchEvent(event)
    }

    const switchAccount = useMutation({
        mutationFn: async ({ accountId }: SwitchAccountProps) => (
            switchAccountApi({ accountId })
        ),
        onSuccess: ({ accountId, errors }) => {
            if (errors) {
                errors.forEach(error => toast.error(error.message))
            }
            if (!accountId) return

            customerDataReload(CUSTOMER_DATA_KEY)
            void reloadAccount({ accountId })
            const account = getAccount(accountId)

            toast.success(<FormattedMessage {...intl.switchAccountSuccess} values={{
                id: account.customer_id,
                name: account.customer_name
            }}/>)
        },
        onError: (error) => {
            console.error('API error', error)
            toast.error(<FormattedMessage {...intl.switchAccountError} />)
        }
    })

    const switchJob = useMutation({
        mutationFn: async ({ jobId }: SwitchJobProps) => (
            switchJobApi({ jobId })
        ),
        onSuccess: ({ errors, ...result }) => {
            if (errors) {
                errors.forEach(error => toast.error(error.message))
                return
            }

            customerDataReload(CUSTOMER_DATA_KEY)
            void reloadAccount(result)
            if (!result.jobId) {
                toast.success(<FormattedMessage {...intl.removeJobSuccess} />)
                return
            }

            const job = getJob(result.jobId)
            toast.success(<FormattedMessage {...intl.switchAccountSuccess} values={{
                id: job.id,
                name: job.name
            }}/>)
        },
        onError: error => {
            console.error('API error', error)
            toast.error(<FormattedMessage {...intl.switchAccountError} />)
        }
    })

    const getAccount = (accountId: number) => {
        const accounts = accountQuery.data?.accounts || accountLocalStorageData?.accounts || []
        return accounts.find(({ customer_id }) => customer_id === accountId) || null
    }

    const getJob = (jobId: string) => {
        const account = getAccount(accountQuery.data?.currentAccount)
        const jobs = account?.jobs || []
        return jobs.find(({ id }) => id === jobId) || null
    }

    const accounts = accountQuery.data?.accounts || []
    const account = getAccount(accountQuery.data?.currentAccount || accountLocalStorageData?.currentAccount)
    const jobs = account?.jobs || []
    const job = jobs.find(({ id }) => id === accountQuery.data?.currentJob) || null

    return (
        <AccountContext.Provider value={{
            accounts,
            account,
            jobs,
            job,
            accountQuery,
            switchAccount,
            switchJob,
        }}>
            {children}
        </AccountContext.Provider>
    )
}

export const useAccountData = () => {
    const context = useContext(AccountContext)
    if (!context) {
        throw new Error('useAccount must be used within an AccountProvider')
    }
    return context
}
