import React from 'react'
import { getProductUrl } from '../../../utils'
import { SupersedeIcon } from '../../../icons'
import { FormattedMessage } from 'react-intl'
import { Product } from '../../../types'
import intl from './intl'

type SupersedeBadgeProps = {
    product?: Product
}

const BadgeContent = () => (
    <span className="ec-flex ec-items-center ec-text-blue-light hover:ec-underline">
        <SupersedeIcon/>
        <span className="ec-font-medium ec-uppercase ec-text-xs ec-ml-1">
            <FormattedMessage {...intl.supersede}/>
        </span>
    </span>
)

export const SupersedeBadge = (
    { product }: SupersedeBadgeProps
) => {
    const productUrl = product ? getProductUrl(product) : null

    return productUrl
        ? <a href={productUrl.href}><BadgeContent/></a>
        : <BadgeContent/>
}
