import React, { ComponentPropsWithoutRef, ElementType } from 'react'
import { UIOmit } from '../../../types'
import clsx from 'clsx'

export type UIGridProps<T extends ElementType> = UIOmit<ComponentPropsWithoutRef<T>> & {
    element?: ElementType
    spacing?: UIGridSpacing
}

export type UIGridItemProps = UIOmit<ComponentPropsWithoutRef<'div'>> & {
    span?: UIGridSpan
    newLine?: boolean
}

export type UIGridSpacing = 'small' | 'medium' | 'large'
export type UIGridSpan = 1 | 2 | 3 | 4 | 5 | 'half' | 'full'

const spacingVariants = {
    small: 'ec-gap-2',
    medium: 'ec-gap-4',
    large: 'ec-gap-8'
}

const spanVariants = {
    1: 'md:ec-col-span-1',
    2: 'md:ec-col-span-2',
    3: 'md:ec-col-span-3',
    4: 'md:ec-col-span-4',
    5: 'md:ec-col-span-5',
    half: 'md:ec-col-span-3',
    full: 'ec-col-span-full'
}

export const UIGrid = <T extends ElementType>(
    { element = 'div', spacing = 'medium', children, ...attributes }: UIGridProps<T>
) => {
    if (!children || Array.isArray(children) && children.every((child: any) => !child)) return null
    const ElementComponent = element

    return (
        <ElementComponent className={`ec-grid ec-grid-cols-1 md:ec-grid-cols-6 ${spacingVariants[spacing]}`}
                          {...attributes}>
            {children}
        </ElementComponent>
    )
}

UIGrid.Span = (
    { span = 'full', newLine, children, ...attributes }: UIGridItemProps
) => (
    <div className={clsx(spanVariants[span], { 'ec-col-start-1': newLine })} {...attributes}>
        {children}
    </div>
)
