import React, { ComponentPropsWithoutRef, MouseEvent } from 'react'
import clsx from 'clsx'
import { UIOmit } from '../../../types'
import { UICloseIcon } from '../../../icons'

export type UIChipProps = UIOmit<ComponentPropsWithoutRef<'div'>> & {
    label: string
    disabled?: boolean
    onClose?: (event: MouseEvent<HTMLButtonElement>) => void
    messages?: {
        close?: string
    }
}

export const UIChip = (
    { label, disabled, onClose, messages, ...attributes }: UIChipProps
) => {
    const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
        !disabled && onClose(e)
    }

    return (
        <div className={clsx(
            'ec-inline-flex ec-text-white ec-items-center ec-text-center ec-rounded-full ec-text-sm',
            'ec-leading-none ec-py-1.5 ec-px-3',
            {
                'ec-bg-red-dark': !disabled,
                'ec-cursor-not-allowed ec-bg-gray-dark': disabled
            }
        )} {...attributes}>
            <span className="ec-leading-5">{label}</span>
            {onClose &&
                <button type="button" onClick={handleClose} className={clsx(
                    'ec-text-white ec-opacity-60 ec-ml-1 -ec-mr-1',
                    {
                        'hover:ec-opacity-100': !disabled,
                        'ec-cursor-not-allowed': disabled
                    }
                )}>
                    <UICloseIcon/>
                    <span className="ec-sr-only">{messages?.close || 'Close'}</span>
                </button>
            }
        </div>
    )
}
