import React from "react";
import { cn } from '@utils';

export const MapPinIcon = () => {

    return (
        <div>
            <div style={{ color: '#cb0015' }}></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="2" stroke="white"
                 className={cn("ec-w-8 ec-h-8")}>
                <path fill="white" strokeLinecap="round" strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"/>
                <path fill={'#cb0015'} stroke={'#cb0015'} strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"/>
            </svg>
        </div>
    );
}
