import React, {memo} from 'react'
import cn from "clsx";
import { useIntl } from "react-intl";
import { useWindowSize } from "../../../../Various";
import {UIModal} from "@ui/components";
import { CartIcon } from '../../../icons'
import {isPropsAreEqual} from "../../../../../utils";

export const GuestCart =  memo(() => {
    const intl = useIntl();

    const [isOpen, setIsOpen] = React.useState(false);
    const { isMobile } = useWindowSize();

    const cartLabel = intl.formatMessage({ id: 'account.header.top', defaultMessage: 'Shopping Cart' });
    const cartLabelMobile = intl.formatMessage({ id: 'account.header.top', defaultMessage: 'Cart' });

    return (
        <>
            <div onClick={() => setIsOpen(true)}
                 className={cn("ec-flex ec-overflow-visible ec-h-10 ec-flex-col ec-cursor-pointer", {
                     'ec-w-32 ec-mr-6 ec-top-[-7px] ec-relative ec-w-[150px] ': !isMobile
                 })}>
                <div className={cn('ec-flex ec-items-center ec-justify-around ec-relative ', {
                    'ec-flex-wrap ec-flex-col': isMobile,
                    'ec-flex-row-reverse': !isMobile
                })}>

                    <CartIcon/>
                    <span className={cn("ec-text-white ec-relative", {'ec-text-xs': isMobile}, {'ec-pl-2': !isMobile})}>
                        {isMobile ? cartLabelMobile : cartLabel}
                        </span>
                </div>
            </div>
            <UIModal open={isOpen}
                     slide={true}
                     title={isMobile ? cartLabelMobile : cartLabel}
                     onClose={() => setIsOpen(false)}>
                <div className={cn('ec-flex', 'ec-flex-col', 'ec-items-center', 'ec-justify-center', 'ec-p-6')}>
                    <div className={cn('ec-text-center', 'ec-text-2xl', 'ec-font-bold', 'ec-mb-2')}>
                        {intl.formatMessage({
                            id: "cart.empty",
                            defaultMessage: "Your cart is empty"
                        })}
                    </div>
                    <div className={cn('ec-text-center', 'ec-text-sm')}>
                        {intl.formatMessage({
                            id: "cart.empty.description",
                            defaultMessage: "You have no items in your shopping cart."
                        })}
                    </div>
                </div>
            </UIModal>
        </>
    )
}, isPropsAreEqual);

GuestCart.whyDidYouRender = true;
