import React, { useCallback } from 'react'
import {AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef} from '@vis.gl/react-google-maps'
import { useMap } from '../hooks'
import { MapMarker } from '../types'
import { Marker as GoogleMarker } from '@googlemaps/markerclusterer'

type MapMarkerProps = MapMarker & {
    setMarkerRef: (markerRef: GoogleMarker, id: string | number) => void
    clusterMarkers?: boolean
    pinProps?: google.maps.marker.PinElementOptions
}

export const Marker = (
    marker: MapMarkerProps
) => {
    const {
        id,
        infoWindow,
        onMarkerClick,
        onInfoWindowClose,
        setMarkerRef,
        clusterMarkers = false,
        pinProps,
        ...markerProps
    } = marker

    const [markerRef, markerAnchor] = useAdvancedMarkerRef()
    const { selectedMarkerId, selectMarker } = useMap()
    const isSelected = selectedMarkerId === id
    const isClickable = marker.clickable === true || marker.clickable === undefined

    const handleMarkerClick = () => {
        if (!isClickable) return
        selectMarker(marker)
        !!onMarkerClick && onMarkerClick(marker)
    }

    const handleInfoWindowClose = () => {
        if (!isClickable) return
        selectMarker(null)
        !!onInfoWindowClose && onInfoWindowClose(marker)
    }

    const ref2 = useCallback((ref: google.maps.marker.AdvancedMarkerElement) => {
        markerRef(ref)
        clusterMarkers && setMarkerRef(ref, marker.id)
    }, [setMarkerRef, marker.id])

    return (
        <>
            <AdvancedMarker {...markerProps}
                            ref={ref2}
                            onClick={handleMarkerClick}>
                {pinProps ? (
                    <Pin {...pinProps} />
                ) : null}
            </AdvancedMarker>
            {isSelected && !!infoWindow &&
                <InfoWindow anchor={markerAnchor}
                            onClose={handleInfoWindowClose}>
                    {infoWindow}
                </InfoWindow>
            }
        </>
    )
}
