import React, { PropsWithChildren } from 'react'
import { UIButtonLinkIcon, UIButtonLinkIconProps } from '@ui/components'
import { UIArrowLeftIcon } from '@ui/icons'
import { useCustomerPortal } from '../../hooks'
import { TitleLoader } from './TitleLoader'

export type CustomerPortalTitle = PropsWithChildren & {
    backLink?: UIButtonLinkIconProps['href']
}

export const Title = () => {
    const { title, backLink } = useCustomerPortal()

    return (
        <div className="ec-flex ec-items-center">
            {backLink &&
                <UIButtonLinkIcon href={backLink} ariaLabel={'back'}>
                    <UIArrowLeftIcon size="large"/>
                </UIButtonLinkIcon>
            }
            <h1 className="ec-text-3xl ec-m-0">
                {typeof title !== 'function'
                    ? title || <TitleLoader/>
                    : title
                }
            </h1>
        </div>
    )
}
