import React, {useCallback, useEffect} from "react";

export const Search = () => {
    const queryString = window.location.href;
    const urlParams = new URLSearchParams(queryString);
    const currentSearchString =
        urlParams.get("q") !== "*" ? urlParams.get("q") : null;

    // @ts-ignore
    const {useURLState,  useQuery, useSearchState, SearchBar } = window.watsco_search_ui;
    useURLState();
    const { setQuery } = useQuery();

    const { setBranchId, setUserToken } = useSearchState();

    const setQueryPromise = (query: string) => {
        setQuery(query);
        return Promise.resolve(query);
    };

    const handleQuerySubmit = useCallback(async (query: string | null) => {
        if (!query) return;

        try {
            const resolvedQuery = await setQueryPromise(query);
            window.location.href = `/search?q=${resolvedQuery}`;
        } catch (error) {
            console.error('Error handling query submit:', error);
        }
    }, [setQuery]);

    useEffect(() => {
        const userToken = localStorage.getItem('analytics-anonymous-uuid')
            ? localStorage.getItem('analytics-anonymous-uuid')
            : '';
        setUserToken(userToken);
    }, []);

    useEffect(() => {
        setBranchId([""]);
    }, []);

    return (
        // @ts-ignore
        <SearchBar
            onQuerySubmit={handleQuerySubmit}
            showRecentSearches
            showProductSuggestions={false}
            currentSearchString={currentSearchString}
        />
    );
}
