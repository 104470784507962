import { GraphQlError, request } from '@model/api'
import { getInventoryQuery } from './query'
import { Inventory } from '../types'

export type InventoryApiParams = {
    products: string[]
}

export type GetInventoryRawResponse = {
    inventory: Inventory[]
}

export type InventoryResult = {
    inventory: Inventory[]
    errors?: GraphQlError[]
}

export const getInventoryApi = async (
    params: InventoryApiParams
): Promise<InventoryResult> => (
    request<GetInventoryRawResponse>(getInventoryQuery, params).then(data => ({
        inventory: data.data.data.inventory,
        errors: data.data?.errors
    }))
)
