import { GraphQlErrorResult, request } from '@model/api'
import { saveActiveCartQuery } from './queries'

export type SaveActiveCartParams = {
    name: string
}

export type SaveActiveCartApiResult = {
    saveActiveCart: {
        save_cart: {
            save_cart_id: number
        }
    }
}

export type SaveActiveCartResult = GraphQlErrorResult & {
    saveCartId: number | null
}

export const saveActiveCartApi = async (
    params: SaveActiveCartParams
): Promise<SaveActiveCartResult> => (
    request<SaveActiveCartApiResult>(saveActiveCartQuery, params).then(data => ({
        saveCartId: data.data?.data?.saveActiveCart?.save_cart?.save_cart_id || null,
        errors: data.data?.errors || null
    }))
)
