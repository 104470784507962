import React, { createContext, PropsWithChildren, useState } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getPricingApi, PricingResult } from '../api'
import { useCustomer } from '@customer/hooks'
import { useBranch } from '@branch/hooks'
import { useAccount } from '@account/hooks'
import { Product } from '@catalog/types'

type PricingProviderProps = {
    products: Product[]
}

export type PricingStateProvider = {
    query: UseQueryResult<PricingResult>
    products: Product[]
    setProducts: UseStateSet<Product[]>
}

export const PricingContext = createContext<PricingStateProvider>(null)

export const PricingProvider = (
    { products, children }: PropsWithChildren<PricingProviderProps>
) => {
    const getPimIds = (products: Product[]) => products
        .filter(product => product?.pim_id)
        .map(product => product.pim_id)
        .sort()

    const [pimIds, setPimIds] = useState(getPimIds(products))

    const { isLoggedIn } = useCustomer()
    const { currentBranch } = useBranch()
    const { account, job } = useAccount()

    const query = useQuery({
        queryKey: ['pricing', currentBranch?.branch_id, account?.customer_id, job?.id, pimIds.join(',')],
        queryFn: async () => getPricingApi({ products: pimIds }),
        enabled: isLoggedIn && !!currentBranch && pimIds.length > 0
    })

    const setProducts = (products: Product[]) => setPimIds(getPimIds(products))

    return (
        <PricingContext.Provider value={{ query, products, setProducts }}>
            {children}
        </PricingContext.Provider>
    )
}
