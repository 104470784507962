import React from 'react'
import { AppProvider } from '@app/context'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { PartsList, PartsListProps } from '../PartsList'
import { ADD_TO_CART_LIST_DATA } from '@watsco/analytics'
import { ErrorMessage } from '@ui/components'
import { ErrorBoundary } from '@app/components'
import { Toaster } from '@model/tostify'

export const WarrantyPartsApp = (
    { modelNumber }: PartsListProps
) => (
    <ErrorBoundary fallback={<ErrorMessage section/>}>
        <AppProvider>
            <AnalyticsProvider area={AnalyticsArea.Warranty}
                               listData={ADD_TO_CART_LIST_DATA.WARRANTY_PARTS_LIST}>
                <PartsList modelNumber={modelNumber}/>
            </AnalyticsProvider>
            <Toaster/>
        </AppProvider>
    </ErrorBoundary>
)

