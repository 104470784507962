import React, {memo, useEffect, useState} from 'react';
import { CloseSubmenu } from "./CloseSubmenu";
import { useMenuState, MenuItemType } from "../../../../state";
import { FormattedMessage } from 'react-intl';
import {cn, isPropsAreEqual} from "@utils";
import {useWindowSize} from "../../../../../Various";
import {UIBadge} from "../../../../../Ui";


type SubmenuProps = {
    data: MenuItemType[];
};

type MenuItemPropsType = {
    data: MenuItemType;
    allItem?: boolean;
};

const SubMenuItem = memo(({ data, allItem = false }: MenuItemPropsType) => {
    const { handleClick, isOpen, currentSubmenuOpened, menuStyle, currentSubmenu } = useMenuState();
    const { isMobile } = useWindowSize();

    const [isAnimating, setIsAnimating] = useState(false);
    const [isMenuFullyOpen, setIsMenuFullyOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);

            const timer = setTimeout(() => {
                setIsAnimating(false);
                setIsMenuFullyOpen(true);
            }, 500);

            return () => clearTimeout(timer);
        } else {
            setIsMenuFullyOpen(false);
        }
    }, [isOpen]);

    let listItemStyle = '';
    if (!isMobile && !allItem) {
        listItemStyle = isMenuFullyOpen ? menuStyle.menuItemVisible : (isOpen && isAnimating ? menuStyle.menuItemVisible : menuStyle.menuItemHidden);
    }

    let currentSubmenuStyle = menuStyle.submenuClose;

    if (currentSubmenu?.level === data.level) {
        currentSubmenuStyle = 'relative';
    }

    if (currentSubmenu?.id >= data.id) {
        currentSubmenuStyle = 'relative';
    }

    if (!isOpen) return null;

    return (
        <li className={cn(listItemStyle, currentSubmenuOpened(data) ? `${menuStyle.slide} ${menuStyle.submenuOpen}` : currentSubmenuStyle)}>
            <a href={data.href}
               className={cn(menuStyle.generic, currentSubmenuOpened(data) ? 'ec-flex' : 'ec-hidden', data.class)}
               onClick={(e) => handleClick(e, data)}>
                {data.name}
                {data?.badges?.includes('new') &&
                    <div className="ec-ml-2 ec-inline-block">
                        <UIBadge>New</UIBadge>
                    </div>
                }
                {data?.children?.length ? (
                    <svg className={isMobile ? menuStyle.nextArrowMobile : menuStyle.nextArrow}
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth={3}
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                ) : null}
            </a>
            {data.children && <Submenu data={data.children}/>}
        </li>
    );
}, isPropsAreEqual);

export const Submenu = memo(({ data }: SubmenuProps) => {
    const { currentSubmenu } = useMenuState();
    let currentSubmenuStyle = 'ec-translate-y-0 ec-flex ec-flex-col';

    const allCategoryItem: MenuItemType = currentSubmenu
        ? {
            name: <FormattedMessage
                id="submenu.all"
                defaultMessage="All {text}"
                description="The parent category name"
                values={{ text: currentSubmenu.name }}
            />,
            href: currentSubmenu.href,
            class: data[0]?.class,
            level: data[0]?.level,
            id: 'all_' + currentSubmenu.id,
            parentId: data[0]?.parentId,
            children: []
        }
        : null;

    if (!data[0]) return null;

    if (data[0].level > currentSubmenu?.level + 1 || !currentSubmenu) {
        currentSubmenuStyle = 'ec-translate-y-0 ec-h-0 ec-w-0 ec-overflow-hidden ec-flex ec-flex-col';
    }

    return (
        <ul className={cn(currentSubmenuStyle)}>
            {data[0].parentId === currentSubmenu?.id && <CloseSubmenu/>}

            {currentSubmenu?.href && allCategoryItem && <SubMenuItem data={allCategoryItem} allItem={true}/>}

            {data.map((category, index) => (
                <SubMenuItem key={index} data={category}/>
            ))}
        </ul>
    );
}, isPropsAreEqual);

// SubMenuItem.whyDidYouRender = true;
Submenu.whyDidYouRender = true;
