export const HeaderStyle = {
    headerContainer: "ec-bg-brand ec-relative ec-z-41",
    headerContainerMobile: "ec-bg-brand ec-z-40 ec-relative ec-px-4 ec-py-2",
    headerTop: "ec-flex ec-flex-row ec-justify-between ec-items-center ec-relative ec-h-24 ec-z-50",
    headerTopMobile: "ec-flex ec-flex-row ec-justify-between ec-items-center ec-relative ec-h-24",
    horizontalMenu: "ec-flex ec-flex-row ec-relative ec-h-9",
    desktopLogoImg: "ec-object-cover ec-w-[212px] ec-px-4 ec-py-2 ec-mr-6 ec-pl-[20px] ec-pt-[14px] ec-relative",
    desktopLogoImgMobile: "ec-object-cover ec-w-36 ec-px-4 ec-py-2",
    desktopSearch: "ec-bg-white ec-flex-grow ec-relative ec-my-1.5 ec-relative ec-p-0.5",
    desktopLeftRight: "ec-flex",
}
