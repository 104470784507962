import React from "react";
import Skeleton from "react-loading-skeleton";

export const MiniCartSkeleton = () => {
    return (
        <div className={'ec-border-b'}>
            <div className="ec-mb-6 ec-text-right">
                <Skeleton width={110} height={20}/>
            </div>
            <div className={'ec-mb-6'}>
                <Skeleton width={'100%'}  height={20}/>
            </div>
            <div>
                <div className="ec-flex ec-w-full ec-mb-2 ec-justify-between">
                    <div className="ec-w-[47%]">
                        <Skeleton width={'100%'} height={40} />
                    </div>
                    <div className="ec-w-[47%]">
                        <Skeleton width={'100%'} height={40} />
                    </div>
                </div>
                <div className={'ec-flex ec-w-full ec-mb-4 ec-justify-between'}>
                    <div className="ec-w-[47%]">
                        <Skeleton width={'100%'} height={30} />
                    </div>
                    <div className="ec-w-[47%]">
                        <Skeleton width={'100%'} height={30} />
                    </div>
                </div>
            </div>
        </div>
    )
}