import React from 'react'
import { UIPopover, UIPopoverProps, UIButtonExpand } from '@ui/components'
import { Panel } from './Dropdown/Panel'
import { Modal } from './Dropdown/Modal'
import { useCustomer } from '@customer/hooks'
import { Product } from '@catalog/types'
import { FormattedMessage } from 'react-intl'
import { useModal } from '@ui/hooks'
import intl from './intl'

type OrderTemplateProps = {
    product: Product | Product[]
    width?: UIPopoverProps['width']
}

export const OrderTemplateDropdown = (
    { product, width = 'auto' }: OrderTemplateProps
) => {
    const { isLoggedIn } = useCustomer()
    const { open, handleOpen, handleClose } = useModal()

    if (!isLoggedIn) return null

    const products = !Array.isArray(product)
        ? [{ sku: '', ...product }]
        : product.map(product => ({ sku: '', ...product }))

    const OrderTemplatePanel = () => (
        <Panel products={products} onModalOpen={handleOpen}/>
    )

    return (
        <>
            <UIPopover width={width}
                       panel={<OrderTemplatePanel/>}>
                <UIButtonExpand variant="triangle">
                    <FormattedMessage id="orderTemplate.action"
                                      defaultMessage="Add to Order Template"/>
                </UIButtonExpand>
            </UIPopover>
            <Modal products={products}
                   open={open}
                   onClose={handleClose}/>
        </>
    )
}
