import React from "react";
import { UIButton, UIFixedFooter } from '@ui/components'
import { useWindowSize } from '@ui/hooks'
import { useSortingListState } from '../state'
import { LargeItemList } from './LargeItemList'
import { useIntl} from "react-intl";

export const Top = () => {

    const { handleDataUpdate, isContentButtonVisible, isOrderChanged } = useSortingListState();
    const {formatMessage} = useIntl();
    const {isMobile} = useWindowSize();

    return (
        <div>
            <div className={'ec-pb-4 ec-border-b ec-border-gray-lighter'}>

                {!isMobile && <h2>
                    {
                        formatMessage({
                            id: 'sort.order.findByLabel',
                            defaultMessage: 'Filter by Label:'
                        })
                    }
                </h2>}
                <div>
                    {
                        formatMessage({
                            id: 'sort.order.labelHint',
                            defaultMessage: 'Add Labels to your products to easily group, sort, or filter your Order Template.'
                        })
                    }
                </div>
                <LargeItemList/>
                {!isMobile && <div className={'ec-p-2 ec-text-xs'}>
                    {
                        formatMessage({
                            id: 'sort.order.hint',
                            defaultMessage: 'Drag and drop items to reorder them or change their position manually.'
                        })
                    }
                </div>}
            </div>
            <UIFixedFooter visible={!isContentButtonVisible}>
                {!isOrderChanged && <div>
                    <UIButton onClick={handleDataUpdate} size={'medium'}>
                        <span>{
                            formatMessage({
                                id: 'sort.order.cancel',
                                defaultMessage: 'Return to List'
                            })
                        }</span>
                    </UIButton>
                </div>}
                {isOrderChanged && <UIButton onClick={handleDataUpdate} size={'medium'}>
                        <span>{
                            formatMessage({
                                id: 'sort.order.apply',
                                defaultMessage: 'Update Positions'
                            })
                        }</span>
                </UIButton>}
            </UIFixedFooter>
        </div>
    );
};
