import React from 'react'
import { useStore } from '@store/hooks'
import { Product } from '../../../types'

type ProductImageProps = {
    product: Product
    size?: 'small' | 'medium' | 'large' | number
}

const sizeVariants = {
    small: 80,
    medium: 200,
    large: 700
}

export const ProductImage = (
    { product, size = 'medium' }: ProductImageProps
) => {
    const { config: { secure_base_media_url, default_image } } = useStore()
    const defaultSrc = secure_base_media_url + default_image

    const sizeValue = typeof size !== 'number'
        ? sizeVariants[size]
        : size

    const getImageSrc = () => {
        if (sizeValue <= sizeVariants.small && !!product.small_image?.url) {
            return product.small_image?.url
        }
        if (sizeValue <= sizeVariants.medium && !!product.thumbnail?.url) {
            return product.thumbnail?.url
        }
        return product.image?.url || defaultSrc
    }

    return (
        <img src={getImageSrc()}
             width={sizeValue}
             height={sizeValue}
             alt={product.name}
        />
    )
}
