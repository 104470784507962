import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../types'

export type UIFormErrorProps = UIOmit<ComponentPropsWithoutRef<'p'>> & {
    error: string | undefined | null
}

export const UIFormError = (
    { error, ...attributes }: UIFormErrorProps
) => (
    error
        ? <p className="ec-my-1 ec-text-xs ec-text-red" role="alert" {...attributes}>{error}</p>
        : null
)
