import { GraphQlErrorResult, request } from '@model/api'
import { branchFragment, getBranchesQuery } from './query'
import { Branch } from '../types'

export type BranchesApiResponse = {
    branches: Branch[] | null
}

export type BranchesResult = BranchesApiResponse & GraphQlErrorResult

export const getBranchesApi = async (): Promise<BranchesResult> => (
    request<BranchesApiResponse>([getBranchesQuery, branchFragment]).then(data => ({
        branches: data.data.data?.branches,
        errors: data.data?.errors || null
    }))
)
