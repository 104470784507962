import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import clsx from 'clsx'
import { UIOmit } from '../../../types'

export type UIButtonLinkIconProps = UIOmit<ComponentPropsWithoutRef<'a'>, 'href'> & {
    href: LinkProps['to']
    ariaLabel: ReactNode
    size?: 'small' | 'medium' | 'large'
    color?: 'dark' | 'light'
    disabled?: boolean
}

export type UIButtonLinkIconSize<T = string> = Record<UIButtonLinkIconProps['size'], T>
export type UIButtonLinkIconColor<T = string> = Record<UIButtonLinkIconProps['color'], T>

const sizeVariant: UIButtonLinkIconSize = {
    small: 'ec-p-1',
    medium: 'ec-p-2',
    large: 'ec-p-2.5'
}

const colorVariant: UIButtonLinkIconColor = {
    dark: 'ec-text-gray-darker',
    light: 'ec-text-white'
}

const colorHoverVariant: UIButtonLinkIconColor = {
    dark: 'hover:ec-text-red',
    light: 'hover:ec-text-red'
}

export const UIButtonLinkIcon = (
    {
        ariaLabel,
        size = 'medium',
        color = 'dark',
        disabled,
        children,
        href,
        ...attributes
    }: UIButtonLinkIconProps
) => (
    <Link to={href} className={clsx(
        sizeVariant[size],
        colorVariant[color],
        !disabled && colorHoverVariant[color],
        disabled && 'ec-opacity-50 ec-cursor-not-allowed'
    )} {...attributes}>
        {children}
        <span className="ec-sr-only">
            {ariaLabel}
        </span>
    </Link>
)
