import { usePreferencesState } from '../../state/context/PreferencesStateProvider'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { UIInput, UIPanel, UIToggle } from '@ui/components'
import { useYup } from '@yup/context'
import { Section } from '../../../CustomerPortal/components/Elements/Section'

export const InactiveCustomers = () => {
    const { register, watch, setValue, formState: { errors } } = useFormContext()
    const { isLoading } = usePreferencesState()
    const { errorMessage } = useYup()
    const { formatMessage } = useIntl()

    const enabledValue = watch('inactiveCustomers.enabled')
    const offsetValue = watch('inactiveCustomers.offset')

    useEffect(() => {
        if (!enabledValue) {
            setValue('inactiveCustomers.offset', '0')
        }
    }, [enabledValue])

    return (
        <UIPanel>
            <UIPanel.Body>
                <Section>
                    <Section.Item title={
                        <FormattedMessage id="inactiveCustomers.title" defaultMessage="Inactive Customer Email"/>
                    }>
                        <UIToggle
                            register={register('inactiveCustomers.enabled')}
                            name="inactiveCustomers.enabled"
                            disabled={isLoading}
                            label={formatMessage({
                                id: 'send.email.label.toggle',
                                defaultMessage: 'Send Email'
                            })}/>
                        {enabledValue &&
                            <UIInput
                                register={register('inactiveCustomers.offset', { valueAsNumber: true })}
                                type={'number'}
                                value={offsetValue}
                                disabled={isLoading}
                                name={'inactiveCustomers.offset'}
                                label={formatMessage({
                                    id: 'send.email.label.toggle',
                                    defaultMessage: 'Days Inactive'
                                })}
                                error={errorMessage(errors, 'inactiveCustomers.offset')}/>
                        }
                    </Section.Item>
                </Section>
            </UIPanel.Body>
        </UIPanel>
    )
}
