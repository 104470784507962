import React, { ChangeEvent } from 'react'
import { UIToggle } from '@ui/components'
import { FormattedMessage } from 'react-intl'
import { useWingmanList } from '../../../hooks'
import intl from '../intl'

export const Toolbar = () => {
    const { filters, onSearch, query } = useWingmanList()

    const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onSearch({ ...filters, accounts: event.target.checked })
    }

    return (
        <UIToggle label={<FormattedMessage {...intl.toggle}/>}
                  checked={filters.accounts || filters.accounts === undefined}
                  onChange={handleToggleChange}
                  disabled={query.isFetching}/>
    )
}
