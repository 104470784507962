import { useContext } from 'react'
import { CustomerPortalContext } from '../../context'

export const useCustomerPortal = () => {
    const context = useContext(CustomerPortalContext)

    if (!context) {
        console.warn('useCustomerPortal must be used within the CustomerPortalProvider')
    }
    return context
}
