import React, { useState, ButtonHTMLAttributes, ChangeEvent } from 'react'
import { OrderTemplate } from '../../../types'
import { useOrderTemplate } from '../../../hooks'
import { UIInput, UIPopover } from '@ui/components'
import { UIPlusIcon } from '@ui/icons'
import { Product } from '@catalog/types'
import { FormattedMessage } from 'react-intl'
import intl from '../intl'

type OrderTemplateProps = {
    products: Product[]
    onModalOpen: () => void
}

export const Panel = (
    { products, onModalOpen }: OrderTemplateProps
) => {
    const { orderTemplates, addToOrderTemplate } = useOrderTemplate()
    const [search, setSearch] = useState('')

    const filteredOrderTemplates = orderTemplates
        .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
        .slice(0, 5)

    const MenuItem = (
        { children, ...attributes }: ButtonHTMLAttributes<HTMLButtonElement>
    ) => (
        // Use Popover button to trigger close on click
        <UIPopover.Button
            role="menuitem"
            className="ec-w-full ec-text-left ec-text-sm ec-py-2 hover:ec-bg-gray-lighter ec-truncate"
            {...attributes}>
            {children}
        </UIPopover.Button>
    )

    const handleAdd = (orderTemplate: OrderTemplate) => {
        if (addToOrderTemplate.isLoading) return
        addToOrderTemplate.mutate({ orderTemplate, products })
    }

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }

    return (
        <>
            <UIInput name="search"
                     value={search}
                     onChange={handleSearch}
                     autoComplete="off"
                     label={<FormattedMessage {...intl.label} />}/>
            <ul className="ec-mt-2" role="menu">
                {filteredOrderTemplates.map(orderTemplate =>
                    <li key={orderTemplate.id}>
                        <MenuItem onClick={() => handleAdd(orderTemplate)}>
                            {orderTemplate.name}
                        </MenuItem>
                    </li>
                )}
                <li>
                    <MenuItem onClick={onModalOpen}>
                        <div className="ec-flex ec-items-center">
                            <div className="ec-mr-1"><UIPlusIcon size="small"/></div>
                            <div><FormattedMessage {...intl.create}/></div>
                        </div>
                    </MenuItem>
                </li>
            </ul>
        </>
    )
}
