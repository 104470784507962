import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { WINGMAN_LIST_PATH, WINGMAN_VIEW_PATH } from '../../config'
import { View, List } from '../../pages'
import { AppProvider } from '@app/context'
import { ErrorMessage } from '@ui/components'
import { ErrorBoundary } from '@app/components'

export const WingmanApp = () => (
    <ErrorBoundary fallback={<ErrorMessage section/>}>
        <AppProvider>
            <AnalyticsProvider area={AnalyticsArea.Wingman}>
                <Switch>
                    <Route exact path={WINGMAN_LIST_PATH} component={List}/>
                    <Route exact path={WINGMAN_VIEW_PATH} component={View}/>
                </Switch>
            </AnalyticsProvider>
        </AppProvider>
    </ErrorBoundary>
)
