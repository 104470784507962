import React, { PropsWithChildren } from 'react'
import { CustomerPortalProvider, CustomerPortalProviderProps } from '../../context'
import { Title } from './Title'
import { Breadcrumbs } from './Breadcrumbs'
import { ErrorBoundary } from '@app/components'
import { ErrorMessage } from '@ui/components'

type CustomerPortalProps = PropsWithChildren & CustomerPortalProviderProps

export const CustomerPortal = (
    { breadcrumbs, title, children }: CustomerPortalProps
) => (
    <CustomerPortalProvider title={title} breadcrumbs={breadcrumbs}>
        <main className="-ec-mx-5 ec-h-full">
            <div className="ec-p-5 ec-bg-white">
                <div className="ec-flex ec-flex-col ec-gap-2 ec-w-full">
                    <ErrorBoundary fallback={<ErrorMessage/>}>
                        <Breadcrumbs/>
                        <Title/>
                    </ErrorBoundary>
                </div>
            </div>
            <div className="ec-bg-gray-lighter ec-p-5 ec-h-full">
                <ErrorBoundary fallback={<ErrorMessage section center/>}>
                    {children}
                </ErrorBoundary>
            </div>
        </main>
    </CustomerPortalProvider>
)
