import React from 'react'

type UISubstituteIconProps = {
    size?: 'small' | 'medium' | 'large'
}

const sizeVariants = {
    small: 'ec-w-4 ec-h-4',
    medium: 'ec-w-5 ec-h-5',
    large: 'ec-w-6 ec-h-6'
}

export const SubstituteIcon = (
    { size = 'medium' }: UISubstituteIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="none" className={sizeVariants[size]}>
        <path
            d="M126.592 160H48C39.164 160 32 152.836 32 144C32 135.164 39.164 128 48 128H126.592C162.676 128 196.756 145.532 217.732 174.9L236.336 200.944L216.672 228.472L191.692 193.5C176.712 172.524 152.376 160 126.592 160ZM475.312 356.688L411.312 292.688C405.06 286.436 394.936 286.436 388.688 292.688C382.44 298.94 382.436 309.064 388.688 315.312L425.376 352H385.408C359.628 352 335.292 339.476 320.308 318.5L295.328 283.528L275.664 311.056L294.268 337.1C315.244 366.468 349.32 384 385.408 384H425.376L388.688 420.688C382.436 426.94 382.436 437.064 388.688 443.312C391.812 446.436 395.908 448 400 448C404.092 448 408.188 446.436 411.312 443.312L475.312 379.312C481.564 373.064 481.564 362.936 475.312 356.688ZM475.312 132.688L411.312 68.688C405.06 62.436 394.936 62.436 388.688 68.688C382.44 74.94 382.436 85.064 388.688 91.312L425.376 128H385.408C349.324 128 315.244 145.532 294.26 174.908L191.704 318.492C176.712 339.476 152.376 352 126.592 352H48C39.164 352 32 359.164 32 368C32 376.836 39.164 384 48 384H126.592C162.676 384 196.756 366.468 217.74 337.092L320.296 193.508C335.288 172.524 359.624 160 385.408 160H425.376L388.688 196.688C382.436 202.94 382.436 213.064 388.688 219.312C391.812 222.436 395.908 224 400 224C404.092 224 408.188 222.436 411.312 219.312L475.312 155.312C481.564 149.064 481.564 138.936 475.312 132.688Z"
            fill="#004B87"></path>
    </svg>
)
