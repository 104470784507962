import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../types'
import clsx from 'clsx'

export type UIBadgeProps = UIOmit<ComponentPropsWithoutRef<'div'>> & {
    style?: 'red' | 'blue'
}

const styleVariants = {
    red: 'ec-bg-red-light ec-text-white',
    blue: 'ec-bg-blue ec-text-white'
}

export const UIBadge = (
    {
        style = 'red',
        children,
        ...attributes
    }: UIBadgeProps
) => (
    <div className={clsx(
        'ec-inline-flex ec-items-center ec-justify-center ec-leading-none ec-text-xxs ec-uppercase ec-rounded-full',
        'whitespace-nowrap ec-min-w-5 ec-h-5 ec-p-1',
        styleVariants[style]
    )} {...attributes}>
        {children}
    </div>
)
