import React from 'react'
import { UIIconProps, UIIconVariantProps } from '../types'
import { sizeVariants } from './variants'

export type UIExclamationIcon = UIIconVariantProps & {
    shape?: 'circle' | 'triangle'
}

export const UIExclamationIcon = (
    { shape = 'circle', variant = 'outline', size = 'medium' }: UIExclamationIcon
) => {
    switch (true) {
        case (shape === 'circle' && variant === 'outline'):
            return <UIExclamationOutlineCircleIcon size={size}/>
        case (shape === 'circle' && variant === 'solid'):
            return <UIExclamationSolidCircleIcon size={size}/>
        case (shape === 'triangle' && variant === 'outline'):
            return <UIExclamationOutlineTriangleIcon size={size}/>
        case (shape === 'triangle' && variant === 'solid'):
            return <UIExclamationSolidTriangleIcon size={size}/>
    }
}

const UIExclamationOutlineCircleIcon = (
    { size }: UIIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
         className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
    </svg>
)

const UIExclamationOutlineTriangleIcon = (
    { size }: UIIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
         className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"/>
    </svg>
)

const UIExclamationSolidCircleIcon = (
    { size }: UIIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={sizeVariants[size]}>
        <path fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clipRule="evenodd"/>
    </svg>
)

const UIExclamationSolidTriangleIcon = (
    { size }: UIIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={sizeVariants[size]}>
        <path fillRule="evenodd"
              d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clipRule="evenodd"/>
    </svg>
)

