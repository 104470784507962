import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import { UIIconProps, UIOmit } from '@ui/types'
import {
    UIChevronDownIcon,
    UIChevronUpIcon,
    UIMinusIcon,
    UIPlusIcon,
    UITriangleDownIcon,
    UITriangleUpIcon
} from '../../../icons'

export type UIButtonExpandProps = UIOmit<ComponentPropsWithoutRef<'button'>> & {
    open?: boolean
    size?: 'small' | 'medium' | 'large'
    variant?: 'plus-minus' | 'chevron' | 'triangle'
    color?: 'inherit' | 'link'
}

export type UIButtonExpandSize<T = string> = Record<UIButtonExpandProps['size'], T>
export type UIButtonExpandColor<T = string> = Record<UIButtonExpandProps['color'], T>

type IconVariantProps = {
    open: boolean
    size: UIIconProps['size']
}

const sizeVariants: UIButtonExpandSize = {
    small: 'ec-text-s',
    medium: 'ec-text-sm',
    large: 'ec-text-base'
}

const colorVariants: UIButtonExpandColor = {
    inherit: 'ec-text-inherit',
    link: 'ec-text-blue-light'
}

const PlusMinusIcon = (
    { open, size }: IconVariantProps
) => (
    open
        ? <UIMinusIcon size={size}/>
        : <UIPlusIcon size={size}/>
)

const ChevronIcon = (
    { open, size }: IconVariantProps
) => (
    open
        ? <UIChevronUpIcon size={size}/>
        : <UIChevronDownIcon size={size}/>
)

const TriangleIcon = (
    { open, size }: IconVariantProps
) => (
    open
        ? <UITriangleUpIcon size={size}/>
        : <UITriangleDownIcon size={size}/>
)

export const UIButtonExpand = (
    {
        variant = 'chevron',
        color = 'link',
        size = 'medium',
        open = false,
        children,
        ...attributes
    }: PropsWithChildren<UIButtonExpandProps>
) => (
    <button className={`ec-flex ec-space-x-1 ec-items-center ${sizeVariants[size]} ${colorVariants[color]}`}
            {...attributes}>
        {variant === 'plus-minus' &&
            <PlusMinusIcon open={open} size={size}/>
        }
        <div>{children}</div>
        {variant === 'triangle' &&
            <TriangleIcon open={open} size={size}/>
        }
        {variant === 'chevron' &&
            <ChevronIcon open={open} size={size}/>
        }
    </button>
)
