import { request, GraphQlErrorResult } from '@model/api'
import { Part, PartModel, PartModelNumber, PartModelWid } from '../types'
import { getPartsListQuery } from './queries'
import { productFragment } from '@catalog/api'
import { Document } from '@documents/types'

export type GetPartsListApiParams = {
    modelNumber?: PartModelNumber
    modelWid?: PartModelWid
    documentModelNumber?: PartModelNumber
    documentManufacturer?: string
}

export type PartsListModelsParts = {
    models: PartModel[] | null
    parts: Part[] | null
}

type PartsListApiResult = {
    parts: PartsListModelsParts | null
    documents: Document[] | null
}

export type PartsListResult = PartsListApiResult & GraphQlErrorResult

export const getPartsListApi = async (
    params: GetPartsListApiParams
): Promise<PartsListResult> => {
    const { modelNumber, modelWid, ...otherParams } = params
    const apiParams = modelWid ? { modelWid, ...otherParams } : params

    return request<PartsListApiResult>([getPartsListQuery, productFragment], apiParams).then(data => ({
        parts: data.data?.data?.parts || null,
        documents: data.data?.data?.documents || null,
        errors: data.data?.errors || null
    }))
}
