import { GraphQlErrorResult, request } from '@model/api'
import { branchFragment, switchCurrentBranchQuery } from './query'
import { Branch } from '../types'

export type SwitchCurrentBranchApiParams = {
    branchId: number
}

type SwitchCurrentBranchApiResponse = {
    switchCurrentBranch: Branch | null
}

export type SwitchCurrentBranchResult = GraphQlErrorResult & {
    currentBranch: Branch | null
}

export const switchCurrentBranchApi = async (
    { branchId }: SwitchCurrentBranchApiParams
): Promise<SwitchCurrentBranchResult> => (
    request<SwitchCurrentBranchApiResponse>([switchCurrentBranchQuery, branchFragment], { branchId })
        .then(data => ({
            currentBranch: data?.data?.data?.switchCurrentBranch[0] ?? null,
            errors: data.data?.errors || null
        }))
)
