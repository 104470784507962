import { useContext } from 'react'
import { OrderTemplateStateContext } from '../../context'

export const useOrderTemplate = () => {
    const context = useContext(OrderTemplateStateContext)

    if (!context) {
        console.warn('useCatalog must be used within the CatalogProvider')
    }
    return context
}
