import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useBranch } from '../../hooks'
import { formatPhoneNumber } from '@utils'
import intl from './intl'

interface AssistanceProps {
    size?: 'medium' | 'small'
}

const sizeVariants = {
    medium: 'ec-text-sm',
    small: 'ec-text-s'
}

export const Assistance = (
    { size = 'medium' }: AssistanceProps
) => {
    const { currentBranch } = useBranch()
    const phoneNumber = formatPhoneNumber(currentBranch?.phone || '')

    return (
        <div className={sizeVariants[size]}>
            <FormattedMessage {...intl.action} values={{
                phone: <a className="ec-block" href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            }}/>
        </div>
    )
}
