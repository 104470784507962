import React from 'react'
import { AppProvider } from '@app/context'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { Contact } from '../Contact'
import { MapProvider } from '@map/context'
import { ErrorMessage } from '@ui/components'
import { ErrorBoundary } from '@app/components'

export const BranchContactApp = () => (
    <ErrorBoundary fallback={<ErrorMessage section/>}>
        <AppProvider>
            <AnalyticsProvider area={AnalyticsArea.Default}>
                <MapProvider>
                    <Contact/>
                </MapProvider>
            </AnalyticsProvider>
        </AppProvider>
    </ErrorBoundary>
)
