import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import {BranchStructure} from "./BranchStructure";
import {useInventoryFinderState} from "../../state";
import {BranchSkeleton } from './Skeleton/BranchSkeleton'

export const Nearby = () => {

    const {
        branches,
        distances,
        visibleMarkers,
        currentBranch,
        preferredBranch,
        isBranchesLoading,
        isLoadingData,
        setZoom
    } = useInventoryFinderState();

    const intl = useIntl();

    const nearbyBranchesTitle = intl.formatMessage({
        id: 'nearby.branches.title',
        defaultMessage: 'Nearby Branches'
    });

    if (isBranchesLoading || isLoadingData) {
        return (<BranchSkeleton/>)
    }

    if (branches?.length === 0 || distances.length === 0) return null;

    const sortedBranches = branches
        .filter(branch => branch.branch_id !== currentBranch?.branch_id || branch.branch_id !== preferredBranch?.branch_id)
        .sort((a, b) => distances[a.branch_id] - distances[b.branch_id]);
    const closestSixBranches = sortedBranches.slice(0, 6);
    const visibleMarkerIDs = visibleMarkers.map(marker => marker.branch_id);
    const visibleSortedBranches = sortedBranches.filter(branch =>
        visibleMarkerIDs.includes(branch.branch_id) && branch.branch_id !== currentBranch?.branch_id
    );

    const noBranchesVisible = visibleSortedBranches.length === 0;

    const isShowMoreButtonVisible = (visibleSortedBranches.length > 0
        && visibleSortedBranches.length < branches.length - 1) || visibleSortedBranches.length === 0;

    const branchStructureData = useMemo(() => {
            if (visibleSortedBranches.length === 1 && currentBranch?.branch_id === visibleSortedBranches[0].branch_id) {
                return closestSixBranches;
            }
            return noBranchesVisible ? closestSixBranches : visibleSortedBranches
        },
        [noBranchesVisible, visibleSortedBranches, closestSixBranches, currentBranch?.branch_id]
    )

    const loadMoreBranches = () => {
        if (visibleSortedBranches.length < 2) {
            setZoom(8);
            return;
        }
        setZoom(prevZoom => prevZoom - 2);
    }

    return (
        <div>
           <div>
                <h2>{nearbyBranchesTitle}</h2>
                <BranchStructure data={branchStructureData}/>
                {isShowMoreButtonVisible && <a className="ec-w-full ec-block ec-text-center ec-uppercase ec-pt-0 ec-pb-5 ec-cursor-pointer"
                   onClick={loadMoreBranches}>
                    <FormattedMessage
                        id="nearby.branches.loadMore"
                        defaultMessage="Load more"
                    />
                </a>}
            </div>
        </div>
    )
}
