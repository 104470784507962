import {useState, useEffect, useMemo} from "react";

export const useWindowSize = () => {
    const breakPointRef = 1025;

    const [values, setValues] = useState(null);

    const handleResize = () => {
        setValues(JSON.stringify({width: window.innerWidth, height: window.innerHeight}));
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobile = useMemo(() => window.innerWidth < breakPointRef, [values]);
    const width = useMemo(() => window.innerWidth, [values]);
    const height = useMemo(() => window.innerHeight, [values]);

    return {
        width,
        height,
        isMobile,
    }
};
