import React, { createContext, useContext, PropsWithChildren } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getInventoryApi, InventoryResult } from '../api'
import { useCustomer } from '@customer/hooks'
import { useBranch } from '@branch/hooks'
import { Product } from '@catalog/types'

type InventoryProviderProps = PropsWithChildren & {
    products: Product[]
}

export type InventoryStateProvider = {
    inventoryQuery: UseQueryResult<InventoryResult>
}

const InventoryStateProvider = createContext<InventoryStateProvider>(null)

export const InventoryProvider = (
    { products, children }: InventoryProviderProps
) => {
    const { isLoggedIn } = useCustomer()
    const { currentBranch } = useBranch()

    const pimIds = products.map(product => product.pim_id).sort()

    const inventoryQuery = useQuery({
        queryKey: ['inventory', currentBranch?.branch_id, pimIds.join(',')],
        queryFn: async () => getInventoryApi({ products: pimIds }),
        enabled: isLoggedIn && !!currentBranch && pimIds.length > 0
    })

    return (
        <InventoryStateProvider.Provider value={{ inventoryQuery }}>
            {children}
        </InventoryStateProvider.Provider>
    )
}

export const useInventoryState = () => {
    const context = useContext(InventoryStateProvider)

    if (!context) {
        console.warn('useInventoryState must be used within the InventoryProvider')
    }
    return context
}
