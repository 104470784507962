export default {
    empty: {
        id: 'ui.table.empty',
        defaultMessage: 'No data found'
    },
    pagination: {
        prev: {
            id: 'ui.table.pagination.prev',
            defaultMessage: 'Prev'
        },
        prevLabel: {
            id: 'ui.table.pagination.prev.label',
            defaultMessage: 'Go to the previous page'
        },
        next: {
            id: 'ui.table.pagination.next',
            defaultMessage: 'Next'
        },
        nextLabel: {
            id: 'ui.table.pagination.next.label',
            defaultMessage: 'Go to the next page'
        },
        currentLabel: {
            id: 'ui.table.pagination.current.label',
            defaultMessage: 'You\'re currently reading page {page}'
        },
        pageLabel: {
            id: 'ui.table.pagination.page.label',
            defaultMessage: 'Go to page {page}'
        },
        label: {
            id: 'ui.table.pagination.label',
            defaultMessage: 'Pagination Navigation'
        },
        itemsCount: {
            id: 'ui.table.pagination.itemsCount',
            defaultMessage: '{from}-{to} of {total} items'
        },
        itemsCountSimple: {
            id: 'ui.table.pagination.itemsCount',
            defaultMessage: '{total} {total, plural, one {item} other {items}}'
        }
    }
}
