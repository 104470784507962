import React, { createContext, PropsWithChildren } from 'react'
import { getWingmanViewApi, WingmanViewResult } from '../api'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'

type WingmanViewProviderProps = PropsWithChildren & {
    id?: string | null
}

export type WingmanViewProviderState = {
    query: UseQueryResult<WingmanViewResult>
}

export const WingmanViewContext = createContext<WingmanViewProviderState>(null)

export const WingmanViewProvider = (
    { id = null, children }: WingmanViewProviderProps
) => {
    const query = useQuery({
        queryKey: ['wingman', id],
        queryFn: async () => {
            const result = await getWingmanViewApi({ id })
            result.errors && result.errors.forEach(error => toast.error(error.message))

            return result
        },
        enabled: !!id
    })

    return (
        <WingmanViewContext.Provider value={{ query }}>
            {children}
        </WingmanViewContext.Provider>
    )
}
