import React from 'react'
import { FormattedMessage } from 'react-intl'

type BranchStockMessageProps = {
    currentStock: boolean
    nearbyStock: boolean
    transferStock: boolean
}

export const BranchStockMessage = (
    { currentStock, nearbyStock, transferStock }: BranchStockMessageProps
) => {
    if (currentStock) {
        return <FormattedMessage id="inventory.standard.current"
                                 defaultMessage="Available at Branch"/>
    }

    if (nearbyStock) {
        return <FormattedMessage id="inventory.standard.nearby"
                                 defaultMessage="Available at Nearby Branches"/>
    }

    if (transferStock) {
        return <FormattedMessage id="inventory.standard.transfer"
                                 defaultMessage="Available for Transfer"/>
    }

    return <FormattedMessage id="inventory.standard.outOfStock"
                             defaultMessage="Out of Stock"/>
}
