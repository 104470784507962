import React, { ComponentPropsWithoutRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useCustomerPortal } from '../../hooks'
import intl from './intl'
import { UIChevronRightIcon } from '@ui/icons'
import { useIntl } from 'react-intl'
import { CUSTOMER_PORTAL_PATH } from '@customer/config'
import { useCustomer } from '@customer/hooks'

type BreadcrumbsProps = ComponentPropsWithoutRef<'nav'>

export type Breadcrumb =
    | BreadcrumbWithoutPath
    | BreadcrumbWithPath

type BreadcrumbWithoutPath = {
    label: string
    path?: never
    app?: never
}

type BreadcrumbWithPath = {
    label: string
    path: string
    app?: boolean
}

export const Breadcrumbs = (
    { children, ...attributes }: BreadcrumbsProps
) => {
    const { formatMessage } = useIntl()
    const { isAssociate } = useCustomer()
    const { breadcrumbs } = useCustomerPortal()

    const homeIntl = isAssociate
        ? intl.associatePortal
        : intl.customerPortal

    return (
        <nav aria-label={formatMessage(intl.breadcrumbsTitle)} {...attributes}>
            <ol role="list" className="ec-flex ec-text-s ec-items-center">
                <Crumb label={formatMessage(homeIntl)}
                       path={CUSTOMER_PORTAL_PATH}
                       app={false}/>
                <Divider/>
                {breadcrumbs.map((crumb, index) => (
                    <Fragment key={crumb.label || 'undefined'}>
                        <Crumb label={crumb.label} path={crumb.path}/>
                        {index !== breadcrumbs.length - 1 && <Divider/>}
                    </Fragment>
                ))}
            </ol>
        </nav>
    )
}

const Crumb = (
    { label, path, app = true }: Breadcrumb
) => {
    if (!path) {
        return (
            <li className="ec-flex ec-items-center">
                <span>{label || <Skeleton className="ec-w-28"/>}</span>
            </li>
        )
    }

    return (
        <li className="ec-flex ec-items-center">
            {app
                ? <Link to={path}>{label || <Skeleton className="ec-w-28"/>}</Link>
                : <a href={path}>{label || <Skeleton className="ec-w-28"/>}</a>}
        </li>
    )
}

const Divider = () => (
    <div className="ec-mx-1">
        <UIChevronRightIcon size="extra-small" stroke="semibold"/>
    </div>
)
