import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { UIOmit } from '../../../types'
import clsx from 'clsx'

export type UIInputLabelProps = UIOmit<ComponentPropsWithoutRef<'label'>> & {
    label: ReactNode | undefined | null
    size?: 'small' | 'medium' | 'large'
    active?: boolean
    error?: boolean
    required?: boolean
}

export type UIInputLabelSize<T = string> = Record<UIInputLabelProps['size'], T>

const sizeDefault: UIInputLabelSize = {
    small: 'peer-placeholder-shown:ec-text-xs',
    medium: 'peer-placeholder-shown:ec-text-sm',
    large: 'peer-placeholder-shown:ec-text-sm'
}

const sizeVariants: UIInputLabelSize = {
    small: 'peer-placeholder-shown:ec-leading-6 peer-placeholder-shown:ec-py-[2px] peer-focus:ec-py-0',
    medium: 'peer-placeholder-shown:ec-leading-8 peer-placeholder-shown:ec-py-[2px] peer-focus:ec-py-0',
    large: 'peer-placeholder-shown:ec-leading-10  peer-placeholder-shown:ec-py-[1px] peer-focus:ec-py-0'
}

export const UIInputLabel = (
    { label, size = 'medium', active, error, required, ...attributes }: UIInputLabelProps
) => {
    if (!label) return null

    if (active) {
        return (
            <label className={clsx(
                'ec-absolute ec-left-0 ec-pointer-events-none ec-text-xs ec-font-medium',
                'ec-translate-x-0 -ec-translate-y-4 ec-top-0',
                {
                    'after:ec-content-["*"] after:ec-ml-0.5': required,
                    'ec-text-gray-darker/75': !error,
                    'ec-text-red/75': error
                }
            )} {...attributes}>
                {label}
            </label>
        )
    }

    return (
        <label className={clsx(
            // Value (label up)
            'ec-absolute ec-transition-all ec-duration-300 ec-left-0 ec-pointer-events-none',
            'ec-translate-x-0 -ec-translate-y-4 ec-text-xs ec-font-medium',
            // Focus (label up)
            'peer-focus:ec-translate-x-0 peer-focus:-ec-translate-y-4 peer-focus:ec-top-0 peer-focus:ec-text-xs',
            'peer-focus:ec-font-medium',
            // Placeholder (label down)
            // 'peer-placeholder-shown:ec-translate-x-2 peer-placeholder-shown:-ec-translate-y-1/2',
            `peer-placeholder-shown:ec-translate-x-2 peer-placeholder-shown:-ec-translate-y-0 ${sizeVariants[size]}`,
            `${sizeDefault[size]} peer-placeholder-shown:ec-font-normal'`,
            {
                'after:ec-content-["*"] after:ec-ml-0.5': required,
                'ec-text-gray-darker/75': !error,
                'ec-text-red/75': error
            }
        )} {...attributes}>
            {label}
        </label>
    )
}
