import React from 'react'
import { Product } from '../../../types'

type ProductBrandProps = {
    product: Product
}

export const ProductBrand = (
    { product }: ProductBrandProps
) => (
    <div className="ec-text-gray-darker/75">
        {product.brand_label}
    </div>
)
