import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Container, CustomerPortal, Loader } from '@customerPortal/components'
import { useIntl } from 'react-intl'
import { UIPanel } from '@ui/components'
import { WingmanLocationState, WingmanViewProvider } from '../context'
import { Description, Details, Tabs } from '../components/View'
import { useWingmanView } from '../hooks'
import { WINGMAN_LIST_PATH } from '../config'
import intl from './intl'

export const View = () => {
    const { id } = useParams<{ id: string }>()

    return (
        <WingmanViewProvider id={id}>
            <ViewContent/>
        </WingmanViewProvider>
    )
}

const ViewContent = () => {
    const { formatMessage } = useIntl()
    const { state } = useLocation<WingmanLocationState>()
    const { query } = useWingmanView()

    const wingman = query?.data?.wingman || state?.wingman || null

    return (
        <CustomerPortal title={wingman?.case_number}
                        breadcrumbs={[
                            { label: formatMessage(intl.title), path: WINGMAN_LIST_PATH },
                            { label: wingman?.case_number }
                        ]}>
            <Container>
                <Container.Content>
                    {wingman &&
                        <UIPanel>
                            <UIPanel.Body>
                                <Details/>
                            </UIPanel.Body>
                        </UIPanel>
                    }
                    <Loader show={query.isFetching}>
                        <UIPanel>
                            <UIPanel.Body>
                                <Description/>
                            </UIPanel.Body>
                        </UIPanel>
                        <Tabs/>
                    </Loader>
                </Container.Content>
            </Container>
        </CustomerPortal>
    )
}
