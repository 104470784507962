import React from 'react'
import { useWingmanView } from '../../../hooks'
import { UIDateTime } from '@ui/components'
import { Section } from '@customerPortal/components'

export const Feeds = (
) => {
    const { query } = useWingmanView()
    const feeds = query?.data?.wingman.feeds || []

    return (
        <Section>
            <Section.Item span={4}>
                <ul className="ec-space-y-4 ec-w-full">
                    {feeds.map(feed => (
                        <li key={feed.id} className="ec-space-y-1">
                            <div className="ec-flex ec-flex-col ec-items-baseline lg:ec-flex-row lg:ec-space-x-2">
                                <p className="ec-m-0 ec-font-medium">
                                    {feed.created_by.name}
                                </p>
                                <p className="ec-m-0 ec-text-s ec-text-gray-dark ec-font-light">
                                    <UIDateTime date={feed.created_date} withTime/>
                                </p>
                            </div>
                            <div className="ec-bg-gray-lightest ec-p-4 ec-text-sm">
                                <p className="ec-m-0 ec-break-words">{feed.body}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </Section.Item>
        </Section>
    )
}
