import React, {memo, useEffect, useMemo, useState} from 'react'
import cn from 'clsx'
import { useIntl } from 'react-intl'
import { useWindowSize } from '../../../../Various'
import { UIModal, UIBadge } from '@ui/components'
import { MiniCart, getPricing, useCart } from '../../../../Checkout'
import { CartIcon } from '../../../icons'
import { useAnalytics } from '../../../../Analytics'
import {useThemeState} from "../../../state";
import eventBus from "../../../../../models/events/eventBus";
import { ADD_TO_CART_EVENT } from '../../../../Catalog'
import { useCustomerData} from "../../../../Customer";
import {GuestCart} from "./GuestCart";
import {isPropsAreEqual} from "../../../../../utils";

export const Cart = memo(() => {
    const intl = useIntl();
    //using this is bad because the object will trigger updates on all the components it uses
    //just importing  it without using it will trigger updates, a god state update in providers will ensure that the data is updated once
    // const {localStorageData} = useStorageContext();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { isMobile } = useWindowSize();
    const { cart, reloadCart, itemsCount } = useCart();
    const { trackViewMiniCart } = useAnalytics();
    const {isCheckout} = useThemeState();
    const {isLoggedIn} = useCustomerData();

    const cartLabel = intl.formatMessage({ id: 'account.header.top', defaultMessage: 'Shopping Cart' });
    const cartLabelMobile = intl.formatMessage({ id: 'account.header.top', defaultMessage: 'Cart' });

    const handleCartOpen = () => !isCheckout && setIsOpen(true)

    const products = useMemo(() => cart?.items.map(cartItem => ({
        product: cartItem.product,
        pricing: getPricing(cartItem)
    })) || [], [JSON.stringify(cart?.items)]);

    useEffect(() => {
        if (isOpen) {
            void trackViewMiniCart({ products })
            reloadCart();
        }
    }, [isOpen]);

    useEffect(() => {
        eventBus.on(ADD_TO_CART_EVENT, handleCartOpen)

        return () => {
            eventBus.off(ADD_TO_CART_EVENT, handleCartOpen);
        };
    }, []);

    const handleSetIsOpen = () => {

        if (isCheckout) {
            window.location.href = '/checkout/cart';
        } else {
            setIsOpen(!isOpen);
        }
    };

    if(!isLoggedIn ) {
        return <GuestCart/>
    }

    return (
        <div>
            <div onClick={handleSetIsOpen}
                 className={cn("ec-flex ec-overflow-visible ec-h-10 ec-flex-col ec-cursor-pointer", {
                     'ec-mr-6 ec-top-[-7px] ec-relative': !isMobile
                 })}>
                <div className={cn('ec-flex ec-items-center ec-justify-around ec-relative ', {
                    'ec-flex-wrap ec-flex-col': isMobile,
                    'ec-flex-row-reverse': !isMobile
                })}>
                    {!!itemsCount &&
                        <div className={cn('ec-absolute ec-text-white', '-ec-top-2 -ec-right-2')}>
                            <UIBadge style="blue">{itemsCount}</UIBadge>
                        </div>
                    }
                    <CartIcon/>
                    <span className={cn('ec-text-white ec-relative lg:ec-mr-1', { 'ec-text-xs': isMobile })}>
                        {isMobile ? cartLabelMobile : cartLabel}
                        </span>
                </div>
            </div>
            <UIModal open={isOpen}
                     slide={true}
                     title={isMobile ? cartLabelMobile : cartLabel}
                     onClose={() => setIsOpen(false)}>
                <MiniCart/>
            </UIModal>
        </div>
    )
}, isPropsAreEqual);

Cart.whyDidYouRender = true;
