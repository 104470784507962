export default {
    substitute: {
        id: 'product.badge.substitute',
        defaultMessage: 'Substitute'
    },
    supersede: {
        id: 'product.badge.supersede',
        defaultMessage: 'Supersede'
    }
}
