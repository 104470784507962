import React from "react";
import { MenuProvider, useThemeState } from "../../state";
import { MainMenuMobile } from "./Menu/MainMenuMobile";
import { SearchContainer } from "./Search/SearchContainer";
import { Logo } from "./Logo/Logo";
import { UserMenu } from "./Menu/UserMenu/UserMenu";
import { BranchLocator } from "./BranchLocator/BranchLocator";
import { Cart } from "./Cart/Cart";
import { userMenu } from "../../state/context/userMenu";
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import {ErrorCart} from "./ErrorReplacements/ErrorCart";
import { ErrorBoundary } from '@app/components'
import {ErrorUserMenu} from "./ErrorReplacements/ErrorUserMenu";
import {AccountProvider} from "../../../Account";
import {ErrorBranchLocator} from "./ErrorReplacements/ErrorBranchLocator";
import {BranchProvider} from "../../../Branch";
import {MapProvider} from "../../../Map";
import {BranchesInventoryProvider} from "../../../Inventory";
import {InventoryFinderProvider} from "../../../InventoryFinder";
import {ErrorMenus} from "./ErrorReplacements/ErrorMenus";
import {YupProvider} from "../../../Yup";
import {CartProvider} from "../../../Checkout";
import {ErrorSearch} from "./ErrorReplacements/ErrorSearch";
import {getAnalyticsConfigs} from "../../../Analytics";
import {AnalyticsContextProvider} from "@watsco/analytics-react";
import {StorageProvider} from "../../../Storage";
import { linksMenu } from '../../state/context/linksMenu'

export const HeaderContainerMobile = () => {
    const { headerStyle, config } = useThemeState();
    const menuData = config.menuData;
    menuData.links = linksMenu;

    const analyticsConfigs = getAnalyticsConfigs(window.pureAppConfig.analytics || {})

    return (
        <div className={headerStyle.headerContainerMobile}>
            <div className={'ec-absolute ec-w-full'}>
                <ErrorBoundary fallback={<ErrorMenus/>}>
                    <StorageProvider>
                        <AccountProvider>
                            <MenuProvider config={{menuClass: 'react-main-menu', overlayId: 'menu-overlay-main'}}
                                          data={menuData}>
                                <MainMenuMobile/>
                            </MenuProvider>
                        </AccountProvider>
                    </StorageProvider>
                </ErrorBoundary>
            </div>

            <div className={'ec-flex ec-flex-row ec-ml-10 ec-z-[22] ec-relative'}>
                <div className={'ec-flex'}>
                    <div>
                        <Logo />
                    </div>
                </div>
                <div className={'ec-flex ec-justify-end ec-ml-auto'}>
                    <ErrorBoundary fallback={<ErrorUserMenu/>}>
                        <StorageProvider>
                            <AccountProvider>
                                <MenuProvider config={{ menuClass: 'react-main-menu', overlayId: 'menu-overlay-main' }} data={userMenu}>
                                        <UserMenu />
                                </MenuProvider>
                            </AccountProvider>
                        </StorageProvider>
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<ErrorBranchLocator/>}>
                        <StorageProvider>
                            <BranchProvider>
                                <MapProvider>
                                    <BranchesInventoryProvider>
                                        <InventoryFinderProvider>
                                            <BranchLocator />
                                        </InventoryFinderProvider>
                                    </BranchesInventoryProvider>
                                </MapProvider>
                            </BranchProvider>
                        </StorageProvider>
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<ErrorCart/>}>
                        <StorageProvider>
                            <YupProvider>
                                <BranchProvider>
                                    <AccountProvider>
                                        <AnalyticsContextProvider configs={analyticsConfigs}>
                                            <AnalyticsProvider area={AnalyticsArea.Cart}>
                                                <CartProvider>
                                                    <Cart/>
                                                </CartProvider>
                                            </AnalyticsProvider>
                                        </AnalyticsContextProvider>
                                    </AccountProvider>
                                </BranchProvider>
                            </YupProvider>
                        </StorageProvider>
                    </ErrorBoundary>
                </div>
            </div>
            <ErrorBoundary fallback={<ErrorSearch/>}>
                <StorageProvider>
                    <YupProvider>
                        <BranchProvider>
                            <AccountProvider>
                                <AnalyticsContextProvider configs={analyticsConfigs}>
                                    <AnalyticsProvider area={AnalyticsArea.Header}>
                                        <SearchContainer/>
                                    </AnalyticsProvider>
                                </AnalyticsContextProvider>
                            </AccountProvider>
                        </BranchProvider>
                    </YupProvider>
                </StorageProvider>
            </ErrorBoundary>

            <div className="ec-fixed ecmd:inset-0 ec-bg-black ec-opacity-50 ec-hidden" id={'menu-overlay-main'} />
        </div>
    );
}
