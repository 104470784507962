import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../../types'
import clsx from 'clsx'

export const UITableBodyCellHeader = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'span'>>
) => (
    <span className={clsx(
        'ec-text-s ec-leading-5 ec-font-medium ec-mr-1_ ec-text-gray-dark',
        'lg:ec-hidden after:ec-content-[\':\']'
    )} {...attributes}>
        {children}
    </span>
)
