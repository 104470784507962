import { useContext } from 'react'
import { PricingContext } from '../../context'
import { Product } from '@catalog/types'

export const usePricing = (product?: Product) => {
    const context = useContext(PricingContext)
    const { query } = context || {}

    if (!context) {
        console.warn('usePricing must be used within the PricingProvider')
    }

    const getProductPricing = (product?: Product) => {
        const pricing = query.data?.pricing || []

        if (!product || !pricing.length) return null
        return query?.data.pricing.find(price => price.pim_id === product.pim_id) || null
    }

    const pricing = getProductPricing(product)
    const errors = query?.data?.errors || null

    return {
        query,
        pricing,
        errors,
        getProductPricing
    }
}
