import { AnalyticsTrackContext } from '../types'
import { ProductInfo } from '@watsco/analytics'
import { getProduct } from './getProduct'

export const getProducts = (
    context: AnalyticsTrackContext
): ProductInfo[] => (
    context.products?.map((productsData, index) => {
        const productContext: AnalyticsTrackContext = {...context, index, ...productsData}
        return getProduct(productContext)
    }) || []
)
