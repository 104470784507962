import React, { ComponentPropsWithoutRef } from 'react'
import { ColumnMeta } from '@tanstack/table-core'
import { UIOmit } from '../../../../types'

export type UITableHeadCellProps = UIOmit<ComponentPropsWithoutRef<'th'>, 'align'> & {
    align?: ColumnMeta<any, any>['align']
}

const alignVariants = {
    'center': 'lg:ec-text-center',
    'top-left': 'lg:ec-text-left',
    'top-center': 'lg:ec-text-center',
    'top-right': 'lg:ec-text-right',
    'bottom-left': 'lg:ec-text-left',
    'bottom-center': 'lg:ec-text-center',
    'bottom-right': 'lg:ec-text-right',
    'center-left': 'lg:ec-text-left',
    'center-right': 'lg:ec-text-right'
}

export const UITableHeadCell = (
    { align = 'center-left', children, ...attributes }: UITableHeadCellProps
) => (
    <th className={`ec-p-3 ec-font-medium ${alignVariants[align]}`} {...attributes}>
        <span>{children}</span>
    </th>
)
