import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'react-hook-form'
import { UIInput, UIModal, UITextarea, UIModalProps } from '@ui/components'
import { useOrderTemplate } from '../../../hooks'
import { CreateOrderTemplateParams } from '../../../context'
import { Product } from '@catalog/types'
import { useYup } from '@yup/context'
import intl from './../intl'

type ModalProps = Pick<UIModalProps, 'open' | 'onClose'> & {
    products: Product[]
}

export const Modal = (
    { products, open, onClose }: ModalProps
) => {
    const { yup, resolver, errorMessage } = useYup()
    const { createOrderTemplate } = useOrderTemplate()

    const validationSchema = yup.object({
        name: yup.string().required(),
        description: yup.string().max(255)
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: resolver(validationSchema)
    })

    const onCreate = (data: CreateOrderTemplateParams) => {
        createOrderTemplate.mutate({ ...data, products }, {
            onSuccess: () => onClose()
        })
    }

    return (
        <UIModal open={open}
                 onClose={onClose}
                 title={<FormattedMessage {...intl.create}/>}
                 buttons={[
                     {
                         children: <FormattedMessage {...intl.formCancel}/>,
                         disabled: createOrderTemplate.isLoading
                     },
                     {
                         children: <FormattedMessage {...intl.formSave}/>,
                         loading: createOrderTemplate.isLoading,
                         onClick: () => {
                             handleSubmit(onCreate)()
                         }
                     }
                 ]}>
            <form onSubmit={handleSubmit(onCreate)}>
                <div className="ec-flex ec-flex-col ec-gap-4 ec-w-full">
                    <UIInput name="name"
                             label={<FormattedMessage {...intl.formName}/>}
                             disabled={createOrderTemplate.isLoading}
                             error={errorMessage(errors, 'name')}
                             register={register('name')}
                             required/>
                    <UITextarea name="description"
                                maxLength={255}
                                label={<FormattedMessage {...intl.formDescription}/>}
                                disabled={createOrderTemplate.isLoading}
                                error={errorMessage(errors, 'description')}
                                register={register('description')}/>
                </div>
            </form>
        </UIModal>
    )
}
