import React from 'react'
import { UIIconOutlineProps } from '../types'
import { sizeVariants, strokeVariants } from './variants';

export const UIChevronLeftIcon = (
    { size = 'medium', stroke = 'medium' }: UIIconOutlineProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
         strokeWidth={strokeVariants[stroke]} className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    </svg>
)
