import React from "react";
import {cn} from "../../../../../utils";
import {FormattedMessage} from "react-intl";
import {useThemeState} from "../../../state";

export const ErrorMenus = () => {
    const {headerStyle} = useThemeState();

    return (<div className={cn(headerStyle.horizontalMenu, 'open-sans-font-area', 'ec-text-white', 'ec-px-4')}>
        <FormattedMessage id="headerMenuError.ErrorMenu" defaultMessage="Site navigaton encountered a problem."/>
    </div>)
}
