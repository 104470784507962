import { useState } from 'react'

export type UseUIChipProps = {
    items?: (string | UIChipItem)[]
    submitKeys?: string[]
    validate?: (chip: UIChipItem) => string | boolean | null
    messages?: {
        errorDefault?: string
        errorUnique?: string
    }
}

export type UIChipItem = {
    id: string
    label?: string
    disabled?: boolean
    [key: string]: any
}

const getChip = (item: string | UIChipItem): UIChipItem => (
    typeof item === 'string'
        ? { id: item }
        : item
)

export const useUIChip = (
    options?: UseUIChipProps
) => {
    const { items = [], validate, messages } = options || {}

    const [chips, setChips] = useState(items.map(getChip))
    const [error, setError] = useState<string | null>(null)

    const addItem = (item: string | UIChipItem): boolean => {
        const chip = getChip(item)

        if (isValid(chip)) {
            setChips([...chips, chip])
            return true
        }
        return false
    }

    const setItems = (items: (string | UIChipItem)[]) => {
        setChips(items.map(getChip))
    }

    const removeItem = (chip: UIChipItem) => {
        const list = chips.filter(({ id }) => chip.id !== id)
        setChips(list)
        setError(null)
    }

    const isValid = (chip: UIChipItem): boolean => {
        const isUnique = !chips.some(({ id }) => chip.id === id)

        if (!isUnique) {
            setError(messages?.errorUnique || 'Item has already been added.')
            return false
        }

        const error = validate && validate(chip)

        if (error) {
            setError(typeof error !== 'string'
                ? messages?.errorDefault || 'Item is not valid.'
                : error)
            return false
        }
        return true
    }

    return {
        chips,
        error,
        setError,
        addChip: addItem,
        setChips: setItems,
        removeChip: removeItem
    }
}
