import React from 'react'
import { UIIconProps } from '../types'
import { sizeVariants } from './variants'

export const UIArrowLeftIcon = (
    { size = 'medium' }: UIIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
         stroke="currentColor" className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
    </svg>

)
