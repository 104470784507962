import { MenuItemType } from './MenuStateProvider'

export const linksMenu: MenuItemType[] = [
    {
        name: 'Quick Order',
        class: '',
        href: '/quickorder',
        id: 'quickorder',
        level: 1,
        children: []
    },
    {
        name: 'Warranty Search',
        class: '',
        href: '/using-our-website/warranty-lookup',
        href_logged_in: '/warranty',
        id: 'warranty',
        level: 1,
        children: [],
    },
    {
        name: 'Parts List & Documents',
        class: '',
        href: '/using-our-website/parts-list-docs-lookup/',
        href_logged_in: '/parts',
        id: 'parts-list-docs',
        level: 1,
        children: [],
    }
]
