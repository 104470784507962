import React, { ReactNode } from 'react'
import Skeleton from 'react-loading-skeleton'
import { AddressExtended } from '@ui/types'
import { UIAddress } from '@ui/components'
import { useBranch } from '../../hooks'
import { Branch } from '../../types'

type AddressProps = {
    branch: Branch
    isLoading?: boolean
    title?: ReactNode
}

export const Address = (
    { branch, title, isLoading = false }: AddressProps
) => {
    const { getBranchName } = useBranch()

    const address: AddressExtended = {
        ...branch.address,
        title: getBranchName(branch),
        phone: branch.phone
    }

    return !isLoading
        ? <UIAddress title={title} address={address}/>
        : <Loader/>
}

const Loader = () => (
    <div className="ec-text-sm">
        <Skeleton count={5} className="ec-w-1/2"/>
    </div>
)
