import { GraphQlError, request } from '@model/api'
import { productFragment } from '@catalog/api/queries'
import { getPricingQuery } from './query'
import { Price } from '../types'

export type PricingApiParams = {
    products: string[]
}

export type GetPricingRawResponse = {
    pricing: Price[]
}

export type PricingResult = {
    pricing: Price[]
    errors?: GraphQlError[]
}

export const getPricingApi = async (params: PricingApiParams): Promise<PricingResult> => (
    request<GetPricingRawResponse>([getPricingQuery, productFragment], params).then(data => ({
        pricing: data.data.data.pricing,
        errors: data.data?.errors
    }))
)
