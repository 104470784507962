import React from 'react'
import { useFormContext } from 'react-hook-form'
import { UIButton, UISticky } from '@ui/components'
import { useIntl } from 'react-intl'
import { usePreferencesState } from '../../state'
import clsx from 'clsx'

export const SaveChangesButton = () => {
    const { formState: { isDirty } } = useFormContext()
    const { isLoading, isEditMode, onEditClick } = usePreferencesState()
    const intl = useIntl();

    const save = intl.formatMessage({
        id: 'preferences.save.changes.button',
        defaultMessage: 'Save Changes'
    })

    const edit = intl.formatMessage({
        id: 'preferences.edit.button',
        defaultMessage: 'Edit'
    })

    const cancel = intl.formatMessage({
        id: 'preferences.cancel.button',
        defaultMessage: 'Cancel'
    })

    return (
        <div className="xl:ec-flex xl:ec-h-full">
            <div className={clsx(
                'ec-transition lg:ec-flex ec-flex-row-reverse xl:ec-block xl:ec-sticky xl:ec-bottom-6',
                'xl:ec-w-full xl:ec-self-end'
            )}>
                <UISticky>
                    {!isEditMode && <UIButton
                        onClick={() => {onEditClick(false) }}
                        variant={'outlined'}
                        type={'button'}
                        fullWidth={true}
                        size={'large'}>
                        {edit}
                    </UIButton>
                    }
                    {isEditMode && <UIButton
                        onClick={() => {onEditClick(true)}}
                        variant={'outlined'}
                        type={'button'}
                        fullWidth={true}
                        disabled={isLoading}
                        size={'large'}>
                        {cancel}
                    </UIButton>
                    }
                    {isEditMode && <div className={'ec-pt-4'}>
                        <UIButton
                            type={'submit'}
                            loading={isLoading}
                            fullWidth={true}
                            disabled={isLoading}
                            size={'large'}>
                            {save}
                        </UIButton>
                    </div>}

                </UISticky>
            </div>
        </div>
    )
}
