export default {
    toggle: {
        id: 'wingman.toggle',
        defaultMessage: 'Show Account Cases'
    },
    filters: {
        searchButton: {
            id: 'wingman.filters.searchButton',
            defaultMessage: 'Search'
        },
        resetButton: {
            id: 'wingman.filters.resetButton',
            defaultMessage: 'Reset'
        },
        date: {
            id: 'wingman.filters.date',
            defaultMessage: 'Start Date'
        }
    },
    fields: {
        caseNumber: {
            id: 'wingman.columns.caseNumber',
            defaultMessage: 'Case Number'
        },
        subject: {
            id: 'wingman.columns.subject',
            defaultMessage: 'Subject'
        },
        model: {
            id: 'wingman.columns.model',
            defaultMessage: 'Model'
        },
        serial: {
            id: 'wingman.columns.serial',
            defaultMessage: 'Serial'
        },
        status: {
            id: 'wingman.columns.status',
            defaultMessage: 'Status'
        },
        description: {
            id: 'wingman.columns.description',
            defaultMessage: 'Description'
        },
        createdDate: {
            id: 'wingman.columns.createdDate',
            defaultMessage: 'Created Date'
        },
        modifiedDate: {
            id: 'wingman.columns.modifiedDate',
            defaultMessage: 'Modified Date'
        },
        contact: {
            id: 'wingman.columns.contact',
            defaultMessage: 'Contact'
        },
        owner: {
            id: 'wingman.columns.owner',
            defaultMessage: 'Owner'
        },
        rating: {
            id: 'wingman.columns.rating',
            defaultMessage: 'Rating'
        }
    },
    noResults: {
        id: 'wingman.noResults',
        defaultMessage: 'No Wingman cases found'
    }
}
