import { request, GraphQlErrorResult } from '@model/api'
import { Substitute } from '../types'
import { getSubstitutesQuery } from './queries'
import { productFragment } from '@catalog/api'

export type GetSubstitutesApiParams = {
    productNumber: string
    allBranches?: boolean
    allBus?: boolean
    branchId?: number
    inStock?: boolean
    isSubstitute?: boolean
    isSupersede?: boolean
}

type SubstituteApiResult = {
    willfit: Substitute[]
}

export type SubstituteResult = GraphQlErrorResult & {
    substitutes: Substitute[] | null
}

export const getSubstitutesApi = async (
    params: GetSubstitutesApiParams
): Promise<SubstituteResult> => (
    request<SubstituteApiResult>([getSubstitutesQuery, productFragment], params).then(data => ({
        substitutes: data.data?.data?.willfit || null,
        errors: data.data?.errors || null
    }))
)
