export default {
    title: {
        id: 'parts.search.title',
        defaultMessage: 'Parts List & Documents'
    },
    lookup: {
        title: {
            id: 'parts.search.lookup.title',
            defaultMessage: 'Parts List Search'
        },
        searchLabel: {
            id: 'parts.search.lookup.searchLabel',
            defaultMessage: 'Equipment Model Number'
        },
        searchButton: {
            id: 'parts.search.lookup.searchButton',
            defaultMessage: 'Search'
        }
    },
    result: {
        tabParts: {
            id: 'parts.search.result.tab.parts',
            defaultMessage: 'Parts List'
        },
        tabDocuments: {
            id: 'parts.search.result.tab.documents',
            defaultMessage: 'Documents'
        }
    }
}
