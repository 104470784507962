export default {
    description: {
        id: 'product.description',
        defaultMessage: 'Description'
    },
    specification: {
        id: 'product.specification',
        defaultMessage: 'Specification'
    }
}
