import React, { createContext, PropsWithChildren, useContext } from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
    removeCartItemApi,
    updateCartItemApi,
    CartUpdateItemParams,
    CartRemoveItemResult,
    CartUpdateItemResult
} from '../api'
import { useAnalytics } from '@analytics/hooks'
import { getPricing } from '../utils'
import { CartItem } from '../types'
import { useCart } from '../hooks'

export type CartActionsProviderState = {
    removeCartItem: UseMutationResult<CartRemoveItemResult, unknown, CartItem>
    updateCartItems: UseMutationResult<CartUpdateItemResult, unknown, CartUpdateItemParams['cartItems']>
}

export const CartActionsContext = createContext<CartActionsProviderState>(null)

export const CartActionsProvider = (
    { children }: PropsWithChildren
) => {
    const { cart, invalidateCart } = useCart()
    const { trackRemoveFromCart } = useAnalytics()

    const removeCartItem = useMutation({
        mutationFn: (cartItem: CartItem) => removeCartItemApi({ cartItemId: cartItem.uid }),
        onSuccess: (_, cartItem) => {
            void invalidateCart()

            void trackRemoveFromCart({
                index: cart?.items.findIndex(item => item.uid === cartItem.uid),
                products: [{
                    product: cartItem.product,
                    pricing: getPricing(cartItem)
                }]
            })
        }
    })

    const updateCartItems = useMutation({
        mutationFn: (cartItems: CartUpdateItemParams['cartItems']) => (
            updateCartItemApi({ cartItems })
        ),
        onSuccess: () => {
            void invalidateCart()
        }
    })

    return (
        <CartActionsContext.Provider value={{
            removeCartItem,
            updateCartItems
        }}>
            {children}
        </CartActionsContext.Provider>
    )
}

export const useCartActionsData = () => {
    const context = useContext(CartActionsContext)
    if (!context) {
        throw new Error('useCartData must be used within a CartProvider')
    }
    return context
}
