export default {
    createProgress: {
        id: 'orderTemplate.create.progress',
        defaultMessage: 'Creating order template...'
    },
    createSuccess: {
        id: 'orderTemplate.create.success',
        defaultMessage: 'Order template has been successfully created.'
    },
    createError: {
        id: 'orderTemplate.create.error',
        defaultMessage: 'Something went wrong with creating order template.'
    },
    addProgress: {
        id: 'orderTemplate.add.progress',
        defaultMessage: 'Adding to order template...'
    },
    addError: {
        id: 'orderTemplate.add.error',
        defaultMessage: '{count, plural, one {Product has} other {Products have}} not been added to order template.'
    },
    addSuccess: {
        id: 'orderTemplate.add.success',
        defaultMessage: '{count, plural, one {Product has} other {Products have}} been successfully added to {name}.'
    }
}
