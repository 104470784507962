import React from 'react'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { PartsList, PartsListProps } from '../PartsList/'
import { AppProvider } from '@app/context'
import { ADD_TO_CART_LIST_DATA } from '@watsco/analytics'
import { ErrorBoundary } from '@app/components'
import { ErrorMessage } from '@ui/components'

export const ProductPartsApp = (
    { modelNumber }: PartsListProps
) => (
    <ErrorBoundary fallback={<ErrorMessage section/>}>
        <AppProvider>
            <AnalyticsProvider area={AnalyticsArea.ProductPage}
                               listData={ADD_TO_CART_LIST_DATA.PARTS}>
                <PartsList modelNumber={modelNumber}/>
            </AnalyticsProvider>
        </AppProvider>
    </ErrorBoundary>
)
