import React, {useState} from 'react';
import { useWindowSize } from '@ui/hooks'
import { UIButton, UIModal } from '@ui/components'
import { useSortingListState } from '../state'
import { useIntl } from "react-intl";
import { ItemList } from '../List/ItemList'
import { List } from '../List/List'

export const ReorderActionModal = () => {

    const {appConfig: {withButton, visibleData}, listData, open, handleDataUpdate, setOpen} = useSortingListState();

    const [selectedKey, setSelectedKey] = useState<string>(null);
    const {formatMessage} = useIntl();
    const {isMobile} = useWindowSize();

    if (!listData) return null

    return (
        <div>
            {withButton && <UIButton onClick={() => setOpen(!open)}>
                <span>Reorder</span>
            </UIButton>}
            <UIModal open={open} onClose={() => {
                setOpen(!open)
            }} slide={'wide'}
                     title={formatMessage({
                         id: 'sort.order.title',
                         defaultMessage: 'Reorder Items'
                     })}>
                <div className={'ec-flex ec-pb-4 ec-border-b ec-border-gray-lighter'}>
                    <UIButton onClick={handleDataUpdate} size={'small'}>
                        <span>{
                            formatMessage({
                                id: 'sort.order.apply',
                                defaultMessage: 'Apply'
                            })
                        }</span>
                    </UIButton>
                    {!isMobile && <div className={'ec-ml-8 ec-mr-2 ec-p-2'}>
                        {
                            formatMessage({
                                id: 'sort.order.visible',
                                defaultMessage: 'Select visible data:'
                            })
                        }
                    </div>}
                    <ItemList itemData={listData[0]} setSelectedKey={setSelectedKey} selectedKey={selectedKey} visibleData={visibleData}/>
                    {!isMobile && <div className={'ec-ml-8 ec-mr-2 ec-p-2 ec-text-xs'}>
                        {
                            formatMessage({
                                id: 'sort.order.hint',
                                defaultMessage: 'Drag and drop items to reorder them or change their position manually.'
                            })
                        }
                    </div>}
                </div>

                <List selectedKey={selectedKey}/>

            </UIModal>
        </div>
    );
};
