import React, { useState, useId, useEffect, ReactNode, ComponentPropsWithoutRef, Key, Fragment } from 'react'
import clsx from 'clsx'
import { UIOmit } from '../../../types'
import { useWindowSize } from '../../../hooks'
import { UIMinusIcon, UIPlusIcon } from '../../../icons'

export type UITabItems = {
    key: Key
    title: ReactNode
    panel: ReactNode
    enabled?: boolean
    disabled?: boolean
}[]

export type UITabsProps = UIOmit<ComponentPropsWithoutRef<'div'>> & {
    tabs: UITabItems
    accordion?: boolean
    accordionMobile?: boolean,
    defaultTab?: number | null
}

const wrapperVariants = {
    tab: 'ec-flex-row ec-flex-wrap ec-w-full',
    accordion: 'ec-flex-col',
    tabAccordion: 'ec-flex-col lg:ec-flex-row lg:ec-flex-wrap lg:c-w-full'
}

const buttonVariants = {
    tab: 'ec-border-b-0 ec-border-l-0 first:ec-border-l',
    accordion: 'ec-border-t-0 first:ec-border-t',
    tabAccordion: 'ec-border-t-0 first:ec-border-t lg:ec-border-t lg:ec-border-b-0 lg:ec-border-l-0 lg:first:ec-border-l'
}

const activeButtonVariants = {
    tab: clsx(
        'ec-bg-white after:ec-content-[""] after:ec-block after:ec-absolute after:ec-left-0 after:ec-bottom-[-1px]',
        'after:ec-w-full after:ec-h-[1px] after:ec-bg-white'
    ),
    accordion: '',
    tabAccordion: clsx(
        'lg:ec-bg-white lg:after:ec-content-[""] lg:after:ec-block lg:after:ec-absolute lg:after:ec-left-0',
        'lg:after:ec-bottom-[-1px] lg:after:ec-w-full lg:after:ec-h-[1px] lg:after:ec-bg-white'
    )
}

const iconVariants = {
    tab: 'ec-hidden',
    accordion: 'ec-pl-3',
    tabAccordion: 'ec-pl-3 lg:ec-hidden'
}

const titleVariants = {
    tab: 'ec-px-4',
    accordion: 'ec-pl-3 ec-pr-4',
    tabAccordion: 'ec-pl-3 ec-pr-4 lg:ec-px-4'
}

const panelVariants = {
    tab: 'ec-border ec-order-last ec-flex-[1_1_100%]',
    accordion: 'ec-border ec-border-t-0',
    tabAccordion: 'ec-border ec-border-t-0 lg:ec-border lg:ec-order-last lg:ec-flex-[1_1_100%]'
}

export const UITabs = (
    { tabs, defaultTab, accordion = false, accordionMobile = false, ...attributes }: UITabsProps
) => {
    tabs = tabs.filter(tab => tab.enabled === undefined || tab.enabled)

    const [active, setActive] = useState<number | null>(() => {
        if (accordion) {
            return defaultTab !== undefined
                ? defaultTab
                : null
        }

        return defaultTab !== null && defaultTab !== undefined
            ? defaultTab
            : 0
    })
    const { isMobile } = useWindowSize()
    const id = useId()

    useEffect(() => {
        if (!isMobile && active && !accordion === null) setActive(0)
    }, [isMobile])

    const setActiveTab = (index: number) => {
        if (index !== active) {
            setActive(index)
        } else if (isMobile || accordion) {
            setActive(null)
        }
    }

    let variant: string
    if (accordion) variant = 'accordion'
    else if (accordionMobile) variant = 'tabAccordion'
    else variant = 'tab'

    if (!tabs.length) return null

    return (
        <div className={`ec-flex ec-relative ${wrapperVariants[variant]}`} {...attributes}>
            {tabs.map((tab, index) => (
                <Fragment key={tab.key}>
                    <button type="button"
                            role="tab"
                            className={clsx(
                                'ec-flex ec-h-[50px] ec-items-center ec-bg-gray-lighter ec-border ec-border-gray-dark',
                                `ec-relative ${buttonVariants[variant]}`,
                                index === active && activeButtonVariants[variant]
                            )}
                            aria-selected={index === active}
                            aria-controls={`tabpanel-${id}-${index}`}
                            tabIndex={index > 0 ? -1 : null}
                            onClick={() => setActiveTab(index)}
                            id={`tab-${id}-${index}`}>
                        <div className={iconVariants[variant]}>
                            {index !== active
                                ? <UIPlusIcon/>
                                : <UIMinusIcon/>
                            }
                        </div>
                        <div className={titleVariants[variant]}>
                            {tab.title}
                        </div>
                    </button>
                    {index === active &&
                        <div role="tabpanel"
                             tabIndex={0}
                             id={`tabpanel-${id}-${active}`}
                             className={`ec-bg-white ec-border-gray-dark ec-p-4 ec-w-full ${panelVariants[variant]}`}
                             aria-labelledby={`tab-${id}-${active}`}>
                            {tab.panel}
                        </div>
                    }
                </Fragment>
            ))}
        </div>
    )
}
