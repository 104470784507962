import React, {createContext, PropsWithChildren, useState, Key, ComponentProps, useContext} from 'react'
import { APIProvider, useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { useStore } from '@store/hooks'
import { Marker } from '../components'
import { MapMarker } from '../types'
import {StoreProvider} from "../../Store";

export type MapProviderState = {
    map: ReturnType<typeof useMap>
    selectedMarkerId: Key,
    selectMarker: (marker: Pick<ComponentProps<typeof Marker>, 'id' | 'position'>) => void
}

export const MapContext = createContext<MapProviderState>(null)

export const MapProvider = ({ children }: PropsWithChildren) => {
    return (
        <StoreProvider>
            <MapProviderInner>
                {children}
            </MapProviderInner>
        </StoreProvider>
    )
}

export const MapProviderInner = (
    { children }: PropsWithChildren
) => {
    const { config } = useStore()
    
    if(!config?.map?.client_key && !window.googleMapsClientConfig) {
        throw new Error('Undefined map configuration')
    }

    //@ts-ignore
    const key = config?.map?.client_key || window.googleMapsClientConfig?.client_key?.toString();

    return (
        <APIProvider apiKey={key} libraries={['geometry', 'places']}>
            <MapProviderInnerInner>
                {children}
            </MapProviderInnerInner>
        </APIProvider>
    )
}

const MapProviderInnerInner = (
    { children }: PropsWithChildren
) => {
    const [selectedMarkerId, setSelectedMarkerId] = useState<Key>(null)
    const coreLibrary = useMapsLibrary('core')
    const map = useMap()

    const selectMarker = (
        marker: Pick<MapMarker, 'id' | 'position'>,
        options = { minZoom: 15 }
    ) => {
        setSelectedMarkerId(marker?.id || null)
        if (!marker) return

        const { LatLngBounds } = coreLibrary
        const bounds = new LatLngBounds()
        bounds.extend(marker.position)
        map.fitBounds(bounds)

        if (map.getZoom() > options.minZoom) {
            map.setZoom(options.minZoom)
        }
    }

    return (
        <MapContext.Provider value={{ map, selectedMarkerId, selectMarker }}>
            {children}
        </MapContext.Provider>
    )
}

export const useMapData = () => {
    const context = useContext(MapContext)
    if (!context) {
        throw new Error('useMapData must be used within a MapProvider')
    }
    return context
}
