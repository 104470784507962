import React from 'react'
import { FormattedMessage } from 'react-intl'
import { usePartsList } from '../../hooks'
import { UIInput } from '@ui/components'
import intl from './intl'

export const PartsListSearch = () => {
    const { filter, setFilter } = usePartsList()

    return (
        <div className="lg:ec-flex-1 lg:ec-max-w-1/2">
            <UIInput label={<FormattedMessage {...intl.search}/>}
                     onChange={e => setFilter(e.target.value)}
                     value={filter}
                     size="large"/>
        </div>
    )
}
