import React, { ComponentProps, useEffect, useCallback, useMemo, useState } from 'react'
import { Map as GoogleMap, useMapsLibrary, useApiIsLoaded } from '@vis.gl/react-google-maps'
import { Marker as GoogleMarker, MarkerClusterer } from '@googlemaps/markerclusterer'
import { useStore } from '@store/hooks'
import { MapMarker } from '../types'
import { Marker } from './Marker'
import { useMap } from '../hooks'

type MapProps = ComponentProps<typeof GoogleMap> & {
    markers?: MapMarker[]
    clusterMarkers?: boolean
}

export const Map = (
    { markers = [], clusterMarkers = false, ...mapProps }: MapProps
) => {
    const [markerRefs, setMarkerRefs] = useState<{ [key: string]: GoogleMarker }>({})
    const markersLibrary = useMapsLibrary('marker')
    const isApiLoaded = useApiIsLoaded()
    const { map } = useMap()
    const { config } = useStore()

    if(!config?.map?.client_key && !window.googleMapsClientConfig) {
        throw new Error('Undefined map configuration')
    }

    //@ts-ignore
    const mapId = config?.map?.map_id || window.googleMapsClientConfig?.map_id?.toString()

    const cluster = useMemo(() => {
        if (!map) return null
        return new MarkerClusterer({ map })
    }, [map, Object.values(markerRefs).length])

    useEffect(() => {
        if (!cluster) return

        cluster?.clearMarkers()
        cluster?.addMarkers(Object.values(markerRefs))
    }, [cluster, markerRefs])

    const setMarkerRef = useCallback((marker: GoogleMarker, id: string | number) => {
        setMarkerRefs(prev => {
            if ((marker && prev[id]) || (!marker && !prev[id])) {
                return prev
            }

            if (marker) {
                return { ...prev, [id]: marker }
            } else {
                const { [id]: _, ...newMarkers } = prev
                return newMarkers
            }
        })
    }, [])

    return (
        <>
            <GoogleMap mapId={mapId}
                       {...mapProps}>
                {!!markersLibrary && isApiLoaded &&
                    markers.map(marker => (
                        <Marker {...marker}
                                key={marker.id}
                                clusterMarkers={clusterMarkers}
                                setMarkerRef={setMarkerRef}/>
                    ))
                }
            </GoogleMap>
        </>
    )
}
