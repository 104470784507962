import React from 'react'
import { useInventoryFinderState } from '../../../state'
import { Branch } from '@branch/types'
import { FormattedMessage } from 'react-intl'
import { UIButton } from '@ui/components'
import { Address } from '@branch/components/Branch/Address'
import {useCustomerData} from "../../../../Customer";

type BranchMarkerProps = {
    branch: Branch
}

export const BranchMarker = (
    { branch }: BranchMarkerProps
) => {
    const {
        generateDirectionsLink,
        currentBranch,
        preferredBranch,
        switchCurrentBranch,
        switchPreferredBranch
    } = useInventoryFinderState()

    const { isLoggedIn } = useCustomerData()

    const isCurrentBranch = currentBranch?.branch_id === branch.branch_id
    const isPreferredBranch = preferredBranch?.branch_id === branch.branch_id

    const handleCurrentBranchChange = () => {
        switchCurrentBranch.mutate({ branchId: branch.branch_id })
    }

    const handlePreferredBranchChange = () => {
        switchPreferredBranch.mutate({ branchId: branch.branch_id })
    }

    const BranchTitle = (
        { branch }: { branch: Branch }
    ) => (
        <div className="ec-mb-4">
            <h3 className="ec-h3">{branch.branch_name}</h3>
            <a className="ec-text-s" target="_blank" href={generateDirectionsLink(branch)}>
                <FormattedMessage id="branch.marker.getDirections" defaultMessage="Get Directions"/>
            </a>
        </div>
    )

    return (
        <div className="ec-flex ec-flex-col ec-gap-4">
            <Address branch={branch} title={<BranchTitle branch={branch}/>}/>

            {isLoggedIn && !isCurrentBranch && !isPreferredBranch &&
                <div className="ec-flex ec-flex-col lg:ec-flex-row ec-gap-2">
                    <div className="ec-flex-auto">
                        <UIButton fullWidth
                                  size="small"
                                  loading={switchCurrentBranch.isLoading}
                                  onClick={handleCurrentBranchChange}>
                            <FormattedMessage id="branch.marker.selectBranch" defaultMessage="Select Branch"/>
                        </UIButton>
                    </div>
                    <div className="ec-flex-auto">
                        <UIButton fullWidth
                                  size="small"
                                  loading={switchPreferredBranch.isLoading}
                                  onClick={handlePreferredBranchChange}>
                            <FormattedMessage id="branch.marker.makePreferred" defaultMessage="Make Preferred"/>
                        </UIButton>
                    </div>
                </div>
            }
        </div>
    )
}
