import React, { ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react'
import { remToPx } from '@utils'
import clsx from 'clsx'
import { useWindowSize } from '../../../hooks'
import { UIOmit } from '../../../types'

const stickyClass = clsx([
    'ec-transition max-lg:ec-fixed max-lg:ec-top-0 max-lg:ec-left-0 max-lg:ec-bg-white',
    'max-lg:ec-w-screen max-lg:ec-px-[15px] max-lg:ec-py-2 max-lg:ec-shadow'
])

export const UISticky = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'div'>>
) => {
    const [sticky, setSticky] = useState(false)
    const [height, setHeight] = useState(null)
    const { isMobile } = useWindowSize()
    const wrapRef = useRef<HTMLDivElement>()

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
        handleScroll()
        return () => document.removeEventListener('scroll', handleScroll)
    }, [wrapRef.current])

    const handleScroll = () => {
        const bounding = wrapRef.current?.getBoundingClientRect()
        const isOut = bounding && bounding.top - remToPx(0.5) < 0

        setHeight(isOut ? bounding.height : null)
        setSticky(isOut)
    }

    return (
        <div ref={wrapRef} style={{ height: isMobile && height }} {...attributes}>
            <div className={clsx({ [stickyClass]: sticky })}>
                {children}
            </div>
        </div>
    )
}
