export default {
    addToCart: {
        id: 'catalog.addToCart',
        defaultMessage: 'Add To Cart'
    },
    adding: {
        id: 'catalog.adding',
        defaultMessage: 'Adding...'
    }
}
