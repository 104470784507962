import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { UIOmit } from '../../../types'
import clsx from 'clsx'
import intl from './intl'
import { useIntl } from 'react-intl'
import { UIInputLabel, UIInputLabelProps } from '../UIInputLabel/UIInputLabel'
import { UITriangleDownIcon } from '../../../icons'
import { UIFormError } from '../UIFormError/UIFormError'

type UISelectEmptyOptionDefined = Partial<Omit<UISelectOption, 'value'>>
export type UISelectEmptyOption = UISelectEmptyOptionDefined | boolean

export type UISelectProps = UIOmit<ComponentPropsWithoutRef<'select'>, 'size'> & {
    size?: 'small' | 'medium' | 'large'
    border?: 'default' | 'attention'
    options: UISelectOption[]
    label?: UIInputLabelProps['label']
    emptyOption?: UISelectEmptyOption
    error?: string
    required?: boolean
    register?: UseFormRegisterReturn<string>
}

export type UISelectSize<T = string> = Record<UISelectProps['size'], T>
export type UISelectBorder<T = string> = Record<UISelectProps['border'], T>

export type UISelectOption = {
    value: string
    label: ReactNode
    disabled?: boolean
}

const sizeVariants: UISelectSize = {
    small: 'ec-text-xs ec-leading-5 ec-h-[1.75rem]',
    medium: 'ec-text-sm ec-leading-7 ec-h-[2.25rem]',
    large: 'ec-text-sm ec-leading-8 ec-h-[2.5rem]'
}

const borderVariants: UISelectBorder = {
    default: 'ec-border ec-border-solid',
    attention: 'ec-border ec-border-solid ec-border-red ec-border-2'
}

const isEmptyObject = (emptyOption: UISelectEmptyOption): emptyOption is UISelectEmptyOptionDefined => {
    return (emptyOption as UISelectEmptyOptionDefined).label !== undefined
        || (emptyOption as UISelectEmptyOptionDefined).disabled !== undefined
}

export const UISelect = (
    {
        options,
        label,
        size = 'medium',
        border = 'default',
        emptyOption = false,
        error,
        required,
        register,
        ...attributes
    }: UISelectProps
) => {
    const { formatMessage } = useIntl()

    return (
        <div className={clsx({ 'ec-mt-4': !!label })}>
            <div className="ec-relative">
                <div className="ec-relative">
                    <select className={clsx(
                        'ec-appearance-none ec-w-full ec-pl-2 ec-pr-8 ec-border-gray-dark ec-truncate',
                        sizeVariants[size],
                        borderVariants[border],
                        {
                            'ec-border-gray-dark': !error,
                            'ec-border-red/75': !!error,
                            'ec-bg-white ec-cursor-pointer': !attributes.disabled,
                            'ec-bg-gray-lightest ec-cursor-not-allowed': attributes.disabled
                        }
                    )} {...register || {}} {...attributes}>
                        {emptyOption === true &&
                            <option value="">{formatMessage(intl.emptyLabel)}</option>
                        }
                        {isEmptyObject(emptyOption) &&
                            <option value="" disabled={emptyOption.disabled}>
                                {emptyOption.label || formatMessage(intl.emptyLabel)}
                            </option>
                        }
                        {options.map(({ value, label, disabled = false }, index) =>
                            <option key={index + '_' + value}
                                    value={value}
                                    disabled={disabled}>
                                {label}
                            </option>
                        )}
                    </select>
                    <div className={clsx(
                        'ec-absolute ec-top-1/2 -ec-translate-y-1/2 ec-pointer-events-none ec-right-2 ec-opacity-80'
                    )}>
                        <UITriangleDownIcon/>
                    </div>
                </div>
                <UIInputLabel label={label} error={!!error} required={required} active/>
                <UIFormError error={error}/>
            </div>
        </div>
    )
}
