import { CartItem } from '../types'
import { Price } from '@pricing/types'
import { getProductPimId } from '@catalog/utils'

export const getPricing = (cartItem: CartItem): Price => {
    const { product, prices } = cartItem

    return {
        pim_id: getProductPimId(product),
        can_add: true,
        prices: {
            currency: prices?.price?.currency,
            price: prices?.price?.value
        }
    }
}
