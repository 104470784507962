import React, { createContext, PropsWithChildren } from 'react'
import { CmsBlocksApiResponse, getCmsBlocksApi } from '../api'
import { useStore } from '@store/hooks'
import { useSessionStorage } from '@storage/hooks'
import { ContentBlock } from '../types'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { uniqueObjectArray } from '@utils'

type CmsBlockProviderProps = {
    configIdentifiers: string[] | string
}

export type CmsBlockProviderState = {
    query: UseQueryResult<CmsBlocksApiResponse>
    cmsBlocks: Record<string, ContentBlock>
}

export const CmsBlockContext = createContext<CmsBlockProviderState>(null)

const QUERY_KEY = 'cms-blocks'

export const CmsBlockProvider = (
    { configIdentifiers, children }: PropsWithChildren<CmsBlockProviderProps>
) => {
    const { config } = useStore()
    const configIdentifiersList = !Array.isArray(configIdentifiers)
        ? [configIdentifiers]
        : configIdentifiers

    const identifierMap: Record<string, string> = Object.fromEntries(
        configIdentifiersList
            .map(configId => [configId, config[configId] || null])
            .filter(([_, cmsId]) => !!cmsId && typeof cmsId === 'string')
            .sort()
    )

    const cmsIdentifiers: string[] = Object.values(identifierMap)

    const {
        data: sessionData,
        setData: setSessionData
    } = useSessionStorage<ContentBlock[]>('cms')

    const query = useQuery<CmsBlocksApiResponse, AxiosError>({
        queryKey: [QUERY_KEY, ...cmsIdentifiers],
        queryFn: async () => {
            const result = await getCmsBlocksApi(cmsIdentifiers)

            setSessionData(prevData => uniqueObjectArray(
                [...(prevData || []), ...result.cmsBlocks],
                'identifier'
            ))

            return result
        },
        placeholderData: {
            cmsBlocks: sessionData?.filter(cmsBlock => cmsIdentifiers.includes(cmsBlock.identifier)) || []
        },
        enabled: !!cmsIdentifiers.length
    })

    const cmsBlocks = query.data.cmsBlocks.reduce((all, cmsBlock) => {
        const configId = Object.keys(identifierMap).find(key => identifierMap[key] === cmsBlock.identifier)
        return {...all, [configId]: cmsBlock}
    }, {} as Record<string, ContentBlock>)

    return (
        <CmsBlockContext.Provider value={{ query, cmsBlocks }}>
            {children}
        </CmsBlockContext.Provider>
    )
}
