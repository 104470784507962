import { request, GraphQlErrorResult } from '@model/api'
import { createProposalByActiveCartMutation } from './queries'

export type CreateProposalByActiveCartParams = {
    account: number
}

type CreateProposalByActiveCartApiResult = {
    tokenizeActiveCart: {
        token: string
        create_proposal_link: string
    }
}

export type CreateProposalByActiveCartResult = GraphQlErrorResult
    & CreateProposalByActiveCartApiResult['tokenizeActiveCart']

export const createProposalByActiveCartApi = async (
    params: CreateProposalByActiveCartParams
): Promise<CreateProposalByActiveCartResult> => (
    request<CreateProposalByActiveCartApiResult>(createProposalByActiveCartMutation, params).then(data => ({
        token: data.data?.data?.tokenizeActiveCart?.token || null,
        create_proposal_link: data.data?.data?.tokenizeActiveCart?.create_proposal_link || null,
        errors: data.data?.errors || null
    }))
)
