import React, { useEffect } from 'react';
import { MenuItem } from '../../Elements/MenuItem';
import {useMenuState, useThemeState} from '../../../../../state';
import { useIntl } from "react-intl";
import { UIModal } from '@ui/components'
import { AccountJob } from "./AccountJob";
import { UserMenuStyle } from "../../../../../state/context/UserMenuStyle";
import {cn} from "@utils";
import { AccountIcon } from '../../../../../icons'
import {useCustomerData} from "../../../../../../Customer";

export const MenuMobile = () => {
    const { menuData, isOpen, setMenuStyle, menuStyle, config, setIsOpen } = useMenuState();
    const {isCheckout} = useThemeState();
    const {isLoggedIn} = useCustomerData();
    const intl = useIntl();

    useEffect(() => {
        setMenuStyle(UserMenuStyle);
    }, []);

    if (!menuStyle) return null;

    const accountMenuMobile = intl.formatMessage({ id: 'account.header.top.mobile', defaultMessage: 'Account' });

    const handleGuestLogin = () => {
        if(!isLoggedIn){
            window.location.href = '/customer/account/login/'
        }
    }

    if (!isLoggedIn) {
        return (
            <div className={cn("ec-overflow-visible ec-w-16 ec-h-10", config.menuClass)}>
                <div
                    className={cn({"ec-z-10 ec-relative": !isOpen}, 'ec-flex ec-items-center ec-justify-around ec-flex-wrap')}
                    onClick={() => handleGuestLogin()}>
                    <AccountIcon/>
                    <div>
                        <div className={cn("ec-text-white ec-text-xs")}>{accountMenuMobile}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={cn("ec-overflow-visible ec-w-16 ec-h-10", config.menuClass)}>
                <div
                    className={cn({ "ec-z-10 ec-relative": !isOpen }, 'ec-flex ec-items-center ec-justify-around ec-flex-wrap')}
                    onClick={() => {
                        if(isLoggedIn){
                            setIsOpen(!isOpen)
                        }
                    }}>
                    <AccountIcon />
                    <div>
                        <div className={cn("ec-text-white ec-text-xs")}>{accountMenuMobile}</div>
                    </div>
                </div>
            </div>
            <UIModal open={isOpen} onClose={() => setIsOpen(!isOpen)}>
                {!isCheckout && <AccountJob />}
                <ul className={cn("ec-md:-mt-36 ec-w-full ec-mt-2")}>
                    {menuData.children.map((category, index) => (
                        <MenuItem key={index} data={category} />
                    ))}
                </ul>
            </UIModal>
        </>
    );
}

MenuMobile.whyDidYouRender = true
