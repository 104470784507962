import { useStore } from '@store/hooks'

export const useApp = () => {
    const { config } = useStore()

    const setPageTitle = (title: string | string[]) => {
        const { title_prefix, title_suffix } = config

        const formattedTitle = Array.isArray(title)
            ? title.filter(title => !!title).reverse().map(item => item.trim()).join(' | ')
            : title.trim()

        document.title = [title_prefix, formattedTitle, title_suffix].join(' ').trim()
    }

    return {
        setPageTitle
    }
}
