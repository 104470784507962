export default {
    switchSuccess: {
        id: 'branch.switch.success',
        defaultMessage: 'Your current store is {name}.'
    },
    switchError: {
        id: 'branch.switch.error',
        defaultMessage: 'Branch could not be switched.'
    },
    switchSame: {
        id: 'branch.switch.same',
        defaultMessage: '{name} is already your current store.'
    },
    switchPreferredSuccess: {
        id: 'branch.switch.success',
        defaultMessage: 'Your preferred store is {name}.'
    },
    switchPreferredSame: {
        id: 'branch.switch.same',
        defaultMessage: '{name} is already your preferred store.'
    },
}
