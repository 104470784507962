import { AppConfig } from '@store/types'

export const getAnalyticsConfigs = (config: AppConfig['analytics']) => {
    const analyticsConfigs = [];
    let uuid

    try {
        if (config.containerId) {
            analyticsConfigs.push({
                type: 'gtm-ua',
                options: {
                    trackingId: config.containerId,
                    dataLayerName: config.uaDatalayerName,
                }
            });
        }

        if (config.ga4ContainerId) {
            analyticsConfigs.push({
                type: 'gtm-ga4',
                options: {
                    trackingId: config.ga4ContainerId,
                    dataLayerName: config.ga4DatalayerName,
                    preferredItemId: config.preferredItemId,
                    defaultCurrency: config.defaultCurrency
                }
            });
        }

        if (config.algoliaAppId && config.algoliaApiKey) {
            analyticsConfigs.push({
                type: 'algolia',
                options: {
                    appId: config.algoliaAppId,
                    apiKey: config.algoliaApiKey
                }
            });
        }

        if (config.rudderStackWriteKey && config.rudderStackDataPlaneUrl) {
            analyticsConfigs.push({
                type: 'rudderStack',
                options: {
                    writeKey: config.rudderStackWriteKey,
                    dataPlaneUrl: config.rudderStackDataPlaneUrl,
                    sdkUrl: config.rudderStackSdk,
                    configUrl: config.rudderStackConfigUrl,
                    preferredItemId: config.preferredItemId,
                    defaultCurrency: config.defaultCurrency,
                    algoliaDefaultIndex: config.rudderStackAlgoliaDefaultIndex
                }
            });
        }

        localStorage.setItem('cartId', config.cartId ?  config.cartId : '')
        localStorage.setItem('analytics-module-configs', JSON.stringify(analyticsConfigs))
        if (!localStorage.getItem('analytics-anonymous-uuid')) {
            uuid = self.crypto.randomUUID();
            localStorage.setItem('analytics-anonymous-uuid', uuid)
        } else {
            uuid = localStorage.getItem('analytics-anonymous-uuid')
        }
        localStorage.setItem('customerId', config.customerId ?  config.customerId : uuid)
    } catch (e) {
        console.error('Error in getAnalyticsConfigs', e)
    }

    return analyticsConfigs
}





