import React, {useEffect} from "react";
import {BranchSkeleton} from "../../../../InventoryFinder/components/Branches/Skeleton/BranchSkeleton";
import {useGoogleMapsState, useInventoryFinderState} from "../../../../InventoryFinder";
import {BranchStructure} from "../../../../InventoryFinder/components/Branches/BranchStructure";

export const BranchList = () => {

    const {
        branches,
        distances,
        isBranchesLoading,
        isLoadingData,
    } = useInventoryFinderState();

    const {
       setIsAllBranchMapMarkersVisible
    } = useGoogleMapsState();

    useEffect(() => {
        setIsAllBranchMapMarkersVisible(true);
    }, []);


    if (isBranchesLoading || isLoadingData) {
        return (<BranchSkeleton/>)
    }

    if (branches?.length === 0 || distances.length === 0) return null;

    const sortedBranches = branches
        .sort((a, b) => distances[a.branch_id] - distances[b.branch_id]);

    return (
        <div>
            <div>
                <BranchStructure data={sortedBranches}/>
            </div>
        </div>
    )
}
