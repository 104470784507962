import React, { createContext, useContext, PropsWithChildren } from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { SaveActiveCartParams, saveActiveCartApi, SaveActiveCartResult } from '../api'
import { CartProvider } from '@checkout/context'
import { useCart } from '@checkout/hooks'
import { CustomerProvider } from '@customer/context'

export type SaveCartProviderState = {
    saveActiveCartMutation: UseMutationResult<SaveActiveCartResult, unknown, SaveActiveCartParams>
}

const SaveCartContext = createContext<SaveCartProviderState>(null)

export const SaveCartProvider = (
    { children }: PropsWithChildren
) => (
    <CustomerProvider>
        <CartProvider>
            <SaveCartProviderInner>
                {children}
            </SaveCartProviderInner>
        </CartProvider>
    </CustomerProvider>
)

const SaveCartProviderInner = (
    { children }: PropsWithChildren
) => {
    const { reloadCart } = useCart()

    const saveActiveCartMutation = useMutation({
        mutationFn: (params: SaveActiveCartParams) => saveActiveCartApi(params),
        onSuccess: () => {
            void reloadCart()
        }
    })

    return (
        <SaveCartContext.Provider value={{ saveActiveCartMutation }}>
            {children}
        </SaveCartContext.Provider>
    )
}

export const useSaveCart = () => {
    const context = useContext(SaveCartContext)

    if (!context) {
        console.warn('useSaveCart must be used within the SaveCartProvider')
    }
    return context
}
