import React, {useEffect, useRef} from 'react';
import { UIPanel, UIButton } from '@ui/components'
import { useSortingListState } from '../state'
import { LargeList } from '../LargeList/LargeList'
import { Top } from '../LargeList/Top'
import {useIntl} from "react-intl";

export const ReorderAction = () => {

    const {
        appConfig: {withButton},
        listData,
        open,
        handleDataUpdate,
        setOpen,
        setIsContentButtonVisible,
        isOrderChanged
    } = useSortingListState();

    const {formatMessage} = useIntl();
    const updateButtonRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsContentButtonVisible(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        if (updateButtonRef.current) {
            observer.observe(updateButtonRef.current);
        }

        return () => {
            if (updateButtonRef.current) {
                observer.unobserve(updateButtonRef.current);
            }
        };
    }, [open, isOrderChanged]);

    if (!listData || !open) return null;

    return (
        <div className={'content-wrapper'}>
            <UIPanel>
                <div className={'ec-p-4'}>
                    {withButton && <UIButton onClick={() => setOpen(!open)}>
                        <span>{
                            formatMessage({
                                id: 'sort.order.reorder',
                                defaultMessage: 'Reorder'
                            })
                        }</span>
                    </UIButton>}
                    <Top/>
                    <LargeList/>
                    <div ref={updateButtonRef}>
                        {!isOrderChanged && <div className={'ec-pt-4'}>
                            <UIButton onClick={handleDataUpdate} size={'medium'}>
                        <span>{
                            formatMessage({
                                id: 'sort.order.cancel',
                                defaultMessage: 'Return to List'
                            })
                        }</span>
                            </UIButton>
                        </div>}
                        {isOrderChanged && <div className={'ec-pt-4'}>
                            <UIButton onClick={handleDataUpdate} size={'medium'} >
                        <span>{
                            formatMessage({
                                id: 'sort.order.apply',
                                defaultMessage: 'Update Positions'
                            })
                        }</span>
                            </UIButton>
                        </div>}
                    </div>

                </div>
            </UIPanel>
        </div>
    );
};
