import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getLoginUrl } from '@customer/utils'
import { useStore } from '@store/hooks'
import intl from '../intl'

export const SignIn = () => {
    const { config } = useStore()

    const signInIntl = !config.hide_guest_inventory
        ? intl.signInPriceInventory
        : intl.signInPrice

    return (
        <div className="ec-text-sm ec-font-medium">
            <FormattedMessage {...signInIntl} values={{
                a: text => <a href={getLoginUrl().href}>{text}</a>
            }}/>
        </div>
    )
}
