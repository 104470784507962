import React from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { UIButton, UIButtonProps } from './UIButton'
import { UIPopover } from '../../Overlays/UIPopover/UIPopover'
import { UIChevronDownIcon } from '../../../icons'
import { UIOmit } from '../../../types'
import intl from './intl'

type UIButtonDropdownProps = UIOmit<UIButtonProps, 'children'> & {
    size: 'small' | 'medium' | 'large'
    buttons: UIButtonProps[]
}

export type UIButtonDropdownSize<T = string> = Record<UIButtonDropdownProps['size'], T>

const sizeVariants: UIButtonDropdownSize = {
    small: 'ec-text-s',
    medium: 'ec-text-sm',
    large: 'ec-text-sm'
}

export const UIButtonDropdown = (
    {
        size = 'medium',
        variant = 'contained',
        fullWidth = false,
        loading = false,
        buttons = []
    }: UIButtonDropdownProps
) => {
    const [primaryButton, ...secondaryButtons] = buttons

    const Panel = ({ buttons }: { buttons: UIButtonProps[] }) => (
        <ul>
            {buttons.map(button =>
                <li key={button.key} className="ec-truncate ec-leading-6 ec-text-left">
                    <UIPopover.Button className={clsx('ec-text-blue-light hover:ec-underline', sizeVariants[size])}
                                      onClick={button.onClick}>
                        {button.children}
                    </UIPopover.Button>
                </li>
            )}
        </ul>
    )

    return (
        <div className="ec-flex ec-relative">
            <div className={clsx('ec-overflow-hidden', { 'ec-w-full': fullWidth })}>
                <UIButton onClick={primaryButton.onClick}
                          variant={variant}
                          size={'small'}
                          loading={loading}
                          fullWidth>
                    {primaryButton.children}
                </UIButton>
            </div>
            {secondaryButtons && !!secondaryButtons.length &&
                <div className="-ec-ml-[1px]">
                    <UIPopover panel={<Panel buttons={secondaryButtons}/>}
                               position="right"
                               size="small"
                               width="full"
                               contained={false}>
                        <UIButton size={`${size}-square`} variant={variant}>
                            <div className="child:ec-inline-block">
                                    <span className="ec-sr-only">
                                        <FormattedMessage {...intl.select}/>
                                    </span>
                                <UIChevronDownIcon size={size}/>
                            </div>
                        </UIButton>
                    </UIPopover>
                </div>
            }
        </div>
    )
}
