import React from 'react'
import { UIIconOutlineProps } from '../types'
import { sizeVariants, strokeVariants } from './variants'

export const UIChevronRightIcon = (
    { size = 'medium', stroke = 'medium' }: UIIconOutlineProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
         strokeWidth={strokeVariants[stroke]} className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
    </svg>
)
