import React, { Fragment, ReactNode } from 'react'

export type UIPairItem = [ReactNode, ReactNode]

export type UIPairsProps = {
    pairs: UIPairItem[]
}

export const UIPairs = (
    { pairs }: UIPairsProps
) => {
    return (
        <dl className={
            'ec-grid ec-grid-cols-[8.5rem_1fr] ec-gap-x-4 ec-gap-y-1 ec-w-full ec-text-sm'
        }>
            {pairs.map(([key, value], index) => (
                <Fragment key={index}>
                    <dt className="ec-text-gray-darker/75 ec-font-medium">{key}</dt>
                    <dd>{value}</dd>
                </Fragment>
            ))}
        </dl>
    )
}
