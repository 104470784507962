export default {
    switchAccountSuccess: {
        id: 'account.switch.success',
        defaultMessage: 'You are now browsing the site as #{id} {name}.'
    },
    switchAccountError: {
        id: 'account.switch.error',
        defaultMessage: 'Account could not be switched.'
    },
    switchJobSuccess: {
        id: 'job.switch.success',
        defaultMessage: 'You are now browsing the site with job account #{id} {name}.'
    },
    removeJobSuccess: {
        id: 'job.remove',
        defaultMessage: 'You are no longer browsing the site with a selected job account.'
    }
}
