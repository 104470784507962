export const CategoryMenuStyle = {
    slide: " ec-origin-custom",
    slideMobile: "ec-transition-transform ec-duration-300 ec-ease-in-out ec-transform ec-overflow-visible ec-z-[24]",
    open: "ec-translate-y-0 ec-bg-white",
    openMobile: "ec-translate-x-0 ec-bg-white ec-absolute ec--top-2 ec--left-4 ec-w-full ec-h-full",
    generic: "ec-px-4 ec-py-3 ec-text-black ec-hover:text-black ec-m-0 ec-border-b ec-border-solid ec-border-gray-400 ecmd:w-96 ec-bg-white",
    close: "ec--translate-y-full",
    closeMobile: "ec--translate-x-full",
    buttonClosed: "ec-text-white ec-px-4 ec-py ec-cursor-pointer ec-flex ec-relative ec-z-10 ec-pb-[15px] ec-items-center",
    buttonOpen: "ec-text-white ec-px-4 ec-py ec-cursor-pointer ec-flex ec-relative ecmd:ec-z-10 ec-pb-[15px] ec-items-center",
    arrowUpDown: "ec-ml-2 ec-w-3 ec-h-3",
    submenu: "ec-bg-white ec-px-4 ec-py-2 ec-border-b-2 ec-border-solid ec-border-gray-400",
    submenuOpen: "ec-transition-transform ec-duration-300 ec-ease-in-out ec-transform ec-translate-x-0",
    submenuClose: "",
    backButton: "ec-flex ec-px-4 ec-pb-1.5 ec-cursor-pointer ec-py-3 ec-items-center ec-text-dark",
    backButtonContainer: "ec-bg-white ec-border-b ec-border-solid ec-border-gray-400 ecmd:w-96",
    backArrow: "ec-w-4 ec-h-4",
    backButtonName: "ec-px-4 ec-uppercase ec-cursor-pointer ec-pb-3.5",
    nextArrow: "ec-ml-auto ec-w-5 ec-h-5",
    nextArrowMobile: "ec-ml-auto ec-w-24 ec-h-5",
    menuItemHidden: "ec-transform ec--translate-y-10 ec-opacity-0",
    menuItemVisible: "ec-transform ec-translate-y-0 ec-opacity-100 ec-transition-transform ec-duration-500",
}
