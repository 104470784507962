import { request, GraphQlErrorResult } from '@model/api'
import { Supersede, SupersedeProduct } from '../types'
import { getSupersedesQuery } from './queries'
import { productFragment } from '@catalog/api'

export type GetSupersedesApiParams = {
    productNumber: string
}

type SupersedeApiResult = {
    supersedes_history: {
        product?: SupersedeProduct
        superseded_by?: Supersede[]
        preceded_by?: Supersede[]
    }
}

export type SupersedeResult = GraphQlErrorResult & {
    product: SupersedeProduct | null
    superseded_by: Supersede[] | null
    preceded_by: Supersede[] | null
}

export const getSupersedesApi = async (
    params: GetSupersedesApiParams
): Promise<SupersedeResult> => (
    request<SupersedeApiResult>([getSupersedesQuery, productFragment], params).then(data => ({
        product: data.data?.data?.supersedes_history?.product || null,
        superseded_by: data.data?.data?.supersedes_history?.superseded_by || null,
        preceded_by: data.data?.data?.supersedes_history?.preceded_by || null,
        errors: data.data?.errors || null
    }))
)
