import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useInventory } from '../../hooks'
import { Finder, Summary, SummaryError } from './InventorySummary/index'
import { useCustomer } from '@customer/hooks'
import { Product } from '@catalog/types'
import clsx from 'clsx'

export type InventorySummaryProps = {
    product: Product
    finder?: boolean
    fullWidth?: boolean
}

export const InventorySummary = (
    {
        product,
        finder = false,
        fullWidth = false
    }: InventorySummaryProps
) => {
    const { inventoryQuery, inventory } = useInventory(product)
    const { isLoggedIn } = useCustomer()
    if (!isLoggedIn) return null

    const isLoading = inventoryQuery.isFetching
    const isError = inventoryQuery.isError || !isLoading && !inventory || inventory?.error

    return (
        <div className={clsx(
            'ec-flex ec-flex-col ec-text-s ec-leading-5 ec-bg-gray-lightest ec-p-3 ec-justify-center ec-min-h-16',
            {
                'ec-w-full ec-min-w-52': fullWidth,
                'ec-w-52': !fullWidth
            }
        )}>
            {finder && !isError &&
                <Finder product={product} loading={isLoading}/>
            }
            {isLoading &&
                <Skeleton count={2} containerClassName="ec-flex-1"/>
            }
            {isError &&
                <SummaryError product={product} finder={finder} error={inventory?.error}/>
            }
            {!isError && inventory &&
                <Summary inventory={inventory}/>
            }
        </div>
    )
}
