import { GraphQlErrorResult, request } from '@model/api'
import { updateCartItemQuery } from './queries'
import { Cart, CartItem } from '../types'

export type CartUpdateItemParams = {
    cartItems: CartItem[]
}

export type CartUpdateItemApiResult = {
    removeItemFromCart: {
        cart: Cart | null
    };
}

export type CartUpdateItemResult = GraphQlErrorResult & {
    cart: Cart
}

export const updateCartItemApi = async (
    params: CartUpdateItemParams
): Promise<CartUpdateItemResult> => {
    const formattedParams = {
        ...params,
        cartItems: params.cartItems.map(item => ({
            cart_item_uid: item.uid,
            quantity: item.quantity
        }))
    }

    return request<CartUpdateItemApiResult>(updateCartItemQuery, formattedParams).then(data => ({
        cart: data.data?.data?.removeItemFromCart?.cart || null,
        errors: data.data?.errors || null
    }))
}
