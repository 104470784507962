import React from 'react'
import { UIIconProps } from '../types'
import { sizeVariants } from './variants'

export const UICloseIcon = (
    { size = 'medium' }: UIIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
         className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
    </svg>
)
