import React from 'react'
import { useBranch } from '../../../hooks'
import { Map } from '@map/components'

export const MapInfo = () => {
    const { getMapLocation } = useBranch()
    const position = getMapLocation()

    return (
        <div className="ec-w-full ec-min-h-80 ec-aspect-square">
            <Map defaultCenter={position}
                 defaultZoom={14}
                 markers={[
                     {
                         id: 'default',
                         clickable: false,
                         position
                     }
                 ]}/>
        </div>
    )
}
