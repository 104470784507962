import { GraphQlError, request } from '@model/api'
import { switchJobQuery } from './query'
import { Account, Job } from '../types'

export type SwitchJobApiParams = {
    jobId: string
}

type SwitchJobApiResult = {
    switchJob: {
        account: Account | null
        job: Job | null
    }
}

export type SwitchJobResult = {
    accountId: number
    jobId: string
    errors?: GraphQlError[]
}

export const switchJobApi = async (
    { jobId }: SwitchJobApiParams
): Promise<SwitchJobResult> => (
    request<SwitchJobApiResult>(switchJobQuery, { jobId }).then(data => ({
        accountId: data.data?.data?.switchJob?.account?.customer_id,
        jobId: data.data?.data?.switchJob?.job?.id,
        errors: data.data?.errors || null
    }))
)
