import React, { ChangeEvent, ReactNode } from 'react'
import { UIAlert, UISelect, UISelectOption } from '@ui/components'

type PartsListProductSelect = {
    productOptions: UISelectOption[]
    product?: string | null
    onProductSelect: (event: ChangeEvent<HTMLSelectElement>) => void
    alert?: ReactNode
}

export const PartsListProductSelect = (
    { productOptions, product, alert, onProductSelect }: PartsListProductSelect
) => {
    return (
        <div className="ec-flex ec-flex-col ec-gap-2 ec-w-full">
            {alert &&
                <UIAlert type="info">{alert}</UIAlert>
            }
            <UISelect options={productOptions}
                      onChange={onProductSelect}
                      value={product}/>
        </div>
    )
}
