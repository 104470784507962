import { defineMessages } from 'react-intl'

export default defineMessages({
    mixedDefault: {
        id: 'yup.default',
        defaultMessage: 'Invalid field.'
    },
    mixedRequired: {
        id: 'yup.required',
        defaultMessage: 'This is a required field.'
    },
    stringRangeLength: {
        id: 'yup.string.range.length',
        defaultMessage: 'Please enter a value between {min} and {max} characters long.'
    },
    stringPhoneNumber: {
        id: 'yup.string.phone.number',
        defaultMessage: 'Invalid phone number.'
    },
    stringEmailOrPhoneNumber: {
        id: 'yup.string.email.phone.number',
        defaultMessage: 'Invalid email address or phone number.'
    },
    stringMax: {
        id: 'yup.string.max',
        defaultMessage: 'Please enter at most {max} characters.'
    },
    stringEmail: {
        id: 'yup.string.email',
        defaultMessage: 'Please enter a valid email address.'
    },
    numberRange: {
        id: 'yup.number.range',
        defaultMessage: 'Please enter a value greater than {moreThan} and less than {lessThan}.'
    },
    numberRangeEqual: {
        id: 'yup.number.range.equal',
        defaultMessage: 'Please enter a value between {min} and {max}.'
    },
    arrayMin: {
        id: 'yup.array.min',
        defaultMessage: 'Please enter more or equal than {min} {min, plural, one {item} other {items}}.'
    },
    dateMin: {
        id: 'yup.date.min',
        defaultMessage: 'Date must be later than {min}.'
    },
    dateMax: {
        id: 'yup.date.max',
        defaultMessage: 'Date must be earlier than {max}.'
    }
})
