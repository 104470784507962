import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { UIRadio } from '@ui/components'
import { FormattedMessage } from 'react-intl'
import { Section } from '@customerPortal/components'
import { usePreferencesState } from '../../state'
import { PaymentMethod } from '../../types'
import { useYup } from '@yup/context'

export interface PaymentMethodProps {
    methods: PaymentMethod[]
}

export const PaymentMethods = (
    { methods }: PaymentMethodProps
) => {
    const { register, setValue, watch, formState: { errors } } = useFormContext()
    const { isLoading } = usePreferencesState()
    const { errorMessage } = useYup()

    if (!methods) return null

    const deliveryMethod = watch('checkout.default_delivery_method')

    const availableMethods = methods.filter(method => {
        return !method.allowed || method.allowed.includes(deliveryMethod)
    })

    useEffect(() => {
        const activeMethod = availableMethods.find(method => method.active)

        setValue('checkout.default_payment_method', activeMethod
            ? activeMethod.code
            : availableMethods[0]?.code || null
        )
    }, [deliveryMethod])

    return (
        <Section.Item title={
            <FormattedMessage id="paymentMethods.defaultMethod" defaultMessage="Default Payment Method"/>
        }>
            <UIRadio error={errorMessage(errors, 'checkout.default_payment_method')}>
                {availableMethods.map(method =>
                    <UIRadio.Item key={method.code}
                                  register={register('checkout.default_payment_method')}
                                  value={method.code}
                                  disabled={isLoading}
                                  label={method.title}/>
                )}
            </UIRadio>
        </Section.Item>
    )
}
