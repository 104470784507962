import { GraphQlErrorResult, request } from '@model/api'
import { removeCartItemQuery } from './queries'
import { Cart } from '../types'

export type CartRemoveItemParams = {
    cartItemId: string
}

export type CartRemoveItemApiResult = {
    updateCartItems: {
        cart: Cart | null
    };
}

export type CartRemoveItemResult = GraphQlErrorResult & {
    cart: Cart
}

export const removeCartItemApi = async (
    params: CartRemoveItemParams
): Promise<CartRemoveItemResult> => (
    request<CartRemoveItemApiResult>(removeCartItemQuery, params).then(data => ({
        cart: data.data?.data?.updateCartItems?.cart || null,
        errors: data.data?.errors || null
    }))
)
