import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { RowData } from '@tanstack/table-core/src/types'
import { flexRender, Row, Cell } from '@tanstack/react-table'
import { UIOmit } from '../../../types'
import clsx from 'clsx'
import { UITableData } from '../../../hooks'
import { UITableHead } from './UITableHead/UITableHead'
import { UITableHeadRow } from './UITableHead/UITableHeadRow'
import { UITableHeadCell } from './UITableHead/UITableHeadCell'
import { UITableBody } from './UITableBody/UITableBody'
import { UITableBodyRow } from './UITableBody/UITableBodyRow'
import { UITableBodyCellLoading } from './UITableBody/UITableBodyCellLoading'
import { UITableBodyCellEmpty } from './UITableBody/UITableBodyCellEmpty'
import { UITableBodyCell } from './UITableBody/UITableBodyCell'
import { UITableBodyCellHeader } from './UITableBody/UITableBodyCellHeader'
import { UITableBodyCellContent } from './UITableBody/UITableBodyCellContent'
import { UITablePaginationInfo, UITablePaginationNavigator, UITablePaginationPageSize } from './UITablePagination'

export type UITableProps<TData> = UIOmit<ComponentPropsWithoutRef<'table'>> & {
    table: UITableData<TData>
    loading?: boolean,
    noResultsMessage?: ReactNode
    variant?: 'default' | 'bordered'
    onRowClick?: (row: Row<TData>) => void,
    toolbar?: ReactNode | { top?: ReactNode, bottom?: ReactNode }
}

const tableVariant = {
    'default': 'has-sibling:ec-border-b has-sibling:ec-border-gray-light',
    'bordered': 'lg:ec-border lg:ec-border-gray-light'
}

export const UITable = <TData extends RowData>(
    {
        variant = 'default',
        loading = false,
        noResultsMessage,
        table,
        onRowClick,
        toolbar,
        children,
        ...attributes
    }: UITableProps<TData>
) => {
    const getHeader = (cell: Cell<TData, unknown>) => (
        table.getFlatHeaders().find(({ id }) => id === cell.column.id)
    )

    const topToolbar = typeof toolbar === 'object' && 'top' in toolbar ? toolbar.top : toolbar as ReactNode
    const bottomToolbar = typeof toolbar === 'object' && 'bottom' in toolbar && toolbar.bottom

    const rows = table.getRowModel().rows

    return (
        <div className="ec-w-full">
            <div className="ec-flex ec-flex-col ec-gap-4 ec-w-full">
                {topToolbar}
                <table className={`ec-w-full ${tableVariant[variant]}`} {...attributes}>
                    <UITableHead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <UITableHeadRow key={headerGroup.id} variant={variant}>
                                {headerGroup.headers.map(header => (
                                    <UITableHeadCell
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        align={header.column.columnDef.meta?.align}>
                                        {!header.isPlaceholder && flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </UITableHeadCell>
                                ))}
                            </UITableHeadRow>
                        ))}
                    </UITableHead>
                    <UITableBody>
                        {loading &&
                            <UITableBodyRow>
                                <UITableBodyCellLoading/>
                            </UITableBodyRow>
                        }
                        {!loading && !rows.length &&
                            <UITableBodyRow>
                                <UITableBodyCellEmpty>{noResultsMessage}</UITableBodyCellEmpty>
                            </UITableBodyRow>
                        }
                        {!loading && !!rows.length && rows.map(row => (
                            <UITableBodyRow key={row.id} onClick={onRowClick ? () => onRowClick(row) : undefined}>
                                {row.getVisibleCells().map((cell, index, array) => {
                                    const {
                                        align,
                                        linearize = true,
                                        wrap = linearize,
                                        cellContainer = true
                                    } = cell.column.columnDef.meta || {}

                                    const header = linearize && getHeader(cell)
                                    const prevCell = array[index - 1] || null
                                    const prevLinearize = prevCell?.column.columnDef.meta?.linearize ?? true
                                    const linearizeCell = prevLinearize == false ? false : linearize

                                    return (
                                        <UITableBodyCell key={cell.id}
                                                         colSpan={header.colSpan}
                                                         align={align}
                                                         wrap={wrap}
                                                         linearize={linearizeCell}>
                                            {header && !header.isPlaceholder &&
                                                <UITableBodyCellHeader>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </UITableBodyCellHeader>
                                            }
                                            {cellContainer &&
                                                <UITableBodyCellContent>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </UITableBodyCellContent>
                                            }
                                            {!cellContainer &&
                                                flexRender(cell.column.columnDef.cell, cell.getContext())
                                            }
                                        </UITableBodyCell>
                                    )
                                })}
                            </UITableBodyRow>
                        ))}
                    </UITableBody>
                </table>
                {table.hasPagination &&
                    <div className={clsx(
                        'ec-flex ec-flex-col ec-gap-l ec-w-full',
                        'lg:ec-flex-row lg:ec-justify-end lg:ec-items-center'
                    )}>
                        <UITablePaginationInfo table={table}/>
                        <UITablePaginationPageSize table={table}/>
                        <UITablePaginationNavigator table={table}/>
                    </div>
                }
                {bottomToolbar}
            </div>
        </div>
    )
}
