import React from 'react'
import { Product } from '../../../types'
import { useIntl } from 'react-intl'
import intl from './intl'

type ProductNumbersProps = {
    product: Product
}

type NumbersItemProps = {
    label: string
    value?: string | null | undefined,
    itemProp?: string
}

export const ProductNumbers = (
    { product }: ProductNumbersProps
) => {
    const { formatMessage } = useIntl()

    const NumbersItem = (
        { label, value, itemProp }: NumbersItemProps
    ) => {
        if (!value) return null

        return (
            <dl className="ec-flex ec-text-s ec-text-gray-darker/75">
                <dt className="after:ec-inline after:ec-content-[':'] after:ec-mr-1">{label}</dt>
                <dd itemProp={itemProp}>{value}</dd>
            </dl>
        )
    }

    return (
        <div className="ec-block">
            <NumbersItem label={formatMessage(intl.bu)}
                         value={product.bu_product_num}
                         itemProp="item-number"/>
            <NumbersItem label={formatMessage(intl.mfg)}
                         value={product.bu_mfg_item_num || product.mfg_item_num}
                         itemProp="manufacturer-number"/>
        </div>
    )
}
