import React, {useEffect, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import { UIReorderArrowIcon } from '@ui/icons'
import { UIInput } from '@ui/components'

const ItemTypes = {
    ITEM: 'item',
};

export const DraggableItem = ({ id, index, itemKey, moveItem, findItem, visualIndex, items }) => {

    const ref = React.useRef(null);

    const [inputValue, setInputValue] = useState<number>(visualIndex);

    useEffect(() => {
        setInputValue(visualIndex);
    }, [visualIndex]);

    const onIndexChange = (e) => {
        setInputValue(e.target.value ? Number(e.target.value) : 1);
    };

    const onBlur = () => {
        const newIndex = inputValue - 1;
        const originalIndex = findItem(id).index;
        moveItem(originalIndex, newIndex);
    };

    const [, drop] = useDrop({
        accept: ItemTypes.ITEM,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            //@ts-ignore
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveItem(dragIndex, hoverIndex);
            //@ts-ignore
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: () => {
            return { id, index: findItem(id).index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div ref={ref} className="ec-flex ec-items-center ec-justify-between ec-p-2 ec-border-b ec-cursor-pointer hover:ec-bg-gray-lighter">
            <div className={'ec-flex'}>
                <div className="ec-mr-2 ec-text-gray-500 hover:ec-text-black ">
                    <UIReorderArrowIcon/>
                </div>
                <div>
                    {itemKey}
                </div>
            </div>

            <UIInput
                type="number"
                value={inputValue}
                onChange={onIndexChange}
                onBlur={onBlur}
                style={{ width: '40px' }}
                min="1"
                max={items.length}
                align={'center'}
                size={'small'}
            />
        </div>
    );
};
