import { useCartData } from '../context'
import { useMemo } from 'react'

export const useCart = () => {
    const context = useCartData()
    const { cartQuery, ...cartContext } = context

    const cart = useMemo(() => {
        return cartQuery?.data?.cart
    }, [JSON.stringify(cartQuery?.data?.cart)])

    const isLoading = cartQuery.isFetching

    const itemsCount = cart?.items.reduce((sum, item) => (
        sum + (item.quantity || 0)
    ), 0)

    return {
        ...cartContext,
        cart,
        itemsCount,
        isLoading
    }
}
