import React, { createContext, useEffect, useState } from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { toast, Id as ToastId } from 'react-toastify'
import { Product } from '@catalog/types'
import { getProductSku } from '@catalog/utils'
import { customerDataReload, getCustomerData, urlBuilder } from '@utils'
import { config } from '@model/tostify'
import intl from './intl'
import { OrderTemplate } from '../types'
import {
    addToOrderTemplateApi,
    createOrderTemplateApi,
    OrderTemplateAddApiResponse,
    OrderTemplateAddItem,
    OrderTemplateCreateApiResponse
} from '../api'
import { ORDER_TEMPLATE_DETAILS_PATH } from '../config'
import eventBus from "../../../models/events/eventBus";

type OrderTemplateContext = {
    orderTemplates: OrderTemplate[]
    createOrderTemplate: UseMutationResult<OrderTemplateCreateApiResponse, unknown, CreateOrderTemplateParams, MutationContext>
    addToOrderTemplate: UseMutationResult<OrderTemplateAddApiResponse, unknown, AddToOrderTemplateParams, MutationContext>
}

export type CreateOrderTemplateParams = {
    name: string
    description?: string
    products?: Product[]
}

export type AddToOrderTemplateParams = {
    orderTemplate: OrderTemplate
    products: Product[]
    context?: MutationContext
}

export type MutationContext = {
    toastId: ToastId
}

const CUSTOMER_DATA_KEY = 'requisition'
export const OrderTemplateStateContext = createContext<OrderTemplateContext>(null)

const getItems = (products: Product[]): OrderTemplateAddItem[] => (
    products.map(product => ({
        sku: getProductSku(product),
        qty: product.qty || 1
    }))
)

export const OrderTemplateProvider = (
    { children }
) => {
    const [orderTemplates, setOrderTemplates] = useState<OrderTemplate[]>([])

    useEffect(() => {
        const CUSTOMER_DATA_KEY = 'requisition';

        const customerData = getCustomerData<{ items?: OrderTemplate[] }>(CUSTOMER_DATA_KEY)
        if (customerData) {
            setOrderTemplates(customerData.items)
        }

        const handleCustomerDataUpdate = (event: { sectionNames: string[], data: any }) => {
            if (event.sectionNames.includes(CUSTOMER_DATA_KEY) && event.data[CUSTOMER_DATA_KEY]) {
                setOrderTemplates(event.data[CUSTOMER_DATA_KEY].items)
            }
        };

        eventBus.on('magento-data-update', handleCustomerDataUpdate);

        return () => {
            eventBus.off('magento-data-update', handleCustomerDataUpdate);
        };
    }, [])

    const createOrderTemplate = useMutation({
        mutationFn: async (data: CreateOrderTemplateParams) => {
            const { products, ...orderTemplateData } = data
            const createResult = await createOrderTemplateApi(orderTemplateData)

            if (!products) return createResult

            return addToOrderTemplateApi({
                orderTemplateId: createResult.orderTemplate.id,
                items: getItems(products)
            })
        },
        onMutate: (): MutationContext => {
            const toastId = toast.loading(<FormattedMessage {...intl.createProgress}/>)
            return { toastId }
        },
        onSuccess: async (data, variables, context) => {
            if (variables.products) {
                const orderTemplateUrl = urlBuilder()
                    .setPath(ORDER_TEMPLATE_DETAILS_PATH, { id: data.orderTemplate.id })
                    .getUrl()

                toast.update(context.toastId, {
                    render: <FormattedMessage {...intl.addSuccess} values={{
                        count: variables.products.length,
                        name: <a href={orderTemplateUrl.href}>{data.orderTemplate.name}</a>
                    }}/>,
                    type: 'success',
                    autoClose: config.autoClose,
                    isLoading: false
                })
            } else {
                toast.update(context.toastId, {
                    render: <FormattedMessage {...intl.createSuccess}/>,
                    type: 'success',
                    autoClose: config.autoClose,
                    isLoading: false
                })
            }

            customerDataReload(CUSTOMER_DATA_KEY)
        },
        onError: (error, _, context) => {
            toast.update(context.toastId, {
                render: <FormattedMessage {...intl.createError}/>,
                type: 'error',
                autoClose: config.autoClose,
                isLoading: false
            })
            console.error('API error', error)
        }
    })

    const addToOrderTemplate = useMutation({
        mutationFn: ({ orderTemplate, products }: AddToOrderTemplateParams) => (
            addToOrderTemplateApi({
                orderTemplateId: orderTemplate.id,
                items: getItems(products)
            })
        ),
        onMutate: (): MutationContext => {
            const toastId = toast.loading(<FormattedMessage {...intl.addProgress}/>)
            return { toastId }
        },
        onSuccess: (_, variables, context) => {
            const orderTemplateUrl = urlBuilder()
                .setPath(ORDER_TEMPLATE_DETAILS_PATH, { id: variables.orderTemplate.id })
                .getUrl()

            toast.update(context.toastId, {
                render: <FormattedMessage {...intl.addSuccess} values={{
                    count: variables.products.length,
                    name: <a href={orderTemplateUrl.href}>{variables.orderTemplate.name}</a>
                }}/>,
                type: 'success',
                autoClose: config.autoClose,
                isLoading: false
            })

            customerDataReload(CUSTOMER_DATA_KEY)
        },
        onError: (error, variables, context) => {
            toast.update(context.toastId, {
                render: <FormattedMessage {...intl.addError} values={{ count: variables.products.length }}/>,
                type: 'error',
                autoClose: config.autoClose,
                isLoading: false
            })
            console.error('API error', error)
        }
    })

    return (
        <OrderTemplateStateContext.Provider value={{
            orderTemplates,
            createOrderTemplate,
            addToOrderTemplate
        }}>
            {children}
        </OrderTemplateStateContext.Provider>
    )
}
