import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../../types'

export const UITableBody = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'tbody'>>
) => (
    <tbody {...attributes}>
        {children}
    </tbody>
)
