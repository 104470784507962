import { Branch } from '../../types'
import { useStoreData } from '@store/context'
import { useCustomer } from '@customer/hooks'

const BASE_MAPS_URL = 'https://maps.google.com?saddr=&daddr='

export const useBranchInfo = () => {
    const { config } = useStoreData()
    const { isCompanyUser } = useCustomer()

    const getDirectionsLink = (branch: Branch) => {
        const { address, branch_name } = branch
        const destination = `${branch_name} ${address.address_1} ${address.city}, ${address.region.name} ${address.postcode}`
        return `${BASE_MAPS_URL}${encodeURIComponent(destination)}`
    }

    const canTextBranch = (branch: Branch) => (
        config?.branch_text?.includes(branch?.branch_id) || false
    )

    const canEmailBranch = (branch: Branch) => isCompanyUser

    return {
        getDirectionsLink,
        canEmailBranch,
        canTextBranch
    }
}
