import { GraphQlError, request } from '@model/api'
import { OrderTemplate, OrderTemplateRaw } from '../types'
import { createOrderTemplateQuery, orderTemplateFragment } from './query'
import { getModel } from './getModel'

export type OrderTemplateCreateApiParams = {
    name: string
    description?: string
}

export type OrderTemplateCreateApiResponse = {
    orderTemplate: OrderTemplate | null
    errors?: GraphQlError[]
}

type OrderTemplateApiRawResponse = {
    createRequisitionList: {
        requisition_list: OrderTemplateRaw
    }
}

export const createOrderTemplateApi = async (
    params: OrderTemplateCreateApiParams
): Promise<OrderTemplateCreateApiResponse> => (
    request<OrderTemplateApiRawResponse>(
        [createOrderTemplateQuery, orderTemplateFragment],
        { data: params }
    ).then(data => ({
        orderTemplate: getModel(data.data?.data.createRequisitionList?.requisition_list || null),
        errors: data.data?.errors || null
    }))
)
