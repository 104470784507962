import React from 'react'
import { SubstituteBadge } from './SubstituteBadge'
import { ProEssentialsIcon } from '../../../icons'
import { Product } from '../../../types'
import clsx from 'clsx'

type ProductBadgesProps = {
    product: Product,
    preserveSpace?: boolean
    space?: 'none' | 'small'
}

const spaceVariants = {
    'none': '',
    'small': 'ec-mt-2'
}

export const ProductBadges = (
    { product, space = 'none', preserveSpace = false }: ProductBadgesProps
) => {
    const hasBadges = product.pro_points || product.pro_essential

    return (
        <div className={clsx(
            'ec-flex ec-flex-wrap ec-gap-2',
            spaceVariants[space],
            {
                'ec-min-h-5': !hasBadges && preserveSpace,
                'ec-hidden lg:ec-block lg:ec-min-h-5': !hasBadges && !preserveSpace
            }
        )}>
            {product.pro_essential && <ProEssentialsIcon/>}
            {product?.metadata?.substitutes && <SubstituteBadge product={product}/>}
        </div>
    )
}
