import React, { useState } from 'react'
import { UIPhoneIcon } from '@ui/icons'
import { UILoader, UIModal } from '@ui/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAnalytics } from '@analytics/hooks'
import { BranchInfo, MapInfo, SalespersonInfo } from './Info'
import { useBranch } from '../../hooks'
import { Address, Hours } from '../Branch'
import clsx from 'clsx'
import { useCustomer } from '@customer/hooks'

export const Contact = () => {
    const { currentBranch, isBranchLoading } = useBranch()
    const { trackContactDrawerOpen } = useAnalytics()
    const [isOpen, setIsOpen] = useState(false)
    const { isLoggedIn } = useCustomer()
    const { formatMessage } = useIntl()

    if (!isLoggedIn) {
        return null
    }

    const handleOpen = () => {
        void trackContactDrawerOpen()
        setIsOpen(true)
    }
    const handleClose = () => setIsOpen(false)

    return (
        <>
            <div className=''>
                <div className='ec-fixed ec-right-0 ec-top-1/2 ec-z-90'>
                    <button
                        onClick={handleOpen}
                        className={clsx(
                            'ec-absolute ec-bottom-0 ec-w-max [transform:rotate(-90deg)_translateX(-50%)]',
                            'ec-origin-bottom-left ec-rounded-tr-md ec-rounded-tl-md ec-flex ec-px-3 ec-py-2',
                            'ec-text-white ec-bg-red hover:ec-bg-red-dark ec-cursor-pointer ec-items-center'
                        )}>
                        <div className='ec-rotate-90'>
                            <UIPhoneIcon variant='solid' size='small' />
                        </div>
                        <p className='ec-text-xs ec-m-0 ec-uppercase ec-ml-2'>
                            <FormattedMessage id='branch.contact.button' defaultMessage='Contact Us' />
                        </p>
                    </button>
                </div>
            </div>
            <UIModal
                open={isOpen}
                onClose={handleClose}
                title={formatMessage({ id: 'branch.contact.title', defaultMessage: 'Your Current Branch' })}
                slide>
                <div className='ec-flex ec-flex-col ec-gap-4 ec-w-full'>
                    <UILoader show={isBranchLoading}>
                        <Address branch={currentBranch} />
                        <Hours branch={currentBranch} />
                        <BranchInfo />
                        <MapInfo />
                        <SalespersonInfo />
                    </UILoader>
                </div>
            </UIModal>
        </>
    )
}
