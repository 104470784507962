export default {
    customerPortal: {
        id: 'customerPortal.breadcrumbs.customerPortal',
        defaultMessage: 'Customer Portal'
    },
    associatePortal: {
        id: 'customerPortal.breadcrumbs.associatePortal',
        defaultMessage: 'Associate Portal'
    },
    breadcrumbsTitle: {
        id: 'customerPortal.breadcrumbs.title',
        defaultMessage: 'Breadcrumbs'
    }
}
