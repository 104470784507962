import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

type SummaryItemProps = {
    inStock: boolean
}

export const SummaryItem = (
    { inStock, children }: PropsWithChildren<SummaryItemProps>
) => (
    <div className={clsx('ec-truncate', { 'ec-text-green': inStock })}>
        {children}
    </div>
)
