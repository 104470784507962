/**
 * Yup localization and i18n
 * @see https://github.com/jquense/yup#localization-and-i18n
 */
export const localeConfig = {
    mixed: {
        default: 'mixedDefault',
        required: 'mixedRequired'
    },
    array: {
        min: ({ min }) => ({ key: 'arrayMin', values: { min } })
    },
    string: {
        email: 'stringEmail',
        max: ({ max }) => ({ key: 'stringMax', values: { max } })
    },
    date: {
        min: ({ min }) => ({ key: 'dateMin', values: { min: getFormattedDate(min) } }),
        max: ({ max }) => ({ key: 'dateMax', values: { max: getFormattedDate(max) } })
    }
}

const getFormattedDate = (date: string) => {
    const dateTime = new Date(date)
    return dateTime.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })
}
