import { GraphQlErrorResult, request } from '@model/api'
import { Customer, CustomerBranches, CustomerPermissions } from '../types'
import { GraphQlPreferences } from '@customerPreferences/state'
import { CustomerPreferences } from '@customerPreferences/types'
import { getCustomerQuery } from './queries'

export type CustomerApiResponse = GraphQlPreferences & {
    customer: Customer
    customerPermissions: CustomerPermissions
    customerBranches: CustomerBranches
}

export type CustomerResult = GraphQlErrorResult & {
    customer: Customer | null
    branches: CustomerBranches | null
    permissions: CustomerPermissions | null
    preferences: CustomerPreferences | null
}

export const getCustomerApi = async (): Promise<CustomerResult> => (
    request<CustomerApiResponse>(getCustomerQuery).then(data => ({
        customer: data.data?.data?.customer || null,
        permissions: data.data?.data?.customerPermissions || null,
        preferences: data.data?.data?.preferences?.data || null,
        branches: data.data?.data?.customerBranches || null,
        errors: data.data?.errors || null
    }))
)
