import React from "react";
import {MenuMobile} from "./Status/MenuMobile";
import {useWindowSize} from "../../../../../Various";
import {MenuDesktop} from "./Status/MenuDesktop";
import { cn } from "@utils";

export const UserMenu = () => {
    const {isMobile} = useWindowSize();

    if (isMobile) {
        return (
            <div className={cn('ec-flex-col')}>
                <MenuMobile/>
            </div>
        )
    }

    return (
        <div className={cn('ec-flex-col ec-relative')}>
            <MenuDesktop/>
        </div>
    )
}

UserMenu.whyDidYouRender = true
