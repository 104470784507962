import React, { ComponentPropsWithoutRef } from 'react'
import { useStore } from '@store/hooks'
import { UIOmit } from '../../../types'

export type UIDateTimeProps = UIOmit<ComponentPropsWithoutRef<'time'>> & {
    date: Date | string | number | null | undefined
    withTime?: boolean
}

const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
}

const timeOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
}

export const UIDateTime = (
    {
        date,
        withTime = false,
        ...attributes
    }: UIDateTimeProps
) => {
    if (!date) return null

    const { config } = useStore()
    const { locale = 'en-US' } = config

    const dateTime = new Date(date)

    const dateTimeFormatted = dateTime.toLocaleDateString(
        locale.replace(/_/g, '-'),
        !withTime ? dateOptions : timeOptions
    )

    return (
        <time dateTime={dateTime.toISOString()} {...attributes}>
            {dateTimeFormatted}
        </time>
    )
}
