export default {
    waiting: {
        id: 'catalog.addToCart.waiting',
        defaultMessage: 'Adding products to cart. Please wait...'
    },
    success: {
        id: 'catalog.addToCart.success',
        defaultMessage: '{count, plural, one {Product has} other {Products have}} been successfully added to cart.'
    },
    warning: {
        id: 'catalog.addToCart.warning',
        defaultMessage: 'Some products have not been added to cart.'
    },
    error: {
        id: 'catalog.addToCart.error',
        defaultMessage: '{count, plural, one {Product has} other {Products have}} not been added to cart.'
    }
}
