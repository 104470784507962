import React, { ReactNode, JSX } from 'react'
import { UIExpand } from '@ui/components'
import { useCmsBlock } from '../../hooks'

type CmsBlockProps = {
    children: (content: () => JSX.Element) => ReactNode
    identifier?: string
}

export const CmsBlock = (
    { identifier, children }: CmsBlockProps
) => {
    const { cmsBlock } = useCmsBlock(identifier)

    const Content = () => (
        <div dangerouslySetInnerHTML={{ __html: cmsBlock?.content }}/>
    )

    return (
        <UIExpand expand={!!cmsBlock}>
            {children(Content)}
        </UIExpand>
        )
}
