import { useBranchesInventoryState } from '../../context'
import { Product } from '@catalog/types'
import { useBranch } from '@branch/hooks'
import { BranchesInventoryItem } from '../../types'
import { Branch } from '@branch/types'
import { useMemo } from 'react'

export type InventoryListItem = {
    qty: number,
    in_stock: boolean | null
    branch: Branch
}

export const useBranchesInventory = (
    product?: Product
) => {
    const { branchesInventoryQuery, setProducts } = useBranchesInventoryState()
    const { branches, isBranchLoading, currentBranch, nearbyBranches, switchCurrentBranch } = useBranch()

    const getProductInventory = (product?: Product) => {
        const inventory = branchesInventoryQuery.data?.inventory || []

        if (!product || !inventory.length) return null
        return inventory.find(inventory => inventory.pim_id === product.pim_id) || null
    }

    const isLoading = branchesInventoryQuery.isFetching || isBranchLoading
    const inventory = getProductInventory(product)

    const isError = branchesInventoryQuery.isError || !isLoading && !inventory || inventory?.error
    const errors = branchesInventoryQuery.data?.errors

    const getInventoryItem = (inventory: BranchesInventoryItem): InventoryListItem => ({
        qty: inventory.qty_available,
        in_stock: inventory.in_stock,
        branch: branches.find(branch => branch.branch_id === inventory.branch_id)
    })

    const nearbyBranchIds = nearbyBranches?.map(({ branch_id }) => branch_id) || []

    const nearbyInventory = useMemo(() => (
        inventory?.items
            ?.filter(inventory => nearbyBranchIds.includes(inventory.branch_id))
            .map(getInventoryItem) || []
    ), [product?.pim_id, inventory])

    const allInventory = useMemo(() => (
        inventory?.items
            ?.filter(inventory => inventory.branch_id !== currentBranch?.branch_id)
            .filter(inventory => !nearbyBranchIds.includes(inventory.branch_id))
            .map(getInventoryItem) || []
    ), [product?.pim_id, inventory])

    const currentInventory = inventory?.items?.find(({ branch_id }) => branch_id === currentBranch.branch_id) || null
    const isAvailableAtCurrent = !!currentInventory?.in_stock
    const isAvailableAtNearby = allInventory.some(inventory => inventory.in_stock)
    const isAvailableForTransfer = allInventory.some(inventory => inventory.in_stock)

    const loadInventory = (products?: Product[]) => {
        setProducts(products || [product])
    }

    const switchBranch = (branch: Branch) => {
        switchCurrentBranch.mutate({ branchId: branch.branch_id })
    }

    return {
        branchesInventoryQuery,
        loadInventory,
        isLoading,
        inventory,
        currentInventory,
        nearbyInventory,
        allInventory,
        isAvailableAtCurrent,
        isAvailableAtNearby,
        isAvailableForTransfer,
        isError,
        errors,
        switchBranch
    }
}
