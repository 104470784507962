import { ProductInfo } from '@watsco/analytics'

const INDEX_USED = 'indexUsed'
const QUERY_ID = 'queryID'

export const getProviderMeta = (
    products?: ProductInfo[]
) => ({
    algolia: {
        index: localStorage.getItem(INDEX_USED),
        queryID: localStorage.getItem(QUERY_ID),
        ...(products ? { positions: products.map(product => product.position) } : {})
    }
})
