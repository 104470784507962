import eventBus from '../models/events/eventBus';

type SectionNameList<T> = { sectionNames: string[], data: T }

const MAGE_CACHE_STORAGE = 'mage-cache-storage'
const UPDATE_EVENT = 'magento-data-update'
const RELOAD_EVENT = 'magento-data-reload'
const REMOVE_ALL_EVENT = 'magento-data-remove-all'

export const customerDataReload = (
    sectionNames: string | string[] | '*'
) => {
    if (!Array.isArray(sectionNames) && sectionNames !== '*') {
        sectionNames = [sectionNames]
    }

    eventBus.emit(RELOAD_EVENT, { sectionNames });
}

export const customerDataListen = <T = any>(
    sectionName: string,
    callback: (data: T | undefined) => void
) => {
    if (sectionName === '*') {
        console.warn('"*" cannot be used as section name. Exiting.')
        return
    }

    const listener = (event: SectionNameList<T>) => {
        const currentUrl = window.location.href;

        let updatedSectionNames = [...event.sectionNames];

        if (currentUrl.includes('checkout/cart') || event.sectionNames.length !== 1) {
            updatedSectionNames = updatedSectionNames.filter(name => name !== 'cart');
        }

        const matching = updatedSectionNames.includes(sectionName)
        matching && callback(event.data[sectionName] || undefined)
    }

    eventBus.on(UPDATE_EVENT, listener)
    return {
        remove: () => eventBus.off(UPDATE_EVENT, listener)
    }
}

export const customerDataListenRemoveAll = (
    callback: () => void
) => {
    eventBus.on(REMOVE_ALL_EVENT, callback)

    return {
        remove: () => eventBus.off(REMOVE_ALL_EVENT, callback)
    }
}

export const getCustomerData = <T = any>(
    sectionName: string
): T => {
    const jsonData = localStorage.getItem(MAGE_CACHE_STORAGE)
    if (!jsonData) return null

    const data = JSON.parse(jsonData)
    return data[sectionName] || null
}
