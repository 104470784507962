import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo} from 'react'
import {UseQueryResult, QueryClientProvider, useQuery, useQueryClient} from '@tanstack/react-query'
import {CustomerResult, getCustomerApi} from '../api'
import {getQueryClient} from "../../../models/api";
import {StorageKeys, StorageProvider, useStorageContext, timestampStorage} from "../../Storage";

const queryClient = getQueryClient()

export type CustomerProviderState = {
    isLoggedIn: boolean
    customerQuery: UseQueryResult<CustomerResult>
    reloadCustomer: (optimisticData?: Partial<CustomerResult>) => Promise<void>
    isCompanyUser: boolean
    customerCompanyStatus: number
    isCustomerLoading: boolean
    customerData: CustomerResult
    isAssociate: boolean
    isRequestStatus: boolean
    customer: CustomerResult['customer']
    customerBranches: CustomerResult['branches']
}

//queries should be done in very specific places and the provider should not be available in the whole app
//we do not wnt a query to be done at some component level that is already available in the provider
export const CustomerProvider = ({children}: PropsWithChildren) => {
    return (
        <QueryClientProvider client={queryClient}>
            <StorageProvider>
                <CustomerProviderInner>
                    {children}
                </CustomerProviderInner>
            </StorageProvider>
        </QueryClientProvider>
    )
}

// CustomerContext should be private, please keep this in mind for future updates
const CustomerContext = createContext<CustomerProviderState>(null);

const QUERY_KEY = 'customer'

export const CustomerProviderInner = ({ children }: PropsWithChildren) => {

    const {setKeyData, customerStorage, removeAll} = useStorageContext();
    const queryClient = useQueryClient();

    const customerLocalStorageData = customerStorage;

    const magentoLocalStorageData = localStorage.getItem('mage-cache-storage');
    const parsedMagentoLocalStorageData = magentoLocalStorageData ? JSON.parse(magentoLocalStorageData) : {};
    const magentoCustomerData = useMemo(() => parsedMagentoLocalStorageData, [parsedMagentoLocalStorageData?.customer?.fullname]);

    const customerQuery = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const result = await getCustomerApi()
            const { errors, ...resultData } = result

            setKeyData({[StorageKeys.CUSTOMER]: resultData});
            timestampStorage.set(StorageKeys.CUSTOMER);

            !!errors && errors.forEach(error => console.error(error.message))

            return result
        },
        initialData: customerLocalStorageData,
        initialDataUpdatedAt: () => timestampStorage.get(StorageKeys.CUSTOMER),
        staleTime: 1000 * 60 * 5,
    })

    const reloadCustomer = useCallback((optimisticData?: Partial<CustomerResult>) => {
        optimisticData && queryClient.setQueryData([QUERY_KEY], (oldData: CustomerResult) => ({
            ...oldData,
            ...optimisticData
        }))

        return queryClient.invalidateQueries(
            {queryKey: [QUERY_KEY], refetchType: 'all'},
            {cancelRefetch: false}
        )
    }, [])

    const isLoggedIn = useMemo(() => {
        return !!(customerQuery.data?.customer?.name ||
            magentoCustomerData?.customer?.firstname);
    }, [customerQuery.data?.customer?.name, magentoCustomerData?.customer?.firstname]);

    useEffect(() => {
        if(!customerQuery.data?.customer && customerLocalStorageData?.customer) {
            removeAll();
        }
    }, [customerQuery?.data]);

    useEffect(() => {
        const currentUrl = window.location.href;
        const isLogOutUrl = currentUrl.includes('customer/account/logoutSuccess');

        if(isLogOutUrl) {
            localStorage.removeItem('mage-cache-storage');
            removeAll();
            return;
        }

    }, [magentoCustomerData]);

    const isCustomerLoading = customerQuery.isFetching && !customerLocalStorageData?.customer;

    // is not a good practice to pass the whole query object (even if it has a state), if some part is not consumed in the component
    // it will cause unnecessary re-renders
    // because we only use the data and isFetching, we should only pass those, react query can do updates on a lot of properties
    // also we should make sure we do not pass things that will change references, or we will cause unnecessary re-renders
    const values = useMemo(() => ({
        customerBranches: customerQuery.data?.branches,
        isLoggedIn,
        customerQuery,
        reloadCustomer,
        isCompanyUser: customerQuery.data?.customer?.is_company_user,
        customerCompanyStatus: parseInt(customerQuery.data?.customer?.customer_company_status),
        isCustomerLoading: isCustomerLoading,
        customerData: customerQuery?.data,
        isAssociate: customerQuery.data?.customer?.is_associate,
        isRequestStatus: parseInt(customerQuery.data?.customer?.customer_company_status) === 2,
        customer: customerQuery.data?.customer
    }), [isLoggedIn, customerQuery.data, customerQuery.isFetching, isCustomerLoading]);

    return (
        <CustomerContext.Provider value={values}>
            {children}
        </CustomerContext.Provider>
    )
}

// Please use a base hook for the rest hooks
export const useCustomerData = () => {
    const context = useContext<CustomerProviderState>(CustomerContext);

    if (!context) {
        throw new Error('useCustomer must be used within the CustomerProvider')
    }

    return context
}
