import { useInventoryState } from '../../context'
import { Product } from '@catalog/types'

export const useInventory = (
    product?: Product
) => {
    const { inventoryQuery } = useInventoryState()

    const getProductInventory = (product?: Product) => {
        const inventory = inventoryQuery.data?.inventory || []

        if (!product || !inventory.length) return null
        return inventory.find(inventory => inventory.pim_id === product.pim_id) || null
    }

    const inventory = getProductInventory(product)
    const errors = inventoryQuery.data?.errors || null

    return {
        inventoryQuery,
        inventory,
        errors
    }
}
