import { Product } from '@catalog/types'

export type Inventory = {
    pim_id: Product['pim_id']
    items: InventoryItems | null
    error: InventoryError | null
}

export type InventoryItems = {
    delivery: InventoryItem
    nearby: InventoryItem
    store: InventoryItem
}

export type InventoryItem = {
    branch_id: number | null
    qty: number | null
    in_stock: boolean | null
    status?: {
        code: string
        label: string
    }
}

export type InventoryError = {
    code: InventoryErrorCode
    description?: string | null
    metadata?: any[] | null
}

export enum InventoryErrorCode {
    message = 'message',
    no_product = 'no_product',
    assistance = 'assistance',
    availability = 'availability',
    supersede = 'supersede'
}
