import React from 'react'
import { getProductUrl } from '../../../utils'
import { Product } from '../../../types'
import { SubstituteIcon } from '../../../icons'
import { FormattedMessage } from 'react-intl'
import intl from './intl'

type SubstituteBadgeProps = {
    product?: Product
}

const BadgeContent = () => (
    <span className="ec-flex ec-items-center ec-text-blue-light">
        <SubstituteIcon/>
        <span className="ec-font-medium ec-uppercase ec-text-xs ec-ml-1">
            <FormattedMessage {...intl.substitute}/>
        </span>
    </span>
)

export const SubstituteBadge = (
    { product }: SubstituteBadgeProps
) => {
    const productUrl = product ? getProductUrl(product) : null

    return productUrl
        ? <a href={productUrl.href}><BadgeContent/></a>
        : <BadgeContent/>
}
