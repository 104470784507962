import React from 'react'
import { UIMagnifyingGlassIcon } from '../../icons'
import { FormattedMessage } from 'react-intl'

export const NoResults = () => (
    <div className="ec-p-4">
        <div className="ec-flex ec-flex-col ec-items-center">
            <div className="ec-text-gray-dark">
                <UIMagnifyingGlassIcon size="extra-large"/>
            </div>
            <p className="ec-p ec-text-sm ec-font-semibold ec-mt-2">
                <FormattedMessage id="ui.emptyStates.noResultsTitle"
                                  defaultMessage="No results found"/>
            </p>
            <p className="ec-p ec-text-sm ec-text-gray-dark">
                <FormattedMessage id="ui.emptyStates.noResultsText"
                                  defaultMessage="We couldn't find what you're looking for."/>
            </p>
        </div>
    </div>
)
