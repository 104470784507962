import { request, GraphQlErrorResult } from '@model/api'
import { getWingmanListQuery } from './queries'
import { WingmanList } from '../types'

export type GetWingmanListApiParams = {
    accounts?: number[]
    startDate?: string
}

type WingmanListApiResult = {
    wingman_list: {
        items: WingmanList[]
    }
}

export type WingmanListResult = GraphQlErrorResult & {
    wingmanList: WingmanList[]
}

export const getWingmanListApi = async (
    params: GetWingmanListApiParams
): Promise<WingmanListResult> => (
    request<WingmanListApiResult>(getWingmanListQuery, params).then(data => ({
        wingmanList: data.data?.data?.wingman_list.items || null,
        errors: data.data?.errors || null
    }))
)
