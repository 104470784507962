import React from 'react';
import {Search} from "./Search";
import {useThemeState} from "../../../state";
import {cn} from "@utils";
import {useWindowSize} from "../../../../Various";
import {useAnalytics} from "../../../../Analytics";

export const SearchContainer = () => {

    const apiUrl = window.SearchApiConfig.apiUrl
    const baseMediaUrl = window.SearchApiConfig.baseMediaUrl
    const businessUnit = window.SearchApiConfig.businessUnit
    const defaultImage = window.SearchApiConfig.defaultImage

    const {headerStyle} = useThemeState();
    const {isMobile} = useWindowSize();
    // @ts-ignore
    const { SearchStateProvider, AnalyticsProvider } = window.watsco_search_ui;
    const analytics = useAnalytics()

    return (
        <div className={cn(headerStyle.desktopSearch, {'ec-ml-8':!isMobile})}>
            <SearchStateProvider
                apiUrl={apiUrl}
                noSearch={true}
                baseMediaUrl={baseMediaUrl}
                defaultImage={defaultImage}
                businessUnit={businessUnit}
                useSearchCache={true}
            >
                <AnalyticsProvider provider={analytics}>
                    <Search />
                </AnalyticsProvider>
            </SearchStateProvider>
        </div>
    );
}
