import React from 'react'
import { AppProvider } from '@app/context'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { PartsSearch } from '../../pages'
import { ErrorMessage } from '@ui/components'
import { ErrorBoundary } from '@app/components'

export const PartsSearchApp = () => (
    <ErrorBoundary fallback={<ErrorMessage section center/>}>
        <AppProvider>
            <AnalyticsProvider area={AnalyticsArea.PartsListSearch}
                               listData={{ list_id: 'parts_list_search', list_name: 'Parts List Search' }}>
                <PartsSearch/>
            </AnalyticsProvider>
        </AppProvider>
    </ErrorBoundary>
)
