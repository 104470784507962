import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo} from 'react'
import {
    QueryClientProvider,
    useMutation,
    UseMutationResult,
    useQuery,
} from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import { customerDataReload } from '@utils'
import { customerDataListen } from '@utils'
import { Branch } from '../types'
import {
    getBranchesApi, switchCurrentBranchApi,
    SwitchCurrentBranchApiParams,
    SwitchCurrentBranchResult, switchPreferredBranchApi,
    SwitchPreferredBranchApiParams,
    SwitchPreferredBranchResult
} from '../api'
import intl from './intl'
import { CustomerBranches } from '@customer/types'
import {getQueryClient} from "../../../models/api";
import {StoreProvider, useStore, useStoreData} from "../../Store";
import {CustomerProvider, useCustomerData} from "../../Customer";
import {StorageKeys, useStorageContext, timestampStorage} from "../../Storage";

export type SwitchCurrentBranchMutation = UseMutationResult<
    SwitchCurrentBranchResult,
    unknown,
    SwitchCurrentBranchApiParams>

export type SwitchPreferredBranchMutation = UseMutationResult<
    SwitchPreferredBranchResult,
    unknown,
    SwitchPreferredBranchApiParams>

export type BranchProviderState = {
    branches: Branch[],
    currentBranch: Branch,
    preferredBranch: Branch,
    recentBranches: Branch[],
    nearbyBranches: Branch[],
    switchCurrentBranch: SwitchCurrentBranchMutation
    switchPreferredBranch: SwitchPreferredBranchMutation
    getBranchEmail: (branch: Branch) => string
    getBranchName: (branch: Branch | null) => string
    getMapLocation: (branch?: Branch) => { lat: number, lng: number, title: string }
    isBranchLoading: boolean,
    errors: any,
    currentBranchName: string
}

export const BranchContext = createContext<BranchProviderState>(null)

const QUERY_KEY = 'branches'
const CUSTOMER_DATA_KEY = 'branch'

type CustomerDataBranch = {
    current: Branch
    preferred: Branch
    nearby: Branch[]
    recent: Branch[]
}

const BRANCH_EMAIL = 'branch%1@%2'

const queryClient = getQueryClient()

export const BranchProvider = ({ children }: PropsWithChildren) => {
    return (
        <QueryClientProvider client={queryClient}>
            <CustomerProvider>
                <StoreProvider>
                    <BranchProviderInner>
                        {children}
                    </BranchProviderInner>
                </StoreProvider>
            </CustomerProvider>
        </QueryClientProvider>
    )
}

export const BranchProviderInner  = (
    { children }: PropsWithChildren
) => {
    const { config } = useStoreData();
    const { customerBranches, reloadCustomer } = useCustomerData()
    const {setKeyData, branchStorage, customerStorage} = useStorageContext();
    const branchLocalStorageData = branchStorage;
    const customerLocalStorageData = customerStorage;

    const branchesQuery = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const result = await getBranchesApi()
            const { errors, ...resultData } = result

            const storageData = {[StorageKeys.BRANCH]: resultData}
            timestampStorage.set(StorageKeys.BRANCH);
            setKeyData(storageData);

            !!errors && errors.forEach(error => console.error(error.message))

            return result
        },
        initialData: branchLocalStorageData,
        initialDataUpdatedAt: () => timestampStorage.get(StorageKeys.BRANCH),
        staleTime: 1000 * 60 * 5,
    })

    const currentBranchCached = useMemo(() => {
        if (branchLocalStorageData?.branches.length === 0
            || !customerLocalStorageData?.branches) return null;

        if(customerLocalStorageData?.branches?.current_branch_id) {
            return branchLocalStorageData?.branches.find(
                branch => branch.branch_id === customerLocalStorageData?.branches?.current_branch_id
            )
        }

        return branchLocalStorageData?.branches.find(
            branch => branch.branch_id === customerLocalStorageData.branches.default_branch_id
        )
    }, [
        customerLocalStorageData?.branches?.default_branch_id,
        customerLocalStorageData?.branches?.current_branch_id,
        branchLocalStorageData?.branches?.length
    ])

    const branches = useMemo(() => branchesQuery?.data?.branches || [], [branchesQuery]);
    const preferredBranchId = useMemo(() => customerBranches?.preferred_branch_id || null, [customerBranches])
    const preferredBranch = useMemo(() =>
            branches.find(branch => branch.branch_id === preferredBranchId) || null,
        [branches, preferredBranchId]
    );
    const defaultBranchId = useMemo(() => customerBranches?.default_branch_id || null, [customerBranches])
    const recentBranchIds = useMemo(() => customerBranches?.recent_branch_ids || [], [customerBranches])
    const currentBranchId = useMemo(() => customerBranches?.current_branch_id || null, [customerBranches])

    const recentBranches = useMemo(() => (
        branches.filter(branch => recentBranchIds.includes(branch.branch_id)) || null
    ), [branches, recentBranchIds])

    const nearbyBranches = useMemo(() => {
        const current = branches.find(branch => branch.branch_id === currentBranchId)
        return current?.nearby
            ? branches.filter(branch => current.nearby.includes(branch.branch_id))
            : null
    }, [branches, currentBranchId])

    const switchCurrentBranch = useMutation({
        mutationFn: switchCurrentBranchApi,
        onSuccess: (response) => {
            const errors = response.errors
            const currentBranch = response.currentBranch
            !!errors && errors.map(error => {
                !currentBranch
                    ? toast.error(error.message)
                    : toast.warning(error.message)
            })

            if (!currentBranch) return

            customerDataReload(CUSTOMER_DATA_KEY)
            void reloadCustomer()

            toast.success(<FormattedMessage defaultMessage={'Your current branch is {name}.'}
                                            id={'branch.switch.success'}
                                            values={{name: currentBranch.branch_name}}/>)
        },
        onError: error => {
            console.error('API error', error)
            toast.error(<FormattedMessage {...intl.switchError}/>)
        }
    })

    const switchPreferredBranch = useMutation({
        mutationFn: switchPreferredBranchApi,
        onSuccess: (response) => {
            const errors = response.errors
            const preferredBranch = response.preferredBranch

            !!errors && errors.map(error => {
                !preferredBranch
                    ? toast.error(error.message)
                    : toast.warning(error.message)
            })

            if (!preferredBranch) return

            customerDataReload(CUSTOMER_DATA_KEY)
            void reloadCustomer()

            toast.success(<FormattedMessage defaultMessage={'Your preferred branch is {name}.'}
                                            id={'preferred.branch.switch.success'}
                                            values={{ name: preferredBranch.branch_name }}/>)
        },
        onError: error => {
            console.error('API error', error)
            toast.error(<FormattedMessage {...intl.switchError}/>)
        }
    })

    // For functions that create objects, need to use useMemo
    const getMapLocation = useMemo(() => (branch?: Branch) => ({
        lat: (branch || currentBranchCached).latitude,
        lng: (branch || currentBranchCached).longitude,
        title: (branch || currentBranchCached).branch_name
    }), [currentBranchCached]);

    // For pure string transformation functions, need to use useCallback
    const getBranchEmail = useCallback((branch: Branch) => {
        const branchId = branch.branch_id.toString().padStart(2, '0')
        return BRANCH_EMAIL.replace('%1', branchId).replace('%2', config.branch_email_domain)
    }, [config.branch_email_domain]);

    //@deprecated, this will tell a false story, methods like this should be avoided or used as part of a non re-rendering model
    const getBranchName = useCallback((branch: Branch | null) => (
        branch ? `${branch.branch_name} #${branch.branch_id}` : null
    ), []);

    const getBranchIds = (branches: Branch[]) => {
        if (!branches) return []
        return branches.map(branch => branch.branch_id).sort()
    }

    useEffect(() => {
        customerDataListen(CUSTOMER_DATA_KEY, (data: CustomerDataBranch) => {
            if (currentBranchCached?.branch_id !== data?.current.branch_id
                || preferredBranch?.branch_id !== data?.preferred.branch_id
                || getBranchIds(recentBranches).join(',') !== getBranchIds(Object.values(data.recent)).join(',')
            ) {
                const branches: CustomerBranches = {
                    default_branch_id: defaultBranchId,
                    current_branch_id: data?.current.branch_id,
                    preferred_branch_id: data?.preferred.branch_id,
                    recent_branch_ids: getBranchIds(Object.values(data.recent))
                }
                void reloadCustomer({ branches })
            }
        })
    }, [])

    return (
        <BranchContext.Provider value={{
            branches: branchLocalStorageData?.branches || branchesQuery?.data?.branches || [],
            currentBranch: currentBranchCached,
            preferredBranch,
            recentBranches,
            nearbyBranches,
            switchCurrentBranch,
            switchPreferredBranch,
            errors: branchesQuery.data?.errors || [],
            getBranchEmail,
            getBranchName,
            getMapLocation,
            isBranchLoading: branchesQuery.isFetching && !customerLocalStorageData,
            currentBranchName: getBranchName(currentBranchCached)
        }}>
            {children}
        </BranchContext.Provider>
    )
}

export const useBranchData = () => {
    const context = useContext(BranchContext)
    if (!context) {
        throw new Error('useBranchData must be used within a BranchProvider')
    }
    return context
}
