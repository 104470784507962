import React, { Fragment, PropsWithChildren } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FormattedMessage } from 'react-intl'
import { UIButtonIcon } from '../../Elements'
import { UICloseIcon } from '../../../icons'
import intl from './intl'
import clsx from 'clsx'

export type UIMediaModalProps = PropsWithChildren & {
    open: boolean
    onClose: () => void
}

export const UIMediaModal = (
    { open, onClose, children }: UIMediaModalProps
) => {
    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" onClose={() => onClose()} className="ec-z-50 ec-fixed ec-inset-0">
                <Transition.Child
                    enter="ec-transition-opacity ec-ease-in-out ec-duration-150"
                    enterFrom="ec-opacity-0 ec-h-full"
                    enterTo="ec-opacity-100 ec-h-full"
                    leave="ec-transition-opacity ec-ease-in-out ec-duration-150"
                    leaveFrom="ec-opacity-100 ec-h-full"
                    leaveTo="ec-opacity-0 ec-h-full">
                    <div className="ec-h-full ec-bg-white ec-flex ec-flex-col landscape:ec-flex-row">
                        <div className={clsx(
                            'ec-flex ec-border-b ec-border-gray-lighter ec-p-2',
                            'landscape:ec-border-b-0 landscape:ec-border-r'
                        )}>
                            <div className="ec-ml-auto">
                                <UIButtonIcon ariaLabel={<FormattedMessage {...intl.close}/>}
                                              onClick={() => onClose()}>
                                    <UICloseIcon/>
                                </UIButtonIcon>
                            </div>
                        </div>
                        <div className="ec-w-full ec-h-full ec-flex ec-justify-center ec-items-center ec-p-4">
                            {children}
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}
