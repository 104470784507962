import React, {memo} from 'react'
import { useMenuState } from '../../../../state'
import { FormattedMessage } from "react-intl";
import {cn, isPropsAreEqual} from "@utils";

export const CloseSubmenu = memo(() => {
    const { handleCloseSubmenu, currentSubmenu, menuStyle, config} = useMenuState()

    return (
        <div className={cn(menuStyle.backButtonContainer, config.menuClass)}>
            <div className={cn(menuStyle.backButton)} onClick={handleCloseSubmenu}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className={cn(menuStyle.backArrow)}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>

                <b className={'ec-pl-2.5'}>
                    <FormattedMessage
                        id="back.to.previous.menu"
                        defaultMessage="Back"
                        description="The back to previous menu"/>
                </b>
            </div>
            <div className={cn(menuStyle.backButtonName)} onClick={handleCloseSubmenu}><b>{currentSubmenu?.name}</b></div>
        </div>
    )
}, isPropsAreEqual);

CloseSubmenu.whyDidYouRender = true
