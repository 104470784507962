import { GraphQlErrorResult, request } from '@model/api'
import { cartFragment, cartQuery } from './queries'
import { productFragment } from '@catalog/api/queries'
import { Cart } from '../types'

export type CartApiResponse = {
    customerCart: Cart | null
}

export type CartResult = GraphQlErrorResult & {
    cart: Cart | null
}

export const getCartApi = async (): Promise<CartResult> => (
    request<CartApiResponse>([cartQuery, cartFragment, productFragment]).then(data => ({
        cart: data.data?.data?.customerCart || null,
        errors: data.data?.errors || null
    }))
)
