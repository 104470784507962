import React, { useState } from 'react'
import { Product } from '@catalog/types'
import { FormattedMessage } from 'react-intl'
import { Branch } from '@branch/types'
import { InventoryListItem, useBranchesInventory } from '../../hooks'
import { useCustomer } from '@customer/hooks'
import { UIButtonExpand } from '@ui/components'
import Skeleton from 'react-loading-skeleton'
import { useBranch } from '@branch/hooks'
import intl from './intl'

type BranchInventoryProps = {
    product: Product,
    allowBranchChange?: boolean
}

export const BranchInventoryList = (
    { product, allowBranchChange = false }: BranchInventoryProps
) => {
    const [open, setOpen] = useState(false)
    const { nearbyInventory, allInventory, isError, isLoading, switchBranch } = useBranchesInventory(product)
    const { isLoggedIn, isCompanyUser } = useCustomer()
    const { getBranchName } = useBranch()

    if (!isLoggedIn || isError) return false

    const Qty = (
        { item }: { item: InventoryListItem }
    ) => {
        if (isCompanyUser) return item.qty

        return item.in_stock
            ? <FormattedMessage id="inventory.standard.yes" defaultMessage="Yes"/>
            : <FormattedMessage id="inventory.standard.no" defaultMessage="No"/>
    }

    const ListItem = (
        { item }: { item: InventoryListItem }
    ) => (
        <li>
            <div className="ec-inline-block ec-font-medium ec-min-w-[1.5rem] ec-mr-1">
                <Qty item={item}/>
            </div>
            <div className="ec-inline-block">
                {allowBranchChange &&
                    <button className="ec-text-blue-light"
                            onClick={() => switchBranch(item.branch)}>
                        {getBranchName(item.branch)}
                    </button>
                }
                {!allowBranchChange &&
                    <p className="ec-m-0">{getBranchName(item.branch)}</p>
                }
            </div>
        </li>
    )

    const ExpandButton = () => (
        <UIButtonExpand open={open}
                        variant="plus-minus"
                        size="small"
                        onClick={() => setOpen(!open)}>
            <FormattedMessage {...intl.branchInventoryListAll}/>
        </UIButtonExpand>
    )

    return (
        <section className="ec-border ec-border-gray ec-p-4">
            <h2 className="ec-font-roboto ec-font-medium ec-m-0 ec-text-s">
                {isLoading
                    ? <Skeleton containerClassName="flex-1" className="ec-w-1/2"/>
                    : <FormattedMessage {...intl.branchInventoryListLabel}/>
                }
            </h2>
            <ul className="ec-text-s ec-py-4 ec-font-light ec-leading-5">
                {isLoading &&
                    <Skeleton className="ec-w-1/2" count={nearbyInventory.length || 4}/>
                }
                {!isLoading && nearbyInventory.map(inventory =>
                    <ListItem key={inventory.branch.branch_id} item={inventory}/>
                )}
            </ul>
            <div className="ec-text-s ec-font-medium">
                {isLoading
                    ? <Skeleton className="ec-w-1/2"/>
                    : <ExpandButton/>
                }
            </div>
            {open && !isLoading &&
                <ul className="ec-text-s ec-pt-4 ec-font-light">
                    {allInventory.map(inventory =>
                        <ListItem key={inventory.branch.branch_id} item={inventory}/>
                    )}
                </ul>
            }
        </section>
    )
}
