export default {
    feedsTab: {
        id: 'wingman.tabs.feeds',
        defaultMessage: 'Comments'
    },
    documentsTab: {
        id: 'wingman.tabs.documents',
        defaultMessage: 'Documents'
    },
    detailsTitle: {
        id: 'wingman.details.title',
        defaultMessage: 'Details'
    },
    descriptionTitle: {
        id: 'wingman.details.description',
        defaultMessage: 'Description'
    },
    emptyFile: {
        id: 'wingman.file.empty',
        defaultMessage: 'Unknown file format'
    },
    errorFile: {
        id: 'wingman.file.error',
        defaultMessage: 'File could not be loaded'
    }
}
