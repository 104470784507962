import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { AppProvider } from '@app/context'
import { AppConfig } from '@store/types'
import { Preferences } from '../../pages'

type PurePreferencesAppProps = {
    config: AppConfig
}

export const PurePreferencesApp = () => (
    <AppProvider>
        <Preferences/>
    </AppProvider>
)
