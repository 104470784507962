import React from 'react'
import { UIButton } from '@ui/components'
import { ShareCartAction } from '@shareCart//components'
import { useModal } from '@ui/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { ShareCartProvider, useShareCart } from '@shareCart/context'
import { useAnalytics } from '@analytics/hooks'
import { ShareCartEventMeta } from '@analytics/types'

export const ShareCart = () => (
    <ShareCartProvider>
        <ShareCartContent/>
    </ShareCartProvider>
)

const ShareCartContent = () => {
    const modal = useModal()
    const { isAllowed } = useShareCart()
    const { trackSavedCartShare } = useAnalytics()
    const { formatMessage } = useIntl()

    if (!isAllowed) return false

    const handleSuccess = (metadata: ShareCartEventMeta) => trackSavedCartShare({ metadata })

    return (
        <>
            <UIButton variant="text"
                      size="small"
                      fullWidth={true}
                      onClick={modal.handleOpen}>
                <span><FormattedMessage id="cart.share.cart" defaultMessage="Share Cart"/></span>
            </UIButton>
            <ShareCartAction {...modal}
                             onSuccess={handleSuccess}
                             formatMessage={formatMessage}/>
        </>
    )
}
