import React from 'react'
import { FormattedMessage } from 'react-intl'
import { InventoryError, InventoryErrorCode } from '../../types'
import { Product } from '@catalog/types'
import { getProductUrl } from '@catalog/utils'
import { Assistance } from '@branch/components'
import intl from './intl'

export type ErrorProps = {
    error?: InventoryError | undefined
    product?: Product
}

export const Error = (
    { error, product }: ErrorProps
) => {
    if (error?.code === InventoryErrorCode.assistance) {
        return <Assistance size="small"/>
    }

    if (error?.code === InventoryErrorCode.supersede) {
        return (
            <a className="ec-text-blue-light" href={getProductUrl(product).href}>
                {error.description}
            </a>
        )
    }

    return (
        <span>
            {error?.description
                ? error.description
                : <FormattedMessage {...intl.unavailable}/>
            }
        </span>
    )
}
