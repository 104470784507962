import React, { createContext, useState, PropsWithChildren } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getSupersedesApi, SupersedeResult } from '../api'
import { toast } from 'react-toastify'

type SupersedeProviderProps = PropsWithChildren & {
    productNumber?: string | null
}

export type SupersedeProviderState = {
    query: UseQueryResult<SupersedeResult>
    setProductNumber: UseStateSet<string | null>
    productNumber: string | null
}

export const SupersedeContext = createContext<SupersedeProviderState | null>(null)

export const SupersedeProvider = (
    { productNumber = null, children }: SupersedeProviderProps
) => {
    const [queryProductNumber, setProductNumber] = useState<string | null>(productNumber)

    const query = useQuery({
        queryKey: ['part_supersedes', queryProductNumber],
        queryFn: async () => {
            const result = await getSupersedesApi({ productNumber: queryProductNumber })

            result.errors && result.errors.forEach(error => toast.error(error.message))
            return result
        },
        enabled: !!queryProductNumber
    })

    return (
        <SupersedeContext.Provider value={{
            query,
            productNumber: queryProductNumber,
            setProductNumber
        }}>
            {children}
        </SupersedeContext.Provider>
    )
}
