import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAccount } from '@account/hooks'
import { ErrorMessage, UIModal } from '@ui/components'
import { useCustomer } from '@customer/hooks'
import { ErrorBoundary } from '@app/components'

export const SalespersonInfo = () => {
    const { account } = useAccount()
    const { formatMessage } = useIntl()
    const { isCompanyUser } = useCustomer()

    if (!isCompanyUser || !account?.salesperson_email) return false

    return (
        <ErrorBoundary fallback={<ErrorMessage/>}>
            <UIModal.Section title={
                formatMessage({ id: 'branch.contact.salespersonTitle', defaultMessage: 'Your Salesperson' })
            }>
                <div className="ec-text-sm">
                    <p className="ec-m-0">{account?.salesperson_name}</p>
                    <a href={`mailto:${account?.salesperson_email}`}>
                        <FormattedMessage id="branch.contact.salesperson" defaultMessage="Email the salesperson"/>
                    </a>
                </div>
            </UIModal.Section>
        </ErrorBoundary>
    )
}
