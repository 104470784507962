import React, {useEffect, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import { useWindowSize } from '@ui/hooks'
import { UIReorderArrowIcon } from '@ui/icons'
import { UIInput } from '@ui/components'
import {useIntl} from "react-intl";
import clsx from "clsx";

const ItemTypes = {
    ITEM: 'item',
};

export const LargeDraggableItem = ({ id, index, moveItem, findItem, visualIndex, items }) => {

    const {formatMessage} = useIntl();
    const {isMobile} = useWindowSize();

    const ref = React.useRef(null);

    const [inputValue, setInputValue] = useState<number>(visualIndex);

    useEffect(() => {
        setInputValue(visualIndex);
    }, [visualIndex]);

    const onIndexChange = (e) => {
        setInputValue(e.target.value ? Number(e.target.value) : 1);
    };

    const onBlur = () => {
        const newIndex = inputValue - 1;
        const originalIndex = findItem(id).index;
        moveItem(originalIndex, newIndex);
    };

    const [, drop] = useDrop({
        accept: ItemTypes.ITEM,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            //@ts-ignore
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveItem(dragIndex, hoverIndex);
            //@ts-ignore
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: () => {
            return { id, index: findItem(id).index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    return (
        <div ref={ref} className={clsx(
            "ec-flex ec-items-center ec-justify-between ec-p-2 ec-border-b ec-cursor-pointer hover:ec-bg-gray-lighter",
            {'ec-hidden' : items[index]?.hidden}
            )}>
            <div className={'ec-flex ec-w-96'}>
                {!isMobile && <div className="ec-mr-2 ec-text-gray-500 hover:ec-text-black ec-w-16 ec-flex ec-items-center">
                    <span className={'ec-pr-2'}>
                         <UIReorderArrowIcon/>
                    </span>
                    <UIInput
                        type="number"
                        value={inputValue}
                        onChange={onIndexChange}
                        onBlur={onBlur}
                        style={{ width: '40px' }}
                        min="1"
                        max={items.length}
                        align={'center'}
                        size={'small'}
                        onKeyDown={onKeyPress}
                    />
                </div>}
                <div>
                    <img src={items[index]?.image} alt={items[index]?.name} className={'ec-w-8 ec-h-8 ec-mr-2'}/>
                </div>
                <div>
                    <div className={'ec-text-base'}>
                        {items[index]?.name}
                    </div>
                    <div>
                        {
                            formatMessage({
                                id: 'sort.product.Product',
                                defaultMessage: 'Product:'
                            })
                        }
                         {items[index]?.bu_product_num}
                    </div>
                    <div className={'ec-pb-6'}>
                        {
                            formatMessage({
                                id: 'sort.product.Product',
                                defaultMessage: 'MFG:'
                            })
                        } {items[index]?.mfg_item_num}
                    </div>
                    <div>
                        {
                            formatMessage({
                                id: 'sort.product.Product',
                                defaultMessage: 'My Part:'
                            })
                        } {items[index]?.item_part}
                    </div>
                </div>
            </div>
            <div className={clsx({'ec-text-red-dark': !items[index]?.label}, 'ec-text-sm')}>
                {items[index]?.label ? items[index]?.label : formatMessage({
                    id: 'sort.product.no-label',
                    defaultMessage: 'no label'
                })}
            </div>
        </div>
    );
};
