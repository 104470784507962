import { request, GraphQlErrorResult } from '@model/api'
import { getWingmanFileQuery } from './queries'
import { WingmanFile } from '../types'

export type GetWingmanFileApiParams = {
    id: string
    file_type: string
    filename?: string
    token: string
}

type WingmanFileApiResult = {
    wingman_file: WingmanFile
}

export type WingmanFileResult = GraphQlErrorResult & {
    file: WingmanFile
}

export const getWingmanFileApi = async (
    params: GetWingmanFileApiParams,
): Promise<WingmanFileResult> => (
    request<WingmanFileApiResult>(getWingmanFileQuery, params).then(data => ({
        file: data.data?.data?.wingman_file || null,
        errors: data.data?.errors || null
    }))
)
