import React, { MouseEvent, ReactNode, ComponentPropsWithoutRef } from 'react'
import { UILoaderIcon } from '../../../icons'
import { UIOmit } from '../../../types'
import clsx from 'clsx'

export type UIButtonProps = UIOmit<ComponentPropsWithoutRef<'button'>> & {
    size?: 'small' | 'medium' | 'large' | 'small-square' | 'medium-square' | 'large-square'
    variant?: 'contained' | 'outlined' | 'text'
    fullWidth?: boolean
    loading?: boolean | ReactNode
}

export type UIButtonSize<T = string> = Record<UIButtonProps['size'], T>
export type UIButtonVariant<T = string> = Record<UIButtonProps['variant'], T>

const activeVariants: UIButtonVariant = {
    contained: clsx(
        'ec-bg-blue-dark ec-border ec-border-blue-dark ec-text-white',
        'hover:ec-bg-blue hover:ec-text-white hover:ec-border-blue'
    ),
    outlined: clsx(
        'ec-bg-white ec-text-blue-dark ec-border ec-border-blue-dark ec-border-solid',
        'hover:ec-bg-blue hover:ec-text-white'
    ),
    text: clsx(
        'ec-bg-transparent ec-border ec-border-transparent ec-text-blue-light',
        'hover:ec-bg-blue-lighter hover:ec-text-blue-light hover:ec-border-blue-lighter'
    )
}

const disabledVariants: UIButtonVariant = {
    contained: clsx(
        'ec-bg-gray-lightest ec-border ec-border-gray-lightest !ec-cursor-not-allowed ec-text-gray-light',
        'hover:ec-text-gray-light'
    ),
    outlined: clsx(
        'ec-bg-gray-lightest ec-border ec-border-gray-lightest !ec-cursor-not-allowed ec-text-gray-light',
        'hover:ec-text-gray-light'
    ),
    text: clsx(
        'ec-bg-transparent ec-border ec-border-transparent !ec-cursor-not-allowed ec-text-gray-light',
        'hover:ec-text-gray-light'
    )
}

const sizeVariants: UIButtonSize = {
    small: 'ec-text-xs ec-leading-6 ec-min-h-6 ec-py-[1px] ec-px-2',
    medium: 'ec-text-sm ec-leading-8 ec-min-h-8 ec-py-[1px] ec-px-4',
    large: 'ec-text-sm ec-leading-10 ec-min-h-10 ec-px-4',
    'small-square': 'ec-text-xs ec-leading-6 ec-min-h-6 ec-py-[1px] ec-px-1 ec-m-[1px] ec-aspect-square',
    'medium-square': 'ec-text-sm ec-leading-8 ec-min-h-8 ec-py-[1px] ec-px-1 ec-m-[1px] ec-aspect-square',
    'large-square': 'ec-text-sm ec-leading-10 ec-min-h-10 ec-py-[1px] ec-px-1 ec-m-[1px] ec-aspect-square'
}

export const UIButton = (
    {
        size = 'medium',
        variant = 'contained',
        fullWidth = false,
        loading = false,
        children,
        ...attributes
    }: UIButtonProps
) => {
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (attributes.disabled || loading) return
        attributes.onClick && attributes.onClick(event)
    }

    return (
        <button type="button"
                className={clsx(
                    'ec-uppercase ec-inline-flex ec-justify-center ec-items-center ec-tracking-wider ec-relative',
                    'ec-transition-all ec-ease-in-out ec-duration-200 hover:ec-no-underline',
                    !attributes.disabled && activeVariants[variant],
                    attributes.disabled && disabledVariants[variant],
                    fullWidth && 'ec-w-full'
                )}
                {...attributes}
                onClick={handleClick}>
            {!!loading &&
                <div className={clsx('ec-absolute', {
                    'ec-fill-white': variant === 'contained',
                    'ec-fill-blue-light': variant !== 'contained'
                })}>
                    {typeof loading === 'boolean'
                        ? <UILoaderIcon/>
                        : loading
                    }
                </div>
            }
            <span className={clsx(
                `ec-truncate ${sizeVariants[size]}`,
                { 'ec-invisible': !!loading }
            )}>
                    {children}
            </span>
        </button>
    )
}
