import React, { createContext, useState, PropsWithChildren } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getSubstitutesApi, SubstituteResult } from '../api'
import { useBranch } from '@branch/hooks'
import { toast } from 'react-toastify'

type SubstituteProviderProps = PropsWithChildren & {
    productNumber?: string | null
}

export type SubstituteProviderState = {
    query: UseQueryResult<SubstituteResult>
    setProductNumber: UseStateSet<string | null>
    productNumber: string | null
}

export const SubstituteContext = createContext<SubstituteProviderState | null>(null)

export const SubstituteProvider = (
    { productNumber = null, children }: SubstituteProviderProps
) => {
    const { currentBranch } = useBranch()
    const [queryProductNumber, setProductNumber] = useState<string | null>(productNumber)

    const query = useQuery({
        queryKey: ['part_substitutes', queryProductNumber],
        queryFn: async () => {
            const result = await getSubstitutesApi({
                productNumber: queryProductNumber,
                branchId: currentBranch.branch_id
            })

            result.errors && result.errors.forEach(error => toast.error(error.message))
            return result
        },
        enabled: !!queryProductNumber && !!currentBranch?.branch_id
    })

    return (
        <SubstituteContext.Provider value={{
            query,
            productNumber: queryProductNumber,
            setProductNumber
        }}>
            {children}
        </SubstituteContext.Provider>
    )
}
