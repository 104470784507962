import React from "react"
import { useIntl } from 'react-intl'
import { CustomerPortal } from '@customerPortal/components'
import { CustomerPreferences } from '../components/Preferences/CustomerPreferences'
import { PreferencesProvider } from '../state'
import intl from './intl'

export const Preferences = () => {
    const { formatMessage } = useIntl()

    return (
        <CustomerPortal title={formatMessage(intl.title)}>
            <PreferencesProvider>
                <CustomerPreferences/>
            </PreferencesProvider>
        </CustomerPortal>
    )
}
