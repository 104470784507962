import React, { createContext, PropsWithChildren } from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
    createProposalByActiveCartApi,
    CreateProposalByActiveCartParams,
    CreateProposalByActiveCartResult,
} from '../api'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'

type ProposalActionProviderProps = PropsWithChildren & {}

export type ProposalActionProviderState = {
    createProposalFromActiveCart: UseMutationResult<CreateProposalByActiveCartResult, unknown, CreateProposalByActiveCartParams>
}

export const ProposalActionContext = createContext<ProposalActionProviderState>(null)

export const ProposalActionProvider = (
    { children }: ProposalActionProviderProps
) => {
    const createProposalFromActiveCart = useMutation({
        mutationFn: (params: CreateProposalByActiveCartParams) => createProposalByActiveCartApi(params),
        onSuccess: ({ create_proposal_link, errors }) => {
            if (!create_proposal_link) throw Error(errors[0]?.message)

            toast.success(
                <FormattedMessage id="proposal.redirect"
                                  defaultMessage="Redirecting to create proposal..."/>
            )

            !!errors && errors.map(error => toast.error(error.message))
            window.location.replace(create_proposal_link)
        },
        onError: (error) => {
            consoleError(error)
            toast.error(
                <FormattedMessage id="proposal.createError"
                                  defaultMessage="Proposal could not be created"/>
            )
        }
    })

    const consoleError = (error: unknown) => {
        console.error('API error:', error instanceof Error
            ? error.message || 'Unknown error'
            : error
        )
    }

    return (
        <ProposalActionContext.Provider value={{
            createProposalFromActiveCart
        }}>
            {children}
        </ProposalActionContext.Provider>
    )
}
