import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { UIInputChip, UIToggle } from '@ui/components'
import { useYup } from '@yup/context'
import { UIChipItem } from '@ui/hooks'
import { ValidationError } from 'yup'
import { Section } from '@customerPortal/components'
import { usePreferencesState } from '../../state'

export const OrderConfirmationEmails = () => {
    const { yup, errorMessage } = useYup()
    const { formatMessage } = useIntl()
    const { control, register, watch, setValue, formState: { errors } } = useFormContext()
    const { isLoading } = usePreferencesState()
    const enabledValue = watch('checkout.receive_order_confirmation')

    useEffect(() => {
        if (!enabledValue) {
            setValue('checkout.order_confirmation_emails', [])
        }
    }, [enabledValue])

    const validateEmail = (chip: UIChipItem) => {
        try {
            yup.object({
                email: yup.string().email()
            }).validateSync({ email: chip.id })
        } catch (e) {
            return errorMessage(e as ValidationError)
        }
    }

    return (
        <Section.Item title={
            <FormattedMessage id="orderConfirmation.title" defaultMessage="Order Confirmation Emails"/>
        }>
            <UIToggle
                register={register('checkout.receive_order_confirmation')}
                name="checkout.receive_order_confirmation"
                disabled={isLoading}
                label={formatMessage({
                    id: 'orderConfirmation.sendEmail',
                    defaultMessage: 'Send Web Order Confirmation Email'
                })}/>
            {enabledValue &&
                <div>
                    <label className={'ec-text-gray-darker'}>
                        {formatMessage({
                            id: 'orderConfirmation.chips.title',
                            defaultMessage: 'Send order confirmation email to these additional people:'
                        })}
                    </label>
                    <Controller
                        control={control}
                        name="checkout.order_confirmation_emails"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <UIInputChip disabled={isLoading}
                                         inputRef={ref}
                                         items={value}
                                         onBlur={onBlur}
                                         validate={validateEmail}
                                         onChange={data => onChange(data.map(datum => datum.id))}
                                         error={errorMessage(errors, 'checkout.order_confirmation_emails')}/>
                        )}/>
                </div>
            }
        </Section.Item>
    )
}
