import React from 'react'
import { Branch } from '../../types'
import { ErrorBoundary } from '@app/components'
import { ErrorMessage, UIButtonExpand, UIExpand } from '@ui/components'
import { useHours } from '../../hooks'
import { FormattedMessage } from 'react-intl'
import { useModal } from '@ui/hooks'

type HoursProps = {
    branch: Branch
}

export const Hours = (
    { branch }: HoursProps
) => {
    const { today, hours } = useHours(branch)
    const { open, handleToggle } = useModal()

    return (
        <ErrorBoundary fallback={<ErrorMessage/>}>
            <section className="ec-flex ec-flex-col">
                <p className="ec-p">
                    <strong>{today.day}</strong>: <span>{today.hours}</span>
                </p>
                <UIButtonExpand size="medium" onClick={handleToggle} open={open}>
                    <FormattedMessage id="branch.hours" defaultMessage="Hours"/>
                </UIButtonExpand>
                <UIExpand expand={open}>
                    <ul className="ec-grid ec-grid-cols-[max-content_1fr] ec-gap-x-4 ec-text-sm ec-mt-1">
                        {Object.values(hours).map(({ key, day, hours }) => (
                            <li key={key} className="ec-contents">
                                <strong>{day}</strong>
                                <div>{hours}</div>
                            </li>
                        ))}
                    </ul>
                </UIExpand>
            </section>
        </ErrorBoundary>
    )
}
