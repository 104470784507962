import React, {createContext, useContext, useEffect, useState} from "react";
import { Branch } from '@branch/types'
import { useBranch } from '@branch/hooks'
import { SwitchCurrentBranchMutation, SwitchPreferredBranchMutation } from '@branch/context'
import { BranchesInventoryItem } from '@inventory/types'
import { useBranchesInventory } from '@inventory/hooks'
import { Product } from '@catalog/types'
import { useCheckout } from '@checkout/hooks'
import { useMap } from '@map/hooks'
import {useCustomerData} from "../../../Customer";

type InventoryFinderStateType = {
    isEnabled: boolean
    config: any;
    branches: Branch[] | null;
    currentBranch: Branch | null
    preferredBranch: Branch | null;
    recentBranches: Branch[] | null;
    generateDirectionsLink: any;
    distances: any;
    setDistances: any;
    isProductInventory: boolean;
    visibleMarkers:  Branch[] | [];
    setVisibleMarkers: any;
    mileLimit: number;
    setMileLimit: any;
    setCenterMap: (branch: Branch) => void;
    handleMapLocationClick: (branch: Branch) => void;
    switchCurrentBranch: SwitchCurrentBranchMutation;
    switchPreferredBranch: SwitchPreferredBranchMutation;
    isBranchesLoading: boolean;
    isOpen: boolean;
    setIsOpen: any;
    product: Product;
    setProduct: UseStateSet<Product|null>
    setIsProductInventory: any;
    inventoryData: BranchesInventoryItem[];
    isLoadingData: boolean;
    setZoom: (zoom: number | ((prevZoom: number) => number)) => void
};

const InventoryFinderStateProvider = createContext<InventoryFinderStateType | null>(null);

type PreferencesProviderProps = React.PropsWithChildren<Record<string, unknown>>

export const InventoryFinderProvider = (
    {children, config}: PreferencesProviderProps
) => {
    const {
        branches,
        currentBranch,
        preferredBranch,
        recentBranches,
        switchCurrentBranch,
        switchPreferredBranch,
        isBranchLoading
    } = useBranch()

    const {isCustomerLoading} = useCustomerData();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isProductInventory, setIsProductInventory] = useState<boolean>(false);
    const [product, setProduct] = useState<Product|null>(null);
    const [mileLimit, setMileLimit] = useState<number>(100);
    const { inventory, loadInventory } = useBranchesInventory(product)
    const { isBid } = useCheckout()
    const { map, selectMarker } = useMap()

    const isLoadingData = isBranchLoading || isCustomerLoading;

    const [distances, setDistances] = useState({});
    const [visibleMarkers, setVisibleMarkers] = useState([]);
    const BASE_MAPS_URL = "https://maps.google.com?saddr=&daddr=";

    const generateDirectionsLink = (branch: Branch) => {
        const { address, branch_name } = branch;
        const destination = `${branch_name} ${address.address_1} ${address.city}, ${address.region.name} ${address.postcode}`;
        return `${BASE_MAPS_URL}${encodeURIComponent(destination)}`;
    };

    const handleMapLocationClick = (branch: Branch) => {
        selectMarker({
            id: branch.branch_id,
            position: { lat: branch.latitude, lng: branch.longitude }
        })
    };

    const setCenterMap = (branch: Branch) => {
        !!map && map.setCenter({ lat: branch.latitude, lng: branch.longitude })
    }

    const setZoom = (zoom: number | ((prevZoom: number) => number)) => {
        if (typeof zoom === 'function') {
            map.setZoom(zoom(map.getZoom()))
        } else {
            map.setZoom(zoom)
        }
    }

    // Handles legacy finder
    useEffect(() => {
        const handleListData = (event) => {
            const product: Product = event.detail.product
            loadInventory([product])
            setIsProductInventory(!!product);
            setProduct(product)
            setIsOpen(true)
        };
        window.addEventListener('productBranchViewMoreEvent', handleListData);

        return () => {
            window.removeEventListener('productBranchViewMoreEvent', handleListData);
        };
    }, []);

    return (
        <InventoryFinderStateProvider.Provider value={{
            isEnabled: !isBid,
            config,
            currentBranch,
            branches: branches,
            preferredBranch,
            recentBranches,
            generateDirectionsLink,
            distances,
            setDistances,
            isProductInventory,
            visibleMarkers,
            setVisibleMarkers,
            mileLimit,
            setMileLimit,
            setCenterMap,
            handleMapLocationClick,
            switchCurrentBranch,
            switchPreferredBranch,
            isBranchesLoading: isBranchLoading,
            setIsOpen,
            isOpen,
            product,
            setProduct,
            setIsProductInventory,
            inventoryData: inventory?.items,
            isLoadingData,
            setZoom
        }}>
            {children}
        </InventoryFinderStateProvider.Provider>
    );
}

export const useInventoryFinderState = () => {
    const context = useContext(InventoryFinderStateProvider);

    if (!context) {
        console.warn(`useInventoryFinderState must be used within the InventoryFinderProvider`);
    }

    return context;
}
