import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '@model/graphql/request'
import { preferencesQuery, savePreferencesQuery } from '../../queries'
import { useForm } from 'react-hook-form'
import { useYup } from '@yup/context'
import { useIntl } from 'react-intl'
import intl from '../../components/Preferences/intl'
import { GraphQlResponse } from '@model/api'
import { useCustomer } from '@customer/hooks'
import { toast } from 'react-toastify'
import { CustomerPreferences, DeliveryMethod, PaymentMethod } from '../../types'

export type GraphQl<Type> = {
    data: Type
}

type SaveCustomerPreferencesInterface = {
    saveCustomerPreferences: CustomerPreferences
}

export type CustomerMetaPreferences = {
    checkout?: {
        delivery_method_list: DeliveryMethod[],
        payment_method_list: PaymentMethod[]
    }
}

export type GraphQlPreferences = {
    preferences: {
        data?: CustomerPreferences,
        meta?: CustomerMetaPreferences
    }
}

export type PreferencesStateProviderType = {
    sendWebOrderEmail?: boolean;
    setSendWebOrderEmail?: Dispatch<SetStateAction<boolean>>;
    onSubmit: any;
    form: any;
    query: any;
    mutation: any;
    preferences: any;
    isLoading: boolean;
    isSuccess: boolean;
    isEditMode: boolean;
    onEditClick: (cancel: boolean) => void
}

const PreferencesStateProvider = createContext<PreferencesStateProviderType>(null)

export interface PreferencesFormData {
    shareCart: {
        contacts: ShareCartRecipientData[]
    }
}

interface ShareCartRecipientData {
    name: string
    contact: string
}

export const PreferencesProvider = ({ children }: PropsWithChildren) => {

    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const { yup, resolver } = useYup()
    const { formatMessage } = useIntl()
    const { reloadCustomer } = useCustomer()
    const queryClient = useQueryClient()
    const [queryData, setQueryData] = useState<GraphQlPreferences>()

    const validationSchema = yup.object({
        checkout: yup.object({
            default_delivery_method: yup.string().required(),
            default_payment_method: yup.string().required(),
            order_confirmation_emails: yup.array().of(yup.string().email())
        }),
        shareCart: yup.object({
            contacts: yup.array().of(
                yup.object({
                    name: yup.string().required(),
                    contact: yup.string().required().emailOrPhone(formatMessage(intl.shareCartContactErrorMessage))
                })
            )
        }),
        inactiveCustomers: yup.object({
            offset: queryData?.preferences?.data?.inactiveCustomers?.enabled ? yup.number().nan().required().range(0, 60) : null
        }),
        residioProPerks: yup.object({
            value: queryData?.preferences?.data?.residioProPerks?.visible ? yup.string().rangeLength(7, 11) : null
        })
    })

    const [sendWebOrderEmail, setSendWebOrderEmail] = useState<boolean>(false)
    // @ts-ignore
    const form = useForm<PreferencesFormData>({ resolver: resolver(validationSchema) })

    const query = useQuery<GraphQl<GraphQlPreferences>, Error>({
        queryKey: ['customerPreferences'],
        queryFn: async () => {
            return request<GraphQlPreferences>(preferencesQuery)
        }
    })

    useEffect(() => {
        if(query.isSuccess){
            setQueryData(query.data?.data)
            form.reset(query.data.data.preferences.data)
        }
    }, [JSON.stringify(query?.data)])

    const mutation = useMutation<GraphQlResponse<SaveCustomerPreferencesInterface>, Error>(async (formData) => {
        return request(savePreferencesQuery, { preferences: formData })
    }, {
        onMutate: (data) => {
            return void reloadCustomer();
        },
        onSuccess: (response) => {
            const errors = response.errors
            const data = response?.data?.saveCustomerPreferences

            if (errors && !!errors.length) {
                toast.error(errors[0].message)
            } else {
                toast.success(formatMessage({
                    id: 'customer.preferences.success',
                    defaultMessage: 'Preferences have been successfully saved'
                }))
            }

            data && queryClient.setQueryData<GraphQlResponse<GraphQlPreferences>>(
                ['customerPreferences'],
                (oldData) => oldData
                    ? { data: { preferences: { ...oldData.data.preferences, data } } }
                    : oldData
            )
            queryClient.invalidateQueries({ queryKey: ['customerPreferences'] })
        },
        onError: (error, data, context) => {
            // @ts-ignore
            customerData.setData({ preferences: context.previousData }, false)
            toast.error(error.message)
        },
        onSettled: async () => {
            void reloadCustomer();
        }
    });

    const onEditClick = (cancel:boolean) => {
        if (cancel) {
            setIsEditMode(false)
            form.reset(query.data.data.preferences.data)
            return;
        }

        setIsEditMode(!isEditMode)
    }

    const onSubmit = (data): void => {
        if (!mutation.isLoading) {
            mutation.mutate(data)
        }
    }

    useEffect(() => {
        if(!mutation.isLoading && mutation.isSuccess) {
            setIsEditMode(!isEditMode);
        }
    }, [mutation.isLoading, mutation.isSuccess]);

    const { preferences = {} }: GraphQlPreferences = query.data?.data || {} as GraphQlPreferences

    return (
        <PreferencesStateProvider.Provider value={{
            sendWebOrderEmail,
            setSendWebOrderEmail,
            onSubmit,
            form,
            query,
            mutation,
            preferences,
            isLoading: mutation.isLoading || !isEditMode,
            isSuccess: mutation.isSuccess,
            isEditMode,
            onEditClick
        }}
        >
            {children}
        </PreferencesStateProvider.Provider>
    )
}

export const usePreferencesState = () => {
    const context = useContext(PreferencesStateProvider)

    if (!context) {
        console.warn(`usePreferencesState must be used within the PreferencesProvider`)
    }
    return context
}
