import { useContext } from 'react'
import { ProposalActionContext } from '../../context'
import { Proposal } from '../../types'

export const useProposalAction = () => {
    const context = useContext(ProposalActionContext)

    if (!context) {
        console.warn('useProposalAction must be used within the ProposalActionProvider')
    }

    const canEdit = (proposal: Proposal | null) => {
        if (!proposal) return false

        const expireDate = new Date(proposal.expiration_date)
        const now = new Date()

        return !proposal.accepted_at && proposal.is_owner && expireDate > now
    }

    return {
        ...context,
        canEdit
    }
}
