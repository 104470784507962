import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { toast, Id as ToastId } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import { addToCartApi, AddToCartResult } from '../../api'
import { useAnalytics } from '@analytics/hooks'
import eventBus from '@model/events/eventBus'
import { usePricing } from '@pricing/hooks'
import { Product } from '../../types'
import intl from './intl'

export type AddToCartProps = {
    products: Product | Product[]
}

export type AddToCartContext = {
    toastId: ToastId
    products: Product[]
    chunks: Product[][]
}

const BATCH_SIZE = 20
export const ADD_TO_CART_EVENT = 'productAddToCart'

const getProducts = (products: AddToCartProps['products']) => (
    !Array.isArray(products) ? [products] : products
)

const getProductChunks = (products: Product[]): Product[][] => {
    let chunks = []

    for (let i = 0; i < products.length; i += BATCH_SIZE) {
        const chunk = products.slice(i, i + BATCH_SIZE)
        chunks.push(chunk)
    }
    return chunks
}

const dispatchAddToCartEvent = (products: Product[]) => {
    eventBus.emit(ADD_TO_CART_EVENT, { products })
}

export const useAddToCart = () => {
    const { getProductPricing } = usePricing()
    const { trackAddToCart } = useAnalytics()

    const addToCart = useMutation({
        mutationFn: async ({ products }: AddToCartProps) => {
            products = getProducts(products)
            const chunks = getProductChunks(products)
            const response: AddToCartResult[] = []

            for (const chunk of chunks) {
                const addToCartResponse = await addToCartApi({ products: chunk })
                response.push(addToCartResponse)
            }

            void trackAddToCart({
                products: products.map(product => ({
                    product,
                    pricing: getProductPricing(product)
                }))
            })

            return response
        },
        onMutate: (variables): AddToCartContext => {
            const products = getProducts(variables.products)
            const chunks = getProductChunks(products)

            if (getProducts(variables.products).length <= BATCH_SIZE) {
                return { toastId: null, products, chunks }
            } else {
                const toastId = toast.loading(<FormattedMessage {...intl.waiting}/>, { autoClose: false })
                return { toastId, products, chunks }
            }
        },
        onSuccess: (data, _, context) => {
            const { products, chunks } = context

            const errorCount = data.reduce((count, { userErrors, errors }, index) => {
                const chunkErrorCount = errors ? chunks[index].length : (userErrors || []).length
                return count + chunkErrorCount
            }, 0)

            if (!errorCount) {
                dispatchAddToCartEvent(products)

                toast.success(<FormattedMessage {...intl.success} values={{
                    count: products.length
                }}/>)
            } else if (errorCount !== products.length) {
                dispatchAddToCartEvent(products)

                toast.warning(<FormattedMessage {...intl.warning}/>)
            } else {
                toast.error(<FormattedMessage {...intl.error} values={{
                    count: products.length
                }}/>)
            }
        },
        onError: (error, _, context) => {
            console.error(error)
            context.toastId && toast.done(context.toastId)

            toast.error(<FormattedMessage {...intl.error} values={{
                count: context.products.length
            }}/>)
        },
        onSettled: (_0, _1, _2, context) => {
            context.toastId && toast.done(context.toastId)
        }
    })

    return {
        addToCart
    }
}
