import { getProducts } from './getProducts'
import { AnalyticsArea, AnalyticsTrackContext } from '../types'
import { ProductInfo } from '@watsco/analytics'

const partsListTypeMap = {
    [AnalyticsArea.Supersede]: AnalyticsArea.PartsListSupersede,
    [AnalyticsArea.Substitute]: AnalyticsArea.PartsListSubstitute
}

export const getAddToCartProducts = (
    context: AnalyticsTrackContext
): ProductInfo[] => {
    const { area, areaRecords } = context

    const isPartsList = Object.keys(partsListTypeMap).includes(area)
        && areaRecords.some(record => record.area === AnalyticsArea.PartsList)

    if (!isPartsList) return getProducts(context)

    return getProducts(context).map(product => ({
        ...product,
        type: partsListTypeMap[product.type] || product.type
    }))
}
