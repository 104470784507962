import React from 'react';
import { ReorderActionModal } from './ReorderActionModal'
import {IntlProvider} from "react-intl";
import {ReorderAction} from "./ReorderAction";
import { SortingListProvider } from '../state'

interface ReorderAppPropsInterface {
    appConfig: {
        withButton: boolean,
        visibleData: any,
        isModal: boolean
    }
}

export const ReorderApp = (
    { config }: { config: ReorderAppPropsInterface }
) => {
    return (
        <IntlProvider locale={'en'}>
            <SortingListProvider config={config}>
                {config?.appConfig?.isModal && <ReorderActionModal/>}
                {!config?.appConfig?.isModal && <ReorderAction/>}
            </SortingListProvider>
        </IntlProvider>
    );
};
