import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'react-hook-form'
import { Section } from '@customerPortal/components'
import { UIButton, UIInput } from '@ui/components'
import { WingmanListSearchData } from '../../context'
import { useWingmanList } from '../../hooks'
import { useYup } from '@yup/context'
import { getFormInputDate } from '@utils'
import intl from './intl'

export const Filters = () => {
    const { query, minAllowedDate, maxAllowedDate, filters, onSearch } = useWingmanList()
    const { yup, resolver, errorMessage } = useYup()

    const validationSchema = yup.object({
        startDate: yup.date().required().min(minAllowedDate).max(maxAllowedDate)
    })

    const { handleSubmit, register, reset, formState: { errors } } = useForm<WingmanListSearchData>({
        resolver: resolver(validationSchema),
        reValidateMode: 'onSubmit',
        defaultValues: {
            // @ts-ignore
            startDate: filters?.startDate ? getFormInputDate(filters.startDate) : undefined
        }
    })

    const handleSearch = (data: WingmanListSearchData) => {
        onSearch({ ...filters, ...data })
    }

    const handleReset = () => {
        onSearch({ accounts: filters.accounts })
        reset()
    }

    return (
        <form onSubmit={handleSubmit(handleSearch)} noValidate>
            <Section>
                <Section.Item span="half">
                    <UIInput label={<FormattedMessage {...intl.filters.date}/>}
                             register={register('startDate', { setValueAs: v => v || null })}
                             error={errorMessage(errors, 'startDate')}
                             min={minAllowedDate}
                             max={maxAllowedDate}
                             type="date"
                             required/>
                    <div className="ec-flex ec-gap-2 ec-w-full">
                        <UIButton type="submit" loading={!query.isInitialLoading && query.isFetching}>
                            <FormattedMessage {...intl.filters.searchButton}/>
                        </UIButton>
                        <UIButton onClick={handleReset} variant="outlined">
                            <FormattedMessage {...intl.filters.resetButton}/>
                        </UIButton>
                    </div>
                </Section.Item>
            </Section>
        </form>
    )
}
