import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useBranch } from '@branch/hooks'
import { Inventory } from '../../../types'
import { SummaryItem } from './SummaryItem'
import { useCustomer } from '@customer/hooks'
import { BranchStockMessage } from '../../BranchStockMessage'
import intl from '../intl'

type SummaryProps = {
    inventory: Inventory
}

export const Summary = (
    { inventory }: SummaryProps
) => {
    const { isCompanyUser } = useCustomer()
    const { currentBranchName } = useBranch()
    const { store, nearby, delivery } = inventory?.items || {}

    if (!isCompanyUser) {
        return (
            <SummaryItem inStock={store.in_stock}>
                <BranchStockMessage currentStock={store.in_stock}
                                    nearbyStock={nearby.in_stock}
                                    transferStock={delivery.in_stock}/>
            </SummaryItem>
        )
    }

    if (!store || !delivery) return null

    return (
        <>
            {store &&
                <SummaryItem inStock={store.in_stock}>
                    {store.qty} {currentBranchName}
                </SummaryItem>
            }
            {delivery &&
                <SummaryItem inStock={delivery.in_stock}>
                    {delivery.qty} <FormattedMessage {...intl.allBranches}/>
                </SummaryItem>
            }
        </>
    )
}
