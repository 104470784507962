import React, { useState } from "react";
import { useInventoryFinderState } from "../../state";
import { useWindowSize } from '@ui/hooks';
import { Branch } from '@branch/types';
import { BranchDetails } from './BranchStructure/BranchDetails';

type BranchStructureProps = {
    data: Branch[]
}

export const BranchStructure = ({ data }: BranchStructureProps) => {
    const {
        handleMapLocationClick,
        isProductInventory,
        inventoryData
    } = useInventoryFinderState();
    const { isMobile } = useWindowSize();

    const [gradientVisibility, setGradientVisibility] = useState({});

    const handleGradientVisibility = (branchId) => {
        setGradientVisibility(prev => ({
            ...prev,
            [branchId]: !prev[branchId]
        }));
    }

    return (
        <div className="ec-container ec-mx-auto ec-py-4">
            {data.map((branch) => (
                <BranchDetails
                    key={branch.branch_id}
                    branch={branch}
                    isProductInventory={isProductInventory}
                    inventoryData={inventoryData}
                    isMobile={isMobile}
                    handleMapLocationClick={handleMapLocationClick}
                    gradientVisibility={!!gradientVisibility[branch.branch_id]}
                    setGradientVisibility={handleGradientVisibility}
                />
            ))}
        </div>
    );
};
