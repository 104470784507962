import React from 'react'
import { sizeVariants, strokeVariants } from './variants'
import { UIIconOutlineProps } from '../types'

export const UIChevronDownIcon = (
    { size = 'medium', stroke = 'medium' }: UIIconOutlineProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={strokeVariants[stroke]}
         stroke="currentColor" className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
    </svg>
)
