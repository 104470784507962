import React from 'react'
import { createPortal } from 'react-dom'
import { ToastContainer } from 'react-toastify'
import { config as toastConfig } from '@model/tostify/config'

export const Toaster = () => (
    <>
        {createPortal(
            <ToastContainer {...toastConfig}/>,
            document.body
        )}
    </>
)
