import React, { createContext, useState, PropsWithChildren } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { Document } from '@documents/types'
import { Part, PartModel, PartModelNumber, PartModelWid } from '../types'
import { getPartsListApi, GetPartsListApiParams, PartsListResult } from '../api'
import { useDebounce } from '@ui/hooks'

type PartsListProviderProps = {
    modelNumber?: PartModelNumber
}

export type PartsListProviderState = {
    allParts: Part[]
    parts: Part[]
    models: PartModel[]
    documents: Document[]
    isLoading: boolean
    isNoResult: boolean
    modelNumberQuery: UseQueryResult<PartsListResult>
    modelWidQuery: UseQueryResult<PartsListResult>
    loadPartsList: (params: Pick<GetPartsListApiParams, 'modelNumber' | 'modelWid'>) => void
    filter: string
    isWid: boolean
    setFilter: UseStateSet<string>
}

export const PartsListContext = createContext<PartsListProviderState>(null)

export const PartsListProvider = (
    { modelNumber: initModelNumber, children }: PropsWithChildren<PartsListProviderProps>
) => {
    const [modelNumber, setModelNumber] = useState<PartModelNumber>(initModelNumber)
    const [modelWid, setModelWid] = useState<PartModelWid>(null)
    const [filter, debouncedFilter, setFilter] = useDebounce('')

    const getQueryParams = (): GetPartsListApiParams => ({
        modelNumber,
        modelWid,
        documentModelNumber: modelNumber,
        documentManufacturer: 'all'
    })

    const modelNumberQuery = useQuery({
        queryKey: ['parts_list', modelNumber],
        queryFn: async () => {
            const result = await getPartsListApi(getQueryParams())
            result.errors && result.errors.forEach(error => toast.error(error.message))

            return result
        },
        enabled: !!modelNumber
    })

    const modelWidQuery = useQuery({
        queryKey: ['parts_list_wid', modelWid],
        queryFn: async () => {
            const result = await getPartsListApi(getQueryParams())
            result.errors && result.errors.forEach(error => toast.error(error.message))

            return result
        },
        enabled: !!modelWid
    })

    const loadPartsList = (params: Pick<GetPartsListApiParams, 'modelNumber' | 'modelWid'>) => {
        setModelWid(params.modelWid || null)

        if (params.modelNumber !== undefined && params.modelNumber !== modelNumber) {
            setModelNumber(params.modelNumber)
            setModelWid(null)
        }
    }

    const allParts = modelNumberQuery.data?.parts?.parts || modelWidQuery.data?.parts?.parts || []
    const models = modelNumberQuery.data?.parts?.models || []
    const documents = modelNumberQuery.data?.documents || modelWidQuery.data?.documents || []
    const isWid = !!models.length

    const isLoading = modelNumberQuery.isFetching || modelWidQuery.isFetching
    const isNoResult = (modelNumberQuery.isSuccess && !allParts.length && !models.length)
        || (modelWidQuery.isSuccess && !models.length)

    const parts = allParts.filter(part => {
        try {
            return !part.product && part.part_description?.toLowerCase().includes(debouncedFilter)
                || part.product?.name?.toLowerCase().includes(debouncedFilter)
                || part.part_number?.toLowerCase().includes(debouncedFilter)
                || String(part.item_number || '').includes(debouncedFilter)
        } catch (error) {
            console.error(error, { part })
            return true
        }
    })

    return (
        <PartsListContext.Provider value={{
            allParts,
            parts,
            models,
            documents,
            isLoading,
            isNoResult,
            isWid,
            modelNumberQuery,
            modelWidQuery,
            loadPartsList,
            filter,
            setFilter
        }}>
            {children}
        </PartsListContext.Provider>
    )
}
