import { Product } from '../../Catalog'

export type CustomerDataCart = {
    cart: {
        cart_cid?: string
        items: {
            product_sku: string
            product_price_value: string
            qty: number
            [key: string]: any
        }[],
        subtotalAmount: string
    }
}

export type Cart = {
    id?: string
    original_id?: number
    cart_cid?: string
    items?: CartItem[]
    prices?: CartPrices
}

export type CartItem = {
    uid?: string
    quantity?: number
    prices?: CartItemPrice
    product?: Product
}

export type CartItemPrice = {
    price?: Money
}

export type CartPrices = {
    grand_total: Money
}

export type CartError = {
    code: number
    message: string
}

type Money = {
    value: number
    currency: CurrencyEnum
}

export enum CurrencyEnum {
    USD = 'USD'
}
