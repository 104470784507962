import React, { createContext, PropsWithChildren, useState } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { getWingmanFileApi, WingmanFileResult } from '../api'
import { WingmanDocument } from '../types'

type WingmanFileProviderProps = PropsWithChildren & {
    document?: WingmanDocument | null
    securityToken?: string | null
}

export type WingmanFileProviderState = {
    query: UseQueryResult<WingmanFileResult>
    loadDocument: UseStateSet<WingmanDocument | null>
}

export const WingmanFileContext = createContext<WingmanFileProviderState>(null)

export const WingmanFileProvider = (
    { document = null, children }: WingmanFileProviderProps
) => {
    const [wingmanDocument, setWingmanDocument] = useState<WingmanDocument | null>(document)

    const query = useQuery({
        queryKey: ['wingman_file', wingmanDocument?.document_id],
        queryFn: async () => {
            const result = await getWingmanFileApi({
                id: wingmanDocument?.document_id,
                file_type: wingmanDocument?.file_type,
                filename: wingmanDocument?.title,
                token: wingmanDocument?.token || ''
            })

            result.errors && result.errors.forEach(error => toast.error(error.message))
            return result
        },
        enabled: !!wingmanDocument?.document_id
    })

    return (
        <WingmanFileContext.Provider value={{ query, loadDocument: setWingmanDocument }}>
            {children}
        </WingmanFileContext.Provider>
    )
}
