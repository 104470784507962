import React from "react";
import {useStorageContext} from "../../../Storage";
import {useAccountData} from "../../../Account";
import {TestAppChild} from "./TestAppChild";
import {useCustomerData} from "../../../Customer";
import {useBranchData} from "../../../Branch";

const isDevelopment = function () {

    try {
        var scripts = document.head.getElementsByTagName('script');
        for (var i = 0; i < scripts.length; i++) {
            if (scripts[i].src.indexOf('react.development.js') !== -1) {
                return true;
            }
        }
    } catch (e) {
        return false;
    }

    return false;
};

export const TestApp = () => {

    return null;

    if (!isDevelopment()) {
        return null;
    }

    const storage = useStorageContext();
    const customer = useCustomerData();
    const account = useAccountData();
    const branchData = useBranchData();

    return (
        <TestAppChild/>
    );
};

// TestApp.whyDidYouRender = true;
