import React from 'react'
import { UIExclamationIcon } from '@ui/icons'
import { FormattedMessage } from 'react-intl'
import cn from 'clsx'

type ErrorMessageProps = {
    section?: boolean
    center?: boolean
}

export const ErrorMessage = (
    { section = false, center = false }: ErrorMessageProps
) => {
    return (
        <div className={cn(
            {
                'ec-flex ec-w-full ec-h-full ec-justify-center': center
            }
        )}>
            <div className={cn(
                'ec-inline-flex ec-items-center',
                {
                    'ec-gap-1': !section,
                    'ec-flex-col ec-p-4': section,
                    'ec-h-full ec-justify-center': center
                }
            )}>
                <UIExclamationIcon shape="triangle" size={!section ? 'large' : 'extra-large'}/>
                <p className="ec-p ec-text-sm">
                    <FormattedMessage id="error.boundary.message"
                                      defaultMessage="Something went wrong"/>
                </p>
            </div>
        </div>
    )
}
