export const UserMenuStyle = {
    slide: "ec-transition-transform ec-duration-300 ec-ease-in-out ec-transform",
    slideMobile: "ec-transition-transform ec-duration-300 ec-ease-in-out ec-transform ec-overflow-visible",
    open: "ec-shadow",
    openMobile: "ec-translate-x-0 ec-bg-white ec-absolute ec--top-2 ec--left-4 ec-w-full ec-h-full",
    generic: "ec-flex lg:ec-px-4 ec-py-2 ec-text-sm ec-text-black ec-hover:text-black ec-m-0 ecmd:ec-w-64 ec-bg-white ec-items-center",
    close: "",
    closeMobile: "ec--translate-x-full",
    buttonClosed: "ec-text-white ec-px-4 ec-py-2 ec-cursor-pointer ec-flex ec-relative ec-pb-[15px] ec-items-center",
    buttonOpen: "ec-text-white ec-px-4 ec-py-2 ec-cursor-pointer ec-flex ec-relative ec-pb-[15px] ec-items-center",
    arrowUpDown: "ec-ml-2 ec-w-3 ec-h-3",
    submenu: "ec-bg-white ec-px-2 ec-py-2 ec-border-b-2 ec-border-solid ec-border-gray-400",
    submenuOpen: "ec-translate-x-0",
    submenuClose: "ec--translate-x-full",
    backButton: "ec-flex ec-px-4 ec-pb-1.5 ec-cursor-pointer ec-py-3",
    backButtonContainer: "ec-bg-white ec-border-b ec-border-solid ec-border-gray-400 ecmd:w-96",
    backArrow: "ec-w-4 ec-h-4",
    backButtonName: "ec-px-4 ec-uppercase ec-cursor-pointer",
    nextArrow: "ec-ml-auto ec-w-5 ec-h-5",
    nextArrowMobile: "ec-ml-auto ec-w-24 ec-h-5",
}
