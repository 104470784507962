import React from 'react'
import { useInventoryFinderState } from '../../../state'
import { ProductImage} from '@catalog/components/Product'

export const ProductInventory = () => {
    const { isProductInventory, product } = useInventoryFinderState()
    if (!isProductInventory || !product) return null

    return (
        <div className={'ec-bg-gray-lightest ec-p-2 ec-my-2 ec-flex ec-items-center'}>
            <div className={'ec-pr-2'}>
                <ProductImage product={product} size={50}/>
            </div>
            {product.name}
        </div>
    )
}
