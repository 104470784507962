import React from 'react'
import { PriceError, PriceErrorCode } from '../../../types'
import { FormattedMessage } from 'react-intl'
import intl from '../intl'

type ErrorProps = {
    error?: PriceError | undefined
}

const allowedErrors = [
    PriceErrorCode.Message,
    PriceErrorCode.StandardUser
]

export const Error = (
    { error }: ErrorProps
) => {
    if (error && !allowedErrors.includes(error.code)) return null

    return (
        <div className="ec-text-sm ec-whitespace-normal">
            {error?.description || <FormattedMessage {...intl.unavailable}/>}
        </div>
    )
}
