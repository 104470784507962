import React from 'react'
import { UIStarIcon } from '../../../icons'

export type UIRatingProps = {
    rating: number
}

export const UIRating = (
    { rating }: UIRatingProps
) => (
    <ul className="ec-inline-flex ec-align-text-bottom ec-text-yellow">
        {[1, 2, 3, 4, 5].map(rate =>
            <UIStarIcon key={rate} size="small" variant={rate <= rating ? 'solid' : 'outline'}/>
        )}
    </ul>
)
