import React, { ChangeEvent } from 'react'
import { RowData } from '@tanstack/table-core/src/types'
import { UITableData } from '../../../../hooks'
import { UISelect } from '../../../Forms/UISelect/UISelect'

export type UITablePaginationPageSizeProps<TData extends RowData> = {
    table: UITableData<TData>
}

export const UITablePaginationPageSize = <TData extends RowData>(
    { table }: UITablePaginationPageSizeProps<TData>
) => {
    const { pageSize } = table.getState().pagination

    const pageSizeOptions = table.pageSizes.map(pageSize => ({
        value: pageSize.toString(),
        label: pageSize.toString()
    }))

    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        table.setPageSize(Number(event.target.value))
    }

    return (
        <div className="ec-flex ec-items-center">
            <span>Show</span>
            <span className="ec-mx-2">
                <UISelect value={pageSize}
                          onChange={handlePageSizeChange}
                          options={pageSizeOptions}/>
            </span>
            <span>per page</span>
        </div>
    )
}
