import { useState, useEffect } from 'react'

export type UseWindowSizeState = {
    width: number | undefined
    height: number | undefined
    isMobile: boolean | undefined
    scrollTop: number | undefined
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<UseWindowSizeState>({
        width: undefined,
        height: undefined,
        isMobile: undefined,
        scrollTop: undefined
    })

    const [breakPoint, setBreakPoint] = useState(1025)

    useEffect(() => {
        const handleResize = () => {
            setWindowSize((prevState) => ({
                ...prevState,
                width: window.innerWidth,
                height: window.innerHeight,
                isMobile: window.innerWidth < breakPoint
            }))
        }

        const handleScroll = () => {
            setWindowSize((prevState) => ({
                ...prevState,
                scrollTop: window.scrollY
            }))
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('scroll', handleScroll)

        handleResize()
        handleScroll()

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('scroll', handleScroll)
        }
    }, [breakPoint])

    return {
        width: windowSize.width,
        height: windowSize.height,
        setBreakPoint: setBreakPoint,
        isMobile: windowSize.isMobile,
        scrollTop: windowSize.scrollTop
    }
}
