import React, { useEffect } from 'react';
import { useMenuState } from '../../../state';
import { CategoryMenuStyle } from "../../../state/context/CategoryMenuStyle";
import { cn } from '@utils';
import {useCustomerData} from "../../../../Customer";

export const Links = () => {
    const { menuData, setMenuStyle, menuStyle } = useMenuState();
    const {isLoggedIn} = useCustomerData();

    useEffect(() => {
        setMenuStyle(CategoryMenuStyle);
    }, []);

    if (!menuStyle) return null;

    return (
        <div className={cn('ec-flex')}>
            {menuData?.links && menuData.links.map((link, index) => (
                <div key={index} className={cn('ec-px-4', 'ec-py', 'ec-h-9', 'ec-cursor-pointer', 'ec-z-[21]', 'ec-relative')}>
                    <a className={cn('ec-text-white', 'hover:ec-text-white', 'hover:ec-no-underline visited:ec-text-white')}
                       href={isLoggedIn && link.href_logged_in ? link.href_logged_in : link.href}
                       title="East Coast Metal Distributors">
                        {link.name}
                    </a>
                </div>
            ))}
        </div>
    );
}

Links.whyDidYouRender = true
