import React, { ComponentPropsWithoutRef } from 'react'
import { ColumnMeta } from '@tanstack/table-core'
import { UIOmit } from '../../../../types'
import clsx from 'clsx'

export type UITableBodyCellProps = UIOmit<ComponentPropsWithoutRef<'td'>, 'align'> & {
    align?: ColumnMeta<any, any>['align']
    wrap?: boolean
    linearize?: boolean
}

const alignVariants = {
    'center': 'lg:ec-align-middle lg:ec-text-center',
    'top-left': 'lg:ec-align-top lg:ec-text-left',
    'top-center': 'lg:ec-align-top lg:ec-text-center',
    'top-right': 'lg:ec-align-top lg:ec-text-right',
    'bottom-left': 'lg:ec-align-bottom lg:ec-text-left',
    'bottom-center': 'lg:ec-align-bottom lg:ec-text-center',
    'bottom-right': 'lg:ec-align-bottom lg:ec-text-right',
    'center-left': 'lg:ec-align-middle lg:ec-text-left',
    'center-right': 'lg:ec-align-middle lg:ec-text-right'
}

export const UITableBodyCell = (
    {
        align = 'center-left',
        wrap = true,
        linearize = true,
        children,
        ...attributes
    }: UITableBodyCellProps
) => (
    <td className={clsx(
        'first:ec-m-0 lg:ec-table-cell lg:ec-p-3 lg:ec-m-0',
        alignVariants[align],
        {
            'ec-grid ec-gap-x-1 ec-gap-y-3 ec-grid-cols-[minmax(0,_max-content)_minmax(60%,_1fr)]': linearize,
            '[&>*:nth-child(n+3)]:ec-col-span-2': linearize,
            'ec-block ec-mt-3': !linearize,
            'ec-whitespace-nowrap': !wrap
        }
    )} {...attributes}>
        {children}
    </td>
)
