import React, { ComponentPropsWithoutRef, forwardRef, Ref } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { UIOmit } from '../../../types'
import clsx from 'clsx'
import { UIInputLabel, UIInputLabelProps } from '../UIInputLabel/UIInputLabel'
import { UIFormError } from '../UIFormError/UIFormError'

export type UIInputProps = UIOmit<ComponentPropsWithoutRef<'input'>, 'size'> & {
    size?: 'small' | 'medium' | 'large'
    align?: 'left' | 'center' | 'right'
    label?: UIInputLabelProps['label']
    error?: string
    required?: boolean
    inputRef?: Ref<HTMLInputElement>
    register?: UseFormRegisterReturn<string>
}

 export type UIInputSize<T = string> = Record<UIInputProps['size'], T>
 export type UIInputAlign<T = string> = Record<UIInputProps['align'], T>

const sizeVariants: UIInputSize = {
    small: 'ec-text-xs ec-leading-6 ec-py-[1px]',
    medium: 'ec-text-sm ec-leading-8 ec-py-[1px]',
    large: 'ec-text-sm ec-leading-10'
}

const alignVariants: UIInputAlign = {
    left: 'ec-text-left',
    center: 'ec-text-center',
    right: 'ec-text-right'
}

export const UIInput = forwardRef<HTMLInputElement, UIInputProps>(
    (
        {
            size = 'medium',
            align = 'left',
            placeholder = ' ',
            required,
            error,
            label,
            register,
            ...attributes
        }, ref
    ) => (
        <div>
            <div className={clsx('ec-relative', { 'ec-mt-4': !!label })}>
                <input ref={ref} className={clsx(
                    'ec-border ec-border-solid ec-appearance-none',
                    `focus:ec-ring-0 ec-peer ec-px-2 ec-w-full ${alignVariants[align]} ${sizeVariants[size]}`,
                    {
                        'ec-border-gray-dark': !error,
                        'ec-border-red/75': !!error,
                        'ec-bg-white': !attributes.disabled,
                        'ec-bg-gray-lightest ec-cursor-not-allowed': attributes.disabled
                    }
                )}
                       placeholder={placeholder}
                       aria-invalid={!!error}
                       aria-errormessage={error}
                       {...register || {}}
                       {...attributes}/>
                <UIInputLabel label={label} error={!!error} required={required} size={size}/>
            </div>
            <UIFormError error={error}/>
        </div>
    )
)

UIInput.displayName = 'UIInput'
