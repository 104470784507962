import { GraphQlError, request } from '@model/api'
import { OrderTemplate, OrderTemplateRaw } from '../types'
import { addToOrderTemplateQuery, orderTemplateFragment } from './query'
import { getModel } from './getModel'

export type OrderTemplateAddParams = {
    orderTemplateId: number
    items: OrderTemplateAddItem[]
}

export type OrderTemplateAddItem = {
    sku: string
    qty: number
}

export type OrderTemplateAddApiResponse = {
    orderTemplate: OrderTemplate
    errors?: GraphQlError[]
}

type OrderTemplateApiRawResponse = {
    addProductsToRequisitionList: {
        requisition_list: OrderTemplateRaw
    }
}

export const addToOrderTemplateApi = async (
    params: OrderTemplateAddParams
): Promise<OrderTemplateAddApiResponse> => {
    const orderTemplateUid = btoa(params.orderTemplateId.toString())
    const items = params.items.map(item => ({
        sku: item.sku,
        quantity: item.qty
    }))

    return request<OrderTemplateApiRawResponse>(
        [addToOrderTemplateQuery, orderTemplateFragment],
        { orderTemplateUid, items }
    ).then(data => ({
        orderTemplate: getModel(data.data?.data.addProductsToRequisitionList?.requisition_list || null),
        errors: data.data?.errors || null
    }))
}
