import React from 'react'
import { UIInput, UIModal } from '@ui/components'
import { SAVE_CART_VIEW_PATH } from '../config'
import { SaveActiveCartParams } from '../api'
import { useForm } from 'react-hook-form'
import { useSaveCart } from '../context'
import { useYup } from '@yup/context'
import { useModal } from '@ui/hooks'
import { urlBuilder } from '@utils'
import { IntlProps } from '@intl/types'
import { dummyFormatMessage } from '@intl/model'

type SaveCartModalProps = IntlProps & Pick<ReturnType<typeof useModal>, 'open' | 'handleClose'> & {
    onSuccess?: () => void
}

export const SaveCartAction = (
    { open, handleClose, formatMessage = dummyFormatMessage, onSuccess }: SaveCartModalProps
) => {
    const { errorMessage, yup, resolver } = useYup()
    const { saveActiveCartMutation } = useSaveCart()

    const schema = yup.object({
        name: yup.string().required()
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: resolver(schema)
    })

    const onSubmit = (data: SaveActiveCartParams) => {
        saveActiveCartMutation.mutate(data, {
            onSuccess: ({ saveCartId }) => {
                onSuccess && onSuccess()

                window.location.href = urlBuilder()
                    .setPath(SAVE_CART_VIEW_PATH, { id: saveCartId })
                    .toString()
            }
        })
    }

    return (
        <UIModal open={open}
                 onClose={handleClose}
                 title={formatMessage({ id: 'share.cart.title', defaultMessage: 'Create Saved Cart' })}
                 buttons={[
                     {
                         children: formatMessage({ id: 'share.cart.cancel', defaultMessage: 'Cancel' }),
                         disabled: saveActiveCartMutation.isLoading
                     },
                     {
                         children: formatMessage({ id: 'share.cart.save', defaultMessage: 'Save' }),
                         loading: saveActiveCartMutation.isLoading,
                         onClick: () => handleSubmit(onSubmit)()
                     }
                 ]}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <UIInput label={formatMessage({ id: 'save.cart.form.name', defaultMessage: 'Cart name' })}
                         register={register('name')}
                         error={errorMessage(errors, 'name')}
                         required/>
            </form>
        </UIModal>
    )
}
