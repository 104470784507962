import React from 'react'
import cn from 'clsx'
import { AccountIcon } from '../../../icons'
import { UIWarning, useWindowSize } from "../../../../Ui";
import { useIntl } from "react-intl";

export const ErrorUserMenu = () => {
    const { isMobile } = useWindowSize();
    const intl = useIntl();

    let accountMenu = intl.formatMessage({ id: 'account.header.top', defaultMessage: 'Your Account' });
    let account = intl.formatMessage({ id: 'account.error', defaultMessage: 'Account Issues' });

    return (
        <div className={cn("ec-overflow-visible", isMobile ? "ec-w-16 ec-h-10" : "ec-w-28 ec-h-7", "ec-cursor-pointer")}>
            <div className={cn('ec-z-10 ec-relative', isMobile ? 'ec-flex ec-items-center ec-justify-around ec-flex-wrap' : 'ec-w-36 ec-flex ec-items-center')}>
                <AccountIcon />
                <div>
                    <div className={cn("ec-text-white", isMobile ? "ec-text-xs" : "")}>
                        {isMobile ? intl.formatMessage({ id: 'account.header.top.mobile', defaultMessage: 'Account' }) : accountMenu}
                    </div>
                    {!isMobile && (
                        <div className={cn("ec-text-white ec-flex ec-text-s ec-text-xs")}>
                            {account}
                        </div>
                    )}
                </div>
                <div className={cn('ec-absolute ec-text-white', {'ec-top-4 ec-right-0': !isMobile}, {'-ec-top-2 -ec-right-2': isMobile})}
                     title={intl.formatMessage({
                         id: 'account.error',
                         defaultMessage: 'Account Issues'
                     })}>
                    <UIWarning />
                </div>
            </div>
        </div>
    )
}
