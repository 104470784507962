import React, {createContext, useContext, PropsWithChildren, useEffect} from 'react'
import { useBranch } from '@branch/hooks'
import { useAccount } from '../../Account/hooks/useAccount/useAccount'
import { useCustomer } from '@customer/hooks'
import { AnalyticsAreaRecord, AnalyticsTrackContext } from '../types'

export type AnalyticsProviderState = {
    context: AnalyticsTrackContext
}

export const AnalyticsContext = createContext<AnalyticsProviderState | null>(null)

export const AnalyticsProvider = (
    {
        area,
        listData = null,
        context = {},
        children
    }: PropsWithChildren<AnalyticsAreaRecord>
) => {
    const parentContext = useContext(AnalyticsContext)
    //this needs to be refactored, we should not have such dependencies for analytics
    const appConfig = window.appConfig.analytics;

    const isLoggedIn = !!appConfig.customerId;
    const cartId = appConfig.cartId;

    const { currentBranch } = useBranch()
    const { account, job } = useAccount()
    const { customer } = useCustomer()

    const parentAreaRecords = parentContext?.context?.areaRecords || []
    const areaRecord: AnalyticsAreaRecord = { area, listData, context }
    const areaRecords = [...parentAreaRecords, areaRecord]

    const trackContext: AnalyticsTrackContext = {
        area,
        listData,
        areaRecords,
        currentBranch,
        account,
        job,
        cartId,
        isLoggedIn,
        customer,
        ...context
    }

    return (
        <AnalyticsContext.Provider value={{ context: trackContext }}>
            {children}
        </AnalyticsContext.Provider>
    )
}

export const useAnalyticsContext = () => {
    const context = useContext(AnalyticsContext)
    if (!context) {
        throw new Error('useAnalyticsContext must be used within an AnalyticsProvider')
    }
    return context
}
