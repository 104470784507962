import React, { ComponentPropsWithoutRef, InputHTMLAttributes, ReactNode } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { UIOmit } from '../../../types'
import clsx from 'clsx'
import { UIFormError } from '../UIFormError/UIFormError'

export type UIRadioGroupProps = UIOmit<ComponentPropsWithoutRef<'div'>> & {
    error?: string
}

export type UIRadioProps = UIOmit<InputHTMLAttributes<HTMLInputElement>> & {
    label: ReactNode
    error?: boolean
    register?: UseFormRegisterReturn<string>
}

export const UIRadio = (
    { children, error, ...attributes }: UIRadioGroupProps
) => (
    <div className="ec-flex ec-flex-col ec-gap-2 ec-w-full" {...attributes}>
        {children}
        <UIFormError error={error}/>
    </div>
)

UIRadio.Item = (
    {
        label,
        error,
        register,
        ...attributes
    }: UIRadioProps
) => (
    <label className="ec-inline-flex ec-cursor-pointer ec-col-span-full">
        <input type="radio"
               className="ec-sr-only ec-peer"
               {...register || {}}
               {...attributes}/>
        <span className={clsx(
            'ec-text-sm ec-inline-flex ec-items-center before:ec-mr-2 before:ec-inline-block',
            'before:ec-transition-all before:ec-ease-in-out before:ec-duration-200',
            'before:ec-w-[18px] before:ec-h-[18px] before:ec-border-[2px] before:ec-rounded-full',
            'peer-checked:before:ec-border-[6px]',
            {
                'before:ec-border-gray-dark': !error,
                'ec-text-red before:ec-border-red/75 peer-checked:before:ec-border-red/75': error,
                'peer-checked:before:ec-border-blue-light before:ec-bg-white': !attributes.disabled && !error,
                'ec-cursor-not-allowed peer-checked:before:ec-border-gray-dark before:ec-bg-gray-lighter': attributes.disabled
            }
        )}>
            {label}
        </span>
    </label>
)
