import { MenuItemType } from './MenuStateProvider'

export enum MenuIds {
    SIGN_OUT = 'sign_out',
}

export const userMenu: MenuItemType = {
    name: 'Root',
    class: '',
    href: '',
    id: 'root',
    level: 0,
    children: [
        {
            name: 'Customer Portal',
            class: '',
            href: '/customer/account',
            level: 1,
            children: [],
            authorization: ['!isAssociate'],
            id: 'customer_portal'
        },
        {
            name: 'Associate Portal',
            class: '',
            href: '/customer/account',
            level: 1,
            children: [],
            authorization: ['isAssociate'],
            id: 'associate_portal'
        },
        {
            name: 'Bids',
            class: '',
            href: '/quotes',
            level: 1,
            children: [],
            authorization: ['canViewQuotes'],
            id: 'bids'
        },
        {
            name: 'Order Templates',
            class: '',
            href: '/requisition_list/requisition/index',
            level: 1,
            children: [],
            id: 'order_template',
            authorization: ['canShareQuicklists', '!isEpCustomer']
        },
        //need to add authorization
        {
            name: 'Saved Carts',
            class: '',
            href: '/save-cart',
            level: 1,
            children: [],
            id: 'save_cart'
        },
        {
            name: 'Proposals',
            class: '',
            href: '/proposals',
            level: 1,
            children: [],
            id: 'proposal',
            authorization: ['proposal']
        },
        {
            name: 'Pending Orders',
            class: '',
            href: '/order-approvals',
            level: 1,
            children: [],
            id: 'pending_orders',
            authorization: ['canViewPendingOrders']
        },
        {
            name: 'Open Order Status',
            class: '',
            href: '/sales/order/status',
            level: 1,
            children: [],
            id: 'order_status',
            authorization: ['canViewOrdersStatus']
        },
        {
            name: 'Invoiced Order History',
            class: '',
            href: '/sales/order/history',
            level: 1,
            children: [],
            id: 'order_history',
            authorization: ['canViewOrdersHistory']
        },
        {
            name: 'Pay Invoices',
            class: '',
            href: '/billtrust_link',
            level: 1,
            children: [],
            id: 'invoice',
            authorization: ['canViewBilltrust']
        },
        {
            name: 'Warranty Search',
            class: '',
            href: '/warranty',
            level: 1,
            children: [],
            id: 'warranty',
            authorization: ['canViewWarranty']
        },
        {
            name: 'Parts List & Documents',
            class: '',
            href: '/parts',
            level: 1,
            children: [],
            id: 'part_search'
        },
        {
            name: 'Wingman',
            class: '',
            href: '/wingman-dashboard',
            level: 1,
            children: [],
            id: 'wingman',
            authorization: ['wingman']
        },
        {
            name: 'Your Info',
            class: '',
            href: '/customer/account/edit',
            level: 1,
            children: [],
            id: 'me'
        },
        {
            name: 'Preferences',
            class: '',
            href: '/customer/preferences/index',
            level: 1,
            children: [],
            id: 'preference',
            authorization: ['isEpCustomer']
        },
        {
            name: 'Address Book',
            class: '',
            href: '/customer/address',
            level: 1,
            children: [],
            id: 'address_book',
            authorization: ['isEpCustomer']
        },
        {
            name: 'Saved Credit Cards',
            class: '',
            href: '/card_management',
            level: 1,
            children: [],
            id: 'saved_cards',
            authorization: ['isEpCustomer']
        },
        {
            name: 'PRO Points Essentials Rewards',
            class: '',
            href: '/pro_points',
            level: 1,
            children: [],
            id: 'pro_points',
            authorization: ['canViewProPoints']
        },
        {
            name: 'SimpleStock',
            class: '',
            href: 'stocking_list/stocking/index',
            level: 1,
            children: [],
            id: 'simple_stock',
            authorization: ['canGetStockingLists']
        },
        {
            name: 'Amana Loyalty Funds',
            class: '',
            href: '/amanaloyalty',
            level: 1,
            children: [],
            id: 'amana_loyalty',
            authorization: ['canViewAmanLoyalty']
        },
        {
            name: 'Linked Companies',
            class: '',
            href: '/company/accounts',
            level: 1,
            children: [],
            id: 'company_accounts',
            authorization: ['canViewLinkedAccounts']
        },

        {
            name: 'Manage Users',
            class: '',
            href: '/company/users',
            level: 1,
            children: [],
            id: 'users',
            authorization: ['canViewCompanyUsers']
        },
        {
            name: 'Roles and Permissions',
            class: '',
            href: '/company/role',
            level: 1,
            children: [],
            id: 'roles_permissions',
            authorization: ['canViewCompanyRoles']
        },
        {
            name: 'Company Structure',
            class: '',
            href: '/company',
            level: 1,
            children: [],
            id: 'company',
            authorization: ['canViewCompanyUsers']
        },
        {
            name: 'Sign Out',
            class: 'ec-border-t ec-border-solid ec-border-gray-400',
            href: null,
            level: 1,
            id: MenuIds.SIGN_OUT,
            children: []
        }
    ]
}
