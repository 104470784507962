import React from "react";
import {useThemeState} from "../../../state";
import {useWindowSize} from "../../../../Various";
import {logoBase64 } from '../../../state/context/logoBase64'

export const Logo = () => {

    const {headerStyle} = useThemeState();
    const {isMobile} = useWindowSize();

    return (
        <a href="/"
           title="East Coast Metal Distributors">
            <img className={isMobile ? headerStyle.desktopLogoImgMobile : headerStyle.desktopLogoImg}
                 src={logoBase64}
                 alt="East Coast Metal Distributors"/>
        </a>
    );
}

Logo.whyDidYouRender = true;
