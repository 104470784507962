import { useContext } from 'react'
import { WingmanFileContext } from '../../context'

export const useWingmanFile = () => {
    const context = useContext(WingmanFileContext)

    if (!context) {
        console.warn('useWingmanFile must be used within the WingmanFileProvider')
    }
    return context
}
