import React, { PropsWithChildren } from 'react'
import { ErrorMessage, UILoader } from '@ui/components'
import { PartModelNumber } from '../../types'
import { PartsListProvider, SubstituteProvider, SupersedeProvider } from '../../context'
import { usePartsList } from '../../hooks'
import { PartsListModels } from './PartsListModels'
import { PartsListSearch } from './PartsListSearch'
import { PartsListTable } from './PartsListTable'
import { ErrorBoundary } from '@app/components'

export type PartsListProps = {
    modelNumber?: PartModelNumber
}

export const PartsList = (
    { modelNumber }: PartsListProps
) => (
    <PartsListProvider modelNumber={modelNumber}>
        <SupersedeProvider>
            <SubstituteProvider>
                <PartsListContent/>
            </SubstituteProvider>
        </SupersedeProvider>
    </PartsListProvider>
)

export const PartsListContent = () => {
    const { allParts, isWid, modelNumberQuery, modelWidQuery } = usePartsList()
    const isLoading = modelNumberQuery.isFetching
    const isWidLoading = modelWidQuery.isFetching

    return (
        <div className="ec-w-full">
            <UILoader show={isLoading} size="extra-large"/>
            {!isLoading &&
                <PartsListContentWrapper>
                    <ErrorBoundary fallback={<ErrorMessage/>}>
                        <PartsListModels/>
                        <PartsListSearch/>
                    </ErrorBoundary>
                    <div className="ec-flex-auto ec-w-full">
                        <ErrorBoundary fallback={<ErrorMessage/>}>
                            <UILoader show={isWid && isWidLoading} size="extra-large"/>
                            {!!allParts.length &&
                                <PartsListTable/>
                            }
                        </ErrorBoundary>
                    </div>
                </PartsListContentWrapper>
            }
        </div>
    )
}

export const PartsListContentWrapper = (
    { children }: PropsWithChildren
) => (
    <div className="ec-flex ec-flex-col ec-gap-4 lg:ec-flex-row lg:ec-flex-wrap">
        {children}
    </div>
)
