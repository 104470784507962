import { request, GraphQlErrorResult } from '@model/api'
import { getWingmanViewQuery } from './queries'
import { Wingman } from '../types'

export type GetWingmanViewApiParams = {
    id: string
}

type WingmanViewApiResult = {
    wingman: Wingman
}

export type WingmanViewResult = WingmanViewApiResult & GraphQlErrorResult

export const getWingmanViewApi = async (
    params: GetWingmanViewApiParams
): Promise<WingmanViewResult> => (
    request<WingmanViewApiResult>(getWingmanViewQuery, params).then(data => ({
        wingman: data.data?.data?.wingman || null,
        errors: data.data?.errors || null
    }))
)
