import React, { useEffect } from 'react'
import { MenuItem } from './Elements/MenuItem'
import { MenuButton } from './Elements/MenuButton'
import { useMenuState } from '../../../state'
import { CategoryMenuStyle } from "../../../state/context/CategoryMenuStyle";
import { cn } from "@utils";

export const ProGuides = () => {
    const { menuData, isOpen, setMenuStyle, menuStyle, config } = useMenuState()

    useEffect(() => {
        setMenuStyle(CategoryMenuStyle);
    }, [])

    if (!menuStyle || menuData.proGuides.length === 0) return null;

    return (
        <div className={cn("ec-overflow-visible ec-h-7 ec-w-[120px] ec-whitespace-nowrap", config.menuClass)}>
            <MenuButton text={'PRO Guides'} />
            <ul className={cn("ec-absolute ec-left-0 ec-w-[440px] ec-overflow-hidden ecmd:-ml-52", menuStyle.slide, isOpen ? menuStyle.open : menuStyle.close,  {'ec-h-dvh':isOpen  })}>
                {menuData?.proGuides && menuData.proGuides.map((category, index) => (
                    <MenuItem key={index} data={category} />
                ))}
            </ul>
        </div>
    )
}

ProGuides.whyDidYouRender = true
