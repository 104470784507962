import { GraphQlErrorResult, request } from '@model/api'
import { shareActiveCartQuery } from './queries'

export type ShareActiveCartParams = {
    name: string
    sharedWith: string[]
    message: string
}

export type ShareActiveCartApiResult = {
    shareActiveCart: {
        save_cart: {
            save_cart_id: number
        }
    }
}

export type ShareActiveCartResult = GraphQlErrorResult & {
    saveCartId: number | null
}

export const shareActiveCartApi = async (
    params: ShareActiveCartParams
): Promise<ShareActiveCartResult> => (
    request<ShareActiveCartApiResult>(shareActiveCartQuery, params).then(data => ({
        saveCartId: data.data?.data?.shareActiveCart?.save_cart?.save_cart_id || null,
        errors: data.data?.errors || null
    }))
)
