import React, { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import { AnalyticsContextProvider } from '@watsco/analytics-react'
import { QueryClientProvider } from '@tanstack/react-query'
import { AccountProvider } from '@account/context'
import { getQueryClient } from '@model/api'
import { StoreProvider } from '@store/context'
import { AppConfig } from '@store/types'
import { YupProvider } from '@yup/context'
import { BranchProvider } from '@branch/context'
import { CustomerProvider } from '@customer/context'
import { getAnalyticsConfigs } from '@analytics/utils'
import { BrowserRouter } from 'react-router-dom'
import { StorageProvider } from '../../Storage'

type AppProviderProps = {
    intl?: boolean
}

type IntlProviderProps = ConstructorParameters<typeof IntlProvider>[0]

const queryClient = getQueryClient()

export const AppProvider = (
    { intl = true, children }: PropsWithChildren<AppProviderProps>
) => {
    const config: Partial<AppConfig> = window.pureAppConfig || {}

    if (!config.analytics) {
        console.warn('Undefined analytics configuration')
    }
    const analyticsConfigs = getAnalyticsConfigs(config.analytics || {})

    const IntlProviderWrapper = (
        { children, ...intlProps }: PropsWithChildren<IntlProviderProps>
    ) => (
        intl ? <IntlProvider {...intlProps}>{children}</IntlProvider> : children
    )

    return (
        <IntlProviderWrapper locale={config.locale || 'en-US'}>
            <QueryClientProvider client={queryClient}>
                <YupProvider>
                    <BrowserRouter>
                        <AnalyticsContextProvider configs={analyticsConfigs}>
                            <StorageProvider>
                                <StoreProvider>
                                    <CustomerProvider>
                                        <BranchProvider>
                                            <AccountProvider>
                                                {children}
                                            </AccountProvider>
                                        </BranchProvider>
                                    </CustomerProvider>
                                </StoreProvider>
                            </StorageProvider>
                        </AnalyticsContextProvider>
                    </BrowserRouter>
                </YupProvider>
            </QueryClientProvider>
        </IntlProviderWrapper>
    )
}
