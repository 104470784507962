import { useContext } from 'react'
import { WingmanViewContext } from '../../context'

export const useWingmanView = () => {
    const context = useContext(WingmanViewContext)

    if (!context) {
        console.warn('useWingmanView must be used within the WingmanViewProvider')
    }
    return context
}
