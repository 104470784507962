import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { GraphQlResponse } from '@model/api'
import { DocumentNode } from 'graphql'

export type ApiResponse<T> = AxiosResponse<GraphQlResponse<T>>

export const request = <T>(
    query: DocumentNode|DocumentNode[],
    variables?: { [key: string]: any },
    config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
    const mergedQuery = Array.isArray(query)
        ? query.join("\n")
        : query

    return (
        axios.post<GraphQlResponse<T>>('/graphql', { query: mergedQuery, variables }, {
            ...(config || {})
        })
    )
}
