import React, {createContext, PropsWithChildren, useMemo} from 'react'
import {QueryClientProvider, useQuery} from '@tanstack/react-query'
import {getStoreConfigApi} from '../api'
import {getQueryClient} from '@model/api'
import {toast} from 'react-toastify'
import {AppConfig, StoreConfig} from '../types'
import {StorageKeys, timestampStorage, useStorageContext} from "../../Storage";

const queryClient = getQueryClient()

type StoreProviderState = {
    appConfig: Partial<AppConfig>
    config: Partial<AppConfig & StoreConfig>
}

const StoreContext = createContext<StoreProviderState>(null)

export const StoreProvider = (
    { children }: PropsWithChildren
) => {
    return (
        <QueryClientProvider client={queryClient}>
            <StoreProviderInner>
                {children}
            </StoreProviderInner>
        </QueryClientProvider>
    )
}

const StoreProviderInner = (
    { children }: PropsWithChildren
) => {

    const {setKeyData,  storeStorage} = useStorageContext();
    const appConfig: Partial<AppConfig> = window.pureAppConfig || {}

    const query = useQuery({
        queryKey: ['store-config'],
        queryFn: async () => {
            const result = await getStoreConfigApi()
            const { errors, ...resultData } = result

            const storageData = {[StorageKeys.STORE]: resultData}

            setKeyData(storageData);
            !!errors && errors.forEach(error => toast.error(error.message))

            return result
        },
        initialData: storeStorage,
        initialDataUpdatedAt: () => timestampStorage.get(StorageKeys.STORE),
        staleTime: 1000 * 15 * 60,
    })

    const storeConfig = useMemo(() => query.data, [query.data])
    const config: Partial<AppConfig & StoreConfig> = useMemo(() => ({ ...appConfig, ...storeConfig }), [appConfig, storeConfig])

    return (
        <StoreContext.Provider value={{ appConfig, config }}>
            {children}
        </StoreContext.Provider>
    )
}

export const useStoreData = () => {
    const context = React.useContext(StoreContext)
    if (!context) {
        throw new Error('useStoreData must be used within a StoreProvider')
    }
    return context
}
