export default {
    signInPrice: {
        id: 'pricing.signIn.price',
        defaultMessage: '<a>Sign in</a> to view price'
    },
    signInPriceInventory: {
        id: 'pricing.signIn.price.inventory',
        defaultMessage: '<a>Sign in</a> to view price and inventory'
    },
    unavailable: {
        id: 'pricing.unavailable',
        defaultMessage: 'Pricing is not available at this time'
    }
}
