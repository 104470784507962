import React, { createContext, useContext, useState, PropsWithChildren } from 'react'
import { BranchesInventoryProvider } from '@inventory/context'
import { OrderTemplateProvider } from '@orderTemplate/context'
import { PricingProvider } from '@pricing/context'
import { Product } from '../types'

type CatalogProviderProps = {
    product: Product
}

export type CatalogProviderState = {
    product: Product
    setProduct: UseStateSet<Product>
}

const CatalogContext = createContext<CatalogProviderState>(null)

export const CatalogProvider = (
    { product: initProduct = null, children }: PropsWithChildren<CatalogProviderProps>
) => {
    const [product, setProduct] = useState(initProduct)
    const products = product ? [product] : []

    return (
        <CatalogContext.Provider value={{
            product,
            setProduct
        }}>
            <PricingProvider products={products}>
                <BranchesInventoryProvider products={products}>
                    <OrderTemplateProvider>
                        {children}
                    </OrderTemplateProvider>
                </BranchesInventoryProvider>
            </PricingProvider>
        </CatalogContext.Provider>
    )
}

export const useCatalog = () => {
    const context = useContext(CatalogContext)

    if (!context) {
        console.warn('useCatalog must be used within the CatalogProvider')
    }
    return context
}
