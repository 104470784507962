import React from 'react'
import { RowData } from '@tanstack/table-core/src/types'
import { FormattedMessage } from 'react-intl'
import { UITableData } from '../../../../hooks'
import intl from '../intl'

export type UITablePaginationInfoProps<TData extends RowData> = {
    table: UITableData<TData>
}

export const UITablePaginationInfo = <TData extends RowData>(
    { table }: UITablePaginationInfoProps<TData>
) => {
    const { pageIndex, pageSize } = table.getState().pagination

    const details = {
        from: pageIndex * pageSize + 1,
        to: Math.min((pageIndex + 1) * pageSize, table.dataSize),
        total: table.dataSize
    }

    return (
        <div>
            {table.getPageCount() > 1
                ? <FormattedMessage {...intl.pagination.itemsCount} values={details}/>
                : <FormattedMessage {...intl.pagination.itemsCountSimple} values={details}/>
            }
        </div>
    )
}
