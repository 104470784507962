import React, {useEffect} from 'react'
import {MenuItem} from './Elements/MenuItem'
import {MenuButton} from './Elements/MenuButton'
import {useMenuState} from '../../../state'
import {CategoryMenuStyle} from "../../../state/context/CategoryMenuStyle";
import {cn} from "@utils";

export const Brands = () => {
    const {menuData, isOpen, setMenuStyle, menuStyle, config} = useMenuState()

    useEffect(() => {
        setMenuStyle(CategoryMenuStyle);
    }, [])

    if (!menuStyle) return null;

    return (
        <div className={cn("ec-overflow-visible ec-h-7 ec-w-24 react-menu", config.menuClass)}>
            <MenuButton text={'Brands'}/>
            <ul className={cn("ec-absolute ec-left-0 ec-w-[440px] ec-overflow-auto ecmd:-ml-28", menuStyle.slide, isOpen ? menuStyle.open : menuStyle.close, {'ec-h-dvh':isOpen })}>
                {menuData?.brands && menuData.brands.map((category, index) => (
                    <MenuItem key={index} data={category}/>
                ))}
            </ul>
        </div>
    )
}

Brands.whyDidYouRender = true
