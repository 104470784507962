import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { QueryClientProvider, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { CartResult, getCartApi } from '../api'
import { useCustomer } from '@customer/hooks'
import { StorageKeys, useStorageContext } from '../../Storage'
import { getQueryClient } from '@model/api'
import eventBus from '@model/events/eventBus'

export type CartProviderState = {
    cartQuery: UseQueryResult<CartResult>
    reloadCart: () => Promise<void>
    invalidateCart: () => Promise<void>
}

export const CartContext = createContext<CartProviderState>(null)

const CART_QUERY_KEY = 'cart'
const CART_RELOAD_EVENT = 'cartReload'

const queryClient = getQueryClient()

export const CartProvider = ({ children }: PropsWithChildren) => {
    return (
        <QueryClientProvider client={queryClient}>
            <CartProviderInner>
                {children}
            </CartProviderInner>
        </QueryClientProvider>
    )
}

export const CartProviderInner = (
    { children }: PropsWithChildren
) => {
    const { setKeyData, cartStorage } = useStorageContext()
    const { isLoggedIn } = useCustomer()
    const queryClient = useQueryClient()

    const reloadCart = () => {
        return queryClient.refetchQueries([CART_QUERY_KEY], { type: 'active' })
    }

    const invalidateCart = () => {
        return queryClient.invalidateQueries([CART_QUERY_KEY])
    }

    useEffect(() => {
        // Added for backward compatibility with non-React code
        eventBus.on(CART_RELOAD_EVENT, reloadCart)
        return () => {
            eventBus.off(CART_RELOAD_EVENT, reloadCart)
        }
    }, [])

    const cartQuery = useQuery<CartResult>({
        queryKey: [CART_QUERY_KEY],
        queryFn: async () => {
            const result = await getCartApi()
            const { errors, ...resultData } = result

            setKeyData({ [StorageKeys.CART]: resultData })
            !!errors && errors.forEach(error => console.error(error.message))

            return result
        },
        placeholderData: cartStorage,
        enabled: isLoggedIn
    })

    return (
        <CartContext.Provider value={{
            cartQuery,
            reloadCart,
            invalidateCart
        }}>
            {children}
        </CartContext.Provider>
    )
}

export const useCartData = () => {
    const context = useContext(CartContext)
    if (!context) {
        throw new Error('useCartData must be used within a CartProvider')
    }
    return context
}
