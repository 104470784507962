import axios, { AxiosRequestConfig } from 'axios'
import { GraphQlResponseInterface } from '@model/api'
import { DocumentNode } from 'graphql'

export const request = <GraphQlResponseData>(
    query: string|DocumentNode,
    variables?: {[key: string]: any},
    config?: AxiosRequestConfig
): Promise<GraphQlResponseInterface<GraphQlResponseData>> => {
    return axios.post('/graphql', { query, variables }, config)
        .then(response => response.data as GraphQlResponseInterface<GraphQlResponseData>)
        .catch(error => {
            console.error('There was an error!', error);
            return Promise.reject(error);
        });
}
