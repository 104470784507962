import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../../types'

export const UITableBodyCellContent = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'span'>>
) => (
    <span className="" {...attributes}>
        {children}
    </span>
)
