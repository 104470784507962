import React, { ComponentPropsWithoutRef } from 'react'
import { UILoaderIcon } from '../../../icons'
import { UIIconProps, UIOmit } from '../../../types'

export type UILoaderProps = UIOmit<ComponentPropsWithoutRef<'div'>> & UIIconProps & {
    show?: boolean
}

export const UILoader = (
    { show = true, size, children, ...attributes }: UILoaderProps
) => {
    if (!show) return children || false

    return (
        <div className="ec-flex ec-justify-center ec-w-full ec-p-4 ec-text-blue-light" {...attributes}>
            <UILoaderIcon size={size}/>
        </div>
    )
}
