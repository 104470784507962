import React from "react";
import { useIntl } from 'react-intl';
import { BranchStructure } from "./BranchStructure";
import { useInventoryFinderState } from "../../state";
import {BranchSkeleton } from './Skeleton/BranchSkeleton'
import {useCustomerData} from "../../../Customer";

export const Selected = () => {
    const { currentBranch, isBranchesLoading, isLoadingData } = useInventoryFinderState();
    const intl = useIntl();
    const {isLoggedIn} = useCustomerData();

    const selectedBranchTitle = intl.formatMessage({
        id: 'selected.branch.title',
        defaultMessage: 'Your Selected Branch'
    });

    if(isBranchesLoading || isLoadingData) {
        return (<BranchSkeleton/>)
    }

    if (!currentBranch) return null;

    return (
        <div>
            {isLoggedIn && <h2 className={'ec-text-lg'}>{selectedBranchTitle}</h2>}
            <BranchStructure data={[currentBranch]}/>
        </div>
    )
}
