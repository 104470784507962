import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'react-hook-form'
import { UIButton, UIInput, UIPanel, UISelect } from '@ui/components'
import { usePartsList } from '../../hooks'
import { Section } from '@customerPortal/components'
import { useYup } from '@yup/context'
import intl from './intl'
import {useAnalytics} from "../../../Analytics";

type PartsSearchFormData = {
    modelNumber: string
    modelWid?: string
}

export const PartsSearchLookup = () => {
    const { models, isLoading, loadPartsList } = usePartsList()
    const { yup, resolver, errorMessage } = useYup()
    const { trackPartsListSearch } = useAnalytics()

    const validationSchema = yup.object({
        modelNumber: yup.string().required(),
        modelWid: yup.string().when({
            is: (value?: string) => value !== undefined,
            then: () => yup.string().required()
        })
    })

    const { handleSubmit, register, unregister, reset, formState: { errors, dirtyFields } } = useForm({
        resolver: resolver(validationSchema),
        reValidateMode: 'onSubmit'
    })

    const isSearchDirty = dirtyFields.modelNumber === true

    const handleSearch = (data: PartsSearchFormData) => {
        loadPartsList({ ...data, modelWid: parseInt(data.modelWid) || null })

        void trackPartsListSearch({
            metadata: {
                equipmentNumber: data?.modelNumber,
                revisionNumber: data?.modelWid || null,
                manufacturer:  null
            }
        })

        reset({
            modelNumber: data.modelNumber,
            modelWid: data.modelWid ?? undefined
        })
    }

    useEffect(() => {
        isSearchDirty && unregister('modelWid')
    }, [isSearchDirty])


    return (
        <UIPanel>
            <UIPanel.Body>
                <form onSubmit={handleSubmit(handleSearch)}>
                    <Section>
                        <Section.Item span="half"
                                      title={<FormattedMessage {...intl.lookup.title}/>}>
                            <UIInput label={<FormattedMessage {...intl.lookup.searchLabel}/>}
                                     register={register('modelNumber')}
                                     error={errorMessage(errors, 'modelNumber')}
                                     disabled={isLoading}
                                     required/>
                            {!!models.length && !isSearchDirty &&
                                <UISelect emptyOption
                                          border="attention"
                                          disabled={isLoading}
                                          register={register('modelWid')}
                                          error={errorMessage(errors, 'modelWid')}
                                          options={models.map(model => ({
                                              label: model.model_number,
                                              value: model.model_wid.toString()
                                          }))}/>
                            }
                            <div>
                                <UIButton type="submit" loading={isLoading}>
                                    <FormattedMessage {...intl.lookup.searchButton}/>
                                </UIButton>
                            </div>
                        </Section.Item>
                    </Section>
                </form>
            </UIPanel.Body>
        </UIPanel>
    )
}
