import { useMemo } from 'react'
import { urlBuilder, UrlBuilderSearchParamValue } from '@utils'
import { useUrl } from '../useUrl/useUrl'
import { ObjectSchema } from 'yup'

type TableUrlFilters<T = string> = Partial<Record<keyof T, UrlBuilderSearchParamValue>>

type UseTableUrlProps<T> = {
    defaultPageSize?: number
    filterSchema?: ObjectSchema<T>
    defaultFilters?: Partial<T>
}

const PAGE_PARAM = 'page'
const PAGE_SIZE_PARAM = 'size'
const FILTER_PARAM = 'filter'

export const useTableUrl = <T extends Record<string, any> = Record<string, string>>(
    props?: UseTableUrlProps<T>
) => {
    const { defaultPageSize, filterSchema, defaultFilters } = props || {}
    const { setUrlParams, removeUrlParam } = useUrl()

    const url = urlBuilder().current(),
        searchParams = url.getSearchParams(),
        pageIndex = Math.min(parseInt(searchParams.get(PAGE_PARAM)) || 1) - 1,
        pageSize = Number(searchParams.get(PAGE_SIZE_PARAM)) || defaultPageSize

    const filtersRaw = searchParams.get(FILTER_PARAM)

    const filters: Partial<T> = useMemo(() => {
        const data = JSON.parse(filtersRaw) || {}

        return filterSchema
            ? { ...defaultFilters, ...filterSchema.cast(data) } as T
            : { ...defaultFilters, ...data } as T
    }, [
        filtersRaw,
        JSON.stringify(defaultFilters)
    ])

    const setPageSize = (size: number) => {
        if (size === pageSize) return

        setUrlParams({
            [PAGE_SIZE_PARAM]: size !== defaultPageSize ? size : null,
            [PAGE_PARAM]: null
        })
    }

    const setFilterUrl = (filterItems: TableUrlFilters<T>) => {
        if (defaultFilters) {
            // Set filters to null if matched with default filters
            const parsedDefaultFilters = Object.entries(filterItems).map(([key, value]) => (
                defaultFilters[key]
                    ? [key, defaultFilters[key] === value ? null : value]
                    : [key, value]
            ))
            filterItems = Object.fromEntries(parsedDefaultFilters)
        }

        const filterEntries = Object.entries({ ...filters, ...filterItems })
            .filter(([_, value]) => value !== null)

        if (filterEntries.length > 0) {
            setUrlParams({
                [FILTER_PARAM]: JSON.stringify(Object.fromEntries(filterEntries)),
                [PAGE_PARAM]: null
            })
        } else {
            clearFilters()
        }
    }

    const addFilter = (name: keyof T, value: UrlBuilderSearchParamValue) => {
        setFilterUrl({ [name]: value } as TableUrlFilters<T>)
    }

    const addFilters = (filterItems: TableUrlFilters<T>) => {
        setFilterUrl(filterItems)
    }

    const removeFilter = (name: keyof T) => {
        setFilterUrl({ [name]: null } as TableUrlFilters<T>)
    }

    const clearFilters = () => {
        removeUrlParam([FILTER_PARAM, PAGE_PARAM])
    }

    const getPageUrl = (pageIndex: number) => (
        pageIndex <= 0
            ? url.removeSearchParam(PAGE_PARAM).getUrl()
            : url.setSearchParam(PAGE_PARAM, pageIndex + 1).getUrl()
    )

    return {
        pageIndex,
        pageSize,
        filters,
        addFilter,
        addFilters,
        removeFilter,
        clearFilters,
        setPageSize,
        getPageUrl
    }
}
