import React from 'react'
import { Product } from '@catalog/types'
import { FormattedMessage } from 'react-intl'
import { useBranch } from '@branch/hooks'
import { useBranchesInventory } from '../../hooks'
import { Error } from '../Error/Error'
import Skeleton from 'react-loading-skeleton'
import intl from './intl'
import clsx from 'clsx'
import { useCustomer } from '@customer/hooks'
import { BranchStockMessage } from '../BranchStockMessage'

type BranchInventoryProps = {
    product: Product
}

export const BranchInventory = (
    { product }: BranchInventoryProps
) => {
    const { isLoggedIn, isCompanyUser } = useCustomer()
    const { currentBranchName: branchName } = useBranch()
    const {
        branchesInventoryQuery,
        inventory,
        isLoading,
        currentInventory,
        isAvailableAtCurrent,
        isAvailableAtNearby,
        isAvailableForTransfer
    } = useBranchesInventory(product)

    if (!isLoggedIn) return false

    const qty = currentInventory?.qty_available ?? null
    const isInStock = !!qty || currentInventory?.in_stock

    const isError = branchesInventoryQuery.isError || !isLoading && !inventory || inventory?.error

    return (
        <p className={clsx(
            'ec-m-0 ec-font-medium ec-text-sm',
            { 'ec-text-green': isInStock  }
        )}>
            {isLoading &&
                <Skeleton className="ec-w-1/2"/>
            }
            {!isLoading && !isError && isCompanyUser &&
                <FormattedMessage {...intl.branchInventoryItem} values={{ qty: qty ?? 'N/A', branchName }}/>
            }
            {!isLoading && !isError && !isCompanyUser &&
                <BranchStockMessage currentStock={isAvailableAtCurrent}
                                    nearbyStock={isAvailableAtNearby}
                                    transferStock={isAvailableForTransfer}/>
            }
            {!isLoading && isError &&
                <Error product={product} error={inventory?.error}/>
            }
        </p>
    )
}
