import React, { createContext, PropsWithChildren, ReactElement, useEffect } from 'react'
import { Breadcrumb } from '../components'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'
import { useApp } from '@app/hooks'

export type CustomerPortalProviderProps = {
    title: ReactElement | string
    breadcrumbs?: Breadcrumb[]
}

export type CustomerPortalProviderState = CustomerPortalProviderProps & {
    backLink: Location | string | null
}

export type CustomerPortalLocationState = {
    referer?: Location
}

export const CustomerPortalContext = createContext<CustomerPortalProviderState>(null)

export const CustomerPortalProvider = (
    { title, breadcrumbs = [], children }: CustomerPortalProviderProps & PropsWithChildren
) => {
    const { state } = useLocation<CustomerPortalLocationState>() || {}
    const { setPageTitle } = useApp()

    if (!breadcrumbs.length) {
        typeof title !== 'string' && console.warn('Customer portal breadcrumbs must be defined')
        breadcrumbs = [{ label: typeof title === 'string' ? title : '' }]
    }

    const referer = state?.referer
    const breadcrumbLabels = breadcrumbs.map(({ label }) => label)

    useEffect(() => {
        setPageTitle(breadcrumbLabels)
    }, [...breadcrumbLabels])

    const backLink = breadcrumbs.length > 1
        ? referer || breadcrumbs.slice(-2, -1)?.[0].path
        : null

    return (
        <CustomerPortalContext.Provider value={{
            title,
            breadcrumbs,
            backLink
        }}>
            {children}
        </CustomerPortalContext.Provider>
    )
}
