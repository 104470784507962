export default {
    label: {
        id: 'inventory.label',
        defaultMessage: 'Inventory'
    },
    viewMore: {
        id: 'inventory.viewMore',
        defaultMessage: 'View More'
    },
    allBranches: {
        id: 'inventory.allBranches',
        defaultMessage: 'All Branches'
    }
}
