import React, { ComponentProps, Fragment, PropsWithChildren, ReactNode } from 'react'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'

export type UIPopoverProps = PropsWithChildren & {
    panel: ReactNode
    size?: 'small' | 'medium'
    width?: 'auto' | 'content' | 'full'
    position?: 'left' | 'right'
    contained?: boolean // relative positions have to be handled outside the component
}

export type UIPopoverSize<T = string> = Record<UIPopoverProps['size'], T>
export type UIPopoverWidth<T = string> = Record<UIPopoverProps['width'], T>
export type UIPopoverPosition<T = string> = Record<UIPopoverProps['position'], T>

const sizeVariants: UIPopoverSize = {
    small: 'ec-mt-1 ec-p-2',
    medium: 'ec-mt-2 ec-p-4'
}

const widthVariants: UIPopoverWidth = {
    auto: 'ec-w-full lg:ec-w-80',
    content: 'ec-w-max ec-min-w-full',
    full: 'ec-w-full'
}

const positionVariants: UIPopoverPosition = {
    left: 'ec-left-0',
    right: 'ec-right-0'
}

export const UIPopover = (
    {
        panel,
        width = 'auto',
        size = 'medium',
        position = 'left',
        contained = true,
        children
    }: UIPopoverProps
) => {
    return (
        <Popover className={clsx({
            'ec-static': width !== 'full',
            'ec-relative': contained
        })}>
            <Popover.Button as="div">
                {children}
            </Popover.Button>
            <Transition
                enter="ec-transition-opacity ec-duration-75  ec-ease-out"
                enterFrom="ec-opacity-0"
                enterTo={clsx('ec-opacity-100 ec-z-10', { 'ec-relative': contained })}
                leave={clsx('ec-transition-opacity ec-duration-75 ease-out ec-z-10', { 'ec-relative': contained })}
                leaveFrom="ec-opacity-100"
                leaveTo="ec-opacity-0">
                <Popover.Panel className={clsx(
                    'ec-absolute ec-z-10 ec-bg-white ec-shadow-sm ec-w-full ec-border',
                    'ec-border-gray-lighter',
                    sizeVariants[size],
                    widthVariants[width],
                    positionVariants[position]
                )}>
                    {panel}
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

UIPopover.Button = (
    { children, ...attributes }: ComponentProps<typeof Popover.Button>
) => (
    <Popover.Button {...attributes}>
        {children}
    </Popover.Button>
)
