import React from 'react'
import { UIIconOutlineProps } from '../types'
import { sizeVariants, strokeVariants } from './variants'

export const UIMagnifyingGlassIcon = (
    { size = 'medium', stroke = 'medium' }: UIIconOutlineProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         strokeWidth={strokeVariants[stroke]} stroke="currentColor" className={sizeVariants[size]}>
        <path strokeLinecap="round" strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
    </svg>
)
