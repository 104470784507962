import React from "react";
import {MainMenu} from "./Menu/MainMenu";
import {Brands} from "./Menu/Brands";
import {ProGuides} from "./Menu/ProGuides";
import {MenuProvider, useThemeState} from "../../state";
import {Links} from "./Menu/Links";
import {SearchContainer} from "./Search/SearchContainer";
import {Logo} from "./Logo/Logo";
import {UserMenu} from "./Menu/UserMenu/UserMenu";
import {BranchLocator} from "./BranchLocator/BranchLocator";
import {Cart} from "./Cart/Cart";
import {cn} from '@utils';
import {useWindowSize} from "../../../Various";
import {userMenu} from "../../state/context/userMenu";
import {linksMenu } from '../../state/context/linksMenu'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { ErrorBoundary } from '@app/components'
import {ErrorCart} from "./ErrorReplacements/ErrorCart";
import {ErrorUserMenu} from "./ErrorReplacements/ErrorUserMenu";
import {ErrorBranchLocator} from "./ErrorReplacements/ErrorBranchLocator";
import {ErrorMenus} from "./ErrorReplacements/ErrorMenus";
import {BranchProvider} from "../../../Branch";
import {AccountProvider} from "../../../Account";
import {MapProvider} from "../../../Map";
import {InventoryFinderProvider} from "../../../InventoryFinder";
import {BranchesInventoryProvider} from "../../../Inventory";
import {YupProvider} from "../../../Yup";
import {CartProvider} from "../../../Checkout";
import {ErrorSearch} from "./ErrorReplacements/ErrorSearch";
import {AnalyticsContextProvider} from "@watsco/analytics-react";
import {getAnalyticsConfigs} from "../../../Analytics";
import {StorageProvider} from "../../../Storage";
import {AboutUs} from "./Menu/AboutUs";

export const HeaderContainer = () => {

    const {headerStyle, config} = useThemeState();

    const menuData = config.menuData;
    menuData.links = linksMenu;
    const {isMobile} = useWindowSize();

    const analyticsConfigs = getAnalyticsConfigs(window.pureAppConfig.analytics || {})

    // @ts-ignore
    if (config.map) {
        // @ts-ignore
        window.googleMapsClientConfig = config.map;
    }

    return (
        <div>
            <div className={cn(headerStyle.headerContainer)}>
                <div className={cn(headerStyle.headerTop)}>
                    <div className={cn(headerStyle.desktopLeftRight)}>
                        <Logo/>
                    </div>
                    <ErrorBoundary fallback={<ErrorSearch/>}>
                        <StorageProvider>
                            <YupProvider>
                                <BranchProvider>
                                    <AccountProvider>
                                        <AnalyticsContextProvider configs={analyticsConfigs}>
                                            <AnalyticsProvider area={AnalyticsArea.Header}>
                                                <SearchContainer/>
                                            </AnalyticsProvider>
                                        </AnalyticsContextProvider>
                                    </AccountProvider>
                                </BranchProvider>
                            </YupProvider>
                        </StorageProvider>
                    </ErrorBoundary>
                    <div
                        className={cn(headerStyle.desktopLeftRight, {'ec-w-96 ec-place-content-evenly ec-pt-2.5 ec-ml-4': !isMobile})}>
                        <ErrorBoundary fallback={<ErrorUserMenu/>}>
                            <StorageProvider>
                                <AccountProvider>
                                    <MenuProvider config={{menuClass: 'react-user-menu', overlayId: false}}
                                                  data={userMenu}>
                                        <UserMenu/>
                                    </MenuProvider>
                                </AccountProvider>
                            </StorageProvider>
                        </ErrorBoundary>
                        <ErrorBoundary fallback={<ErrorBranchLocator/>}>
                            <StorageProvider>
                                <BranchProvider>
                                    <MapProvider>
                                        <BranchesInventoryProvider>
                                            <InventoryFinderProvider>
                                                <BranchLocator/>
                                            </InventoryFinderProvider>
                                        </BranchesInventoryProvider>
                                    </MapProvider>
                                </BranchProvider>
                            </StorageProvider>
                        </ErrorBoundary>
                    </div>
                </div>

                <div className={cn(headerStyle.horizontalMenu, 'open-sans-font-area')}>
                    <ErrorBoundary fallback={<ErrorMenus/>}>
                        <StorageProvider>
                            <AccountProvider>
                                <MenuProvider config={{menuClass: 'react-main-menu', overlayId: 'menu-overlay-main'}}
                                              data={menuData}>
                                    <MainMenu/>
                                </MenuProvider>
                                <MenuProvider
                                    config={{menuClass: 'react-brands-menu', overlayId: 'menu-overlay-brands'}}
                                    data={menuData}>
                                    <Brands/>
                                </MenuProvider>
                                <MenuProvider config={{menuClass: 'react-pro-menu', overlayId: 'menu-overlay-pro'}}
                                              data={menuData}>
                                    <ProGuides/>
                                </MenuProvider>

                                <MenuProvider config={{menuClass: 'react-about-menu', overlayId: 'menu-overlay-about'}}
                                              data={menuData}>
                                    <AboutUs/>
                                </MenuProvider>

                                <MenuProvider config={{menuClass: 'react-link-menu', overlayId: 'menu-overlay-links'}}
                                              data={menuData}>
                                    <Links/>
                                </MenuProvider>
                            </AccountProvider>
                        </StorageProvider>
                    </ErrorBoundary>
                    <div className={cn('ec-ml-auto')}>
                        <ErrorBoundary fallback={<ErrorCart/>}>
                            <StorageProvider>
                                <YupProvider>
                                    <BranchProvider>
                                        <AccountProvider>
                                            <AnalyticsContextProvider configs={analyticsConfigs}>
                                                <AnalyticsProvider area={AnalyticsArea.Cart}>
                                                    <CartProvider>
                                                        <Cart/>
                                                    </CartProvider>
                                                </AnalyticsProvider>
                                            </AnalyticsContextProvider>
                                        </AccountProvider>
                                    </BranchProvider>
                                </YupProvider>
                            </StorageProvider>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <div className={cn("overlay-menu ec-fixed ecmd:ec-inset-0 ec-bg-black ec-opacity-50 ec-hidden ec-z-40")}
                 id={'menu-overlay-main'}/>
            <div className={cn("overlay-menu ec-fixed ec-inset-0 ec-bg-black ec-opacity-50 ec-hidden ec-z-40")}
                 id={'menu-overlay-brands'}/>
            <div className={cn("overlay-menu ec-fixed ec-inset-0 ec-bg-black ec-opacity-50 ec-hidden ec-z-40")}
                 id={'menu-overlay-pro'}/>
            <div className={cn("overlay-menu ec-fixed ec-inset-0 ec-bg-black ec-opacity-50 ec-hidden ec-z-40")}
                 id={'menu-overlay-about'}/>
        </div>
    );
}

HeaderContainer.whyDidYouRender = true
