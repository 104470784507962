import React from 'react'

type UISupersedeIconProps = {
    size?: 'small' | 'medium' | 'large'
}

const sizeVariants = {
    small: 'ec-w-4 ec-h-4',
    medium: 'ec-w-5 ec-h-5',
    large: 'ec-w-6 ec-h-6'
}

export const SupersedeIcon = (
    { size = 'medium' }: UISupersedeIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="none" className={sizeVariants[size]}>
        <path
            d="M123.865 415.056L184.842 475.992C192.067 483.213 203.875 483.213 211.101 475.992C218.326 468.771 218.326 456.971 211.101 449.751L180.436 419.194L370.592 419.194C395.441 419.194 416.765 410.389 434.477 392.689C452.188 374.989 461 353.767 461 328.846C461 304.014 452.188 282.704 434.477 265.004C416.765 247.304 395.529 238.498 370.592 238.498L141.408 238.498C125.899 238.498 112.77 233.127 102.02 222.472C91.3575 211.817 85.9824 198.696 85.9824 183.11C85.9824 167.611 91.3575 154.491 102.02 143.748C112.682 133.093 125.811 127.721 141.408 127.721L330.947 127.721L300.371 158.365C293.145 165.586 293.057 177.298 300.282 184.519L300.371 184.607C307.596 191.827 319.404 191.827 326.629 184.607L387.606 123.67C395.008 116.273 395.008 104.385 387.606 96.9886L326.629 36.0522C319.404 28.8314 307.684 28.7433 300.459 35.9641L300.371 36.0522C293.145 43.273 293.057 54.9847 300.282 62.2055L300.371 62.2936L330.947 92.8498L141.408 92.8498C116.559 92.8498 95.2346 101.656 77.5231 119.355C59.8117 137.055 51 158.277 51 183.198C51 208.03 59.8117 229.34 77.5232 247.04C95.2346 264.74 116.471 273.546 141.408 273.546L370.592 273.546C386.101 273.546 399.23 278.917 409.98 289.572C420.731 300.227 426.018 313.348 426.018 328.934C426.018 344.433 420.642 357.553 409.98 368.297C399.318 379.04 386.189 384.323 370.592 384.323L180.524 384.323L211.189 353.679C218.414 346.458 218.414 334.658 211.189 327.437C203.963 320.217 192.156 320.217 184.93 327.437L123.865 388.374C116.463 395.771 116.463 407.659 123.865 415.056Z"
            fill="#CB0015"></path>
    </svg>
)
