import React from 'react'
import { Product } from '@catalog/types'
import { AddToCartQty } from '@catalog/components'
import { Assistance } from '@branch/components'
import { usePricing } from '@pricing/hooks'

interface PartActionsPropsInterface {
    product: Product
}

export const PartsListActions = (
    { product }: PartActionsPropsInterface
) => {
    const { query, pricing } = usePricing(product)

    return (
        <div className="ec-min-w-[12rem]">
            {(query.isFetching || pricing?.can_add) &&
                <AddToCartQty product={product} size="large"/>
            }
            {!query.isFetching && !pricing?.can_add &&
                <Assistance/>
            }
        </div>
    )
}
