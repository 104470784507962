import React, { useEffect } from 'react'
import { MenuItem } from './Elements/MenuItem'
import { useMenuState } from '../../../state'
import { CategoryMenuStyle } from "../../../state";
import { cn } from "@utils";
import {UICloseIcon} from "@ui/icons";

export const MainMenuMobile = () => {
    const { menuData, isOpen, setMenuStyle, menuStyle, config, setIsOpen } = useMenuState()

    useEffect(() => {
        setMenuStyle(CategoryMenuStyle);
    }, [])

    if (!menuStyle) return null;

    return (
        <div className={cn("ec-overflow-visible ec-w-12 ec-h-7", config.menuClass, isOpen && "ec-w-full")}>
            <div className={cn(isOpen ? undefined : "ec-z-10 ec-relative")} onClick={() => setIsOpen(!isOpen)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="ec-w-8 ec-h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>

                <span className='ec-text-white ec-text-xs'>Menu</span>
            </div>

            <ul className={cn("ec-h-screen ecmd:-mt-36 ec-w-full ec-overflow-scroll ec-overflow-x-hidden", menuStyle.slideMobile, isOpen ? menuStyle.openMobile : menuStyle.closeMobile)}>
                {menuData.children.map((category, index) => (
                    <MenuItem key={index} data={category} />
                ))}
            </ul>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={cn(
                    " ec-text-white ec-h-screen ec-w-[50px] ec-fixed ec-right-0 ec-top-0 ec-bg-black ec-z-[25] ec-transition-transform ec-duration-100 ec-ease-in-out ec-transform ec-flex ec-pt-4 ec-justify-center",
                    isOpen ? 'ec-translate-x-0' : 'ec-translate-x-full'
                )}
            >
                <UICloseIcon/>
            </div>
        </div>
    )
}

MainMenuMobile.whyDidYouRender = true
