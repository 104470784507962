import React, { ComponentPropsWithoutRef, forwardRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { UIInputLabel, UIInputLabelProps } from '../UIInputLabel/UIInputLabel'
import { UIOmit } from '../../../types'
import clsx from 'clsx'
import { UIFormError } from '../UIFormError/UIFormError'

export type UITextareaProps = UIOmit<ComponentPropsWithoutRef<'textarea'>> & {
    size?: 'small' | 'medium' | 'large'
    label?: UIInputLabelProps['label']
    required?: boolean
    error?: string
    register?: UseFormRegisterReturn<string>
}

export type UITextareaSize<T = string> = Record<UITextareaProps['size'], T>

const sizeVariants: UITextareaSize = {
    small: 'ec-text-xs ec-p-2 ec-min-h-21',
    medium: 'ec-text-sm ec-py-[7px] ec-min-h-24',
    large: 'ec-text-sm ec-py-[9px] ec-min-h-25'
}

export const UITextarea = forwardRef<HTMLTextAreaElement, UITextareaProps>(
    (
        {
            size = 'medium',
            placeholder = ' ',
            label,
            error,
            register,
            required,
            ...attributes
        }, ref
    ) => (
        <div>
            <div className={clsx('ec-relative', { 'ec-mt-4': !!label })}>
                <textarea ref={ref} className={clsx(
                    'ec-border ec-border-solid ec-appearance-none',
                    `focus:ec-ring-0 ec-peer ec-px-2 ec-w-full ${sizeVariants[size]}`,
                    {
                        'ec-border-gray-dark': !error,
                        'ec-border-red/75': !!error,
                        'ec-bg-white': !attributes.disabled,
                        'ec-bg-gray-lightest ec-cursor-not-allowed': attributes.disabled
                    }
                )}
                          placeholder={placeholder}
                          aria-invalid={!!error}
                          aria-errormessage={error}
                          {...register || {}}
                          {...attributes}/>
                <UIInputLabel label={label} error={!!error} required={required} size={size}/>
            </div>
            <UIFormError error={error}/>
        </div>
    )
)

UITextarea.displayName = 'UITextarea'
