import React from 'react'
import { useIntl } from 'react-intl'
import { Container, CustomerPortal } from '@customerPortal/components'
import { PartsListProvider, SubstituteProvider, SupersedeProvider } from '../context'
import { PartsSearchCms, PartsSearchLookup, PartsSearchResult } from '../components/PartsSearch'
import { ErrorMessage, NoResults } from '@ui/components'
import { ErrorBoundary } from '@app/components'
import { usePartsList } from '../hooks'

export const PartsSearch = () => {
    const { formatMessage } = useIntl()

    return (
        <CustomerPortal title={formatMessage({ id: 'parts.header.title', defaultMessage: 'Parts List & Documents' })}>
            <Container>
                <Container.WideContent>
                    <PartsListProvider>
                        <SupersedeProvider>
                            <SubstituteProvider>
                                <PartsSearchInner/>
                            </SubstituteProvider>
                        </SupersedeProvider>
                    </PartsListProvider>
                </Container.WideContent>
            </Container>
        </CustomerPortal>
    )
}

export const PartsSearchInner = () => {
    const { allParts, isNoResult } = usePartsList()

    return (
        <>
            <div>
                <ErrorBoundary fallback={<ErrorMessage/>}>
                    <PartsSearchCms/>
                </ErrorBoundary>
                <ErrorBoundary fallback={<ErrorMessage/>}>
                    <PartsSearchLookup/>
                </ErrorBoundary>
            </div>
            {!!allParts.length &&
                <ErrorBoundary fallback={<ErrorMessage/>}>
                    <PartsSearchResult/>
                </ErrorBoundary>
            }
            {isNoResult && <NoResults/>}
        </>
    )
}
