import { useContext } from 'react'
import { WingmanListContext } from '../../context'
import { getFormInputDate } from '@utils'

const MIN_ALLOWED_DATE = '2020-01-01'

export const useWingmanList = () => {
    const context = useContext(WingmanListContext)

    if (!context) {
        console.warn('useWingmanList must be used within the WingmanListProvider')
    }

    return {
        ...context,
        minAllowedDate: MIN_ALLOWED_DATE,
        maxAllowedDate: getFormInputDate()
    }
}
