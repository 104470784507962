import React, { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'

type CustomerPortalContainerProps = ComponentPropsWithoutRef<'div'>

export const Container = (
    { children, ...attributes }: CustomerPortalContainerProps
) => (
    <div className="ec-flex ec-flex-col-reverse xl:ec-flex-row ec-w-full" {...attributes}>
        {children}
    </div>
)

Container.WideContent = (
    { children, ...attributes }: CustomerPortalContainerProps
) => (
    <div className="ec-max-w-screen-2xl ec-w-full" {...attributes}>
        <div className="ec-flex ec-flex-col ec-gap-6 ec-w-full">
            {children}
        </div>
    </div>
)

Container.Content = (
    { children, ...attributes }: CustomerPortalContainerProps
) => (
    <div className="ec-items-stretch xl:ec-basis-[60rem] xl:ec-flex-shrink ec-w-full" {...attributes}>
        <div className="ec-flex ec-flex-col ec-gap-6 ec-w-full">
            {children}
        </div>
    </div>
)

Container.Side = (
    { children, ...attributes }: CustomerPortalContainerProps
) => (
    <div className={clsx(
        'ec-mb-6 ec-items-stretch',
        'xl:ec-mb-0 xl:ec-basis-[22rem] xl:ec-min-w-[18rem] xl:ec-flex-shrink xl:ec-ml-6'
    )} {...attributes}>
        {children}
    </div>
)
