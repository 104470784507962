import { AnalyticsTrackContext } from '../types'

export const getEventContext = (
    context: AnalyticsTrackContext
) => {
    const areas = context.areaRecords.map(record => record.area)

    const record = context.areaRecords.slice().reverse()
        .filter(record => !!record.listData)
        .find(record => record.listData) || null

    const listData = record?.listData || {
        list_id: '',
        list_name: ''
    }

    return {
        branchId: context?.currentBranch?.branch_id,
        branchName: context?.currentBranch?.branch_name,
        accountId: context?.account?.customer_id,
        accountNumber: context?.account?.customer_id,
        area: [...new Set(areas)],
        loggedIn: context.isLoggedIn,
        companyRole: context.customer?.company_role,
        ...listData
    }
}
