import React from 'react'

export const ProEssentialsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 97 20" className={'ec-select-none ec-h-5'}>
        <g id="ProEssentials" transform="matrix(0.0803091,0,0,0.0806452,-12.054,-14.4919)">
            <g transform="matrix(4.08725,0,0,3.24121,-1006.03,-252.795)">
                <path
                    d="M578.373,136.976C578.373,135.022 577.11,133.436 575.554,133.436L285.679,133.436C284.123,133.436 282.86,135.022 282.86,136.976L282.86,206.411C282.86,208.365 284.123,209.951 285.679,209.951L575.554,209.951C577.11,209.951 578.373,208.365 578.373,206.411L578.373,136.976Z"
                    style={{ fill: 'rgb(0,75,135)' }}/>
            </g>
            <g transform="matrix(3.83443,0,0,2.91709,-872.513,-197.146)">
                <path
                    d="M578.373,136.976C578.373,135.022 577.161,133.436 575.669,133.436L285.564,133.436C284.072,133.436 282.86,135.022 282.86,136.976L282.86,206.411C282.86,208.365 284.072,209.951 285.564,209.951L575.669,209.951C577.161,209.951 578.373,208.365 578.373,206.411L578.373,136.976Z"
                    style={{ fill: 'white' }}/>
            </g>
            <g transform="matrix(1,0,0,1,-374.729,155.539)">
                <text x="623.637px" y="204.324px"
                      style={{fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '158px', fill: 'rgb(203,0,21)'}}>
                    PRO
                </text>
            </g>
            <g transform="matrix(1,0,0,1,-46.7295,157.854)">
                <text x="623.637px" y="204.324px"
                      style={{fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '158px', fill: 'rgb(0,75,135)'}}>
                    Essentials
                </text>
            </g>
        </g>
    </svg>
)
