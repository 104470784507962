import React, {memo} from 'react'
import { useMenuState } from '../../../../state'
import { FormattedMessage } from 'react-intl'
import {cn, isPropsAreEqual} from "@utils";

type MenuButtonPropsType = {
    text: string
}

export const MenuButton =  memo(({text}: MenuButtonPropsType) => {
    const { setIsOpen, isOpen, menuStyle } = useMenuState()

    return (
        <div
            onClick={() => setIsOpen(!isOpen)}
            className={cn(isOpen ? menuStyle.buttonOpen : menuStyle.buttonClosed)}
        >
            <FormattedMessage
                id="main.category.text"
                defaultMessage="{text}"
                description="The category name from the menu"
                values={{text: text}}
            />
            <span>
                {isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className={cn(menuStyle.arrowUpDown)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className={cn(menuStyle.arrowUpDown)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                )}
            </span>
        </div>
    )
}, isPropsAreEqual);

MenuButton.whyDidYouRender = true;
