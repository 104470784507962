import React, { createContext, PropsWithChildren, useContext } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { localeConfig } from '../config/locale'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import intl from '../config/intl'
import * as yup from 'yup'
import { dummyFormatMessage } from '@intl/model'
import { IntlProps } from '@intl/types'

type YupType = typeof yup
type YupResolverType = typeof yupResolver

type ErrorValues = { [key: string]: any }

export type YupRawProviderState = {
    yup: YupType
    resolver: YupResolverType
    errorMessage: <T extends FieldErrors | yup.ValidationError>(
        errors: T,
        name?: T extends FieldErrors ? string : never
    ) => string | null
}

const YupRawProviderContext = createContext<YupRawProviderState | null>(null)

export const YupRawProvider = (
    { children, formatMessage = dummyFormatMessage }: PropsWithChildren<IntlProps>
) => {
    yup.setLocale(localeConfig)

    yup.addMethod(yup.number, 'nan', function () {
        return this.transform(value => isNaN(value) ? undefined : value)
    })

    yup.addMethod(yup.string, 'phone', function (msg?: string) {
        const message = msg || formatMessage(intl.stringPhoneNumber)

        return this.test('phone', message, value => (
            /^(\+1)?\d{10}$/.test(value.replace(/[^0-9\+]/g, ''))
        ))
    })

    yup.addMethod(yup.string, 'emailOrPhone', function (msg?: string) {
        const message = msg || formatMessage(intl.stringEmailOrPhoneNumber)

        return this.test('emailOrPhone', message, value => (
            yup.string().email().isValidSync(value) || yup.string().phone().isValidSync(value)
        ))
    })

    yup.addMethod(yup.number, 'range', function (moreThan: number, lessThan: number, msg?: string) {
        const message = msg || formatMessage(intl.numberRange, { moreThan, lessThan })

        return this.test('range', message, value => (
            value > moreThan && value < lessThan
        ))
    })

    yup.addMethod(yup.number, 'rangeEqual', function (min: number, max: number, msg?: string) {
        const message = msg || formatMessage(intl.numberRangeEqual, { min, max })

        return this.test('rangeEqual', message, value => (
            value >= min && value <= max
        ))
    })

    yup.addMethod(yup.string, 'rangeLength', function (min: number, max: number, msg?: string) {
        const message = msg || formatMessage(intl.stringRangeLength, { min, max })

        return this.test('rangeLength', message, value => {
            return !value || value.length >= min && value.length <= max
        })
    })

    yup.addMethod(yup.array, 'unique', function(message, mapper = a => a) {
        return this.test('unique', message, function(list) {
            return list.length  === new Set(list.map(mapper)).size;
        });
    });

    const errorMessage = <T extends FieldErrors | yup.ValidationError>(
        errors: T,
        name?: T extends FieldErrors ? string : never
    ): string | null => {
        if (errors instanceof yup.ValidationError) {
            return translateErrorMessage(errors.message)
        }

        let error = errors && name.split('.').reduce((o, i) => o && o[i], errors)
        if (!error) return null

        if (Array.isArray(error)) {
            error = Object.values(error)[0]
        }

        let errorKey: string = error?.message
        let errorValues: ErrorValues = {}

        if (typeof error.message === 'object') {
            errorKey = error?.message?.key
            errorValues = error?.message?.values
        }

        return translateErrorMessage(errorKey, errorValues)
    }

    const translateErrorMessage = (
        errorKey: string,
        values = {} as ErrorValues
    ) => {
        const intlKey = intl[errorKey]
        return intlKey ? formatMessage(intlKey, values) : errorKey
    }

    return (
        <YupRawProviderContext.Provider value={{
            yup: yup as YupType,
            resolver: yupResolver,
            errorMessage
        }}>
            {children}
        </YupRawProviderContext.Provider>
    )
}

export const useYup = () => {
    const context = useContext(YupRawProviderContext)

    if (!context) {
        console.warn(`useYup must be used within the YupProvider or YupRawProvider`)
    }
    return context
}
