import React from 'react'
import { Container, CustomerPortal } from '@customerPortal/components'
import { WingmanListProvider } from '../context'
import { Filters, Table } from '../components/List'
import { useIntl } from 'react-intl'
import { UIPanel } from '@ui/components'
import intl from './intl'

export const List = () => {
    const { formatMessage } = useIntl()

    return (
        <WingmanListProvider>
            <CustomerPortal title={formatMessage(intl.title)}>
                <Container>
                    <Container.WideContent>
                        <UIPanel>
                            <UIPanel.Body>
                                <Filters/>
                            </UIPanel.Body>
                        </UIPanel>
                        <UIPanel>
                            <UIPanel.Body>
                                <Table/>
                            </UIPanel.Body>
                        </UIPanel>
                    </Container.WideContent>
                </Container>
            </CustomerPortal>
        </WingmanListProvider>
    )
}
