import React from 'react'
import { Error, ErrorProps } from '../../Error/Error'
import clsx from 'clsx'

type SummaryErrorProps = ErrorProps & {
    finder?: boolean
}

export const SummaryError = (
    { finder, product, error }: SummaryErrorProps
) => (
    <div className={clsx(
        'ec-text-s ec-flex ec-items-center ec-line-clamp-2 ec-whitespace-normal',
        {
            'ec-h-18': finder,
            'ec-h-10': !finder
        }
    )}>
        <Error error={error} product={product}/>
    </div>
)
