import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { UIButton, UIButtonProps } from '@ui/components'
import { useCustomer } from '@customer/hooks'
import { AddToCartResult } from '../../api'
import { useAddToCart } from '../../hooks'
import { Product } from '../../types'
import intl from './intl'

export type AddToCartButtonProps = UIButtonProps & {
    product: Product
    qty?: number
    onAddToCart?: (
        result: AddToCartResult[],
        error: unknown,
        items: Product[][]
    ) => void,
}

export const AddToCartButton = (
    { qty = 1, product, onAddToCart, ...attributes }: AddToCartButtonProps
) => {
    const { addToCart } = useAddToCart()
    const { isLoggedIn } = useCustomer()
    const { formatMessage } = useIntl()

    const handleAddToCart = async () => {
        if (!isLoggedIn || addToCart.isLoading) return

        addToCart.mutate({ products: { ...product, qty } }, {
            onSettled: (data, error, _, context) => {
                onAddToCart && onAddToCart(data, error, context.chunks)
            }
        })
    }

    return (
        <UIButton onClick={handleAddToCart}
                  loading={addToCart.isLoading ? <FormattedMessage {...intl.adding}/> : false}
                  disabled={!isLoggedIn || attributes.disabled}
                  fullWidth
                  {...attributes}>
            {formatMessage(intl.addToCart)}
        </UIButton>
    )
}
