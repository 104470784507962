import { SupersedePrice } from '@supersede/types'
import { Product } from '@catalog/types'

export type Price = {
    pim_id: Product['pim_id']
    can_add: Boolean
    prices: {
        currency: string
        price: number
        list_price?: number
        trade_price?: number
    } | null
    error?: PriceError | null
} & SupersedePrice

export type PriceError = {
    code: PriceErrorCode
    description?: string
}

export enum PriceErrorCode {
    Message = 'message',
    NoProduct = 'no_product',
    Assistance = 'assistance',
    Availability = 'availability',
    Supersede = 'supersede',
    StandardUser = 'standard_user'
}
