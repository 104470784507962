import React, { ComponentPropsWithoutRef, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { UIOmit } from '../../../types'
import { UIButtonProps } from './UIButton'

export type UIButtonLinkProps = UIOmit<ComponentPropsWithoutRef<'a'>> & {
    size?: 'small' | 'small-square' | 'medium' | 'medium-square' | 'large' | 'large-square'
    variant?: 'contained' | 'outlined' | 'text'
    fullWidth?: boolean
    disabled?: boolean
    app?: boolean
}

export type UIButtonLinkSize<T = string> = Record<UIButtonProps['size'], T>
export type UIButtonLinkVariant<T = string> = Record<UIButtonProps['variant'], T>

const activeVariants: UIButtonLinkVariant = {
    contained: clsx(
        'ec-bg-blue-dark ec-border ec-border-blue-dark ec-text-white',
        'hover:ec-bg-blue hover:ec-text-white hover:ec-border-blue'
    ),
    outlined: clsx(
        'ec-bg-white ec-text-blue-dark ec-border ec-border-blue-dark ec-border-solid',
        'hover:ec-bg-blue hover:ec-text-white'
    ),
    text: clsx(
        'ec-bg-transparent ec-border ec-border-transparent ec-text-blue-light',
        'hover:ec-bg-blue-lighter hover:ec-text-blue-light hover:ec-border-blue-lighter'
    )
}

const disabledVariants: UIButtonLinkVariant = {
    contained: clsx(
        'ec-bg-gray-lightest ec-border ec-border-gray-lightest !ec-cursor-not-allowed ec-text-gray-light',
        'hover:ec-text-gray-light'
    ),
    outlined: clsx(
        'ec-bg-gray-lightest ec-border ec-border-gray-lightest !ec-cursor-not-allowed ec-text-gray-light',
        'hover:ec-text-gray-light'
    ),
    text: clsx(
        'ec-bg-transparent ec-border ec-border-transparent !ec-cursor-not-allowed ec-text-gray-light',
        'hover:ec-text-gray-light'
    )
}

const sizeVariants: UIButtonLinkSize = {
    small: 'ec-text-xs ec-leading-6 ec-min-h-6 ec-py-[1px] ec-px-2',
    medium: 'ec-text-sm ec-leading-8 ec-min-h-8 ec-py-[1px] ec-px-4',
    large: 'ec-text-sm ec-leading-10 ec-min-h-10 ec-px-4',
    'small-square': 'ec-text-xs ec-leading-6 ec-min-h-6 ec-py-[1px] ec-px-1 ec-m-[1px] ec-aspect-square',
    'medium-square': 'ec-text-sm ec-leading-8 ec-min-h-8 ec-py-[1px] ec-px-1 ec-m-[1px] ec-aspect-square',
    'large-square': 'ec-text-sm ec-leading-10 ec-min-h-10 ec-py-[1px] ec-px-1 ec-m-[1px] ec-aspect-square'
}

export const UIButtonLink = (
    {
        size = 'medium',
        variant = 'contained',
        fullWidth = false,
        disabled = false,
        app = true,
        children,
        ...attributes
    }: UIButtonLinkProps
) => {
    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        disabled && event.preventDefault()
    }

    if (app) {
        return (
            <Link to={attributes.href} role="button" className={clsx(
                'ec-uppercase ec-inline-flex ec-justify-center ec-items-center ec-tracking-wider ec-transition-all',
                'ec-duration-300 hover:ec-no-underline',
                !disabled && activeVariants[variant],
                disabled && disabledVariants[variant],
                fullWidth && 'ec-w-full'
            )} onClick={handleClick}>
                <span className={clsx('ec-truncate', sizeVariants[size])}>
                    {children}
                </span>
            </Link>
        )
    }

    return (
        <a className={clsx(
            'ec-uppercase ec-inline-flex ec-justify-center ec-items-center ec-tracking-wider ec-transition-all',
            'ec-duration-300 hover:ec-no-underline',
            !disabled && activeVariants[variant],
            disabled && disabledVariants[variant],
            fullWidth && 'ec-w-full'
        )} role="button"
           onClick={handleClick}
           {...attributes}>
            <span className={clsx('ec-truncate', sizeVariants[size])}>
                {children}
            </span>
        </a>
    )
}
