export default {
    branchInventoryItem: {
        id: 'branch.inventory.item',
        defaultMessage: '{qty} available at {branchName}'
    },
    branchInventoryListLabel: {
        id: 'branch.inventory.list.label',
        defaultMessage: 'Inventory At Nearby Branches'
    },
    branchInventoryListAll: {
        id: 'branch.inventory.list.all',
        defaultMessage: 'Inventory At All Branches'
    }
}
