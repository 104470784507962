import { GraphQlErrorResult, request } from '@model/api'
import { branchFragment, switchPreferredBranchQuery } from './query'
import { Branch } from '../types'

export type SwitchPreferredBranchApiParams = {
    branchId: number
}

type SwitchPreferredBranchApiResponse = {
    switchPreferredBranch: Branch | null
}

export type SwitchPreferredBranchResult = GraphQlErrorResult & {
    preferredBranch: Branch | null
}

export const switchPreferredBranchApi = async (
    { branchId }: SwitchPreferredBranchApiParams
): Promise<SwitchPreferredBranchResult> => (
    request<SwitchPreferredBranchApiResponse>([switchPreferredBranchQuery, branchFragment], { branchId })
        .then(data => ({
            preferredBranch: data?.data?.data?.switchPreferredBranch[0] ?? null,
            errors: data.data?.errors || null
        }))
)
