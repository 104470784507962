import { GraphQlError, request } from '@model/api'
import { cartFragment } from '@checkout/api'
import { Cart, CartError } from '@checkout/types'
import { addToCartQuery, productFragment } from './queries'
import { getProductSku } from '../utils'
import { Product } from '../types'

export type AddToCartApiProps = {
    products: Product[]
}

type AddToCartApiResponse = {
    addProductsToCart: {
        cart: Cart
        user_errors: CartError[]
    }
}

export type AddToCartResult = {
    cart: Cart | null
    userErrors: CartError[] | null
    errors: GraphQlError[] | null
}

export const addToCartApi = async (
    { products }: AddToCartApiProps
): Promise<AddToCartResult> => {
    const cartItems: { sku: string, quantity: number }[] = products.map(product => ({
        sku: getProductSku(product),
        quantity: product.qty || 1
    }))

    return request<AddToCartApiResponse>(
        [addToCartQuery, cartFragment, productFragment],
        { cartItems }
    ).then(data => ({
        cart: data.data.data?.addProductsToCart?.cart || null,
        userErrors: data.data.data?.addProductsToCart?.user_errors || null,
        errors: data.data?.errors || null
    }))
}
