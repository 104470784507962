import React, {memo, useMemo} from 'react'
import {cn, isPropsAreEqual} from '@utils'
import {MapPinIcon} from '../../../icons'
import {useInventoryFinderState} from "../../../../InventoryFinder";
import {useWindowSize} from "../../../../Various";
import {useIntl} from "react-intl";
import {useBranch} from "../../../../Branch";
import {useCustomerData} from "../../../../Customer";

export const CurrentBranch =  memo(() => {

    const {isEnabled,} = useInventoryFinderState()
    const {customerBranches, isLoggedIn} = useCustomerData();
    const { branches } = useBranch()

    const { isMobile } = useWindowSize();
    const intl = useIntl()

    const loading = intl.formatMessage({id: 'branch.header.top.mobile.loading', defaultMessage: 'Loading...'})
    const branchLabel = intl.formatMessage({ id: 'branch.header.top', defaultMessage: 'Your Current Branch' })
    const branchLabelMobile = intl.formatMessage({ id: 'branch.header.top.mobile', defaultMessage: 'Branch' })

    const currentBranchName = useMemo(() => {
        const branchId = isLoggedIn ? customerBranches?.current_branch_id : customerBranches?.default_branch_id;

        //need to check here why one time we get string and one time int for customerBranches?.current_branch_id
        const currentBranch = branches.find(branch => branch?.branch_id == branchId);
        return currentBranch ? `${currentBranch?.branch_name} #${currentBranch?.branch_id}` : '';
    }, [customerBranches, branches]);

    return (<div className={cn(
            'ec-overflow-visible ecmd:ec-w-36 ec-w-16 ec-flex-col ec-relative ec-h-full lg:ec-h-10',
            {'ec-cursor-pointer': isEnabled}
        )}>
            <div className={cn('ec-flex ec-items-center ec-justify-around', {'ec-flex-wrap': isMobile})}>
                <MapPinIcon/>
                <div className="lg:ec-text-left lg:ec-w-full">
                    <div className={cn('ec-text-white ec-text-xs')}>{isMobile ? branchLabelMobile : branchLabel}</div>
                    {!isMobile && <div className={cn('ec-text-white ec-text-s ec-truncate')}>
                        {currentBranchName || loading}
                    </div>}
                </div>
            </div>
        </div>
    )
}, isPropsAreEqual);

CurrentBranch.whyDidYouRender = true
