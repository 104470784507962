import { GraphQlErrorResult, request } from '@model/api'
import { getBranchesInventoryQuery } from './query'
import { BranchesInventory } from '../types'

export type BranchesInventoryApiParams = {
    products: string[]
    sort: {
        field: string
        order: string
    }
}

export type BranchesInventoryApiResponse = {
    branchesInventory: BranchesInventory[]
}

export type BranchesInventoryResult = GraphQlErrorResult & {
    inventory: BranchesInventory[] | null
}

export const getBranchesInventoryApi = async (
    params: BranchesInventoryApiParams
): Promise<BranchesInventoryResult> => (
    request<BranchesInventoryApiResponse>(getBranchesInventoryQuery, params).then(data => ({
        inventory: data.data?.data?.branchesInventory || null,
        errors: data.data?.errors
    }))
)
