import React from 'react'
import { UIPanel } from '@ui/components'
import { CmsBlockProvider } from '@cms/context'
import { CmsBlock } from '@cms/components'

export const PartsSearchCms = () => {

    return (
        <CmsBlockProvider configIdentifiers="parts_search_cms">
            <CmsBlock>
                {Content =>
                    <div className="ec-mb-6">
                        <UIPanel>
                            <UIPanel.Body>
                                <div className="ec-max-w-[800px]">
                                    <Content/>
                                </div>
                            </UIPanel.Body>
                        </UIPanel>
                    </div>
                }
            </CmsBlock>
        </CmsBlockProvider>
    )
}
