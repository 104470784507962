import React from 'react'
import { FormattedNumber } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import { usePricing } from '../../hooks'
import { SignIn } from './Pricing/SignIn'
import { PriceErrorCode } from '../../types'
import { Error } from './Pricing/Error'
import { useCustomer } from '@customer/hooks'
import { Product } from '@catalog/types'

type PricingProps = {
    product: Product
}

export const Pricing = (
    { product }: PricingProps
) => {
    const { query, pricing } = usePricing(product)
    const { isLoggedIn } = useCustomer()

    const isLoading = query.isFetching
    const isError = query.isError || !pricing || pricing?.error

    const { error: priceError } = pricing || {}

    const { price = 0, trade_price = 0, list_price = 0, currency } = pricing?.prices || {}
    const highPrice = Math.max(trade_price, list_price)

    return (
        <div className="ec-w-full ec-min-w-[4rem] ec-text-base">
            {!isLoggedIn &&
                <SignIn/>
            }
            {isLoggedIn && isLoading &&
                <Skeleton className="ec-w-full ec-min-w-[60px] ec-max-w-[160px]"/>
            }
            {isLoggedIn && !isLoading && isError &&
                <Error error={priceError}/>
            }
            {isLoggedIn && !isLoading && !isError && <>
                <span className="ec-font-medium ec-whitespace-nowrap">
                    <FormattedNumber style="currency" value={price} currency={currency}/>
                </span>
                {highPrice > price &&
                    <span className="ec-text-sm ec-line-through ec-ml-2">
                        <FormattedNumber style="currency" value={highPrice} currency={currency}/>
                    </span>
                }
            </>}
        </div>
    )
}
