import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { FormattedMessage } from 'react-intl'
import { InventoryFinder } from '@inventoryFinder/components/InventoryFinder/InventoryFinder'
import { Product } from '@catalog/types'
import intl from '../intl'

type FinderProps = {
    product?: Product
    loading?: boolean
}

export const Finder = (
    { product, loading = false }: FinderProps
) => (
    <div className="ec-flex ec-justify-between ec-items-start ec-mb-3">
        {loading &&
            <Skeleton containerClassName="ec-flex-1"/>
        }
        {!loading && <>
            <span className="ex-text-s ec-font-medium ec-m-0">
                <FormattedMessage {...intl.label}/>
            </span>
            <InventoryFinder product={product}>
                <span className="ec-text-s ec-text-blue-light">
                    <FormattedMessage {...intl.viewMore}/>
                </span>
            </InventoryFinder>
        </>}
    </div>
)
