import { GraphQlErrorResult, request } from '@model/api'
import { getStoreConfigQuery } from './query'
import { StoreConfig } from '../types'

type StoreConfigApiResponse = {
    storeConfig: StoreConfig
}

export type StoreConfigResult = GraphQlErrorResult & {
    config: StoreConfig
}

export const getStoreConfigApi = async (): Promise<StoreConfigResult> => (
    request<StoreConfigApiResponse>(getStoreConfigQuery).then(data => ({
        config: data.data?.data.storeConfig,
        errors: data.data?.errors || null
    }))
)
