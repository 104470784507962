import React from "react";
import {StorageProvider} from "../../../../Storage";
import {BranchProvider} from "../../../context";
import {MapProvider} from "../../../../Map";
import {BranchesInventoryProvider} from "../../../../Inventory/context";
import {GoogleMapsProvider, InventoryFinderProvider} from "../../../../InventoryFinder/state";
import {IntlProvider} from "react-intl";
import {Branches} from "./Branches";

export const BranchesApp = () => {
    return (
        <div>
            <IntlProvider locale={'en'}>
            <StorageProvider>
                <BranchProvider>
                    <MapProvider>
                        <BranchesInventoryProvider>
                            <InventoryFinderProvider>
                                <GoogleMapsProvider>
                                    <Branches/>
                                </GoogleMapsProvider>
                            </InventoryFinderProvider>
                        </BranchesInventoryProvider>
                    </MapProvider>
                </BranchProvider>
            </StorageProvider>
            </IntlProvider>
        </div>
    )
}
