import { useEffect, useState } from 'react'

const KEY = 'ecmd'

export const useSessionStorage = <T = any>(key?: string) => {

    const getAllStorageData = (): Record<string, any> => {
        const jsonData = sessionStorage.getItem(KEY)
        return !!jsonData ? JSON.parse(jsonData) : {}
    }

    const setStorageData = (data: T) => {
        const allData = {
            ...getAllStorageData(),
            [key]: data
        }
        sessionStorage.setItem(KEY, JSON.stringify(allData))
    }

    const [data, setData] = useState<T>(key ? getAllStorageData()[key] : undefined)

    useEffect(() => {
        data !== undefined && !!key && setStorageData(data)
    }, [JSON.stringify(data)])

    const removeAll = () => {
        setData(undefined)
        setStorageData({} as T)
    }

    return {
        data,
        setData,
        removeAll
    }
}
