import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../../types'

export const UITableHead = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'thead'>>
) => (
    <thead className="ec-hidden lg:ec-table-header-group lg:ec-sticky lg:ec-top-[var(--sticky-height)] lg:ec-z-10 " {...attributes}>
        {children}
    </thead>
)
