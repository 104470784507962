import React from "react";
import Skeleton from "react-loading-skeleton";

export const MapFindSkeleton = () => {
    return (
        <div className={'ec-p-4'}>
            <div className="ec-mb-4">
                <Skeleton height={40}/>
                <Skeleton height={20}/>
                <div className={'ec-mt-8'}>
                    <Skeleton height={'75vh'}/>
                </div>
            </div>
        </div>
    )
}