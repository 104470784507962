import React from "react";
import { useIntl } from 'react-intl';
import { useInventoryFinderState } from "../../state";
import { BranchStructure } from "./BranchStructure";
import {BranchSkeleton } from './Skeleton/BranchSkeleton'

export const Preferred = () => {
    const { preferredBranch, currentBranch, isBranchesLoading, isLoadingData } = useInventoryFinderState();
    const intl = useIntl();

    const preferredBranchTitle = intl.formatMessage({
        id: 'preferred.branch.title',
        defaultMessage: 'Your Preferred Branch'
    });

    if (!preferredBranch) return null;

    const isSameBranch = preferredBranch?.branch_id === currentBranch?.branch_id;

    if(isBranchesLoading || isLoadingData){
        return (<BranchSkeleton/>)
    }

    if(isSameBranch) return null;

    return (
        <div>
            <h2>{preferredBranchTitle}</h2>
            <BranchStructure data={[preferredBranch]}/>
        </div>
    )
}
