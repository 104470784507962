export default {
    columns: {
        partNumber: {
            id: 'parts.columns.partNumber',
            defaultMessage: 'Product'
        },
        manufacturer: {
            id: 'parts.columns.manufacturer',
            defaultMessage: 'Brand'
        },
        name: {
            id: 'parts.columns.name',
            defaultMessage: 'Name'
        },
        inventory: {
            id: 'parts.columns.inventory',
            defaultMessage: 'Availability'
        },
        pricing: {
            id: 'parts.columns.pricing',
            defaultMessage: 'Price'
        }
    },
    models: {
        id: 'parts.models.label',
        defaultMessage: 'Models'
    },
    search: {
        id: 'parts.search.label',
        defaultMessage: 'Filter by Item#, MFG#, Name'
    },
    supersede: {
        id: 'parts.supersede',
        defaultMessage: 'Supersede'
    },
    multipleSupersede: {
        id: 'parts.supersede.multiple',
        defaultMessage: 'Multiple Supersedes Found'
    },
    substitute: {
        id: 'parts.substitute',
        defaultMessage: 'Substitute'
    },
    multipleSubstitute: {
        id: 'parts.substitute.multiple',
        defaultMessage: 'Multiple Substitute Found'
    },
    noResults: {
        id: 'parts.table.noResults',
        defaultMessage: 'No parts found'
    }
}
