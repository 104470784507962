import { useContext } from 'react'
import { PartsListContext } from '../../context'

export const usePartsList = () => {
    const context = useContext(PartsListContext)

    if (!context) {
        console.warn('usePartsList must be used within the PartsListProvider')
    }

    const getProducts = () => {
        try {
            return context.allParts
                .filter(part => part.product)
                .map(part => part.product)
        } catch (error) {
            console.error(error)
            return []
        }
    }

    return {
        ...context,
        getProducts
    }
}
