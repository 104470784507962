import { Product } from '../types'
import { urlBuilder } from '@utils'
import { PRODUCT_PERMALINK } from '../config'

export const getProductUrl = (product: Product): URL | null => {
    if (product.url_key) {
        return urlBuilder()
            .setPath(product.url_key)
            .getUrl()
    }
    if (product.pim_id) {
        return urlBuilder()
            .setPath(PRODUCT_PERMALINK, { 'id': product.pim_id })
            .getUrl()
    }
    return null
}
