import EventEmitter from 'eventemitter3';

const originalEmit = EventEmitter.prototype.emit;

class DebugEventEmitter extends EventEmitter {
    emit(event: string | symbol, ...args: any[]) {
        // use to debug events on the fly
        // console.debug(`Event fired: ${String(event)}`, args);
        return originalEmit.apply(this, [event, ...args]);
    }
}

const eventBus = window.eventBus || new DebugEventEmitter();

if (!window.eventBus) {
    window.eventBus = eventBus;
}

export default eventBus;
