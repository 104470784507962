export const theme = {
    colors: {
        brand: `#2563EB`,
            primaryLight: `rgb(238 242 255)`,
            primary: `#3B82F6`,
            primaryDark: `#1D4ED8`,
            secondaryLight: `#fff`,
            secondary: `rgb(228 228 228)`,
            secondaryDark: `rgb(192 195 201)`,
            textPrimary: `#000`,
            textSecondary: `rgba(0, 0, 0, 0.68)`,

            utility: {
            dangerColor: `#D32F2F`,
                infoColor: `#8E24AA`,
                successColor: `#059669`,
                warningColor: `#F59E0B`,
        },
    },

    classes: {
        AddToCart: {
            useStyle: true,
                container: `flex flex-row-reverse w-full gap-2 text-sm`,
                container1: `w-2/3`,
                container2: `w-1/3`,
                button: `w-full px-2 py-1 font-bold text-white rounded disabled:opacity-50 disabled:cursor-not-allowed bg-violet-700`,
                input: `w-12 h-full m-auto text-center bg-transparent border border-solid focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed`,
        },
        Dropdown: {
            useStyle: true,
                container: `origin-top-right absolute right-0 mt-2 w-24 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ease-in duration-100`,
                container1: `relative inline-block`,
                button: `inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`,
        },
        Inventory: {
            useStyle: true,
                container: `status`,
                loading: `individual-loading`,
                available: `stock available`,
                unavailable: `stock unavailable`,
        },
        Pricing: {
            useStyle: true,
                container: `price-box price-final_price`,
                container1: `flex flex-row gap-2 font-bold`,
                messageContainer: ``,
                regularPrice: `price font-semibold`,
                oldPrice: `line-through old-price`,
                loading: `individual-loading`,
        },
        ProductCollectionStyle: {
            gridStyle: `gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 3xl:grid-cols-7`,
        },
        ProductListStyle: {
            gridStyle: ``,
                listStyle: `md:flex md:flex-row items-center justify-around p-2 border-b border-gray-200 products-list`,
        },
        FacetList: {
            useStyle: true,
                usePlusMinus: true,
                filterOptions: `filter-options`,
                collapsibleFilterOptions: `collapsible filter-options`,
                collapsibleContent: `collapsible_content pl-3 text-sm`,
                collapsibleContentCategories: `collapsible_content category-collapsible pl-3 text-sm`,
                collapsibleTitle: `flex items-center space-x-1 py-2 collapsible_title`,
                collapsibleTitleOpened: `collapsible_title collapsible_title--opened flex items-center space-x-1 py-2 pl-0.5`,
                seeMoreLess: `underline text-blue-600 hover:text-blue-800 collapsible_content__see-more-less`,
                count: `count`,
                items: `items`,
                item: `py-0.5 mb-0.5 item flex`,
                filterNameStyle: `font-bold text-sm`,
                longNameLength: 20,
        },
        AppliedFilters: {
            useStyle: true,
                useIcon: true,
                clearAll: `text-blue-600 cursor-pointer hover:underline text-sm`,
                filterCurrent: `filter-current mb-8`,
                items: `mb-1 text-sm items`,
                item: `mb-1 item`,
                actionRemove: `cursor-pointer action remove`,
                appliedFiltersTitle: `mb-2 block-subtitle filter-subtitle`,
        },

        Carousel: {
            useStyle: true,
                arrowColorHover: `#727271`,
                arrowColor: `#babab9`,
        },
        CategoryFilterTree: {
            useStyle: true,
                useFontMediumDepth: 0,
                catTabClass: `flex`,
                useCollapsibleCategories: false,
                collapsibleCategoriesClass: `filter-group`,
        },
        AutoSuggest: {
            container: `lg:flex max-h-96 overflow-auto w-full bg-white absolute z-10`,
                dropdownClass: `py-3 px-4 border border-gray-200`,
                suggestionButton: `inline-block py-1.5 w-full text-left bg-transparent bg-none border-none p-0 m-0`,
                productSuggestionsContainer: `w-full lg:w-3/4`,
                productListCardContainer: `items-center p-2 border-b border-gray-200 md:flex md:flex-row justifiy-around`,
                productContainer: `md:w-9/12 md:mr-2`,
                productLabel: `w-10/12 font-bold truncate`,
                imgContainer: `w-2/12 mr-1`,
                suggestionLine: `search-suggestion`,
        },
        SearchBar: {
            submitButton: `float-right bg-transparent bg-none border-none p-0 m-0`,
                searchInput: `flex-grow focus:outline-none p-1.5 ec-bg-transparent`,
        },
        Pagination: {
            container: `flex items-center justify-between`,
                container1: `flex items-center justify-center flex-1 md:justify-between`,
                paginationNav: `relative z-0 inline-flex -space-x-px`,
        },
    },

    typography: {
        heading: {
            fontSize: `30px`,
                lineHeight: `1.5`,
        },
        subHeading: {
            fontSize: `18px`,
                lineHeight: `1.5`,
                color: `rgba(0,0,0,.54)`,
        },
    },
    urlParams: {
        useUrlFilters: true,
            useBranch: false,
            usePage: true,
            usePageSize: true,
            useViewMode: true,
            useQ: true,
    },
}
