import { RowData } from '@tanstack/table-core'
import { getCoreRowModel, getPaginationRowModel, PaginationState, Table, useReactTable } from '@tanstack/react-table'
import { TableOptions } from '@tanstack/table-core/src/types'
import { useTableUrl } from '../useTableUrl/useTableUrl'

export type UseUITableProps<TData extends RowData> = Omit<TableOptions<TData>, 'getCoreRowModel'> & {
    dataPagination?: boolean
    dataSize?: number
    pageSizes?: number[]
    defaultPageSize?: number
}

export type UITableData<TData extends RowData> = Table<TData> & {
    pages: number[]
    pageSizes: number[]
    defaultPageSize: number
    dataSize: number,
    hasPagination: boolean
}

export const useUITable = <TData extends RowData>(
    {
        dataPagination = false,
        pageSizes = [10, 20, 50],
        defaultPageSize = 20,
        dataSize,
        ...tableOptions
    }: UseUITableProps<TData>
): UITableData<TData> => {
    const { pageIndex, pageSize, setPageSize: setUrlPageSize } = useTableUrl({ defaultPageSize })
    if (dataSize === undefined) dataSize = tableOptions.data.length

    const pagination: PaginationState = {
        pageSize: pageSize,
        pageIndex: pageIndex
    }

    const options: TableOptions<TData> = {
        getCoreRowModel: getCoreRowModel(),
        ...tableOptions,
        state: { pagination, ...tableOptions.state }
    }

    // Pagination
    if (dataPagination && !tableOptions.manualPagination) {
        options.getPaginationRowModel = getPaginationRowModel()
        options.onPaginationChange = updater => {
            // @ts-ignore
            const { pageSize } = updater(pagination) as PaginationState
            setUrlPageSize(pageSize)
        }
    }

    const table = useReactTable(options)
    const pageCount = table.getPageCount()

    let startIndex = Math.max(pageIndex - 3, 0)
    if (startIndex !== 0 && (pageIndex + 3) >= pageCount) {
        startIndex = pageCount - 7
    }

    const pages = Array.from({ length: Math.min(pageCount, 7) }, (_, i) => i + startIndex)
    const hasPagination = dataPagination || tableOptions.manualPagination

    return {
        ...table,
        pages,
        dataSize,
        pageSizes,
        defaultPageSize,
        hasPagination
    }
}
