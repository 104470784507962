import React from 'react'
import {useIntl} from "react-intl";

export const ErrorSearch = () => {

    const intl = useIntl();

    return (
        <div className="ec-bg-gray-light ec-flex-grow ec-relative ec-my-1.5 ec-relative ec-p-0.5 ec-ml-8">
            <form className="relative">
                <div className="flex">
                    <input id="search-input"
                           data-testid="searchInput"
                           className="flex-grow focus:outline-none p-1.5"
                           placeholder={intl.formatMessage({
                               id: 'search.error',
                               defaultMessage: 'Search Issues'
                           })} autoComplete="off" type="search"
                           value="" disabled={true}/>
                </div>
            </form>
        </div>
    )
}
