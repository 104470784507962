import React, {memo, useEffect, useState} from 'react'
import { UIChevronDownIcon, UIChevronUpIcon } from '@ui/icons'
import { BranchMarker } from '../BranchMarker'
import { useGoogleMapsState, useInventoryFinderState } from '../../../../state'
import { Map } from '@map/components'
import { Branch } from '@branch/types'
import {isPropsAreEqual} from "../../../../../../utils";

export const BranchMapMobile = memo(() => {
    const [isOpen, setIsOpen] = useState(false)

    const {
        onMapIdle,
        mapContainerRef,
        mapContainerStyle,
        setMapContainerStyle
    } = useGoogleMapsState()

    const {
        branches,
        handleMapLocationClick
    } = useInventoryFinderState()

    useEffect(() => {
        setMapContainerStyle({
            width: '100%',
            height: '150px',
            backgroundColor: 'grey'
        })
    }, [branches])

    useEffect(() => {
        if (isOpen) {
            setMapContainerStyle({
                width: '100%',
                height: '400px',
                backgroundColor: 'grey'
            })
        } else {
            setMapContainerStyle({
                width: '100%',
                height: '150px',
                backgroundColor: 'grey'
            })
        }
    }, [isOpen, branches])

    const handleMarkerClick = (branch: Branch) => () => handleMapLocationClick(branch)

    return (
        <div className={'ec-mb-4'}>
            <div ref={mapContainerRef} style={mapContainerStyle}>
                <Map
                     defaultZoom={10}
                     onIdle={onMapIdle}
                     markers={
                         branches.map(branch => ({
                             id: branch.branch_id,
                             title: branch.branch_name,
                             position: { lat: branch.latitude, lng: branch.longitude },
                             onMarkerClick: handleMarkerClick(branch),
                             infoWindow: <BranchMarker branch={branch}/>
                         }))
                     }/>
            </div>
            <div className={'ec-bg-blue-light ec-h-6 ec-z-[10000] ec-relative ec-flex ec-items-center ec-justify-around ec-text-white'}
                onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <UIChevronUpIcon/> : <UIChevronDownIcon/>}
            </div>
        </div>
    )
}, isPropsAreEqual);
