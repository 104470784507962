import React, { createContext, useContext, PropsWithChildren, useState } from 'react'
import { PricingProvider } from '@pricing/context'
import { BranchesInventoryProvider, InventoryProvider } from '@inventory/context'
import { InventoryFinderProvider } from '@inventoryFinder/state'
import { MapProvider } from '@map/context'
import { Product } from '../types'

type CatalogListProviderProps = {
    products: Product[]
}

export type CatalogListProviderState = {
    products: Product[]
    setProducts: UseStateSet<Product[]>
}

const CatalogListContext = createContext<CatalogListProviderState>(null)

export const CatalogListProvider = (
    { products: initProducts = [], children }: PropsWithChildren<CatalogListProviderProps>
) => {
    const [products, setProducts] = useState(initProducts)
    const productsWithPim = products.filter(product => product?.pim_id)

    return (
        <CatalogListContext.Provider value={{
            products,
            setProducts
        }}>
            <PricingProvider products={productsWithPim}>
                <InventoryProvider products={productsWithPim}>
                    <BranchesInventoryProvider>
                        <MapProvider>
                            <InventoryFinderProvider>
                                {children}
                            </InventoryFinderProvider>
                        </MapProvider>
                    </BranchesInventoryProvider>
                </InventoryProvider>
            </PricingProvider>
        </CatalogListContext.Provider>
    )
}

export const useCatalogList = () => {
    const context = useContext(CatalogListContext)

    if (!context) {
        console.warn('useCatalogList must be used within the CatalogListProvider')
    }
    return context
}
