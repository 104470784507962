import React, {
    KeyboardEvent,
    ClipboardEvent,
    useState,
    ChangeEvent,
    FocusEvent,
    useEffect,
    Ref,
    ComponentPropsWithoutRef
} from 'react'
import { UIOmit } from '../../../types'
import clsx from 'clsx'
import { UIChipItem, useUIChip, UseUIChipProps } from '../../../hooks'
import { UIInputLabel, UIInputLabelProps } from '../UIInputLabel/UIInputLabel'
import { UIFormError } from '../UIFormError/UIFormError'
import { UIChip } from '../../Elements'

export type UIInputChipProps = UIOmit<ComponentPropsWithoutRef<'div'>, 'onChange' | 'placeholder'> & {
    items?: (string | UIChipItem)[]
    label?: UIInputLabelProps['label'],
    error?: string
    disabled?: boolean
    required?: boolean
    placeholder?: string
    submitKeys?: string[]
    validate?: UseUIChipProps['validate']
    onChange?: (data: UIChipItem[]) => void
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void
    messages?:  UseUIChipProps['messages']
    inputRef: Ref<HTMLInputElement>
}

export const UIInputChip = (
    {
        items = [],
        label,
        error,
        disabled,
        required,
        placeholder,
        submitKeys = ['Tab', 'Enter', ',', ' '],
        validate,
        onChange,
        onBlur,
        messages,
        inputRef,
        ...attributes
    }: UIInputChipProps
) => {
    const {
        chips,
        error: validationError,
        setError: setValidationError,
        setChips,
        addChip,
        removeChip
    } = useUIChip({ items, validate, messages })

    const [input, setInput] = useState('')

    useEffect(() => {
        setChips(items)
    }, [JSON.stringify(items)])

    useEffect(() => {
        onChange && onChange(chips)
    }, [JSON.stringify(chips)])

    useEffect(() => {
        setValidationError(error)
    }, [error])

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setInput(e.currentTarget.value)
        setValidationError(null)
    }

    const handleKeydown = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (!input || 'key' in event && !submitKeys.includes(event.key)) return

        addChip(input) && setInput('')
        event.preventDefault()
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        input && addChip(input) && setInput('')
        onBlur && onBlur(event)
        event.preventDefault()
    }

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        const input: string = event.clipboardData.getData('text')
        addChip(input) || setInput(input)
        event.preventDefault()
    }

    return (
        <div className={clsx(
            `ec-relative`,
            { 'ec-mt-4': !!label }
        )} {...attributes}>
            <div className={clsx(
                'ec-border ec-border-solid',
                {
                    'ec-border-gray-dark': !validationError,
                    'ec-border-red/75': !!validationError,
                    'ec-bg-white': !disabled,
                    'ec-bg-gray-lightest ec-cursor-not-allowed': disabled
                }
            )}>
                <div className="ec-flex ec-w-full ec-flex-wrap">
                    {chips.map(chip => (
                        <div className="ec-m-[2px]" key={chip.id}>
                            <UIChip label={chip.label || chip.id}
                                    disabled={disabled || !!chip.disabled}
                                    onClose={() => removeChip(chip)}
                            />
                        </div>
                    ))}
                    <input className={clsx(
                        'ec-text-sm ec-leading-7 ec-h-[2.25rem] ec-ml-2 ec-w-[200px] ec-flex-auto ec-peer',
                        { 'ec-bg-gray-lightest ec-cursor-not-allowed': disabled }
                    )}
                           ref={inputRef}
                           value={input}
                           disabled={disabled}
                           placeholder={!disabled
                               ? placeholder || 'Type here...'
                               : ''}
                           onChange={handleChange}
                           onKeyDown={handleKeydown}
                           onBlur={handleBlur}
                           onPaste={handlePaste}
                    />
                </div>
            </div>
            <UIInputLabel label={label} error={!!error} required={required} active/>
            <UIFormError error={validationError}/>
        </div>
    )
}
