import { CheckoutConfig } from '../types'

export const useCheckout = () => {
    const config = (window.checkoutConfig || null) as CheckoutConfig

    return {
        isBid: !!config?.isNegotiableQuote,
        branch: config?.current_branch || null
    }
}
