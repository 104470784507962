import { cleanProperties } from '../utils'
import { AnalyticsTrackContext } from '../types'
import { getProductPimId } from '@catalog/utils'
import { ProductInfo } from '@watsco/analytics'

/*
type PathHierarchy = {
    id: string
    name: string
    category: PathHierarchy[]
}
*/

export const getProduct = (
    context: AnalyticsTrackContext
): ProductInfo => {
    /*
     * Converting Algolia pathHierarchy to an array of category names.
     * Include when implementing Algolia in GA
     *
    const getItemCategories = (pathHierarchy: PathHierarchy) =>{
        if (pathHierarchy.category.length > 0) {
            return [pathHierarchy.name, ...getItemCategories(pathHierarchy.category[0])];
        }
        return [pathHierarchy.name];
    }
    */

    const { area, product, currentBranch, index, pricing } = context
    const { category_path } = product

    // Get the last category from category path
    const category = category_path && category_path.length > 0
        ? category_path[category_path.length - 1].trim()
        : 'Category Not Found'

    return cleanProperties({
        product_id: getProductPimId(product),
        sku: getProductPimId(product),
        pim_id: getProductPimId(product),
        erp_id: product.erp_id,
        index: index,
        position: index + 1,
        name: product.name,
        brand: product.brand_label,
        category,
        catalog_code: product.bu_product_num,
        bu_product_num: product.bu_product_num,
        mpn: product.mfg_item_num,
        price: pricing?.prices?.price ? parseFloat(pricing.prices.price.toFixed(2)) : -1,
        location_id: currentBranch && currentBranch.branch_id.toString(),
        image_url: product.image?.url || product.thumbnail?.url || product.small_image?.url,
        quantity: product.qty || 1,
        package_id: undefined, // @todo
        stock_status: undefined, // @todo
        type: area
    })
}
