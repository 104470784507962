import React, { useMemo } from 'react'
import { WingmanLocationState } from '../../context'
import { useWingmanView } from '../../hooks'
import { UIDateTime, UIPairItem, UIPairs, UIRating } from '@ui/components'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Section } from '@customerPortal/components'
import { default as detailsIntl } from './intl'
import intl from '../List/intl'

export const Details = () => {
    const { query } = useWingmanView()
    const { state } = useLocation<WingmanLocationState>()
    const wingman = query.data?.wingman || state?.wingman

    const pairs = useMemo<UIPairItem[]>(() => (
        [
            [
                <FormattedMessage {...intl.fields.status}/>,
                wingman?.status
            ],
            [
                <FormattedMessage {...intl.fields.rating}/>,
                <UIRating rating={wingman?.rating ?? 0}/>
            ],
            [
                <FormattedMessage {...intl.fields.createdDate}/>,
                wingman?.created_date && <UIDateTime date={wingman?.created_date}/>
            ],
            [
                <FormattedMessage {...intl.fields.modifiedDate}/>,
                wingman?.last_modified_date && <UIDateTime date={wingman?.last_modified_date}/>
            ],
            [
                <FormattedMessage {...intl.fields.model}/>,
                wingman?.model_number
            ],
            [
                <FormattedMessage {...intl.fields.serial}/>,
                wingman?.serial_number
            ],
            [
                <FormattedMessage {...intl.fields.contact}/>,
                wingman?.contact?.name || wingman?.supplied_name
            ],
            [
                <FormattedMessage {...intl.fields.owner}/>,
                wingman?.owner?.name
            ]
        ].filter(pair => !!pair[1]) as UIPairItem[]
    ), [wingman?.case_number])

    return (
        <Section>
            <Section.Item span={'half'} title={<FormattedMessage {...detailsIntl.detailsTitle}/>}>
                <UIPairs pairs={pairs}/>
            </Section.Item>
        </Section>
    )
}
