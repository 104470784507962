import React, { memo, useState } from 'react'
import {
    MarkerIcon,
    Nearby,
    StoreFrontIcon,
    useGoogleMapsState,
    useInventoryFinderState
} from '../../../../InventoryFinder'
import { useWindowSize } from '@ui/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { UIInput, UIModal } from '@ui/components'
import { isPropsAreEqual } from '@utils'
import { useCustomerData } from '@customer/context'
import { BranchList } from './BranchList'
import { GoogleMap } from './GoogleMap'

export const Branches = memo(() => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { isLoggedIn } = useCustomerData();
    const {isMobile} = useWindowSize();
    const intl = useIntl();

    const {
        searchInputRef,
        handleUseMyCurrent,
        handleUseMyCurrentBranchLocation,
        isGeoLocationEnabled
    } = useGoogleMapsState()

    const {
        isBranchesLoading,
        isLoadingData,
    } = useInventoryFinderState();

    const useSelectedBranchLocation = intl.formatMessage({
        id: 'branchMap.useSelectedBranchLocation',
        defaultMessage: 'Use my selected branch location'
    });

    const useMyLocation = intl.formatMessage({
        id: 'branchMap.useMyLocation',
        defaultMessage: 'Use my location'
    });

    const findBranchLabel = intl.formatMessage({
        id: 'branchMap.findABranchLabel',
        defaultMessage: 'Find a Branch'
    });

    const handleLocationClick = () => {
        if(isGeoLocationEnabled) {
            handleUseMyCurrent()
            return;
        }
        setIsModalOpen(true);
    }

    if (isMobile) {
        return (
            <div >
                <div className="ec-h-full">
                    {!isBranchesLoading && !isLoadingData && <div className="relative ec-pb-2">
                        <UIInput ref={searchInputRef} label={findBranchLabel}/>
                        {isLoggedIn &&
                            <div>
                                <div className="ec-flex ec-ec-items-center ec-mt-4">
                                    <a className="ec-text-sm ec-flex ec-space-x-2"
                                       onClick={handleUseMyCurrentBranchLocation}>
                                        <StoreFrontIcon/>
                                        <span>{useSelectedBranchLocation}</span>
                                    </a>
                                </div>
                                <div className="ec-flex ec-flex-col ec-mt-4 ec-mb-4">
                                    <a className="ec-text-sm ec-flex ec-space-x-2" onClick={handleUseMyCurrent}>
                                        <MarkerIcon/>
                                        <span>{useMyLocation}</span>
                                    </a>

                                    {!isGeoLocationEnabled && (
                                        <div className="ec-mt-2 ec-text-s">
                                            <FormattedMessage
                                                id="branchMap.enableGeoLocationDescription"
                                                defaultMessage="To use this feature, please enable your browser's location services."
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>}
                    </div>}
                    <div className={'ec-w-full ec-gap-4'}>
                        <GoogleMap/>
                    </div>
                    <div>
                        <Nearby/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="ec-flex ec-flex-col ec-h-[calc(100vh-250px)] ec-mb-8">
            <div className={'ec-flex ec-gap-4 ec-h-full'}>
                <div className="ec-w-[55%] ec-relative">
                    <div className="ec-overflow-y-scroll ec-absolute ec-inset-0 ec-pr-3.5">
                        {!isBranchesLoading && !isLoadingData && <div className="relative">
                            <UIInput ref={searchInputRef} label={findBranchLabel}/>
                            {isLoggedIn &&
                                <div>
                                    <div className="ec-flex ec-ec-items-center ec-mt-4">
                                        <a className="ec-text-sm ec-flex ec-space-x-2"
                                           onClick={handleUseMyCurrentBranchLocation}>
                                            <StoreFrontIcon/>
                                            <span>{useSelectedBranchLocation}</span>
                                        </a>
                                    </div>
                                    <div className="ec-flex ec-ec-items-center ec-mb-6">
                                        <a className="ec-text-sm ec-flex ec-space-x-2" onClick={handleLocationClick}>
                                            <MarkerIcon/>
                                            <span>{useMyLocation}</span>
                                        </a>
                                        <UIModal open={isModalOpen} onClose={() => setIsModalOpen(false)}
                                                 title={intl.formatMessage(
                                                     {
                                                         id: 'branchMap.enableGeoLocation',
                                                         defaultMessage: 'Enable Geo Location'
                                                     }
                                                 )}>
                                            <FormattedMessage
                                                id="branchMap.enableGeoLocationDescription"
                                                defaultMessage="To use this feature, please enable your browser's location services."/>
                                        </UIModal>
                                    </div>
                                </div>}
                        </div>}
                        <BranchList/>
                    </div>
                </div>
                <div className={'ec-w-full'}>
                    <GoogleMap/>
                </div>
            </div>
        </div>

    )
}, isPropsAreEqual);

Branches.whyDidYouRender = true
