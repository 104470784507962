import React, { PropsWithChildren, useState } from 'react'
import { WingmanDocument, WingmanFile } from '../../../../types'
import { useWingmanFile } from '../../../../hooks'
import { UILoader, UIMediaModal } from '@ui/components'
import { FormattedMessage } from 'react-intl'
import { UIExclamationIcon } from '@ui/icons'
import intl from '../../intl'

type WingmanFileProps = PropsWithChildren & {
    document: WingmanDocument
}

type WingmanFileContentProps = {
    file?: WingmanFile | null
    loading: boolean
    error: boolean
}

export const FileModal = (
    { document, children }: WingmanFileProps
) => {
    const [isOpen, setIsOpen] = useState(false)
    const { query, loadDocument } = useWingmanFile()

    const file = query.data?.file
    const error = !!query.data?.errors

    const handleDocumentOpen = () => {
        loadDocument(document)
        setIsOpen(true)
    }

    return (
        <>
            <button onClick={handleDocumentOpen} className="ec-h-full ec-w-full">
                {children}
            </button>
            <UIMediaModal open={isOpen} onClose={() => setIsOpen(false)}>
                <FileContent file={file} error={error} loading={query.isFetching}/>
            </UIMediaModal>
        </>
    )
}

const FileContent = (
    { file, error, loading }: WingmanFileContentProps
) => {
    if (loading) return <UILoader size="extra-large"/>
    if (error) return <FileErrorMessage/>

    const { source, media_type } = file || {}

    switch (media_type) {
        case 'image':
            return <img src={source} alt="" className="ec-max-h-full"/>
        case 'application':
            return <embed src={source} className="ec-w-full ec-h-full"/>
        case 'video':
            return <video src={source} controls className="ec-max-h-full"/>
        case 'audio':
            return <audio src={source} controls className="ec-max-h-full"/>
        default:
            return <div><FormattedMessage {...intl.emptyFile}/></div>
    }
}

const FileErrorMessage = () => (
    <div className="ec-flex ec-space-x-2">
        <UIExclamationIcon shape="triangle"/>
        <span><FormattedMessage {...intl.errorFile}/></span>
    </div>
)
