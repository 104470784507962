import React from 'react'
import { UIIconProps } from '../types'
import { sizeVariants } from './variants'

export const UITriangleUpIcon = (
    { size = 'medium' }: UIIconProps
) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"
         className={sizeVariants[size]}>
        <path d="M7,14.5l5,-5l5,5l-10,0Z"/>
    </svg>
)
