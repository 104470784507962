import { GraphQlErrorResult, request } from '@model/api'
import { getCmsBlocksQuery } from './query'
import { ContentBlock } from '../types'

export type CmsBlocksApiResponse = GraphQlErrorResult & {
    cmsBlocks: ContentBlock[]
}

export const getCmsBlocksApi = async (
    identifiers: string[]
): Promise<CmsBlocksApiResponse> => (
    request<{ cmsBlocks: { items: ContentBlock[] } }>(getCmsBlocksQuery, { identifiers }).then(data => ({
        cmsBlocks: data.data?.data?.cmsBlocks?.items,
        ...(data.data?.errors ? { errors: data.data?.errors } : {})
    }))
)
