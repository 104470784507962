import React from 'react'
import { Product } from '../../../types'

type ProductSpecificationProps = {
    product: Product
}

export const ProductSpecification = (
    { product }: ProductSpecificationProps
) => {
    return (
        <table className="ec-w-full">
            <tbody>
            {(product?.specification || []).map(item => (
                <tr key={item.code} className="ec-border-b last:ec-border-b-0 ec-border-gray-lighter">
                    <th className="ec-font-medium ec-px-2 ec-py-3">{item.label}</th>
                    <td className="ec-px-2 ec-py-3">{item.value}</td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}
