import React, { ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { UIOmit } from '../../../types'
import { UICheckIcon, UIExclamationIcon, UIInfoIcon } from '../../../icons'

export type UIAlertProps = UIOmit<ComponentPropsWithoutRef<'div'>> & {
    type: 'success' | 'error' | 'info' | 'warning'
}

const AlertIcon = (
    { type }: UIAlertProps
) => {
    switch (type) {
        case 'error':
            return <UIExclamationIcon variant="solid"/>
        case 'info':
        case 'warning':
            return <UIInfoIcon variant="solid"/>
        default:
            return <UICheckIcon variant="solid"/>
    }
}

export const UIAlert = (
    { type = 'success', children, ...attributes }: UIAlertProps
) => (
    <div className="ec-flex ec-items-stretch ec-bg-gray-lightest" {...attributes}>
        <div className={clsx(
            'ec-flex ec-items-center ec-p-2 ec-text-white',
            {
                'ec-bg-green': type === 'success',
                'ec-bg-red-light': type === 'error',
                'ec-bg-purple': type === 'info',
                'ec-bg-orange': type === 'warning'
            }
        )}>
            <AlertIcon type={type}/>
        </div>
        <div className="ec-p-2 ec-text-sm">
            {children}
        </div>
    </div>
)
