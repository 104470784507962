import {getSuffix, StorageKeys} from "../../state";

const suffix = getSuffix();
const TS_KEY = `ecmd_ts${suffix}`;

//keep this please a helper method, same for all that could trigger re-renders, non react methods
//now that we know more we can keep react clean and create hooks only when needed
export const timestampStorage = {
    getAll: (): Record<StorageKeys, number> => {
        try {
            return JSON.parse(localStorage.getItem(TS_KEY) || '{}');
        } catch {
            return {} as Record<StorageKeys, number>;
        }
    },

    get: (key: StorageKeys): number => {
        try {
            const data = JSON.parse(localStorage.getItem(TS_KEY) || '{}');
            return data[key] || 0;
        } catch {
            return 0;
        }
    },

    set: (key: StorageKeys, timestamp: number = Date.now()): void => {
        try {
            const data = JSON.parse(localStorage.getItem(TS_KEY) || '{}');
            data[key] = timestamp;
            localStorage.setItem(TS_KEY, JSON.stringify(data));
        } catch (error) {
            console.error('Error setting timestamp:', error);
        }
    },

    remove: (key: StorageKeys): void => {
        try {
            const data = JSON.parse(localStorage.getItem(TS_KEY) || '{}');
            delete data[key];
            localStorage.setItem(TS_KEY, JSON.stringify(data));
        } catch (error) {
            console.error('Error removing timestamp:', error);
        }
    },

    clear: (): void => {
        localStorage.removeItem(TS_KEY);
    },
};
