import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ProductDrawer, SubstituteBadge } from '@catalog/components'
import { useSubstitutes } from '../../../hooks'
import { PartsListProductSelect } from './PartsListProductSelect'
import { Part } from '../../../types'
import { useAnalytics } from '@analytics/hooks'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { ADD_TO_CART_LIST_DATA } from '@watsco/analytics'
import intl from '../intl'

type PartsListSubstituteProps = {
    part: Part
}

export const PartsListSubstitute = (
    { part }: PartsListSubstituteProps
) => {
    const { trackProductListView } = useAnalytics()

    const {
        substituteOptions,
        selectedSubstitute,
        selectedSubstituteId,
        selectSubstitute,
        loadSubstitutes,
        products,
        query
    } = useSubstitutes(part)

    useEffect(() => {
        !!products.length && trackProductListView({
            listData: {
                list_id: 'supersedes_part_list',
                list_name: 'Supersedes Part List'
            },
            products: products.map(product => ({ product }))
        })
    }, [part.part_number, products.map(({ pim_id}) => pim_id).join(',')])

    const ProductSelect = () => (
        substituteOptions.length > 1
            ? <PartsListProductSelect
                productOptions={substituteOptions}
                product={selectedSubstituteId}
                onProductSelect={e => selectSubstitute(e.target.value)}
                alert={<FormattedMessage {...intl.multipleSubstitute}/>}/>
            : null
    )

    return (
        <AnalyticsProvider area={AnalyticsArea.Substitute}
                           listData={ADD_TO_CART_LIST_DATA.SUBSTITUTE_PARTS_LIST}>
            <ProductDrawer product={selectedSubstitute?.product || null}
                           title={<FormattedMessage {...intl.substitute} />}
                           content={{ top: <ProductSelect/> }}
                           loading={query.isFetching}
                           onOpen={loadSubstitutes}>
                <SubstituteBadge/>
            </ProductDrawer>
        </AnalyticsProvider>
    )
}
