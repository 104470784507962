import { Part } from '../types'
import { Product } from '@catalog/types'

export const getProduct = (part: Part) => {
    if (part.product) return part.product

    const product: Product = {
        brand_label: part.manufacturer,
        name: part.part_description,
        mfg_item_num: part.part_number,
        pim_id: part.pim_id
    }
    return product
}
