import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { UIPanel, UITabItems, UITabs } from '@ui/components'
import { PartsListTable, PartsListSearch, PartsListContentWrapper } from '../PartsList'
import { Part } from '../../types'
import { usePartsList } from '../../hooks'
import { Documents } from '@documents/components'
import intl from './intl'

export const PartsSearchResult = () => {
    const { allParts, documents } = usePartsList()
    const part = allParts && allParts[0] || {} as Part

    const tabs = useMemo<UITabItems>(() => ([
        {
            key: 'parts',
            title: <FormattedMessage {...intl.result.tabParts}/>,
            panel: <PartsList/>
        },
        {
            key: 'documents',
            title: <FormattedMessage {...intl.result.tabDocuments}/>,
            panel: <Documents documents={documents}/>
        }
    ]), [])

    return (
        <UIPanel>
            <UIPanel.Body>
                <div className="ec-flex ec-flex-col">
                    <h2 className="ec-text-xl ec-m-0">{part.model_number} - {part.manufacturer}</h2>
                    <p className="ec-text-sm ec-m-0">{part.model_description}</p>
                </div>
            </UIPanel.Body>
            <UIPanel.Body>
                {!documents.length
                    ? <PartsList/>
                    : <UITabs tabs={tabs}/>
                }
            </UIPanel.Body>
        </UIPanel>
    )
}

const PartsList = () => (
    <PartsListContentWrapper>
        <PartsListSearch/>
        <PartsListTable/>
    </PartsListContentWrapper>
)
