import { useHistory } from 'react-router-dom'
import { urlBuilder, UrlBuilderSearchParamValue } from '@utils'

export const useUrl = () => {
    const history = useHistory()

    const getUrlParam = (name: string) => {
        return urlBuilder().current().getSearchParam(name)
    }

    const setUrlParam = (name: string, value: UrlBuilderSearchParamValue) => {
        const url = urlBuilder().current().setSearchParam(name, value)
        history.push(url.getLocationDescriptor())
    }

    const setUrlParams = (params: { [key: string]: UrlBuilderSearchParamValue }) => {
        const url = urlBuilder().current().setSearchParams(params)
        history.push(url.getLocationDescriptor())
    }

    const removeUrlParam = (name: string | string[]) => {
        const url = urlBuilder().current().removeSearchParam(name)
        history.push(url.getLocationDescriptor())
    }

    return {
        getUrlParam,
        setUrlParam,
        setUrlParams,
        removeUrlParam
    }
}
