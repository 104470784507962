import React, {createContext, PropsWithChildren, useContext, useMemo} from 'react'
import {HeaderStyle} from "./HeaderStyle";
import {MenuItemType} from './MenuStateProvider'

type ThemeProviderProps = PropsWithChildren & {
    config: { menuData: MenuItemType }
}

type ThemeStateType = {
    config: { menuData: MenuItemType };
    headerStyle: any | null;
    isCheckout: boolean;
}

type ThemeContextType = ThemeStateType & {}

const ThemeStateProvider = createContext<ThemeContextType>(null);

export const ThemeProvider = (
    { children, config }: ThemeProviderProps
) => {
    const headerStyle = HeaderStyle;

    const isCheckout = () => {
        const currentPath = window.location.pathname;

        const checkoutPaths = [
            '/checkout',
            '/checkout/index',
            '/checkout/index/index'
        ];

        return checkoutPaths.some(path => currentPath.startsWith(path));
    }

    return (
        <ThemeStateProvider.Provider value={
            {
                config,
                headerStyle,
                isCheckout: isCheckout()
            }
        }>
            {children}
        </ThemeStateProvider.Provider>
    );
}

export const useThemeState = () => {
    const context = useContext(ThemeStateProvider);

    if (!context) {
        console.warn(`useThemeState must be used within the ThemeProvider`);
    }

    return context;
}
