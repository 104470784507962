import React, { useMemo } from 'react'
import { UITabItems, UITabs } from '@ui/components'
import { FormattedMessage } from 'react-intl'
import { useWingmanView } from '../../hooks'
import { Feeds, Documents } from './Tabs/index'
import intl from './intl'

export const Tabs = () => {
    const { query } = useWingmanView()

    const tabs = useMemo<UITabItems>(() => ([
        {
            key: 'feeds',
            title: <FormattedMessage {...intl.feedsTab}/>,
            panel: <Feeds/>,
            enabled: !!query.data.wingman?.feeds
        },
        {
            key: 'documents',
            title: <FormattedMessage {...intl.documentsTab}/>,
            panel: <Documents/>,
            enabled: !!query.data.wingman?.documents
        }
    ]), [
        !!query.data.wingman?.feeds,
        !!query.data.wingman?.documents
    ])

    return (
        <UITabs tabs={tabs}/>
    )
}
