import React from "react";
import { UIButton, UIButtonDropdown } from '@ui/components'
import {useInventoryFinderState } from '../../../state'
import {useIntl} from "react-intl";
import {useCustomerData} from "../../../../Customer";

export const Right = ({branch}) => {
    const { isLoggedIn } = useCustomerData();
    const intl = useIntl();

    const {
        generateDirectionsLink,
        distances,
        switchCurrentBranch,
        switchPreferredBranch,
        currentBranch,
        preferredBranch,
        setIsOpen
    } = useInventoryFinderState();

    const getDirectionsLabel = intl.formatMessage({
        id: 'inventory.finder.get.directions',
        defaultMessage: 'Get Directions',
    });

    const formatDistance = (distance) => {
        const formattedDistance = intl.formatNumber(distance, {style: 'unit', unit: 'mile'});
        return `${formattedDistance}`;
    };

    const branchId = branch.branch_id
    const isCurrentBranch = currentBranch?.branch_id === branchId;
    const isPreferredBranch = preferredBranch?.branch_id === branchId;
    const isSameBranch = isCurrentBranch && isPreferredBranch;

    return (
        <div className="ec-text-right">
            {distances[branchId] && <p className="ec-p">{formatDistance(distances[branchId])}</p>}
            <p className={'inventory-action ec-mb-2'}>
                <a target="_blank"
                   href={generateDirectionsLink(branch)}>
                    {getDirectionsLabel}
                </a></p>
            <div className={'inventory-action'}>
                {isLoggedIn && !isSameBranch && <div>
                    {isCurrentBranch && <UIButton variant={"outlined"} size={"small"} fullWidth={true}
                                                  onClick={() => switchPreferredBranch.mutate({ branchId })}>
                    <span>
                        {intl.formatMessage({
                            id: 'inventory.finder.button.label',
                            defaultMessage: 'Set preferred'
                        })}
                    </span>
                    </UIButton>}
                    {isPreferredBranch && <UIButton variant={"outlined"} size={"small"} fullWidth={true}
                                                    onClick={() => {
                                                        switchCurrentBranch.mutate({branchId})
                                                        setIsOpen(false)
                                                    }}>
                    <span>
                        {intl.formatMessage({
                            id: 'inventory.finder.button.label',
                            defaultMessage: 'Select'
                        })}
                    </span>
                    </UIButton>}
                </div>}

                {isLoggedIn && !isCurrentBranch && !isPreferredBranch &&
                    <UIButtonDropdown variant={"outlined"} size={"small"} fullWidth buttons={[
                        {
                            children: 'Set current',
                            key: 'current',
                            onClick: () => {
                                switchCurrentBranch.mutate({ branchId })
                                setIsOpen(false)
                            }
                        },
                        {
                            children: 'Set preferred',
                            key: 'preferred',
                            onClick: () => switchPreferredBranch.mutate({ branchId })
                        }
                    ]}/>}
            </div>
        </div>
    );
};
