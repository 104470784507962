import React from "react";
import Skeleton from "react-loading-skeleton";

export const BranchSkeleton = () => {
    return (
        <div className={'ec-border-b ec-pb-4 ec-pt-4 ec-pr-4'}>
            <div className="ec-mb-6">
                <Skeleton width={150} height={20}/>
            </div>
            <div>
                <div className="ec-flex ec-w-full ec-mb-2 ec-justify-between ec-mt-4">
                    <div className="ec-w-[47%]">
                        <Skeleton count={5} width={'100%'} height={20}/>
                    </div>
                    <div className="ec-w-[47%] ec-mt-6">
                        <Skeleton count={2} width={'100%'} height={20}/>
                        <div className={'ec-mt-4'}>
                            <Skeleton width={'100%'} height={30}/>
                        </div>
                    </div>

                </div>
                <div className={'ec-flex ec-w-full ec-mb-4 ec-justify-between'}>
                    <div className="ec-w-[47%] ec-mt-4">
                        <Skeleton count={2} width={'100%'} height={20}/>
                    </div>
                    <div className="ec-w-[47%]">
                    </div>
                </div>
            </div>
        </div>
    )
}