import React, { Fragment, PropsWithChildren, MutableRefObject, ReactNode } from 'react'
import { UIBackdrop } from '../UIBackdrop/UIBackdrop'
import { UIButton, UIButtonIcon, UIButtonProps } from '../../Elements'
import { Transition, Dialog } from '@headlessui/react'
import clsx from 'clsx'
import { UICloseIcon } from '../../../icons'

export type UIModalProps = PropsWithChildren & {
    open: boolean
    onClose: () => void
    title?: ReactNode
    slide?: 'wide' | boolean
    scroll?: boolean
    buttons?: UIButtonProps[]
    focus?: MutableRefObject<HTMLElement | null>
}

type FullWidthSectionProps = PropsWithChildren & {
    title?: ReactNode
}

export const UIModal = (
    { open, onClose, title, buttons, slide, focus, scroll = true, children }: UIModalProps
) => (
    <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative ec-z-100" onClose={() => onClose()} initialFocus={focus}>
            <Transition.Child
                enter="ec-transition-opacity ec-ease-in-out ec-duration-150"
                enterFrom="ec-opacity-0"
                enterTo="ec-opacity-100"
                leave="ec-transition-opacity ec-ease-in-out ec-duration-150"
                leaveFrom="ec-opacity-100"
                leaveTo="ec-opacity-0">
                <UIBackdrop/>
            </Transition.Child>
            <div className={clsx(
                'ec-z-100 ec-fixed ec-inset-0 ec-h-dvh',
                { 'ec-flex ec-w-dvw lg:ec-items-center lg:ec-justify-center': !slide }
            )}>
                <Transition.Child
                    enter="ec-absolute lg:ec-static ec-transition-all ec-ease-in-out ec-duration-150"
                    enterFrom={clsx(
                        'ec-translate-x-[100vw] ec-translate-y-0 ec-h-full',
                        {
                            'lg:ec-translate-x-0 lg:ec-translate-y-[-50vh] lg:ec-opacity-0 lg:ec-h-auto': !slide,
                            'lg:ec-translate-x-[100vw] lg:ec-translate-y-0': slide
                        })}
                    enterTo={clsx(
                        'ec-translate-x-[50px] ec-translate-y-0 ec-h-full',
                        {
                            'lg:ec-translate-x-0 lg:ec-opacity-100 lg:ec-h-auto': !slide,
                            'lg:ec-translate-x-[calc(100vw-440px)] lg:ec-translate-y-0': slide === true,
                            'lg:ec-translate-x-[200px] lg:ec-translate-y-0': slide === 'wide'
                        })}
                    entered="ec-absolute lg:ec-static"
                    leave="ec-absolute lg:ec-static ec-transition-all ec-ease-in-out ec-duration-150"
                    leaveFrom={clsx(
                        'ec-translate-x-[50px] ec-translate-y-0 ec-h-full',
                        {
                            'lg:ec-translate-x-0 lg:ec-translate-y-[-50%] lg:ec-opacity-100 lg:ec-h-auto': !slide,
                            'lg:ec-translate-x-[calc(100vw-440px)] lg:ec-translate-y-0': slide === true,
                            'lg:ec-translate-x-[200px] lg:ec-translate-y-0': slide === 'wide'
                        })}
                    leaveTo={clsx(
                        'ec-translate-x-[100vw] ec-translate-y-0 ec-h-full',
                        {
                            'lg:ec-translate-x-0 lg:ec-translate-y-[-50dvh] lg:ec-opacity-0 lg:ec-h-auto': !slide,
                            'lg:ec-translate-x-[100vw] lg:ec-translate-y-0': slide
                        })}>
                    <Dialog.Panel className="ec-h-full">
                        <div className={clsx(
                            'ec-flex ec-flex-col ec-h-full ec-bg-white ec-w-[calc(100vw-50px)] lg:ec-shadow',
                            {
                                'lg:ec-w-[500px] lg:ec-h-fit lg:ec-max-h-[80vh]': !slide,
                                'lg:ec-w-[440px]': slide === true,
                                'lg:ec-w-[calc(100vw-200px)]': slide === 'wide'
                            })}>
                            <div className={clsx(
                                'ec-flex',
                                {
                                    'ec-align-top ec-border-b ec-border-gray-lighter ec-p-4': !!title,
                                    'lg:ec-align-top lg:ec-border-b lg:ec-border-gray-lighter lg:ec-p-4': !title
                                }
                            )}>
                                {!!title &&
                                    <Dialog.Title className={clsx(
                                        'ec-text-xl ec-mb-0 ec-mr-4 ec-truncate',
                                        'ec-whitespace-nowrap'
                                    )}>
                                        {title}
                                    </Dialog.Title>
                                }
                                <div className="ec-ml-auto ec-p-1 -ec-m-2 ec-hidden lg:ec-flex">
                                    <UIButtonIcon ariaLabel={'Close'}
                                                  onClick={() => onClose()}>
                                        <UICloseIcon/>
                                    </UIButtonIcon>
                                </div>
                            </div>
                            <Dialog.Description as="div" className={clsx(
                                'ec-p-4 ec-h-full ec-overflow-y-scroll',
                                { 'lg:ec-overflow-y-visible': !scroll }
                            )}>
                                {children}
                            </Dialog.Description>
                            {buttons && !!buttons.length &&
                                <div className="ec-p-4 ec-border-t ec-border-gray-lighter">
                                    <div className="ec-flex ec-gap-4 ec-justify-end">
                                        {buttons.map((button, index) =>
                                            <UIButton key={index}
                                                      variant="text"
                                                      onClick={() => onClose()}
                                                      {...button}/>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
                <Transition.Child
                    enter="ec-absolute lg:ec-hidden ec-transition-all ec-ease-in-out ec-duration-150"
                    enterFrom="ec-translate-x-[-50px] ec-translate-y-0 ec-h-full"
                    enterTo="ec-translate-x-0 ec-translate-y-0 ec-h-full"
                    entered="ec-absolute lg:ec-hidden"
                    leave="ec-absolute lg:ec-hidden ec-transition-all ec-ease-in-out ec-duration-150"
                    leaveFrom="ec-translate-x-0 ec-translate-y-0 ec-h-full"
                    leaveTo="ec-translate-x-[-50px] ec-translate-y-0 ec-h-full">
                    <div className={clsx(
                        'ec-w-[50px] ec-h-full ec-bg-black ec-flex ec-justify-center ec-items-start ec-p-4'
                    )}>
                        <div className="ec-p-1 -ec-m-2">
                            <UIButtonIcon ariaLabel={'Close'}
                                          onClick={() => onClose()}
                                          color="light">
                                <UICloseIcon/>
                            </UIButtonIcon>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition>
)

UIModal.Section = (
    { title, children }: FullWidthSectionProps
) => (
    <div className="ec-flex ec-flex-col ec-gap-4 ec-w-full">
        {title && <h3 className="ec-h3">{title}</h3>}
        {children}
    </div>
)

UIModal.FullWidthSection = (
    { title, children }: FullWidthSectionProps
) => (
    <div className="ec-bg-gray-lighter ec-px-4 ec-py-6 -ec-mx-4">
        <UIModal.Section title={title}>
            {children}
        </UIModal.Section>
    </div>
)
