import React from "react";
import Skeleton from "react-loading-skeleton";

export const MiniCartItemsSkeleton = () => {
    return (
        <div className={'ec-border-b ec-p-2 ec-m2'}>
            <div className="ec-flex ec-justify-between ec-mb-2">
                <div className="ec-w-10 ec-h-10 ec-mr-4">
                    <Skeleton height={'100%'} width={'100%'} />
                </div>

                <div className="ec-flex-grow">
                    <Skeleton width={`100%`} height={20} />
                </div>

            </div>
            <div className="ec-flex ec-justify-between ec-mb-2">
                <div className="ec-w-1/4 ec-pl-14">
                    <Skeleton width={`100%`} height={20} />
                </div>
                <div className="ec-w-1/6">
                    <Skeleton width={`100%`} height={20} />
                </div>
            </div>
        </div>

    );
};
