import { useContext, useState } from 'react'
import { SubstituteContext } from '../../context'
import { Part, Substitute } from '../../types'
import { UISelectOption } from '@ui/components'
import { uniqueObjectArray } from '@utils'

export const useSubstitutes = (
    part: Part
) => {
    const { query, productNumber, setProductNumber } = getContext() || {}

    const substitutes = part.part_number === productNumber && query.data?.substitutes || []
    const errors = query.data?.errors || null
    const initialSubstitute = substitutes[0] || null

    const [selectedSubstitute, setSelectedSubstitute] = useState<Substitute | null>(initialSubstitute)

    const products = substitutes
        .filter(({ product }) => !!product)
        .map(({ product }) => product)

    const substituteOptions: UISelectOption[] = uniqueObjectArray(
        substitutes.map(substitute => ({
            value: substitute.replacement_mpn,
            label: substitute.product?.name || substitute.replacement_mpn
        })),
        'value'
    )

    const selectSubstitute = async (productNumber: Substitute['replacement_mpn']) => {
        const Substitute = substitutes.find(
            Substitute => Substitute.replacement_mpn === productNumber
        )

        if (!Substitute) console.warn(`Could not find Substitute by product number "${productNumber}"`)
        setSelectedSubstitute(Substitute || initialSubstitute)
    }

    const loadSubstitutes = async () => {
        setProductNumber(part.part_number)
    }

    return {
        substitutes,
        products,
        errors,
        query,
        loadSubstitutes,
        substituteOptions,
        selectedSubstitute: selectedSubstitute || initialSubstitute,
        selectedSubstituteId: selectedSubstitute?.replacement_mpn,
        selectSubstitute
    }
}

const getContext = () => {
    const context = useContext(SubstituteContext)

    if (!context) {
        console.warn('useSubstitutes must be used within the SubstituteProvider')
    }
    return context
}
