import React, { createContext, PropsWithChildren } from 'react'
import { WINGMAN_VIEW_PATH } from '../config'
import { getWingmanListApi, WingmanListResult } from '../api'
import { WingmanList } from '../types'
import { CustomerPortalLocationState } from '@customerPortal/context'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getFormInputDate, urlBuilder } from '@utils'
import { useLocation } from 'react-router-dom'
import { LocationDescriptor } from 'history'
import { useAccount } from '@account/hooks'
import { toast } from 'react-toastify'
import { useTableUrl } from '@ui/hooks'
import { useYup } from '@yup/context'
import { useCustomer } from '@customer/hooks'

export type WingmanListProviderState = {
    query: UseQueryResult<WingmanListResult>
    getLocationParams: (wingman: WingmanList) => LocationDescriptor<WingmanLocationState>
    isCompanyUser: boolean
    onSearch: (data?: WingmanListSearchData) => void
    filters: WingmanListSearchData
}

export type WingmanLocationState = CustomerPortalLocationState & {
    wingman: WingmanList
}

export type WingmanListSearchData = {
    startDate?: Date
    accounts?: boolean
}

export const WingmanListContext = createContext<WingmanListProviderState>(null)

export const WingmanListProvider = (
    { children }: PropsWithChildren
) => {
    const { yup } = useYup()
    const location = useLocation()
    const { isCompanyUser } = useCustomer()

    const { account } = useAccount()
    const { addFilters, filters } = useTableUrl({
        filterSchema: yup.object({
            accounts: yup.boolean().nullable(),
            startDate: yup.date().nullable()
        }),
        defaultFilters: { accounts: true }
    })

    const query = useQuery({
        queryKey: ['wingman_list', account?.customer_id, JSON.stringify(filters)],
        queryFn: async () => {
            const result = await getWingmanListApi({
                accounts: filters.accounts && isCompanyUser && account?.customer_id ? [account.customer_id] : undefined,
                startDate: filters.startDate ? getFormInputDate(filters.startDate) : undefined
            })

            result.errors && result.errors.forEach(error => toast.error(error.message))
            return result
        },
        enabled: isCompanyUser && !!account?.customer_id || isCompanyUser === false,
        keepPreviousData: true
    })

    const getLocationParams = (wingman: WingmanList): LocationDescriptor<WingmanLocationState> => ({
        ...urlBuilder().setPath(WINGMAN_VIEW_PATH, { id: wingman.id }).getLocationDescriptor(),
        state: { wingman, referer: location }
    })

    const onSearch = (data?: WingmanListSearchData) => {
        addFilters({
            startDate: data?.startDate ? getFormInputDate(data.startDate) : null,
            accounts: data?.accounts
        })
    }

    return (
        <WingmanListContext.Provider value={{
            query,
            filters,
            isCompanyUser,
            getLocationParams,
            onSearch
        }}>
            {children}
        </WingmanListContext.Provider>
    )
}
