import { useContext } from 'react'
import { CmsBlockContext } from '../../context'

export const useCmsBlock = (identifier?: string) => {
    const context = useContext(CmsBlockContext)

    if (!context) {
        console.warn('useCmsBlock must be used within the CmsBlockProvider')
    }

    const getConfigIdentifier = () => {
        if (!!identifier) return identifier
        return Object.keys(context.cmsBlocks)[0] || null
    }

    const cmsBlock = context.cmsBlocks[getConfigIdentifier()] || null

    return {
        ...context,
        cmsBlock
    }
}
