import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../../types'

export type UITableHeadRowProps = UIOmit<ComponentPropsWithoutRef<'tr'>> & {
    variant?: string
}

const headRowVariant = {
    default: 'lg:!ec-bg-white',
    bordered: 'lg:ec-bg-gray-lighter'
}

export const UITableHeadRow = ({ variant = 'default', children, ...attributes }: UITableHeadRowProps) => (
    <>
        <tr
            className={` ${headRowVariant[variant]}`}
            {...attributes}>
            {children}
        </tr>
        <tr>
            <th colSpan={100} className="ec-bg-gray-light ec-h-[1px]"></th>
        </tr>
    </>
)
