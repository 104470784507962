import { OrderTemplateRaw } from '../types'

export const getModel = (orderTemplate: OrderTemplateRaw | null) => {
    if (!orderTemplate) return null

    return {
        id: parseInt(atob(orderTemplate.uid)),
        name: orderTemplate.name,
        item_count: orderTemplate.items_count,
        updated_at: orderTemplate.updated_at
    }
}
