import React, { PropsWithChildren } from 'react'
import { UILoader } from '@ui/components'

type CustomerPortalLoader = PropsWithChildren & {
    show: boolean
}

export const Loader = (
    { show, children }: CustomerPortalLoader
) => (
    <>
        <UILoader show={show} size="extra-large"/>
        {!show && children}
    </>
)
