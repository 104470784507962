import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { AddressExtended, UIOmit } from '@ui/types'
import { formatPhoneNumber } from '@utils'

type AddressProps = UIOmit<ComponentPropsWithoutRef<'address'>, 'title'> & {
    address: AddressExtended
    title?: ReactNode
}

export const UIAddress = (
    { address, title, ...attributes }: AddressProps
) => {
    const phoneNumber = address?.phone && formatPhoneNumber(address.phone)

    return (
        <address className="ec-text-sm ec-flex ec-flex-col" {...attributes}>
            {title !== undefined && title}
            {title === undefined && !!address.title && <strong>{address.title}</strong>}
            {!!address.company && <span>{address.company}</span>}
            {!!address.address_1 && <span>{address.address_1}</span>}
            {!!address.address_2 && <span>{address.address_2}</span>}
            {!!address.address_3 && <span>{address.address_3}</span>}
            <span>{[address?.city, address?.region_code, address?.postcode].join(', ')}</span>
            {phoneNumber &&
                <a href={`tel:${phoneNumber}`}>
                    {phoneNumber}
                </a>
            }
        </address>
    )
}
