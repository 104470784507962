import React from 'react'
import cn from 'clsx'
import {CartIcon} from '../../../icons'
import {UIWarning, useWindowSize} from "../../../../Ui";
import {useIntl} from "react-intl";

export const ErrorCart = () => {
    const {isMobile} = useWindowSize();
    const intl = useIntl();

    return (
        <div>
            <div onClick={() => {
            }}
                 className={cn("ec-flex ec-overflow-visible ec-h-10 ec-flex-col ec-cursor-pointer", {
                     'ec-mr-6 ec-top-[-7px] ec-relative': !isMobile
                 })}>
                <div className={cn('ec-flex ec-items-center ec-justify-around ec-relative ', {
                    'ec-flex-wrap ec-flex-col': isMobile,
                    'ec-flex-row-reverse': !isMobile
                })}>
                    <div className={cn('ec-absolute ec-text-white', '-ec-top-2 -ec-right-2')}
                         title={intl.formatMessage({
                             id: 'cart.error',
                             defaultMessage: 'Cart Issues'
                         })}>
                        <UIWarning/>
                    </div>
                    <CartIcon/>
                    <span className={cn('ec-text-white ec-relative lg:ec-mr-1', {'ec-text-xs': isMobile})}>
                        {isMobile ? 'Cart' : 'Shopping Cart'}
                    </span>
                </div>
            </div>
        </div>
    )
}
