import React from 'react'
import { WingmanFileProvider } from '../../../context'
import { useWingmanView } from '../../../hooks'
import { FileModal } from './Documents/FileModal'
import clsx from 'clsx'

export const Documents = () => {
    const { query } = useWingmanView()
    const documents = query.data?.wingman.documents || []

    return (
        <WingmanFileProvider>
            <ul className={clsx(
                'ec-grid ec-gap-4 ec-grid-cols-[repeat(2,1fr)]',
                'md:ec-grid-cols-[repeat(4,1fr)]',
                'lg:ec-grid-cols-[repeat(6,1fr)]'
            )}>
                {documents.map(document => (
                    <li key={document.document_id} className="ec-border ec-border-gray hover:ec-bg-gray-lightest">
                        <FileModal document={document}>
                            <div className="ec-p-4 lg:ec-p-8 ec-aspect-square">
                                <div className="ec-h-full ec-flex ec-items-center ec-justify-center">
                                    <img className="ec-max-w-full ec-max-h-full"
                                         src={document.thumbnail_source}
                                         alt={document.title}/>
                                </div>
                            </div>
                        </FileModal>
                    </li>
                ))}
            </ul>
        </WingmanFileProvider>
    )
}
