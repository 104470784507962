import React, { createContext, useContext, PropsWithChildren, useState, useEffect } from 'react'
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { BranchesInventoryResult, getBranchesInventoryApi } from '../api'
import { useBranch } from '@branch/hooks'
import { Product } from '@catalog/types'
import { toast } from 'react-toastify'
import { useCustomer } from '@customer/hooks'

export type BranchesInventoryProviderProps = PropsWithChildren & {
    products?: Product[]
}

export type BranchesInventoryProviderState = {
    branchesInventoryQuery: UseQueryResult<BranchesInventoryResult>
    setProducts: UseStateSet<Product[]>
    invalidate: () => Promise<void>
}

const BranchesInventoryContext = createContext<BranchesInventoryProviderState>(null)

const QUERY_KEY = 'branches_inventory'

export const BranchesInventoryProvider = (
    { products: initProducts = [], children }: BranchesInventoryProviderProps
) => {
    const [products, setProducts] = useState<Product[] | null>(initProducts)
    const { isLoggedIn } = useCustomer()

    useEffect(() => {
        setProducts(initProducts)
    }, [initProducts.map(({ pim_id }) => pim_id).join(',')])

    const pimIds = products
        .filter(product => !!product?.pim_id)
        .map(({ pim_id }) => pim_id)

    const queryClient = useQueryClient()
    const { currentBranch } = useBranch()

    const branchesInventoryQuery = useQuery({
        queryKey: [QUERY_KEY, currentBranch?.branch_id, pimIds],
        queryFn: async () => {
            const result = await getBranchesInventoryApi({
                products: pimIds,
                sort: {
                    field: 'branch_distance',
                    order: String(currentBranch?.branch_id)
                }
            })

            const { errors } = result

            !!errors && errors.forEach(error => toast.error(error.message))
            return result
        },
        enabled: !!currentBranch?.branch_id && pimIds.length > 0 && isLoggedIn
    })

    const invalidate = () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] })

    return (
        <BranchesInventoryContext.Provider value={{ branchesInventoryQuery, setProducts, invalidate }}>
            {children}
        </BranchesInventoryContext.Provider>
    )
}

export const useBranchesInventoryState = () => {
    const context = useContext(BranchesInventoryContext)

    if (!context) {
        throw new Error('useBranchesInventoryState must be used within the BranchesInventoryProvider')
    }
    return context
}
