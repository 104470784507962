import React from 'react'
import { UITextarea, UIInput, UIInputChip, UIModal } from '@ui/components'
import { UIChipItem, useModal } from '@ui/hooks'
import { Controller, useForm } from 'react-hook-form'
import { ShareActiveCartParams } from '../api'
import { useCustomer } from '@customer/hooks'
import { useShareCart } from '../context'
import { ValidationError } from 'yup'
import { useYup } from '@yup/context'
import { urlBuilder } from '@utils'
import { SAVE_CART_VIEW_PATH } from '@saveCart/config'
import { IntlProps } from '@intl/types'
import { dummyFormatMessage } from '@intl/model'
import { ShareCartEventMeta } from '@analytics/types'

type ShareCartModalProps = IntlProps & Pick<ReturnType<typeof useModal>, 'open' | 'handleClose'> & {
    onSuccess?: (metadata: ShareCartEventMeta) => void
}

export const ShareCartAction = (
    { open, handleClose, formatMessage = dummyFormatMessage, onSuccess }: ShareCartModalProps
) => {
    const { errorMessage, yup, resolver } = useYup()
    const { shareActiveCartMutation, shareSaveCartMutation, saveCart } = useShareCart()
    const { preferences } = useCustomer()

    const schema = yup.object({
        ...(!saveCart ? { name: yup.string().required() } : {}),
        sharedWith: yup.array()
            .of(yup.string().required().emailOrPhone())
            .min(1, formatMessage({
                id: 'share.cart.contacts.error',
                defaultMessage: 'Please enter at least one email address or phone number.'
            })),
        message: yup.string()
    })

    const { control, register, formState: { errors }, handleSubmit } = useForm({
        resolver: resolver(schema)
    })

    const validateEmail = (chip: UIChipItem) => {
        try {
            yup.object({
                email: yup.string().emailOrPhone()
            }).validateSync({ email: chip.id })
        } catch (e) {
            return errorMessage(e as ValidationError)
        }
    }

    const onSubmit = (data: ShareActiveCartParams) => {
        if (!saveCart) {
            shareActiveCartMutation.mutate(data, {
                onSuccess: ({ saveCartId }, params) => {
                    onSuccess && onSuccess({ contacts: params.sharedWith })

                    window.location.href = urlBuilder()
                        .setPath(SAVE_CART_VIEW_PATH, { id: saveCartId })
                        .toString()
                }
            })
        } else {
            shareSaveCartMutation.mutate(data, {
                onSuccess: (_, params) => {
                    onSuccess && onSuccess({ contacts: params.sharedWith })
                    handleClose()
                }
            })
        }
    }

    const chips = (preferences?.shareCart?.contacts || []).map(({ contact, name }) => {
        const isPhone = yup.string().phone().isValidSync(contact)
        const contactType = isPhone ? 'phone' : 'email'

        return {
            id: contact,
            label: name + ` (${contactType})`
        }
    })

    const isLoading = shareActiveCartMutation.isLoading || shareSaveCartMutation.isLoading

    return (
        <UIModal open={open}
                 onClose={handleClose}
                 title={!saveCart
                     ? formatMessage({ id: 'share.cart.title', defaultMessage: 'Share Cart' })
                     : formatMessage({ id: 'share.cart.savedCartTitle', defaultMessage: 'Share Saved Cart' })
                 }
                 buttons={[
                     {
                         children: formatMessage({ id: 'share.cart.cancel', defaultMessage: 'Cancel' }),
                         disabled: isLoading
                     },
                     {
                         children: formatMessage({ id: 'share.cart.share', defaultMessage: 'Share' }),
                         loading: isLoading,
                         onClick: () => handleSubmit(onSubmit)()
                     }
                 ]}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="ec-flex ec-flex-col ec-gap-4 ec-w-full">
                    {!saveCart &&
                        <UIInput label={formatMessage({ id: 'share.cart.form.name', defaultMessage: 'Cart name' })}
                                 required
                                 register={register('name')}
                                 error={errorMessage(errors, 'name')}/>
                    }
                    <Controller control={control}
                                name="sharedWith"
                                render={({ field: { onChange, onBlur, ref } }) => (
                                    <UIInputChip
                                        inputRef={ref}
                                        items={chips}
                                        onBlur={onBlur}
                                        required
                                        label={formatMessage({
                                            id: 'share.cart.form.contacts',
                                            defaultMessage: 'Send To'
                                        })}
                                        placeholder={formatMessage({
                                            id: 'share.cart.form.contacts.placeholder',
                                            defaultMessage: 'Type email addresses or phone numbers'
                                        })}
                                        validate={validateEmail}
                                        onChange={data => onChange(data.map(datum => datum.id))}
                                        error={errorMessage(errors, 'sharedWith')}/>
                                )}/>
                    <UITextarea label={formatMessage({ id: 'share.cart.form.message', defaultMessage: 'Message' })}
                                register={register('message')}
                                rows={4}/>
                </div>
            </form>
        </UIModal>
    )
}
