import React from 'react'
import { PdfIcon } from '../../icons'
import { Document } from '../../types'

interface DocumentsProps {
    documents: Document[]
}

export const Documents = (
    { documents }: DocumentsProps
) => {
    if (!documents) return null

    const sortedDocuments = documents.sort((a, b) =>
        (a.file_display_name > b.file_display_name) ? 1 : -1
    )

    const Document = ({ document }) => (
        <li>
            <a href={document.file_distinct_name}
               className="ec-inline-flex ec-items-center ec-leading-8"
               target="_blank">
                <PdfIcon/>
                <span className="ec-ml-1">{document.file_display_name}</span>
            </a>
        </li>
    )

    return (
        <section>
            <ul>
                {sortedDocuments.map((document, index) =>
                    <Document key={index} document={document}/>
                )}
            </ul>
        </section>
    )
}
