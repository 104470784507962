import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { usePreferencesState } from '../../state'
import { UIRadio } from '@ui/components'
import { Section } from '@customerPortal/components'
import { DeliveryMethod } from '../../types'
import { useYup } from '@yup/context'

export interface DeliveryMethodProps {
    methods: DeliveryMethod[]
}

export const DeliveryMethods = (
    { methods }: DeliveryMethodProps
) => {
    const { register, formState: { errors } } = useFormContext()
    const { isLoading } = usePreferencesState()
    const { errorMessage } = useYup()

    if (!methods) return null

    return (
        <Section.Item title={
            <FormattedMessage id="deliveryMethods.defaultMethod"
                              defaultMessage="Default Delivery Method"/>}>
            <UIRadio error={errorMessage(errors, 'checkout.default_delivery_method')}>
                {methods.map(method =>
                    <UIRadio.Item key={method.code}
                                  register={register('checkout.default_delivery_method')}
                                  disabled={isLoading}
                                  value={method.code}
                                  label={method.title}/>
                )}
            </UIRadio>
        </Section.Item>
    )
}
