import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import clsx from 'clsx'
import { UIOmit } from '../../../types'

export type UIButtonIconProps = UIOmit<ComponentPropsWithoutRef<'button'>> & {
    ariaLabel: ReactNode
    size?: 'small' | 'medium' | 'large'
    color?: 'dark' | 'light'
}

export type UIButtonIconSize<T = string> = Record<UIButtonIconProps['size'], T>
export type UIButtonIconColor<T = string> = Record<UIButtonIconProps['color'], T>

const sizeVariant: UIButtonIconSize = {
    small: 'ec-p-1',
    medium: 'ec-p-2',
    large: 'ec-p-2.5'
}

const colorVariant: UIButtonIconColor = {
    dark: 'ec-text-gray-darker',
    light: 'ec-text-white'
}

const colorHoverVariant: UIButtonIconColor = {
    dark: 'hover:ec-text-red',
    light: 'hover:ec-text-red'
}

export const UIButtonIcon = (
    {
        ariaLabel,
        size = 'medium',
        color = 'dark',
        children,
        ...attributes
    }: UIButtonIconProps
) => (
    <button type="button" className={clsx(
        sizeVariant[size],
        colorVariant[color],
        !attributes.disabled && colorHoverVariant[color],
        attributes.disabled && 'ec-opacity-50 ec-cursor-not-allowed'
    )} {...attributes}>
        {children}
        <span className="ec-sr-only">
            {ariaLabel}
        </span>
    </button>
)
