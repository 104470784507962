import React, { useState, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import update from 'immutability-helper';
import { DraggableItem } from "./DraggableItem";
import {useWindowSize} from "@ui/hooks";
import {useSortingListState } from '../state'

export const List = ({selectedKey }) => {

    const {isMobile} = useWindowSize();
    const {setListData, listData} = useSortingListState();

    const [items, setItems] = useState(listData.map((item, index) => ({
        ...item,
        visualIndex: index + 1
    })));

    const moveItem = useCallback((dragIndex, hoverIndex) => {

        const dragItem = items[dragIndex];
        let newItems = update(items, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragItem],
            ],
        });

        newItems = newItems.map((item, index) => ({
            ...item,
            visualIndex: index + 1
        }));
        setListData(newItems);
        setItems(newItems);
    }, [items]);

    const findItem = useCallback((id) => {
        const item = items.find((item) => item.id === id);
        return {
            item,
            index: items.indexOf(item),
        };
    }, [items]);

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div>
                {items.map((item, index) => (
                    <DraggableItem
                        index={index}
                        items={items}
                        key={item.id}
                        id={item.id}
                        itemKey={item[selectedKey]}
                        visualIndex={item.visualIndex}
                        moveItem={moveItem}
                        findItem={findItem}
                    />
                ))}
            </div>
        </DndProvider>
    );
};
