import React, { useState } from "react";
import { FormattedMessage } from 'react-intl'
import { BranchStructure } from "./BranchStructure";
import { useInventoryFinderState } from "../../state";
import { UIMinusIcon, UIPlusIcon } from '@ui/icons'
import {BranchSkeleton } from './Skeleton/BranchSkeleton'

export const Recent = () => {
    const [showRecent, setShowRecent] = useState(false);
    const { recentBranches, branches, isBranchesLoading, isLoadingData } = useInventoryFinderState();

    const toggleShowRecent = () => {
        setShowRecent(!showRecent);
    };

    const symbol = (
        <div className="ec-ml-1">
            {showRecent ? <UIMinusIcon/> : <UIPlusIcon/>}
        </div>
    )

    if(isBranchesLoading || isLoadingData){
        return (<BranchSkeleton/>)
    }

    if(branches?.length === 0 || recentBranches.length === 0 ) return null;

    return (
        <div>
            <h2 onClick={toggleShowRecent} className={'ec-flex ec-items-center ec-cursor-pointer ec-leading-7'}>
                <FormattedMessage id="recent.branches.title" defaultMessage="Recent Branches"/> {symbol}
            </h2>
            {showRecent && <BranchStructure data={recentBranches} />}
        </div>
    );
};
