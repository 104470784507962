import React from 'react'
import { Product } from '../../../types'
import { getProductUrl } from '../../../utils'
import { useAnalytics } from '@analytics/hooks'
import { usePricing } from '@pricing/hooks'

type ProductNameProps = {
    product: Product
    size?: 'small' | 'large'
    link?: boolean
}

const sizeVariants = {
    small: 'ec-text-sm ',
    large: 'ec-text-lg ec-leading-6'
}

export const ProductName = (
    { product, size = 'small', link = true }: ProductNameProps
) => {
    const { trackProductClick } = useAnalytics()
    const { pricing } = usePricing(product)

    const productUrl = link && getProductUrl(product)

    const handleTrackProductClick = () => {
        void trackProductClick({ product, pricing })
    }

    return (
        <>
            {productUrl &&
                <a className={`!ec-text-gray-darker ec-m-0 ${sizeVariants[size]}`}
                   onClick={handleTrackProductClick}
                   href={productUrl.href}>
                    <span>{product.name}</span>
                </a>
            }
            {!productUrl &&
                <p className={`!ec-text-gray-darker ec-m-0 ${sizeVariants[size]}`}>
                    {product.name}
                </p>
            }
        </>
    )
}
