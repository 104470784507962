import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../../types'
import { UILoader } from '../../../Elements/UILoader/UILoader'

export const UITableBodyCellLoading = (
    { ...attributes }: UIOmit<ComponentPropsWithoutRef<'td'>>
) => (
    <td colSpan={100} {...attributes}>
        <UILoader/>
    </td>
)
