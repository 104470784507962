import React, { PropsWithChildren, ReactNode } from 'react'
import { UIGrid, UIGridSpan } from '@ui/components'

type CustomerPortalSectionItem = PropsWithChildren & {
    title?: ReactNode
    span?: UIGridSpan
    newLine?: boolean
}

export const Section = (
    { children }: PropsWithChildren
) => (
    <UIGrid element="section" spacing="large">
        {children}
    </UIGrid>
)

Section.Item = (
    { title, span = 'full', newLine = false, children }: CustomerPortalSectionItem
) => (
    <UIGrid.Span span={span} newLine={newLine}>
        <div className="ec-flex ec-flex-col ec-gap-4 ec-w-full">
            {title && <h2 className="ec-m-0">{title}</h2>}
            {children}
        </div>
    </UIGrid.Span>
)
