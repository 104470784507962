import React from 'react'
import { FormProvider } from 'react-hook-form'
import { DeliveryMethods } from '../Checkout/DeliveryMethods'
import { PaymentMethods } from '../Checkout/PaymentMethods'
import { InactiveCustomers } from './InactiveCustomers'
import { OrderConfirmationEmails } from '../Checkout/OrderConfirmationEmails'
import { usePreferencesState } from '../../state/index'
import { ResidioProPerksNumber } from './ResidioProPerksNumber'
import { UILoader, UIPanel } from '@ui/components'
import { SaveChangesButton } from './SaveChangesButton'
import { ShareCartPreferences } from './ShareCartPreferences'
import { Container, Section } from '@customerPortal/components'

export const CustomerPreferences = () => {
    const { onSubmit, form, query, preferences } = usePreferencesState()

    return (
        <FormProvider {...form}>
            <UILoader show={query.isLoading} size="large">
                {query.isError &&
                    <div>
                        <div>{query.error.message}</div>
                        {'Try refreshing the page or contact us if the problem persists.'}
                    </div>
                }

                {!query.isLoading && !query.isError &&
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <Container>
                            <Container.Content>
                                <UIPanel>
                                    <UIPanel.Body>
                                        <Section>
                                            <DeliveryMethods methods={preferences.meta.checkout.delivery_method_list}/>
                                            <PaymentMethods methods={preferences.meta.checkout.payment_method_list}/>
                                            <OrderConfirmationEmails/>
                                        </Section>
                                    </UIPanel.Body>
                                </UIPanel>
                                {preferences.data.inactiveCustomers.isInactiveCustomersEnabled &&
                                    <InactiveCustomers/>
                                }
                                {preferences.data?.residioProPerks?.visible &&
                                    <ResidioProPerksNumber/>
                                }
                                <ShareCartPreferences/>
                            </Container.Content>
                            <Container.Side>
                                <SaveChangesButton/>
                            </Container.Side>
                        </Container>
                    </form>
                }
            </UILoader>
        </FormProvider>
    )
}
