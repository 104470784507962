import { useContext, useState } from 'react'
import { SupersedeContext } from '../../context'
import { Part, Supersede } from '../../types'
import { UISelectOption } from '@ui/components'
import { uniqueObjectArray } from '@utils'

export const useSupersedes = (
    part: Part
) => {
    const { query, productNumber, setProductNumber } = getContext() || {}

    const supersedes = part.part_number === productNumber && query.data?.superseded_by || []
    const errors = query.data?.errors || null
    const initialSupersede = supersedes[0] || null

    const [selectedSupersede, setSelectedSupersede] = useState<Supersede | null>(initialSupersede)

    const products = supersedes
        .filter(({ product }) => !!product)
        .map(({ product }) => product)

    const supersedeOptions: UISelectOption[] = uniqueObjectArray(
        supersedes.map(supersede => ({
            value: supersede.product_number,
            label: supersede.product?.name || supersede.product_description
        })),
        'value'
    )

    const selectSupersede = async (productNumber: Supersede['product_number']) => {
        const supersede = supersedes.find(
            supersede => supersede.product_number === productNumber
        )

        if (!supersede) console.warn(`Could not find supersede by product number "${productNumber}"`)
        setSelectedSupersede(supersede || initialSupersede)
    }

    const loadSupersedes = async () => {
        setProductNumber(part.part_number)
    }

    return {
        supersedes,
        products,
        errors,
        query,
        loadSupersedes,
        supersedeOptions,
        selectedSupersede: selectedSupersede || initialSupersede,
        selectedSupersedeId: selectedSupersede?.product_number,
        selectSupersede
    }
}

const getContext = () => {
    const context = useContext(SupersedeContext)

    if (!context) {
        console.warn('useSupersedes must be used within the SupersedeProvider')
    }
    return context
}
