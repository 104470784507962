import { Branch } from '../../types'
import { useIntl } from 'react-intl'
import { useCallback, useMemo } from 'react'

type BranchHour = {
    key: string
    day: string
    hours: string
}

export const useHours = (branch: Branch) => {
    const { formatMessage, formatTime } = useIntl()

    const labels = useMemo(() => ({
        mon: formatMessage({ id: 'branch.day.mon', defaultMessage: 'Monday' }),
        tue: formatMessage({ id: 'branch.day.tue', defaultMessage: 'Tuesday' }),
        wed: formatMessage({ id: 'branch.day.wed', defaultMessage: 'Wednesday' }),
        thu: formatMessage({ id: 'branch.day.thu', defaultMessage: 'Thursday' }),
        fri: formatMessage({ id: 'branch.day.fri', defaultMessage: 'Friday' }),
        sat: formatMessage({ id: 'branch.day.sat', defaultMessage: 'Saturday' }),
        sun: formatMessage({ id: 'branch.day.sun', defaultMessage: 'Sunday' })
    }), [])

    const getFormattedTime = useCallback((hour: string) => {
        try {
            const date = new Date()

            const hours = parseInt(hour.slice(0, 2), 10)
            const minutes = parseInt(hour.slice(2), 10)

            date.setHours(hours, minutes, 0, 0)
            return formatTime(date)
        } catch (error) {
            console.error(error)
            return hour
        }
    }, [])

    const getHours = useCallback((key: string) => {
        const day = labels[key] || key
        const open = branch.hours[`${key}_open`]
        const close = branch.hours[`${key}_close`]

        const hours = open && close
            ? getFormattedTime(open) + ' - ' + getFormattedTime(close)
            : formatMessage({ id: 'branch.day.closed', defaultMessage: 'Closed' })

        return { key, day, hours } as BranchHour
    }, [])

    const hours = useMemo<BranchHour[]>(() => (
        Object.keys(labels).map(getHours)
    ), [branch.branch_id])

    const today = useMemo<BranchHour>(() => {
        const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
        const today = days[new Date().getDay()]

        return {
            ...getHours(today),
            day: formatMessage({ id: 'branch.day.today', defaultMessage: 'Today' })
        }
    }, [branch.branch_id])

    return {
        today,
        hours
    }
}
