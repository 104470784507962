import React from 'react'
import { cn } from '@utils'
import { useIntl } from 'react-intl'
import { useWindowSize } from '../../../../Various'
import {UIWarning} from "../../../../Ui";
import {MapPinIcon} from "../../../icons";

export const ErrorBranchLocator = () => {
    const intl = useIntl()
    const { isMobile } = useWindowSize()

    const branchLabel = intl.formatMessage({ id: 'branch.header.top', defaultMessage: 'Your Current Branch' })
    const branchLabelMobile = intl.formatMessage({ id: 'branch.header.top.mobile', defaultMessage: 'Branch' })
    const error = intl.formatMessage({ id: 'branch.header.top.mobile.loading', defaultMessage: 'Branch Errors' })

    return (
        <div className={cn('ec-overflow-visible ecmd:ec-w-36 ec-w-16 ec-flex-col ec-relative ec-h-full lg:ec-h-10')}>
            <div className={cn('ec-flex ec-items-center ec-justify-around', {'ec-flex-wrap': isMobile})}>
                <MapPinIcon/>
                <div
                    className={cn('ec-absolute ec-text-white', {'ec-top-4 ec-right-0': !isMobile}, {'-ec-top-2 -ec-right-2': isMobile})}
                    title={intl.formatMessage({
                        id: 'account.error',
                        defaultMessage: 'Account Issues'
                    })}>
                    <UIWarning/>
                </div>
                <div className="lg:ec-text-left lg:ec-w-full">
                    <div className={cn('ec-text-white ec-text-xs')}>{isMobile ? branchLabelMobile : branchLabel}</div>
                    {!isMobile && <div className={cn('ec-text-white ec-text-s ec-truncate')}>
                        {error}
                    </div>}
                </div>
            </div>
        </div>
    )
}
