import React, { useState, KeyboardEvent, ClipboardEvent, ChangeEvent } from 'react'
import { AddToCartButton, AddToCartButtonProps } from './AddToCartButton'
import Skeleton from 'react-loading-skeleton'
import { UIInput } from '@ui/components'
import { usePricing } from '@pricing/hooks'
import { useCustomer } from '@customer/hooks'

type AddToCartQtyProps = {
    product: AddToCartButtonProps['product']
    onAddToCart?: AddToCartButtonProps['onAddToCart']
    size?: 'small' | 'medium' | 'large'
    defaultQty?: number
    disabled?: boolean
}

export const AddToCartQty = (
    { size = 'medium', defaultQty = 1, product, onAddToCart, disabled }: AddToCartQtyProps
) => {
    const [qty, setQty] = useState(defaultQty)
    const { isLoggedIn } = useCustomer()
    const { query } = usePricing(product)
    const isLoading = query.isFetching

    const handleQtyChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        setQty(value && value > 0 ? value : 1)
    }

    const handleInputValidation = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key.charCodeAt(0) < 48) {
            event.preventDefault()
        }
    }

    const handlePasteValidation = (event: ClipboardEvent<HTMLInputElement>) => {
        const value = Number(event.clipboardData.getData('text'))

        if (!value || value < 0) {
            event.preventDefault()
        }
    }

    return (
        <div className="ec-flex ec-leading-none">
            {isLoading &&
                <Skeleton containerClassName="ec-flex-1"
                          className="ec-w-full ec-leading-10 ec-border-y ec-border-transparent"/>
            }
            {!isLoading && <>
                <div className="ec-flex-grow-0 ec-flex-shrink-0 ec-w-12 ec-mr-3">
                    <UIInput size={size}
                             type="number"
                             align="center"
                             value={qty}
                             disabled={!isLoggedIn || disabled}
                             onChange={handleQtyChange}
                             onPaste={handlePasteValidation}
                             onKeyDown={handleInputValidation}/>
                </div>
                <AddToCartButton product={product}
                                 onAddToCart={onAddToCart}
                                 disabled={!isLoggedIn || disabled}
                                 qty={qty}
                                 size={size}/>
            </>}
        </div>
    )
}
