import React, { ChangeEvent } from 'react'
import { useAccount } from '@account/hooks'
import { getAccountName } from '@account/utils'
import { UISelect } from '@ui/components'

export const AccountJob = () => {
    const { account, accounts, switchAccount, switchJob, job } = useAccount()
    const accountOptions = []

    accounts.map((account) => {
        accountOptions.push({
            label: getAccountName(account),
            value: account.customer_id
        })
    })

    const jobOptions = [{
        label: 'Job Account',
        value: ''
    }]

    account?.jobs?.map((job) => (jobOptions.push({
        label: job.name,
        value: job.id
    })))

    const handleAccountChange = (event: ChangeEvent<HTMLSelectElement>) => {
        switchAccount.mutate({ accountId: parseInt(event.target.value) })
    }

    const handleJobChange = (event: ChangeEvent<HTMLSelectElement>) => {
        switchJob.mutate({ jobId: event.target.value })
    }

    if(!account?.customer_id) return null

    return (
        <div className={'ec-border-b ec-border-solid ec-border-gray-400 ec-pb-4 lg:ec-border-0 lg:ec-pb-0'}>
            <div className="ec-flex ec-flex-col ec-gap-4 ec-w-full">
                {account?.customer_id && <UISelect onChange={handleAccountChange}
                                                   disabled={accountOptions.length === 1}
                                                   value={account?.customer_id}
                                                   options={accountOptions}/>}
                {jobOptions.length > 1 &&
                    <UISelect onChange={handleJobChange}
                              value={job?.id ? job.id : ''}
                              options={jobOptions}/>
                }
            </div>
        </div>
    )
}

AccountJob.whyDidYouRender = true
