import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { UIOmit } from '../../../../types'
import intl from '../intl'

type EmptyMessageProps = {
    message?: ReactNode
}

const EmptyMessage = (
    { message }: EmptyMessageProps
) => (
    <div className="ec-flex ec-justify-center ec-w-full ec-p-4">
        {message || <FormattedMessage {...intl.empty}/>}
    </div>
)

export const UITableBodyCellEmpty = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'td'>>
) => (
    <td colSpan={100} {...attributes}>
        {!!children &&
            <EmptyMessage message={children}/>
        }
        {!children &&
            <EmptyMessage/>
        }
    </td>
)
