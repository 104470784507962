import { GraphQlErrorResult, request } from '@model/api'
import { switchAccountQuery } from './query'
import { Account } from '../types'

export type SwitchAccountApiParams = {
    accountId: number
}

type SwitchAccountApiResult = {
    switchAccount: Account | null
}

export type SwitchAccountResult = GraphQlErrorResult & {
    accountId: number
}

export const switchAccountApi = async (
    { accountId }: SwitchAccountApiParams
): Promise<SwitchAccountResult> => (
    request<SwitchAccountApiResult>(switchAccountQuery, { accountId }).then(data => ({
        accountId: data.data?.data?.switchAccount?.customer_id,
        errors: data.data?.errors || null
    }))
)
