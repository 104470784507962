import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ProductDrawer, SupersedeBadge } from '@catalog/components'
import { useSupersedes } from '../../../hooks'
import { PartsListProductSelect } from './PartsListProductSelect'
import { Part } from '../../../types'
import { useAnalytics } from '@analytics/hooks'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { ADD_TO_CART_LIST_DATA } from '@watsco/analytics'
import intl from '../intl'

type PartsListSupersedeProps = {
    part: Part
}

export const PartsListSupersede = (
    { part }: PartsListSupersedeProps
) => {
    const { trackProductListView } = useAnalytics()

    const {
        supersedeOptions,
        selectedSupersede,
        selectedSupersedeId,
        selectSupersede,
        loadSupersedes,
        products,
        query
    } = useSupersedes(part)

    useEffect(() => {
        !!products.length && trackProductListView({
            listData: {
                list_id: 'supersedes_part_list',
                list_name: 'Supersedes Part List'
            },
            products: products.map(product => ({ product }))
        })
    }, [part.part_number, products.map(({ pim_id}) => pim_id).join(',')])

    const ProductSelect = () => (
        supersedeOptions.length > 1
            ? <PartsListProductSelect
                productOptions={supersedeOptions}
                product={selectedSupersedeId}
                onProductSelect={e => selectSupersede(e.target.value)}
                alert={<FormattedMessage {...intl.multipleSupersede}/>}/>
            : null
    )

    return (
        <AnalyticsProvider area={AnalyticsArea.Supersede}
                           listData={ADD_TO_CART_LIST_DATA.SUPERSEDES}>
            <ProductDrawer product={selectedSupersede?.product || null}
                           title={<FormattedMessage {...intl.supersede} />}
                           content={{ top: <ProductSelect/> }}
                           loading={query.isFetching}
                           onOpen={loadSupersedes}>
                <SupersedeBadge/>
            </ProductDrawer>
        </AnalyticsProvider>
    )
}
