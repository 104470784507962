export default {
    bu: {
        id: 'product.numbers.bu',
        defaultMessage: 'Product'
    },
    mfg: {
        id: 'product.numbers.mfg',
        defaultMessage: 'MFG'
    }
}
