import React, {memo, useEffect, useState} from "react";
import { UIButton, UIButtonIcon, UIInput } from '@ui/components'
import {cn, isPropsAreEqual} from "@utils";
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { UITrashIcon } from '@ui/icons'
import { CartItem } from '../../types'
import { AnalyticsProps } from '@analytics/types'
import { getProductUrl } from '@catalog/utils'
import {useCustomer} from '@customer/hooks';
import { useCartActions } from '../../hooks'

type MiniCartItemProps = AnalyticsProps & {
    item: CartItem
}

export const MiniCartItem = memo((
    { item }: MiniCartItemProps
) => {
    const [inputQuantity, setInputQuantity] = useState<number>(item.quantity);
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const { updateCartItems, removeCartItem } = useCartActions()
    const {customerData} = useCustomer();
    const intl = useIntl();

    //@ts-ignore
    const customerCanViewPrices = customerData?.permissions?.canViewPrices;
    const canViewPrices = customerCanViewPrices
        && item?.prices?.price?.value !== null
        && item?.prices?.price?.value !== undefined;
    const productUrl = getProductUrl(item.product)

    useEffect(() => {
        setInputQuantity(item.quantity)
        setShowUpdateButton(false);
    }, [item]);

    const handleQuantityChange = (e) => {
        const newQuantity = parseInt(e.target.value, 10);
        setInputQuantity(newQuantity);

        if (newQuantity !== item.quantity) {
            setShowUpdateButton(true);
        } else {
            setShowUpdateButton(false);
        }
    };

    return (
        <div className={cn('ec-border-b ec-border-solid ec-border-gray-400 my-2 ec-mt-4')}>
            <div className={cn("flex py-4")}>
                <a className={cn('ec-w-14 ec-h-14 ec-mr-2')} href={productUrl.href}>
                    <img className={cn('object-contain')} src={item.product.thumbnail.url} alt={item.product.name}/>
                </a>
                <a className={cn('ec-text-gray-darker hover:ec-text-gray-darker')} href={productUrl.href}>{item.product.name}</a>
            </div>

            <div className={cn("ec-flex ec-justify-between ec-items-center ec-pb-4 ec-mb-1 ec-pl-14")}>
                {canViewPrices &&
                    <div>
                        <strong className={cn('ec-text-base ec-font-bold')}>
                            {item.prices.price.value > 0
                                ? <FormattedNumber style="currency"
                                                   value={item.prices.price.value}
                                                   currency={item.prices.price.currency} />
                                : '-'
                            }
                        </strong>
                    </div>
                }

                <div className={''}>
                    <div className="ec-flex ec-items-center">
                        <span className="ec-mr-2">
                            <FormattedMessage id="cart.item.qty" defaultMessage={'Qty'}/>
                        </span>
                        <div className="ec-w-10">
                            <UIInput type="number"
                                     size="small"
                                     align="center"
                                     value={inputQuantity ?? ''}
                                     onChange={handleQuantityChange}/>
                        </div>
                        <UIButtonIcon ariaLabel={<FormattedMessage id="cart.item.delete" defaultMessage={'Remove'}/>}
                                      onClick={()=>removeCartItem.mutate(item)}>
                            <UITrashIcon variant="solid"/>
                        </UIButtonIcon>
                    </div>
                    <div className={cn('ec-mb-1 w-full')}></div>

                    {showUpdateButton &&<UIButton
                        fullWidth={true}
                        size={"small"}
                        variant={'contained'}
                        onClick={() => updateCartItems.mutate([{ uid: item.uid, quantity: inputQuantity }])}>
                        {intl.formatMessage({
                            id: "cart.item.update",
                            defaultMessage: "Update"
                        })}
                    </UIButton>}
                </div>

            </div>

        </div>
    );
}, isPropsAreEqual);

MiniCartItem.whyDidYouRender = true;
