import React, {memo, useEffect, useState} from 'react';
import { Submenu } from "./Submenu";
import { useMenuState, MenuItemType } from "../../../../state";
import { useWindowSize } from "../../../../../Various";
import { UIBadge } from '@ui/components'
import { cn } from "@utils";
import {useCustomerData} from "../../../../../Customer";
import {MenuButton} from "./MenuButton";
import {isPropsAreEqual} from "@utils";

type MenuItemPropsType = {
    data: MenuItemType;
    allItem?: boolean;
};

export const MenuItem = memo(({ data, allItem = false }: MenuItemPropsType) => {
    const { handleClick, isOpen, currentSubmenuOpened, menuStyle, currentSubmenu } = useMenuState();
    const { isMobile } = useWindowSize();
    const {isLoggedIn} = useCustomerData();

    const [isAnimating, setIsAnimating] = useState(false);
    const [isMenuFullyOpen, setIsMenuFullyOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);

            const timer = setTimeout(() => {
                setIsAnimating(false);
                setIsMenuFullyOpen(true);
            }, 500);

            return () => clearTimeout(timer);
        } else {
            setIsMenuFullyOpen(false);
        }
    }, [isOpen]);

    let listItemStyle = '';
    if (!isMobile && !allItem) {
        listItemStyle = isMenuFullyOpen ? menuStyle.menuItemVisible : (isOpen && isAnimating ? menuStyle.menuItemVisible : menuStyle.menuItemHidden);
    }

    let currentSubmenuStyle = menuStyle.submenuClose;

    if (currentSubmenu?.level === data.level) {
        currentSubmenuStyle = 'relative';
    }

    if (currentSubmenu?.id >= data.id) {
        currentSubmenuStyle = 'relative';
    }

    if (!isOpen) return null;

    return (
        <li className={cn(listItemStyle, currentSubmenuOpened(data) ? `${menuStyle.slide} ${menuStyle.submenuOpen}` : currentSubmenuStyle)}>
            <a href={isLoggedIn && data.href_logged_in ? data.href_logged_in : data.href}
               className={cn(menuStyle.generic, currentSubmenuOpened(data) ? 'ec-flex' : 'ec-hidden', data.class)}
               onClick={(e) => handleClick(e, data)}>
                {data.name}
                {data?.badges?.includes('new') &&
                    <div className="ec-ml-2 ec-inline-block">
                        <UIBadge>New</UIBadge>
                    </div>
                }
                {data?.children?.length ? (
                    <svg className={isMobile ? menuStyle.nextArrowMobile : menuStyle.nextArrow}
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth={3}
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                ) : null}
            </a>
            {data.children && <Submenu data={data.children}/>}
        </li>
    );
}, isPropsAreEqual);

MenuButton.whyDidYouRender = true;
