import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { usePreferencesState } from '../../state'
import { FormattedMessage, useIntl } from 'react-intl'
import { UIInput, UIPanel } from '@ui/components'
import { Section } from '@customerPortal/components'
import { useYup } from '@yup/context'

export const ResidioProPerksNumber = () => {
    const { formatMessage } = useIntl()
    const { errorMessage } = useYup()
    const { isLoading } = usePreferencesState()
    const { register, formState: { errors }, watch, setValue } = useFormContext()

    const emptyValue = watch('residioProPerks.value')

    useEffect(() => {
        if (emptyValue === '0') {
            setValue('residioProPerks.value', '')
        }
    }, [])

    return (
        <UIPanel>
            <UIPanel.Body>
                <Section>
                    <Section.Item title={
                        <FormattedMessage id="residioProPerks.title" defaultMessage="Resideo Pro Perks Number"/>
                    }>
                        <UIInput
                            register={register('residioProPerks.value')}
                            type={'number'}
                            value={emptyValue || ''}
                            name={'residioProPerks.value'}
                            disabled={isLoading}
                            label={formatMessage({
                                id: 'residioProPerks.value.label',
                                defaultMessage: 'Resideo Pro Number'
                            })}
                            error={errorMessage(errors, 'residioProPerks.value')}/>
                    </Section.Item>
                </Section>
            </UIPanel.Body>
        </UIPanel>
    )
}
