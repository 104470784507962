import { QueryClient, QueryCache } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const getQueryClient = () => {
    if (window.queryClient) {
        return window.queryClient
    }

    window.queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error, query) => {
                console.error('API error', error)
                toast.error(query.meta?.errorMessage as string ?? 'Something went wrong')
            }
        }),
        defaultOptions: {
            queries: {
                retry: 1,
                staleTime: 1000 * 60 * 10, // 10 min
                cacheTime: 1000* 60 * 15, // 15 min,
                refetchOnWindowFocus: false
            }
        }
    })
    return window.queryClient
}
