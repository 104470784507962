import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ColumnDef } from '@tanstack/react-table'
import { InventorySummary } from '@inventory/components'
import { UILoader, UITable } from '@ui/components'
import { useUITable } from '@ui/hooks'
import { ProductName } from '@catalog/components'
import { CatalogListProvider } from '@catalog/context'
import { AnalyticsProvider } from '@analytics/context'
import { AnalyticsArea } from '@analytics/types'
import { useCustomer } from '@customer/hooks'
import { Pricing } from '@pricing/components'
import intl from './intl'
import { usePartsList } from '../../hooks'
import { Part } from '../../types'
import { getProduct } from '../../utils'
import { PartsListActions, PartsListSubstitute, PartsListSupersede } from './PartsListTable/index'

export const PartsListTable = () => {
    const { isLoggedIn } = useCustomer()
    const { models, parts, isLoading, modelWidQuery, getProducts } = usePartsList()

    const isWid = !!models.length
    const isWidLoading = modelWidQuery.isFetching
    const isWidFetched = modelWidQuery.isSuccess || modelWidQuery.isError

    const columns = useMemo<ColumnDef<Part>[]>(() => [
        {
            accessorKey: 'part_number',
            header: () => <FormattedMessage {...intl.columns.partNumber}/>
        },
        {
            accessorKey: 'manufacturer',
            header: () => <FormattedMessage {...intl.columns.manufacturer}/>
        },
        {
            id: 'name',
            header: () => <FormattedMessage {...intl.columns.name}/>,
            disableFilters: true,
            cell: ({ row }) => {
                const part = row.original
                const product = getProduct(part)

                return (
                    <AnalyticsProvider area={AnalyticsArea.PartsList}
                                       context={{ product, index: row.index }}>
                        <div className="ec-flex ec-flex-col ec-gap-1 ec-w-full">
                            <ProductName product={product}/>
                            <div className="ec-flex ec-gap-2 ec-w-full ec-flex-wrap">
                                {part.is_superseded && <PartsListSupersede part={part}/>}
                                {part.has_crossref && <PartsListSubstitute part={part}/>}
                            </div>
                        </div>
                    </AnalyticsProvider>
                )
            },
            meta: { cellContainer: false }
        },
        {
            id: 'inventory',
            header: () => <FormattedMessage {...intl.columns.inventory}/>,
            cell: ({ row }) => {
                const product = row.original.product || null
                if (!product) return null

                return <InventorySummary product={product}/>
            },
            meta: { linearize: false }
        },
        {
            id: 'pricing',
            header: () => <FormattedMessage {...intl.columns.pricing}/>,
            cell: ({ row }) => {
                const product = row.original.product || null
                if (!product) return null

                return <Pricing product={product}/>
            },
            meta: { linearize: false }
        },
        {
            id: 'actions',
            cell: ({ row }) => {
                const product = row.original.product || null

                return (
                    <AnalyticsProvider area={AnalyticsArea.PartsList}
                                       context={{ product, index: row.index }}>
                        <PartsListActions product={product}/>
                    </AnalyticsProvider>
                )
            },
            meta: { linearize: false }
        }
    ], [])

    const table = useUITable({
        columns,
        data: parts || [],
        initialState: {
            columnVisibility: {
                inventory: isLoggedIn
            }
        }
    })

    return (
        <CatalogListProvider products={getProducts()}>
            <div className="ec-flex-auto ec-w-full">
                <UILoader show={isWid && isWidLoading} size="extra-large"/>
                {(!isWid || isWidFetched) &&
                    <UITable table={table}
                             variant="bordered"
                             loading={isLoading}
                             noResultsMessage={<FormattedMessage {...intl.noResults}/>}/>
                }
            </div>
        </CatalogListProvider>
    )
}
