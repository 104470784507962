import { getNestedValue } from './object'

export const uniqueArray = (array: (string | number)[]) => (
    array.filter((value, index, array) => array.indexOf(value) === index)
)

export const uniqueObjectArray = <T extends Record<string, any>>(array: T[], key: NestedKeyOf<T>): T[] => (
    [...new Map(array.map(item =>
        [getNestedValue(item, key), item]
    )).values()]
)
