import { GraphQlErrorResult, request } from '@model/api'
import { Account } from '../types'
import { accountAddressFragment, accountFragment, getAccountQuery } from './query'

export type AccountApiResponse = {
    accounts: Account[]
    currentAccount: number
    currentJob: string | null
}

export type AccountResult = AccountApiResponse & GraphQlErrorResult

export const getAccountApi = async (): Promise<AccountResult> => (
    request<AccountApiResponse>([
        getAccountQuery,
        accountFragment,
        accountAddressFragment
    ]).then(data => ({
        accounts: data.data?.data?.accounts,
        currentAccount: data.data?.data?.currentAccount,
        currentJob: data.data?.data?.currentJob,
        errors: data.data?.errors || null
    }))
)
