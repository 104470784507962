import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { ColumnDef, Row } from '@tanstack/react-table'
import { UIDateTime, UITable } from '@ui/components'
import { WingmanList } from '../../types'
import { useWingmanList } from '../../hooks'
import { Toolbar } from './Table/index'
import { useUITable } from '@ui/hooks'
import intl from './intl'

export const Table = () => {
    const { query, filters, isCompanyUser, getLocationParams } = useWingmanList()
    const history = useHistory()

    const columns = useMemo<ColumnDef<WingmanList>[]>(() => [
        {
            accessorKey: 'case_number',
            header: () => <FormattedMessage {...intl.fields.caseNumber}/>,
            cell: ({ row, getValue }) => (
                <Link to={getLocationParams(row.original)} onClick={e => e.stopPropagation()}>
                    {getValue<string>()}
                </Link>
            )
        },
        {
            accessorKey: 'subject',
            header: () => <FormattedMessage {...intl.fields.subject}/>
        },
        {
            accessorKey: 'model_number',
            header: () => <FormattedMessage {...intl.fields.model}/>
        },
        {
            accessorKey: 'status',
            header: () => <FormattedMessage {...intl.fields.status}/>
        },
        {
            accessorKey: 'created_date',
            header: () => <FormattedMessage {...intl.fields.createdDate}/>,
            cell: ({ getValue }) => <UIDateTime date={getValue<string>()}/>
        },
        {
            accessorKey: 'contact',
            header: () => <FormattedMessage {...intl.fields.contact}/>,
            cell: ({ row }) => row.original?.contact?.name || row.original.supplied_name
        }
    ], [])

    const handleRowClick = (row: Row<WingmanList>) => {
        history.push(getLocationParams(row.original))
    }

    const table = useUITable({
        columns,
        data: query.data?.wingmanList || [],
        dataPagination: true,
        state: {
            columnVisibility: {
                contact: filters.accounts
            }
        }
    })

    return (
        <UITable table={table}
                 loading={query.isLoading}
                 noResultsMessage={<FormattedMessage {...intl.noResults}/>}
                 toolbar={isCompanyUser && <Toolbar/>}
                 onRowClick={handleRowClick}/>
    )
}
