import React, { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePartsList } from '../../hooks'
import { UISelect, UISelectEmptyOption } from '@ui/components'
import intl from './intl'

export const PartsListModels = () => {
    const { models, allParts, loadPartsList } = usePartsList()
    if (!models || !models.length) return null

    const onModelChange = (event: ChangeEvent<HTMLSelectElement>) => {
        loadPartsList({ modelWid: parseInt(event.target.value) })
    }

    const emptyOption: UISelectEmptyOption = allParts.length
        ? { disabled: true }
        : true

    return (
        <div className="lg:ec-flex-1 lg:ec-max-w-1/2">
            <UISelect label={<FormattedMessage {...intl.models}/>}
                      onChange={onModelChange}
                      options={models.map(model => ({
                          value: model.model_wid.toString(),
                          label: model.model_number
                      }))}
                      size="large"
                      required
                      emptyOption={emptyOption}/>
        </div>
    )
}
