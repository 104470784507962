import { GraphQlErrorResult, request } from '@model/api'
import { shareSaveCartQuery } from './queries'
import { SaveCart } from '@saveCart/types'

export type ShareSaveCartParams = {
    saveCartId: number
    sharedWith: string[]
    message: string
}

export type ShareSaveCartApiResult = {
    shareSaveCart: {
        save_cart: SaveCart
    }
}

export type ShareSaveCartResult = GraphQlErrorResult & {
    saveCart: SaveCart | null
}

export const shareSaveCartApi = async (
    params: ShareSaveCartParams
): Promise<ShareSaveCartResult> => (
    request<ShareSaveCartApiResult>(shareSaveCartQuery, params).then(data => ({
        saveCart: data.data?.data?.shareSaveCart?.save_cart || null,
        errors: data.data?.errors || null
    }))
)
