import { IntlFormatters } from '@formatjs/intl/src/types'

export const dummyFormatMessage = (
    ...params: Parameters<IntlFormatters['formatMessage']>
): string => {
    const descriptor = params[0] ?? null
    const values = params[1] ?? null

    if (!values) {
        return descriptor.defaultMessage.toString()
    }

    return Object.entries(values).reduce((acc, [key, value]) => {
        return acc.replace(key, value.toString())
    }, descriptor.defaultMessage.toString());
}
