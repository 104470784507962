import React, {useEffect} from 'react'
import { MenuItem } from './Elements/MenuItem'
import { MenuButton } from './Elements/MenuButton'
import { useMenuState } from '../../../state'
import {CategoryMenuStyle} from "../../../state/context/CategoryMenuStyle";
import {cn} from "@utils";

export const MainMenu = () => {
    const { menuData, isOpen, setMenuStyle, menuStyle, config } = useMenuState()

    useEffect(()=> {
        setMenuStyle(CategoryMenuStyle);
    }, [])

    if(!menuStyle) return null;

    return (
        <div className={`ec-overflow-visible ec-w-28 ec-h-7 ${config.menuClass}`}>
            <MenuButton text={' Categories'} />
            <ul className={cn(`ec-w-[440px] ec-overflow-auto ${menuStyle.slide} ${isOpen ? menuStyle.open : menuStyle.close}`, {'ec-h-dvh':isOpen })}>
            {menuData.children.map((category, index) => (
                    <MenuItem key={index} data={category} />
                ))
            }
            </ul>
        </div>
    )
}

MainMenu.whyDidYouRender = true
