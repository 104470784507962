import React from 'react'
import { FormattedMessage } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import { formatPhoneNumber } from '@utils'
import { useBranch, useBranchInfo } from '../../../hooks'
import { ErrorBoundary } from '@app/components'
import { ErrorMessage } from '@ui/components'

export const BranchInfo = () => {
    const { isBranchLoading, currentBranch, getBranchEmail } = useBranch()
    const { getDirectionsLink, canTextBranch, canEmailBranch } = useBranchInfo()

    const phoneNumber = formatPhoneNumber(currentBranch?.phone)

    return (
        <ErrorBoundary fallback={<ErrorMessage/>}>
            <div className="ec-flex ec-flex-col ec-text-sm ec-leading-6">
                {!isBranchLoading
                    ? <>
                        <a href={getDirectionsLink(currentBranch)} target="_blank">
                            <FormattedMessage id="branch.contact.directions" defaultMessage="Get directions"/>
                        </a>
                        {canTextBranch(currentBranch) &&
                            <a href={`sms:${phoneNumber}`}>
                                <FormattedMessage id="branch.contact.text" defaultMessage="Text the branch"/>
                            </a>}
                        {canEmailBranch(currentBranch) &&
                            <a href={`mailto:${getBranchEmail(currentBranch)}`}>
                                <FormattedMessage id="branch.contact.email" defaultMessage="Email the branch"/>
                            </a>
                        }
                    </>
                    : <Skeleton count={3} className="ec-w-1/3"/>
                }
            </div>
        </ErrorBoundary>
    )
}
