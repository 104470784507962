import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useDebounce = <S = undefined>(
    initialState: S | (() => S),
    delay = 200
): [S, S, Dispatch<SetStateAction<S>>] => {
    const [value, setValue] = useState(initialState)
    const [debouncedValue, setDebouncedValue] = useState(initialState)
    let timeout: NodeJS.Timeout

    useEffect(() => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(timeout)
        }
    }, [value, delay])

    return [value, debouncedValue, setValue]
}
